import React from "react";

const SomethingWentWrongAlert = () => (
  <svg
    width="64px"
    height="56px"
    viewBox="0 0 64 56"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>ED3F30AF-9C01-41C5-AD6B-EDB2ADC385B2</title>
    <desc>Created with sketchtool.</desc>
    <defs>
      <path
        d="M0,59.272728 L64,59.272728 L32,4 L0,59.272728 Z M34.9090919,50.545454 L29.09091,50.545454 L29.09091,44.727272 L34.9090919,44.727272 L34.9090919,50.545454 Z M34.9090919,38.9090919 L29.09091,38.9090919 L29.09091,27.272728 L34.9090919,27.272728 L34.9090919,38.9090919 Z"
        id="path-1"
      />
    </defs>
    <g
      id="Error-States"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Revenue-Reporting-Error"
        transform="translate(-753.000000, -812.000000)"
      >
        <g
          id="Group-5"
          transform="translate(785.000000, 840.000000) scale(-1, 1) translate(-785.000000, -840.000000) translate(752.000000, 807.000000)"
        >
          <g id="Group-6" transform="translate(0.090909, 0.046512)">
            <g id="Icon-Copy" transform="translate(0.909091, 0.953488)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <use
                id="Shape"
                fill="#000000"
                fillRule="nonzero"
                xlinkHref="#path-1"
              />
              <g id="Icon-color" mask="url(#mask-2)" fill="#DC3545">
                <rect id="Swatch" x="0" y="0" width="64" height="64" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SomethingWentWrongAlert;
