import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import PropTypes from "prop-types";
import queryString from "query-string";

import { UserContext } from "../auth";
import {
  constructAdminAPIEndPoint,
  escapeSearchText,
  fetchHandler,
  getCount,
  getLastPage,
  getUTCDateTime,
  prepareArgsForFetchHandler,
  trackCustomActionsInDD,
  getErrorMessage,
} from "../../../../utils";
import ErrorMessages from "../../DisplayError/ErrorMessages";
import { get, isEmpty, sortBy, join } from "lodash";
import settings from "../../../../settings";
import { fetchDataset } from "../../../../utils/datasetapi";
import { toast } from "react-toastify";
import ToastMsg from "../../components/Utilities/ToastMsg";

const OrderContext = createContext();

const placementsFields = ["deals_count", "id", "lastmod", "name"];
/*
  ad_request_interval, integration_type, line_item_id -
  Pubs are not expected to view configurations on PXLite type placements yet.
 */
const placementsOmitFields = [
  "ad_request_interval",
  "adunits_count",
  "deals_count",
  "integration_type",
  "line_item_id",
  "networks_count",
  "order_id",
];

const prepareRowObject = (details) => {
  const tableData = details.placementsResponse.data.map((item) => ({
    name: item.name || "-",
    id: item.id || "-",
    deals_count: item.deals_count || 0,
    lastmod: get(item, "lastmod") ? getUTCDateTime(get(item, "lastmod")) : "-",
  }));
  return tableData;
};

const updateStates = (
  response,
  responseType,
  {
    setPlacementDetails,
    setDisplayError,
    setErrorMessage,
    setLoader,
    setRowDetails,
    prepareList,
  },
  error = {}
) => {
  const modifyState = () => {
    setPlacementDetails(response.placementsResponse.data);
    setDisplayError(false);
  };
  if (responseType === "success") modifyState();
  else {
    setDisplayError(true);
    setErrorMessage(error);
  }
  setLoader(true);
  setRowDetails(response);
  prepareList(response);
};

const initialLoad = (params) => {
  let urlParams = [
    { key: "page", value: params.page },
    { key: "page_size", value: params.sizePerPageValue },
    { key: "ordering", value: "-lastmod" },
  ];
  const { fetchPlacements, queryParams } = params;
  if (queryParams.search) {
    urlParams = [
      ...urlParams,
      { key: "search", value: escapeSearchText(queryParams.search) },
    ];
  }
  const paramsToFetchFirstPlacement = [
    { key: "page", value: 1 },
    { key: "page_size", value: 1 },
  ];
  const promises = [
    queryParams.search
      ? fetchPlacements(paramsToFetchFirstPlacement, true, false)
      : null,
    fetchPlacements(urlParams, !queryParams.search, true),
  ];

  return Promise.all(promises)
    .then(([firstPlacementResponse, placementsResponse]) => ({
      firstPlacementResponse,
      placementsResponse,
    }))
    .then((response) => {
      if (response.placementsResponse.data) {
        updateStates(response, "success", params);
      }
    })
    .catch((error) => Promise.reject(error));
};

const handleAbort = (error, params) => {
  const errorResponse = {
    placementsResponse: { data: [] },
  };
  updateStates(errorResponse, "error", params, error);
};

const fetchDataOnLoad = (params) => {
  initialLoad(params).catch((error) => {
    if (!("AbortError" in error)) {
      handleAbort(error, params);
    }
  });
};

const args = ({ urlParams, orgId, abortControlerRef }) => ({
  headers: {
    "cache-control": "no-cache, no-store",
  },
  signal: abortControlerRef.current?.signal,
  getResponseHeaders: true,
  url: constructAdminAPIEndPoint({
    url: `orgs/${orgId}/placements`,
    searchParams: [...urlParams, { key: "integration_type", value: 0 }],
  }),
});

const successBlock = (
  response,
  setCount,
  setSize,
  setTotalPlacements,
  setTotalSize,
  isPager
) => {
  if (setCount) {
    const count = getCount(response);
    setTotalPlacements(count);
  }
  if (setSize) {
    const size = getCount(response);
    setTotalSize(size);
  }
  if (response.json.length) {
    return {
      headers: response.headers,
      data: response.json,
      last: !isPager ? getLastPage(response) : 0,
    };
  }

  throw ErrorMessages.NO_PLACEMENTS;
};

export const createKeywords = (inputValue, orgId) => {
  let inpPayload = inputValue.map((data) => {
    return { name: data };
  });
  return fetchHandler(
    prepareArgsForFetchHandler("POST", `orgs/${orgId}/keywords`, inpPayload)
  )
    .then((response) => {
      let responseList = response.map((data) => data.name);
      // To track created keywords on datadog.
      trackCustomActionsInDD("create keyword from placement form", {
        keywords: {
          name: responseList,
        },
      });
      // Success Toast message
      const props = {
        toastTitle: `${join(responseList, ", ")} keyword created successfully!`,
        toastSubTitle: `Newly created keywords are not associated with adunits. To use this in campaigns, please make sure to register the keywords on the relevant adunits.`,
      };
      toast.success(<ToastMsg {...props} />, {
        position: "top-center",
      });
      return responseList;
    })
    .catch(() => {
      // Failed Toast message
      const props = {
        toastTitle: `Failed to create the keyword!`,
        toastSubTitle: "Keyword creation failed, please try after some time.",
        icon: ["far", "times-circle"],
      };
      toast.error(<ToastMsg {...props} />, {
        position: "top-center",
      });
      return { error: "Create keyword failed" };
    });
};

export const fetchCapTypeResponse = async (
  setCapTypeOptions = () => {},
  setresourceAvailable = () => {}
) => {
  let goalTypeMappings = [];
  let data = await fetchDataset(settings.DATASETS_ID_LOOKUP.CAP_TYPE_ID).catch(
    (error) => {
      setCapTypeOptions(goalTypeMappings);
      setresourceAvailable(false);
      return goalTypeMappings;
    }
  );
  sortBy(get(data, "members", []), ["value"]).forEach((goalType) => {
    goalTypeMappings.push({
      label: goalType.value,
      value: goalType.key,
    });
  });
  setCapTypeOptions(goalTypeMappings);
  setresourceAvailable(true);
  return goalTypeMappings;
};

export const fetchVenueOpenOOHCategory = async (
  setVenueList = () => {},
  setresourceAvailable = () => {}
) => {
  let venueList = [];
  let getVenueData = await fetchDataset(
    settings.DATASETS_ID_LOOKUP.VENUE_CATEGORY_ID
  );
  getVenueData.members = sortBy(getVenueData?.members, "value");
  if (!isEmpty(getVenueData) && !getVenueData?.hasOwnProperty("error")) {
    setresourceAvailable(true);
    venueList = getVenueData?.members.map((item) => {
      return { label: item.value, value: Number(item.key) };
    });
  } else setresourceAvailable(false);
  setVenueList(venueList);
  return { venueList, getVenueData };
};

export const fetchCountryTargeting = async (
  setCountryList,
  setresourceAvailable
) => {
  let fetchCountryData = await fetchDataset(
    settings.DATASETS_ID_LOOKUP.COUNTRY_LIST_ID
  ).catch((error) => {
    setCountryList([]);
    setresourceAvailable(false);
    return [];
  });
  setCountryList(
    get(fetchCountryData, "members")?.map((country) => country.value)
  );
  setresourceAvailable(true);
  return fetchCountryData;
};

/* TO-DO: Refactor to reduce number of lines. */
const OrderProvider = (props) => {
  const { children, location } = props;
  const { loading, organization, data } = useContext(UserContext);
  const [placementDetails, setPlacementDetails] = useState([]);
  const [placementsList, setPlacementList] = useState([]);
  const [capTypeOptions, setCapTypeOptions] = useState([]);
  const [displayError, setDisplayError] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [rowDetails, setRowDetails] = useState({});
  const [venueList, setVenueList] = useState([]);
  const [resourceAvailable, setresourceAvailable] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [isLoaded, setLoader] = useState(false);
  const [totalPlacements, setTotalPlacements] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const abortControlerRef = useRef(null);
  const orgId = organization.id;
  const page = 1;
  const sizePerPageValue = 15;
  const queryParams = queryString.parse(location.search);

  const prepareList = (details) => {
    const check =
      details.placementsResponse.data && details.placementsResponse.data.length;
    if (check) {
      setPlacementList(prepareRowObject(details));
    } else {
      setPlacementList([]);
      setPlacementDetails([]);
    }
  };

  const fetchPlacements = (
    urlParams = [],
    setCount = false,
    setSize = false
  ) => {
    const errorMessage = getErrorMessage("placements");

    let isPager = urlParams.find(
      (obj) => obj.key === "pager" && obj.value === "cursor"
    );

    !isPager
      ? (urlParams = [...urlParams, { key: "fields", value: placementsFields }]) // Fetch fields required for Placements table view
      : (urlParams = [
          ...urlParams,
          { key: "slug_field", value: "name" },
          { key: "omit", value: placementsOmitFields },
        ]); // Omit fields not required for Placements export

    let apiArgs = { urlParams, orgId, abortControlerRef };
    apiArgs = args(apiArgs);
    apiArgs.errorMessage = errorMessage;

    return fetchHandler(apiArgs)
      .then((response) =>
        successBlock(
          response,
          setCount,
          setSize,
          setTotalPlacements,
          setTotalSize,
          isPager
        )
      )
      .catch((error) => {
        if (!error.data) return Promise.reject(error);
        return Promise.reject(errorMessage);
      });
  };

  const input = {
    fetchPlacements,
    page,
    prepareList,
    sizePerPageValue,
  };
  const triggerParams = () => ({
    queryParams,
    setPlacementDetails,
    setDisplayError,
    setErrorMessage,
    setLoader,
    setRowDetails,
    ...input,
  });
  const triggerLoadData = () => {
    fetchDataOnLoad(triggerParams());
  };

  const updateData = () => {
    setLoader(false);
    triggerLoadData();
  };

  useEffect(() => {
    if (!loading) {
      abortControlerRef.current = new AbortController();
      updateData();
    }
    return () => abortControlerRef.current?.abort();
  }, [loading, organization]);

  useEffect(() => {
    fetchCapTypeResponse(setCapTypeOptions, setresourceAvailable);
    fetchVenueOpenOOHCategory(setVenueList, setresourceAvailable);
    fetchCountryTargeting(setCountryList, setresourceAvailable);
  }, []);

  const isBudgetingEnabled = false;

  const states = {
    placementDetails,
    placementsList,
    displayError,
    errorMessage,
    rowDetails,
    isLoaded,
    loading,
    setLoader,
    totalPlacements,
    totalSize,
    triggerLoadData,
    ...input,
    capTypeOptions,
    venueList,
    countryList,
    resourceAvailable,
    isBudgetingEnabled,
  };
  return (
    <OrderContext.Provider value={{ states }}>
      {React.cloneElement(children, { ...props })}
    </OrderContext.Provider>
  );
};

const OrderConsumer = OrderContext.Consumer;

OrderProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape(),
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderType: PropTypes.string,
    }),
  }),
};

export { OrderProvider, OrderConsumer, OrderContext };
