import React from "react";
import PropTypes from "prop-types";

import "./style.css";

const GuidelineSection = (props) => {
  const { children } = props;
  return (
    <div className="guidlines">
      <div className="guidlines--info">
        <strong>Note:</strong>
      </div>
      <ul>{children}</ul>
    </div>
  );
};

GuidelineSection.propTypes = {
  children: PropTypes.string,
};

export default GuidelineSection;
