import React from "react";
import { filter, get, includes, toLower } from "lodash";
import {
  Accordion,
  AccordionBody,
  AccordionTitle,
  Form,
  isFormDirty,
  Input,
  MultiSelect,
  Section,
  setFormError,
  Button,
} from "@px/px_design_system";
import { Prompt } from "react-router-dom";
import PropType from "prop-types";
import { toast } from "react-toastify";
import * as yup from "yup";

import Headline from "../../../Headline";
import { CardDetails } from "../../../OrderManagement/Forms/Add/CardDetails";
import { DealFormContext } from "./DealFormContext";
import { HelpBody } from "../../../OrderManagement/Forms/Add/HelpBody";
import {
  constructAdminAPIEndPoint,
  fetchHandler,
  fixedEncodeURIComponent,
  trackCustomActionsInDD,
} from "../../../../../../utils";
import { getAvailableResources } from "../../../Utilities/FlatFileComponent/fieldValidation";
import ToastMsg from "../../../Utilities/ToastMsg";
import TopSummary from "../../../OrderManagement/Forms/Add/TopSummary";
import info from "../../../OrderManagement/Forms/Add/icons/info.svg";
import {
  auctionTypeChoices,
  statusChoices,
  dealDefaultPriority,
} from "../../DealDetails/DealsMetaData/dealMapping";
import { triggerDDActionEvent } from "../../../Utilities/FlatFileComponent/lib";
import ErrorMessages from "../../../../DisplayError/ErrorMessages";

import "./style.css";
import { getDropdownOptions } from "../../../Utilities/FormComponent";
import ToolTipMessages from "../../../Utilities/ToolTip/TooltipMessages";
import "../../../Utilities/Form/validations";
import ArchiveIcon from "../../../../svgs/ArchiveIcon";
import UnarchiveIcon from "../../../../svgs/UnarchiveIcon";
import { validateBidFloor } from "../../../Utilities/Form/validations";

let uploadCount = 0;
let updateCount = 0;
let startTime = 0;
let endTime = 0;

export default class FormDeal extends React.PureComponent {
  static contextType = DealFormContext;
  context = this.context;

  archivalToastId = React.createRef(null);

  state = {
    /* Disable updates for Read User */
    disableSubmit: this.context.isEditMode && !this.context.isImportEnabled,
    globalError: false,
    isSubmitting: true,
    isArchived: this.context?.formDefaultValues?.archived,
  };

  updateAccordian() {
    const { isEditMode } = this.context;
    if (window.innerWidth < 767) {
      this.setState({
        shouldAccordionOpen: false,
      });
    } else {
      this.setState({
        shouldAccordionOpen: !isEditMode,
      });
    }
  }

  componentDidMount() {
    this.updateAccordian();
    window.addEventListener("resize", this.updateAccordian.bind(this));
  }

  componentWillUnmount() {
    uploadCount = 0;
    updateCount = 0;
  }

  isUserAuthenticated = () => {
    return localStorage.getItem("isAuthenticated");
  };

  submitFormData(formData) {
    const { orgId, isEditMode, formDefaultValues } = this.context;
    this.setState({ globalError: false });
    const url = isEditMode
      ? `orgs/${orgId}/deals/${fixedEncodeURIComponent(formDefaultValues.name)}`
      : `orgs/${orgId}/deals`;
    const apiParams = {
      method: isEditMode ? "PATCH" : "POST",
      url: constructAdminAPIEndPoint({
        url: url,
      }),
      headers: {
        "cache-control": "no-cache, no-store",
      },
      body: formData,
    };

    return fetchHandler(apiParams)
      .then((response) => {
        trackCustomActionsInDD("set deal priority", {
          priority: get(response, "priority"),
          priority_display: get(response, "priority_display"),
          deal_name: get(response, "name"),
        });
        const { history } = this.props;
        isEditMode ? updateCount++ : uploadCount++;
        endTime = performance.now();
        triggerDDActionEvent({
          endTime,
          startTime,
          uploadCount,
          updateCount,
          uploadType: "deals",
        });
        const props = {
          toastTitle: `${formData.name} Deal ${
            !isEditMode ? "Added" : "Updated"
          } Successfully!`,
          toastSubTitle:
            "You can modify the deal details at any time by navigating to this deal on the deal Management page deal section.",
        };
        toast.success(<ToastMsg {...props} />, {
          toastId: "order-success",
          position: "top-center",
        });

        history.push("../deals/summary");
      })
      .catch(() => {
        this.setState({
          globalError: true,
          disableSubmit: false,
        });
      });
  }

  submitDeal = async (orgId, formData) => {
    const { isEditMode, formDefaultValues } = this.context;
    if (!formData.status) formData.status = "";
    formData.priority = Number(formData.priority);
    this.setState({ disableSubmit: true, isSubmitting: false });
    const payload = {
      model: "Deal",
      names: [formData.name],
      show_names: "true",
      is_case_sensitive: "false",
    };
    /* During Edits - Perform existing record check only if name field is updated */
    delete formData.archived;
    if (
      isEditMode &&
      toLower(formDefaultValues.name) === toLower(formData.name)
    ) {
      startTime = performance.now();
      this.submitFormData(formData);
    } else {
      const getExistingDeals = await getAvailableResources(orgId, payload);
      const availableDealsList =
        !Array.isArray(getExistingDeals) &&
        getExistingDeals.resources_available_names;
      const exactDealExist =
        availableDealsList && includes(availableDealsList, formData.name);
      const toLowerDealExist =
        availableDealsList &&
        includes(
          availableDealsList.map((el) => toLower(el)),
          toLower(formData.name)
        );

      if (
        Array.isArray(getExistingDeals) &&
        includes(getExistingDeals, "API Failed")
      ) {
        setFormError("name", {
          type: "manual",
          message: ErrorMessages.API_FAILED_MESSAGE,
        });
        this.setState({ disableSubmit: false });
      } else if (exactDealExist) {
        /* Raise error if deal already exist */
        setFormError("name", {
          type: "manual",
          message: "Deal name already exists.",
        });
        this.setState({ disableSubmit: false });
      } else if (!exactDealExist && toLowerDealExist) {
        /* Raise error if deal name cased differently already exist */
        setFormError("name", {
          type: "manual",
          message:
            "Deal name with different character case exist in the organization. Please enter a unique name.",
        });
        this.setState({ disableSubmit: false });
      } else {
        startTime = performance.now();
        this.submitFormData(formData);
      }
    }
  };

  dropDownValues = (dataObj) => {
    const dropdownOptions = [];
    for (let key in dataObj) {
      dropdownOptions.push(
        <option value={key} key={key}>
          {dataObj[key]}
        </option>
      );
    }
    return dropdownOptions;
  };

  getWbuyerOptions = (dataObj) => {
    let multiWbuyerOptions = [];
    /* Delete the option for 'All DSPs', since with multi-select interaction it has no purpose. */
    delete dataObj["All DSPs"];
    for (let [key, value] of Object.entries(dataObj)) {
      multiWbuyerOptions.push({ value: key, label: value });
    }
    return multiWbuyerOptions;
  };

  closeToastAndRedirect = /* istanbul ignore next */ () => {
    const { history } = this.props;
    const { isArchived } = this.state;

    if (isArchived) {
      history?.push("../deals/summary");
    }
  };

  showToast = /* istanbul ignore next */ (isToastActive, toastProps) => {
    if (isToastActive) {
      this.archivalToastId.current = toast.info(<ToastMsg {...toastProps} />, {
        toastId: "archived-unarchived-success",
        position: "top-center",
        autoClose: 3000,
        onClose: this.closeToastAndRedirect,
      });
    } else {
      toast.update(this.archivalToastId.current, {
        render: <ToastMsg {...toastProps} />,
      });
    }
  };

  handleArchival = /* istanbul ignore next */ (dealName, archivalStatus) =>
    (async () => {
      const { orgId } = this.context;
      const url = `orgs/${orgId}/deals/${fixedEncodeURIComponent(dealName)}`;
      const apiParams = {
        method: "PATCH",
        url: constructAdminAPIEndPoint({
          url: url,
        }),
        headers: {
          "cache-control": "no-cache, no-store",
        },
        body: {
          archived: archivalStatus,
        },
      };

      await fetchHandler(apiParams)
        .then(
          /* istanbul ignore next */ () => {
            this.setState({
              isArchived: archivalStatus,
            });

            const props = {
              toastTitle: `${dealName} is ${
                archivalStatus ? "archived" : "unarchived"
              }`,
              toastSubTitle: `${
                archivalStatus ? " Redirecting to Deals Summary page..." : ""
              }`,
            };

            this.showToast(
              !toast.isActive(this.archivalToastId.current),
              props
            );
          }
        )
        .catch(
          /* istanbul ignore next */ () => {
            const props = {
              toastTitle: `Something went wrong! Please try again later.`,
              toastSubTitle: "",
            };
            toast.error(<ToastMsg {...props} />, {
              toastId: "archived-unarchived-failed",
              position: "top-center",
            });
          }
        );
    })();

  render() {
    const {
      isEditMode,
      formDefaultValues,
      orgId,
      singleDealData,
      wbuyerOptions,
      priorityOptions,
      organization,
    } = this.context;
    const {
      disableSubmit,
      globalError,
      isSubmitting,
      shouldAccordionOpen,
      isArchived,
    } = this.state;
    const isPlacements = singleDealData?.placements.length;
    const indicatorText = !isPlacements
      ? "No placement is selected for this deal"
      : "";

    return (
      <>
        <Headline>Deal</Headline>
        <div className="embed--dashboard wrapper">
          <h2 className="sub__headline--copy">
            <span>
              {isEditMode
                ? `Edit - Deal ID - ${singleDealData?.id}`
                : "Add Deal"}
            </span>
            {isEditMode && (
              <Button
                type="button"
                color="secondary"
                className="btn__archive"
                buttontext={isArchived ? "Unarchive" : "Archive"}
                onClick={this.handleArchival.bind(
                  this,
                  singleDealData?.name,
                  !isArchived
                )}
                icon={isArchived ? UnarchiveIcon : ArchiveIcon}
              />
            )}
            {!isArchived && (
              <TopSummary text={indicatorText} isEditMode={isEditMode} />
            )}
            {isArchived && (
              <TopSummary
                text="Updates are restricted for archived deals"
                isEditMode={isEditMode}
              />
            )}
          </h2>
          {shouldAccordionOpen !== undefined && (
            <div className="deals__help--container help--section">
              <Accordion>
                <AccordionTitle defaultOpen={shouldAccordionOpen}>
                  <img src={info} alt="info" />
                  {isEditMode ? "Updating Deals" : "Creating Deals?"}
                </AccordionTitle>
                <AccordionBody>
                  <p>
                    Note: A deal will not have any avails until either a
                    Placement has been created and associated to this deal or
                    your current adserver is updated to include the deal in ad
                    request API calls to PX
                  </p>
                  <div className="help__icon--section">
                    <HelpBody isEditMode={isEditMode} model="deals" />
                  </div>
                  <p>
                    For more information or suggested practices see{" "}
                    <a
                      href="https://help.placeexchange.com/s/article/Deal-Management"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Deals
                    </a>{" "}
                    in the Help Center.
                  </p>
                </AccordionBody>
              </Accordion>
            </div>
          )}
          <div
            className={`${
              isArchived ? "disable--form" : ""
            } deal--form-container`}
          >
            {isSubmitting && (
              <Prompt
                message={() => {
                  return isFormDirty()
                    ? "Are you sure you want to leave?"
                    : true;
                }}
              />
            )}
            <Form
              isUserAuthenticated={this.isUserAuthenticated}
              submitButtonText={`${isEditMode ? "Update Deal" : "Add Deal"}`}
              redirectUrl={"../deals/summary"}
              defaultValues={formDefaultValues}
              disableSubmit={disableSubmit}
              globalError={globalError}
              tooltipTitle={ToolTipMessages.UPDATE_DEALS.noPermission}
              displayTooltip={this.context.isImportEnabled}
              onSubmit={(formData) => {
                formData = {
                  ...formData,
                  bidfloorcur:
                    !isEditMode && organization
                      ? organization.config.allowed_currency
                      : formData.bidfloorcur,
                  /* Filter undefined (sandboxed buyers) and pass wbuyer id's */
                  wbuyer: filter(formData.wbuyer, (item) => item).map(
                    (element) => element.value
                  ),
                  wadomain: formData.wadomain.map((element) => element.value),
                  wseat: formData.wseat.map((element) => element.value),
                };
                return this.submitDeal(orgId, formData);
              }}
              validationSchema={{
                name: yup
                  .string()
                  .max(100)
                  .required("Name is a required field")
                  .validateEmptyNameField("Name field can not be blank")
                  .validateSpaceNameField(
                    "Name should not have leading or trailing white spaces"
                  ),
                notes: yup
                  .string()
                  .max(4000)
                  .test(
                    "string-can-be-empty",
                    "notes must be at least 2 characters",
                    (val) => val.length === 0 || val.length >= 2
                  ),
                at: yup.string().required("Auction Type is a required field"),
                bidfloorcur: yup
                  .string()
                  .test(
                    "is-valid-bidfloorcur",
                    "Ensure the value is selected from the available options",
                    (value) =>
                      isEditMode
                        ? value === organization.config.allowed_currency
                        : true
                  ),
                bidfloor: validateBidFloor,
                wbuyer: yup
                  .mixed()
                  .test(
                    "is-valid-wbuyer",
                    "The selected DSP is not a valid choice. Please select a value from existing options.",
                    (value) => {
                      if (Array.isArray(value) && value.includes(undefined)) {
                        const wbuyer = filter(value, (item) => item);
                        return wbuyer.length !== 0;
                      }
                      return true;
                    }
                  ),
                wseat: yup
                  .array(
                    yup.object({
                      label: yup
                        .string()
                        .max(
                          100,
                          "Seat cannot contain more than 100 characters."
                        )
                        .notRequired(),
                      value: yup
                        .string()
                        .max(
                          100,
                          "Seat cannot contain more than 100 characters."
                        )
                        .notRequired(),
                    })
                  )
                  .nullable(),
                wadomain: yup
                  .array(
                    yup.object({
                      label: yup
                        .string()
                        .max(
                          100,
                          "Advertiser cannot contain more than 100 characters."
                        )
                        .notRequired(),
                      value: yup
                        .string()
                        .max(
                          100,
                          "Advertiser cannot contain more than 100 characters."
                        )
                        .notRequired(),
                    })
                  )
                  .nullable(),
              }}
              watchFields={["wadomain", "wseat"]}
              {...this.props}
            >
              <Section title="Deal Information" />
              <Input
                type="text"
                name="name"
                label="Name"
                placeholder="Enter name"
                subTitle="Enter a deal name"
                isRequired
              />
              <Input
                type="select"
                name="priority"
                label="Priority"
                subTitle={
                  <>
                    Optionally adjust deal priority when applicable. Refer to{" "}
                    <a
                      href="https://help.placeexchange.com/s/article/Understanding-Deal-Prioritization"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Priorities
                    </a>{" "}
                    section
                  </>
                }
              >
                {getDropdownOptions(priorityOptions, dealDefaultPriority)}
              </Input>
              <Input
                type="textarea"
                name="notes"
                label="Notes"
                placeholder="Additional context or notes about how deal will be used"
              />

              <CardDetails
                title={["Deal Token (DSP Deal ID)"]}
                text={[singleDealData?.token || "-"]}
                isEditMode={isEditMode}
              />
              <CardDetails
                title={["Created By"]}
                text={[singleDealData?.created_by_name || "-"]}
                isEditMode={isEditMode}
              />

              <hr />
              <Section title="Price Floor" />
              <Input
                type="select"
                name="at"
                label="Auction Type"
                isRequired
                subTitle="Auction type of deal"
              >
                <option value="" disabled selected hidden>
                  Select Auction Type
                </option>
                {this.dropDownValues(auctionTypeChoices())}
              </Input>
              <Input
                type="select"
                name="bidfloorcur"
                label="Currency"
                isRequired
                subTitle="Currency specified using ISO-4217 aplha codes"
              >
                {isEditMode && (
                  <option
                    hidden
                    value={singleDealData?.bidfloorcur}
                    key={singleDealData?.bidfloorcur}
                  >
                    {singleDealData?.bidfloorcur}
                  </option>
                )}
                {organization && (
                  <option
                    value={organization.config.allowed_currency}
                    key={organization.config.allowed_currency}
                  >
                    {organization.config.allowed_currency}
                  </option>
                )}
              </Input>
              <Input
                type="number"
                name="bidfloor"
                label="Floor Price"
                isRequired
                subTitle="Minimum bid for an impression expressed in CPM"
              />
              <Input
                type="select"
                name="status"
                label="Status"
                subTitle="Optional selection available to indicate any special handling on deal and does not impact treatment of the deal"
              >
                {this.dropDownValues(statusChoices())}
              </Input>
              <hr />
              <Section title="Restrictions" />
              <MultiSelect
                name="wbuyer"
                label="Allowed Buyers(DSPs)"
                subTitle="Select individual DSP(s) below to restrict bids to be allowed from those DSP(s). All DSP(s) are allowed by default until one or more is selected from below"
                options={this.getWbuyerOptions(wbuyerOptions)}
                isMulti
                isClearable
                isSearchable
                isValidNewOption={() => false}
                placeholderText="Select DSP"
              />
              <MultiSelect
                name="wseat"
                label="Allowed Seat(s)"
                subTitle="Enter the DSP seat ID(s) of an agency or advertiser to restrict bids to be allowed from those seats"
                placeholder="Enter seat. All seats are allowed by default until one or more values are added here"
                options={formDefaultValues.wseat}
                isMulti
                isClearable
                isSearchable
                createable
                validateMaxLength={true}
                maxLength={100}
                maxLengthErrorMsg="Seat cannot contain more than 100 characters."
              />
              <MultiSelect
                name="wadomain"
                label="Allowed Advertisers"
                subTitle="Enter the DSP adomain(s) of an agency or advertiser to restrict bids to be allowed from these domains"
                placeholder="Enter adomain. All adomains are allowed by default until one or more values are added here"
                options={formDefaultValues.wadomain}
                isMulti
                isClearable
                isSearchable
                createable
                validateMaxLength={true}
                maxLength={100}
                maxLengthErrorMsg="Advertiser cannot contain more than 100 characters."
              />
            </Form>
          </div>
        </div>
      </>
    );
  }
}

FormDeal.contextTypes = {
  formDefaultValues: PropType.any,
  isEditMode: PropType.any,
  orgId: PropType.any,
  singleDealData: PropType.any,
  wbuyerOptions: PropType.any,
  priorityOptions: PropType.any,
};
