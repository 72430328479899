import { isEmpty } from "lodash";
import ErrorMessages from "../../../../../DisplayError/ErrorMessages";

const validateNetworkID = (networkExistData, networkID) => {
  let returnMsg = "";
  if (
    networkID &&
    !networkExistData.map((item) => item.id).includes(networkID)
  ) {
    returnMsg = "Network ID does not belong to this organisation.";
  }
  return returnMsg;
};

const validateNetworkName = (networkExistData, networkID, networkName) => {
  if (
    networkName &&
    networkExistData.find(
      ({ id, name }) => name !== networkName && id === networkID
    )
  ) {
    return "Network ID and Network Name mismatch.";
  }
};

export const validateNetworkFields = (record, networkData) => {
  if (networkData && networkData.includes("API Failed")) {
    return record.validate(
      "network_id",
      (value) => false,
      ErrorMessages.API_FAILED_MESSAGE
    );
  }
  const networkIdError = validateNetworkID(
    networkData,
    record.get("network_id")
  );
  if (!isEmpty(networkIdError)) {
    return record.validate("network_id", (value) => false, networkIdError);
  }
  const networkNameError = validateNetworkName(
    networkData,
    record.get("network_id"),
    record.get("network_name")
  );
  if (!isEmpty(networkNameError))
    return record.validate("network_name", (value) => false, networkNameError);
};
