/* Include validation functions which are used in only Adunits view */
/* Validations added in this file are - 
/* - adFormatValidator
/* - validateStartDateWithStatusCol
/* - Status Field Validate
/* - eidsValidator
/* - validateSlotMinMaxDuration
*/
import { forEach, inRange, isEmpty, split, toLower } from "lodash";

import { splitCommaSeparatedStringToArray } from "../../../../../../../utils";
import { isFutureDate } from "../common";
import ErrorMessages from "../../../../../DisplayError/ErrorMessages";

export const adFormatValidator = (record, field, maxLimit) => {
  let error = false,
    maxLengthExceeds = false;
  const adFormatsStr = record.get(field);
  if (adFormatsStr) {
    const adFormats = split(toLower(adFormatsStr.replace(/,$/, "")), ",");
    forEach(adFormats, (size) => {
      if (!size.includes("x") || split(size, "x").length > 2) {
        error = true;
      }
      const index = size.indexOf("x");
      if (
        !(parseInt(size.substr(0, index)) > 0) ||
        !(parseInt(size.substr(index + 1)) > 0)
      ) {
        error = true;
      }
      // check value should be in between 1 to INT_MAX
      if (!error) {
        if (
          !(
            inRange(Number(split(size, "x")[0]), 1, maxLimit + 1) &&
            inRange(Number(split(size, "x")[1]), 1, maxLimit + 1)
          )
        ) {
          maxLengthExceeds = true;
        }
      }
    });
  }
  record.validate(
    field,
    (value) => {
      if (error || maxLengthExceeds) return false;
      return true;
    },
    `Please provide valid positive numbers for Width and Height in WxH format. The Width and Height should be in between 1 to ${maxLimit}`
  );
};

export const validateStartDateWithStatusCol = (record, field) => {
  record.validate(
    field,
    (value) => {
      let error = true;
      if (record.get("status") === "1" && !isEmpty(value)) {
        if (!isFutureDate(value.trim())) {
          error = false;
        }
      }
      return error;
    },
    "start_date should be a future date when status is pending"
  );
};

export const eidsValidator = (record, field) => {
  const eidsStr = record.get(field);
  if (!isEmpty(eidsStr)) {
    const eids = splitCommaSeparatedStringToArray(eidsStr);
    let delimiter_errors = false;
    forEach(eids, (eid) => {
      if ((eid.match(/:/g) || []).length !== 1) {
        delimiter_errors = true;
      }
    });
    record.validate(
      field,
      (value) => !delimiter_errors,
      `Each EID provided should follow a format of source:uid.id (e.g. placeexchange.com:12345). One or more of the EIDs provided is incorrectly formatted`
    );
  }
};

export const statusFieldValidator = (
  record,
  field,
  existingAdunitsWithStatusField,
  isSuperUser
) => {
  if (!isSuperUser) {
    if (existingAdunitsWithStatusField.includes("API Failed")) {
      return record.validate(
        field,
        (value) => false,
        ErrorMessages.API_FAILED_MESSAGE
      );
    }
    const adunitName = record.get("name");
    const statusField = record.get("status");
    const inReviewStatus = "6";
    if (!isEmpty(statusField)) {
      const availableRecords = existingAdunitsWithStatusField
        .map((el) => el.name)
        .filter((item) => !isEmpty(item));
      const inReviewRecords = existingAdunitsWithStatusField
        .filter((item) => item.status === Number(inReviewStatus))
        .map((el) => el.name);

      if (
        (inReviewRecords.includes(adunitName) &&
          statusField !== inReviewStatus) ||
        ((!inReviewRecords.includes(adunitName) ||
          !availableRecords.includes(adunitName)) &&
          statusField === inReviewStatus)
      ) {
        record.validate(
          field,
          (value) => false,
          `You are not permitted to set/reset "In-Review" status. Refer help article or please contact PX Support for more.`
        );
      }
    }
  }
};

export const validateSlotMinMaxDuration = (record, field) => {
  record.validate(
    field,
    (value) => parseFloat(value) >= parseFloat(record.get("slot.min_duration")),
    "max_duration must be greater than 1 and equal to or greater than min_duration"
  );
};
