import React from "react";

const InventoryIcon = (
  <path
    fill="#999"
    fillRule="nonzero"
    d="M3 9v18h24V9H3zM0 6h30v24H0V6zm8 8h14v8H8v-8zm-2-2v12h18V12H6zM3 0h3v4H3V0zm21 0h3v4h-3V0zm-9.5 15.793L10.793 19.5l.707.707 3.707-3.707-.707-.707zm4 0L14.793 19.5l.707.707 3.707-3.707-.707-.707z"
  />
);

export default InventoryIcon;
