import React from "react";

const MeasurementIcon = (
  <g
    xmlns="http://www.w3.org/2000/svg"
    transform="matrix(1.9457 0 0 1.7606 25 25.5005)"
  >
    <path
      fill="#999"
      fill-rule="nonzero"
      vector-effect="non-scaling-stroke"
      transform=" translate(-15.05, -15.1)"
      d="M 24.5 4.1 h -4.1 l -0.8 -2.2 c -0.2 -0.6 -0.8 -1 -1.5 -1 h -6.2 c -0.7 0 -1.3 0.4 -1.5 1 L 9.6 4.1 H 5.5 C 4.7 4.1 4 4.8 4 5.7 v 2.6 c 0 0.9 0.7 1.6 1.6 1.6 h 5 v 17 c 0 1.3 1.1 2.4 2.4 2.4 h 4.1 c 1.3 0 2.4 -1.1 2.4 -2.4 v -17 h 5 c 0.9 0 1.6 -0.7 1.6 -1.6 V 5.7 C 26.1 4.8 25.3 4.1 24.5 4.1 L 24.5 4.1 z M 17.9 12.1 h -1.7 c -0.4 0 -0.8 0.4 -0.8 0.8 c 0 0.4 0.4 0.8 0.8 0.8 h 1.7 V 16 h -1.7 c -0.4 0 -0.8 0.4 -0.8 0.8 c 0 0.4 0.4 0.8 0.8 0.8 h 1.7 v 2.3 h -1.7 c -0.4 0 -0.8 0.4 -0.8 0.8 c 0 0.4 0.4 0.8 0.8 0.8 h 1.7 v 2.3 h -1.7 c -0.4 0 -0.8 0.4 -0.8 0.8 c 0 0.4 0.4 0.8 0.8 0.8 h 1.7 v 1.5 c 0 0.4 -0.4 0.8 -0.8 0.8 h -4.1 c -0.4 0 -0.8 -0.4 -0.8 -0.8 v -17 h 5.7 L 17.9 12.1 z M 5.5 8.2 V 5.7 h 4.1 c 0.7 0 1.3 -0.4 1.5 -1 l 0.8 -2.2 h 6.2 l 0.8 2.2 c 0.2 0.6 0.8 1 1.5 1 h 4.1 v 2.6 H 5.5 z"
      stroke-linecap="round"
    />
  </g>
);

export default MeasurementIcon;
