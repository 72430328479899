import { isEmpty } from "lodash";

export const requiredWithAllValues = (record, field, requiredWithField) => {
  if (
    !isEmpty(record.get("cap_type")) &&
    isEmpty(record.get(field)) &&
    isEmpty(record.get(requiredWithField))
  ) {
    record.validate(
      field,
      (value) => false,
      `${field} field is required if the cap type is selected and ${requiredWithField} is empty`
    );
  }
};

export const setCapValidator = (record, field) => {
  const dailyCap = record.get("daily");
  const lifetimeCap = record.get("lifetime");
  if (dailyCap && lifetimeCap) {
    if (Number(dailyCap) > Number(lifetimeCap)) {
      record.validate(
        field,
        (value) => false,
        "Daily cap cannot exceed lifetime cap"
      );
    }
  }
};
