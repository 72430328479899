/* istanbul ignore file */
import React from "react";
import { RemoteTable } from "@px/px_design_system";
import queryString from "query-string";

import {
  constructAdminAPIEndPoint,
  fetchHandler,
  fixedEncodeURIComponent,
  getCount,
} from "../../../../../utils";
import { capitalize, first, isEmpty, replace } from "lodash";
import ErrorMessages from "../../../DisplayError/ErrorMessages";
import ToolTipMessages from "../ToolTip/TooltipMessages";

export const Table = (props) => {
  const values = queryString.parse(props?.history?.location.search);
  const modelErrorMessage = {
    deals: ErrorMessages.NO_DEALS,
    adapprovals: ErrorMessages.NO_ADAPPROVAL,
  };

  /* START - Update default sort key in '.' notation acceptable by Remote Table */
  let sort;
  if (values.ordering) {
    sort = [
      {
        id:
          first(values.ordering) === "-"
            ? values.ordering.split("-")[1]
            : values.ordering,
        desc: first(values.ordering) === "-",
      },
    ];
    if (values.ordering.includes("__")) {
      const key = props.table_columns.find(
        (item) => item.dataField === sort[0].id
      );
      sort[0].id = replace(sort[0].id, sort[0].id, key.accessorKey);
    }
  }

  const defaultOrderingValue = sort ||
    props?.defaultSort || [
      {
        id: "lastmod", //sort by age by default on page load
        desc: true,
      },
    ];

  /* END */

  const args = ({ urlParams, orgId, controllerRef, isSignal }) => ({
    getResponseHeaders: true,
    headers: {
      "cache-control": "no-cache, no-store",
    },
    signal: isSignal ? controllerRef.current?.signal : "",
    url: constructAdminAPIEndPoint({
      url: `orgs/${orgId}/${props.name}`,
      searchParams: [...urlParams, { key: "slug_field", value: "name" }],
    }),
  });

  const getData = (params) => {
    const { setSearchResultCount = () => {}, table_columns, orgId } = props;
    const sorting = params.sorting[0]
      ? `${params.sorting[0]?.desc ? "-" : ""}${params.sorting[0]?.id}`
      : "";

    /* START - Update default sort key in '__' notation acceptable by API */
    let modifiedSortValue = sorting;

    if (params?.sorting[0]?.id && params?.sorting[0]?.id.includes(".")) {
      const key = table_columns.find(
        (item) => item.accessorKey === params.sorting[0]?.id
      );
      modifiedSortValue = replace(
        modifiedSortValue,
        params.sorting[0].id,
        key.dataField
      );
    }
    /* END */

    // update URL with query params
    const searchQueryParam = `search=${fixedEncodeURIComponent(
      params.globalFilter
    )}`;
    const orderingParam = sorting ? `&ordering=${modifiedSortValue}` : "";
    let queryParam = "";

    if (values?.audit__status) {
      queryParam = `?audit__status=${values.audit__status}${orderingParam}${
        !isEmpty(params.globalFilter) ? `&${searchQueryParam}` : ""
      }`;
    } else {
      queryParam = !isEmpty(params.globalFilter) ? `?${searchQueryParam}` : "";
    }

    props?.history.push({ search: queryParam });

    // prep & return promise

    let urlParams = [
      { key: "page", value: params.start },
      { key: "page_size", value: params.size },
      {
        key: "ordering",
        value: modifiedSortValue,
      },
      {
        key: "search",
        value: `${params.globalFilter}`,
      },
    ];
    if (params.extraFilters) {
      urlParams = [...urlParams, ...params.extraFilters];
    }
    if (params.extraUrlParams) {
      urlParams = [...urlParams, ...params.extraUrlParams];
    }

    let apiArgs = args({
      urlParams,
      orgId: orgId,
    });
    return fetchHandler(apiArgs)
      .then((res) => {
        setSearchResultCount(getCount(res));
        return {
          data: res.json,
          totalCount: getCount(res),
        };
      })
      .catch((error) => Promise.reject(error));
  };

  const prepareErorObject = {
    title: modelErrorMessage[props.name]?.title,
    imgUrl: modelErrorMessage[props.name]?.displayIcon?.src,
    description: modelErrorMessage[props.name]?.subTitle,
  };

  return (
    <div className={`table__wrapper mui_tablewrapper mb-4 pb-1`}>
      <RemoteTable
        fetch_data={getData}
        table_columns={props.table_columns}
        defaultSortingValue={defaultOrderingValue}
        extraFilters={props.extraFilters}
        extraUrlParams={props.extraUrlParams}
        defaultGlobalSearchValue={values.search}
        errorNoResult={prepareErorObject}
        muiPaginationProps={{
          labelRowsPerPage: `${capitalize(props?.name)} per page`,
          rowsPerPageOptions: [15],
          showRowsPerPage: false,
        }}
        seachHelperText={ToolTipMessages.SEARCH_INFO_TEXT[props.name]}
        {...props}
      />
    </div>
  );
};
