import React, { useContext } from "react";
import { isEmpty } from "lodash";

import DisplayError from "../../DisplayError";
import Headline from "../Headline";
import ResourcesDetails from "./ResourcesDetails";
import { UserContext } from "../../context/auth";

const Resources = () => {
  const { data, errorMessage, loading } = useContext(UserContext);

  return (
    <>
      <Headline>Resources</Headline>
      {!isEmpty(data) && !data.orgData.errors && !loading ? (
        <ResourcesDetails />
      ) : (
        <DisplayError {...errorMessage} />
      )}
    </>
  );
};

export default Resources;
