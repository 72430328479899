import { capitalize } from "lodash";

export const workbook = (name, fields, submitActionName) => ({
  name: `${capitalize(name)}`,
  labels: ["pinned"],
  sheets: fields,
  actions: [
    {
      operation: submitActionName,
      mode: "foreground",
      label: "Submit",
      description: "Submit",
      primary: true,
      constraints: [
        { type: "hasAllValid" },
        { type: "hasSelection" },
        { type: "hasData" },
      ],
    },
  ],
  settings: {
    trackChanges: true,
  },
});
