import React from "react";
import moment from "moment";
import { Nav, NavItem, NavLink } from "@px/px_design_system";

import "./style.css";

class Footer extends React.PureComponent {
  render() {
    return (
      <>
        <div className="footer sidenav__side-container">
          <p>
            Copyright &copy; {moment().year()} Place Exchange, Inc. All rights
            reserved.
          </p>
          <Nav>
            <NavItem>
              <NavLink
                href="https://www.placeexchange.com/inventory-standards"
                target="_blank"
              >
                Inventory Standards
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="https://www.placeexchange.com/content-acceptability.html"
                target="_blank"
              >
                Content Acceptability Policy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="https://www.placeexchange.com/privacy.html"
                target="_blank"
              >
                Privacy Policy
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </>
    );
  }
}

export default Footer;
