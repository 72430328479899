import React, { useContext } from "react";
import { isEmpty } from "lodash";

import { DealContext, DealProvider } from "../../context/DealManagement";
import { UserContext } from "../../context/auth";
import DealDetails from "./DealDetails";
import DisplayError from "../../DisplayError";
import Headline from "../Headline";

const DealManagement = (props) => {
  const { data, errorMessage, isImportEnabled, loading, isInvalidSuperUser } =
    useContext(UserContext);
  const { states } = useContext(DealContext);

  return (
    <>
      <Headline>Campaigns</Headline>
      {!isEmpty(data) && !data.orgData.errors && !loading ? (
        <DealDetails
          {...props}
          states={states}
          {...data}
          isImportEnabled={isImportEnabled}
          isInvalidSuperUser={isInvalidSuperUser}
        />
      ) : (
        <DisplayError {...errorMessage} />
      )}
    </>
  );
};

const Deal = (props) => (
  <DealProvider {...props}>
    <DealManagement {...props} />
  </DealProvider>
);

export const DealManagementComponent = DealManagement;
export default Deal;
