import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import pLimit from "p-limit";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Spinner } from "@px/px_design_system";
import { forIn, get, isEmpty, map, sortBy } from "lodash";
import { UserContext } from "../../../../context/auth";
import { getNetwork } from "../../../Utilities/FlatFileComponent/fieldValidation.js";
import {
  cursorPaginationApi,
  fetchHandler,
  getPlacementDealsData,
  getErrorMessage,
  prepareArgsForFetchHandler,
  strToBool,
} from "../../../../../../utils";
import DisplayError from "../../../../DisplayError";
import {
  fetchCapTypeResponse,
  fetchVenueOpenOOHCategory,
} from "../../../../context/OrderManagement";
import { mapVenueIdswithVenueName } from "../../../Utilities/FormComponent";
import { fetchDataset } from "../../../../../../utils/datasetapi";
import settings from "../../../../../../settings";
const moment = require("moment-timezone");

/* Limiting browser concurrent requests especially for long running requests
 * Why 6 - Minimum number of concurrency supported by all browsers
 */
const limit = pLimit(6);

const OrderFormContext = createContext();

const OrderFormProvider = (props) => {
  const { isImportEnabled, loading, organization, isInvalidSuperUser } =
    useContext(UserContext);
  const controllerRef = useRef(null);
  const isBudgetingEnabled = strToBool(
    get(organization, "ext.budgeting_enabled", false)
  );
  const [adsList, setAdsList] = useState([]);
  const [keywordList, setKeywordList] = useState([]);
  const [countryList, setCountry] = useState([]);
  const [dealsOptions, setDealsOptions] = useState([]);
  const [displayError, setDisplayError] = useState(false);
  const [capTypeOptions, setCapTypeOptions] = useState([]);
  const [isEditMode, setEditMode] = useState(false);
  const [isFormReady, setFormReady] = useState(false);
  const [networkList, setNetworkList] = useState([]);
  const [venueList, setVenueList] = useState([]);
  const [singlePlacementData, setPlacementData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [formDefaultValues, setFormDefaultValues] = useState({});
  const isDirectSoldPublisher = get(
    organization,
    "ext.direct_sold_enabled",
    false
  );
  const allowedCurrency = get(organization, "config.allowed_currency", "");
  const orgName = get(organization, "name", "");

  const omitFields = [
    "ad_request_interval",
    "adunits",
    "created_by",
    "deals",
    "deals_count",
    "ext",
    "integration_type",
    "lastmod",
    "line_item_id",
    "order_id",
    "owned_by",
    "ts",
  ];
  const daysList = [
    { label: "Monday", value: "0" },
    { label: "Tuesday", value: "1" },
    { label: "Wednesday", value: "2" },
    { label: "Thursday", value: "3" },
    { label: "Friday", value: "4" },
    { label: "Saturday", value: "5" },
    { label: "Sunday", value: "6" },
  ];

  const fetchPlacements = async (name, paramName, fields) => {
    const baseUrl = settings.ADMIN_API_BASE_URL;
    const endpointUrl = `orgs/${
      organization.id
    }/placements/${encodeURIComponent(
      name
    )}?slug_field=name&cache=false&${paramName}=${fields}`;
    const url = `${baseUrl}${endpointUrl.replace(/^\//, "")}`;
    return fetchHandler({
      method: "GET",
      url: url,
      headers: {
        "cache-control": "no-cache, no-store",
      },
    }).then((json) => json);
  };

  const setPlacementFormData = (
    placementData,
    venueListData,
    dealList,
    adunitsRelatedToPlacement
  ) => {
    if (placementData?.name) {
      setEditMode(true);
      // Change dayparts object to display in multiselect component
      const dayParts = placementData.dayparts.reduce((acc, curr) => {
        let index = acc.findIndex((item) => {
          return (
            item.start_time === curr.start_time &&
            item.end_time === curr.end_time
          );
        });

        const selectedDay = daysList.find(
          (element) => parseInt(element.value) === parseInt(curr.weekday)
        );

        if (selectedDay) {
          const newRecord = {
            label: selectedDay ? selectedDay.label : "",
            value: curr.weekday.toString(),
          };

          if (index === -1) {
            let dayPart = { ...curr, weekday: [newRecord] };
            acc.push(dayPart);
          } else {
            acc[index].weekday.push(newRecord);
            // sort weekday
            acc[index]["weekday"] = sortBy(acc[index]["weekday"], "value");
          }
        }

        return acc;
      }, []);

      let geoArray = get(placementData?.targeting, "geo", []);
      if (!isEmpty(geoArray)) {
        map(geoArray, (item, index) => {
          const dmaList = [];
          forIn(item?.dma, (value, key) => {
            dmaList.push({ label: key, value: value });
          });
          geoArray[index] = {};
          geoArray[index]["country"] = {
            label: item?.country,
            value: item?.country,
          };
          geoArray[index]["region"] = get(item, "region", [])?.map((i) => {
            return { label: i, value: i };
          });
          geoArray[index]["dma_code"] = dmaList;
          geoArray[index]["zip"] = get(item, "zip", [])?.map((i) => {
            return { label: i, value: i };
          });
        });
      }
      const targetingGeo = map(geoArray, (item) => {
        return { geo: item };
      });
      let isDirectDeal = false;
      let createDealDefaultValues = [];
      if (dealList.length > 0) {
        isDirectDeal = dealList?.find((deal) =>
          deal.token.startsWith("managed_service")
        );

        createDealDefaultValues = map(dealList, (deal) => ({
          label: deal.name,
          value: deal.name,
          ...(deal.archived === true && { color: "#f37300" }),
        }));
      }
      if (isDirectSoldPublisher) {
        const bidfloor = get(dealList, "[0].bidfloor", null);

        const wadomain = get(dealList, "[0].wadomain", []).map((ad) => {
          return { label: ad, value: ad };
        });

        setFormDefaultValues({
          name: placementData.name,
          notes: placementData.notes,
          adunits: map(adunitsRelatedToPlacement.adunits, (val) => ({
            adunits: val,
          })),
          networks: map(sortBy(placementData?.networks), (network) => ({
            label: network,
            value: network,
          })),
          deals: isEmpty(isDirectDeal) ? createDealDefaultValues : [],
          direct_sold_deals: !isEmpty(isDirectDeal)
            ? createDealDefaultValues
            : [],
          start_date: placementData.start_date
            ? moment(placementData.start_date, "X")
                .utc()
                .format("YYYY-MM-DD[T]HH:mm:ss")
            : null,
          end_date: placementData.end_date
            ? moment(placementData.end_date, "X")
                .utc()
                .format("YYYY-MM-DD[T]HH:mm:ss")
            : null,
          dayparts: dayParts,
          daily: placementData.daily,
          cap_type: placementData.cap_type,
          lifetime: placementData.lifetime,
          venue_openooh_category: mapVenueIdswithVenueName(
            get(placementData, "targeting.venue_openooh_category", []),
            venueListData
          ),
          targeting: isEmpty(targetingGeo)
            ? [
                {
                  geo: [
                    {
                      country: "",
                      dma_code: [],
                      zip: [],
                      region: [],
                    },
                  ],
                },
              ]
            : [...targetingGeo],
          keywords:
            placementData?.targeting?.keywords?.map((data) => {
              return { label: data, value: data };
            }) || [],
          ads: isDirectDeal && wadomain ? wadomain : [],
          priority: {
            label: "Guaranteed",
            value: 10,
          },
          price: isDirectDeal && bidfloor ? bidfloor : "",
        });
      } else {
        setFormDefaultValues({
          name: placementData.name,
          notes: placementData.notes,
          adunits: map(adunitsRelatedToPlacement.adunits, (val) => ({
            adunits: val,
          })),
          networks: map(sortBy(placementData?.networks), (network) => ({
            label: network,
            value: network,
          })),
          deals: isEmpty(isDirectDeal) ? createDealDefaultValues : [],
          start_date: placementData.start_date
            ? moment(placementData.start_date, "X")
                .utc()
                .format("YYYY-MM-DD[T]HH:mm:ss")
            : null,
          end_date: placementData.end_date
            ? moment(placementData.end_date, "X")
                .utc()
                .format("YYYY-MM-DD[T]HH:mm:ss")
            : null,
          dayparts: dayParts,
          daily: placementData.daily,
          cap_type: placementData.cap_type,
          lifetime: placementData.lifetime,
          venue_openooh_category: mapVenueIdswithVenueName(
            get(placementData, "targeting.venue_openooh_category", []),
            venueListData
          ),
          targeting: isEmpty(targetingGeo)
            ? [
                {
                  geo: [
                    {
                      country: "",
                      dma_code: [],
                      zip: [],
                      region: [],
                    },
                  ],
                },
              ]
            : [...targetingGeo],
          keywords:
            placementData?.targeting?.keywords?.map((data) => {
              return { label: data, value: data };
            }) || [],
        });
      }

      setPlacementData(placementData);
    } else if (props.match.params.deals === "deals") {
      props.history.push(`../summary`);
    } else if (
      placementData.message === "Not Found" &&
      props.match.params.deals !== "deals"
    ) {
      props.history.push("../summary/placements");
    }
  };

  const getOrgKeywords = (orgId) => {
    return fetchHandler(
      prepareArgsForFetchHandler("GET", `orgs/${orgId}/keywords`)
    )
      .then((response) => {
        if (response) return response;
      })
      .catch((error) => []);
  };

  const getOrgAds = (orgId) => {
    return fetchHandler(
      prepareArgsForFetchHandler("GET", `orgs/${orgId}/ads`)
    ).then((response) => {
      if (response) return response;
    });
  };

  const prepareDealsRowObject = (dealsData) => {
    let dealTableData = [];
    if (dealsData.length) {
      dealTableData = dealsData.map((item) => {
        const rowObject = {
          name: item.name || "",
          archived: item.archived,
        };
        return rowObject;
      });
    }
    return dealTableData;
  };

  const setDataContext = async (placementName) => {
    const urls = [
      fetchPlacements(placementName, "fields", ["adunits"]),
      fetchPlacements(placementName, "omit", omitFields),
      getPlacementDealsData(organization.id, "deals", placementName),
      getNetwork(organization.id, [{ key: "fields", value: "name" }]),
      fetchCapTypeResponse(),
      fetchVenueOpenOOHCategory(),
      fetchDataset(settings.DATASETS_ID_LOOKUP.COUNTRY_LIST_ID),
      getOrgKeywords(organization.id),
    ];
    if (isDirectSoldPublisher) {
      urls.push(getOrgAds(organization.id));
    }
    let promises = urls.map((url) => limit(() => url));
    const apiResponse = await Promise.all(promises)
      .then(
        ([
          adunitsRelatedToPlacement,
          placementData,
          dealData,
          networkList,
          capTypeList,
          venueInfo,
          getCountry,
          keywordListData,
          adsList,
        ]) => ({
          adunitsRelatedToPlacement,
          placementData,
          dealData,
          networkList,
          capTypeList,
          venueInfo,
          getCountry,
          keywordListData,
          adsList,
        })
      )
      .then((res) => {
        res.networkList = res.networkList.map((network) => ({
          label: network.networkName,
          value: network.networkName,
        }));
        res.getCountry = map(res.getCountry?.members, "value");
        res.keywordListData = map(
          sortBy(res.keywordListData, ["name"]),
          (item) => {
            return { label: item?.name, value: item?.name };
          }
        );
        res.adsList = map(sortBy(res.adsList?.ads, ["name"]), (item) => {
          return { label: item?.name, value: item?.name };
        });
        setNetworkList(sortBy(res.networkList, "label"));
        setCapTypeOptions(res.capTypeList);
        setVenueList(res.venueInfo.venueList);
        setCountry(res.getCountry);
        setKeywordList(res.keywordListData);
        setAdsList(res.adsList);
        prepareDealsRowObject(res.dealData);
        if (
          (!isEmpty(res.networkList) &&
            res.networkList[0]?.hasOwnProperty("error")) ||
          (!isEmpty(res.venueInfo.getVenueData) &&
            res.venueInfo.getVenueData?.hasOwnProperty("error"))
        ) {
          setDisplayError(true);
        }
        return res;
      });
    if (placementName) {
      setPlacementFormData(
        apiResponse.placementData,
        apiResponse.venueInfo.venueList,
        apiResponse.dealData,
        apiResponse.adunitsRelatedToPlacement
      );
    }
    await fetchOrgDeals();
    setFormReady(true);
  };

  const fetchOrgDeals = async () => {
    let dealList = [];
    let getDealsData = [];
    const headers = {
      "cache-control": "no-cache, no-store",
    };
    const searchParams = [{ key: "archived", value: "false" }];
    getDealsData = await cursorPaginationApi(
      organization.id,
      "deals",
      headers,
      searchParams,
      controllerRef.current?.signal
    );
    if (!isEmpty(getDealsData) && !getDealsData[0].hasOwnProperty("error")) {
      if (isDirectSoldPublisher) {
        const programmticDeals = getDealsData.filter(
          (d) => !d.token.startsWith("managed_service")
        );
        dealList = programmticDeals.map((dealName) => {
          return { label: dealName.name, value: dealName.name };
        });
      } else {
        dealList = getDealsData.map((dealName) => {
          return { label: dealName.name, value: dealName.name };
        });
      }
    }
    setDealsOptions(dealList);
    if (!isEmpty(getDealsData) && getDealsData[0].hasOwnProperty("error")) {
      setDisplayError(true);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    controllerRef.current = controller;
    if (!loading) {
      if (isDirectSoldPublisher) {
        setFormDefaultValues({
          name: "",
          notes: "",
          venue_openooh_category: [],
          keywords: [],
          adunits: [],
          networks: [],
          deals: [],
          direct_sold_deals: [],
          start_date: null,
          end_date: null,
          dayparts: [],
          daily: "",
          cap_type: "",
          lifetime: "",
          targeting: [
            {
              geo: [
                {
                  country: "",
                  dma_code: [],
                  zip: [],
                  region: [],
                },
              ],
            },
          ],
          ads: [],
          price: "",
          priority: {
            label: "Guaranteed",
            value: 10,
          },
        });
      } else {
        setFormDefaultValues({
          name: "",
          notes: "",
          venue_openooh_category: [],
          keywords: [],
          adunits: [],
          networks: [],
          deals: [],
          start_date: null,
          end_date: null,
          dayparts: [],
          daily: "",
          cap_type: "",
          lifetime: "",
          targeting: [
            {
              geo: [
                {
                  country: "",
                  dma_code: [],
                  zip: [],
                  region: [],
                },
              ],
            },
          ],
        });
      }
      setDisplayError(false);
      setFormReady(false);
      const { location } = props;
      const queryParams = queryString.parse(location.search);
      setSearchValue(queryParams.search || "");
      setDataContext(queryParams.name || "");
    }
    return () => {
      controllerRef.current?.abort();
    };
  }, [loading]);

  return (
    <OrderFormContext.Provider
      value={{
        formDefaultValues,
        isEditMode,
        isImportEnabled,
        orgId: organization.id,
        networkList,
        singlePlacementData,
        dealsOptions,
        searchValue,
        capTypeOptions,
        venueList,
        countryList,
        keywordList,
        isInvalidSuperUser,
        isBudgetingEnabled,
        daysList,
        isDirectSoldPublisher,
        adsList,
        allowedCurrency,
        orgName,
      }}
    >
      {!loading && isFormReady ? (
        displayError ? (
          <div className="embed--dashboard wrapper">
            <DisplayError {...getErrorMessage("placement")} />
          </div>
        ) : (
          props.children
        )
      ) : (
        <Spinner color="primary" />
      )}
    </OrderFormContext.Provider>
  );
};

const OrderFormConsumer = OrderFormContext.Consumer;

OrderFormProvider.contextTypes = {
  data: PropTypes.bool,
  loading: PropTypes.any,
  formDefaultValues: PropTypes.any,
  isEditMode: PropTypes.any,
  orgId: PropTypes.any,
  networkList: PropTypes.any,
  singlePlacementData: PropTypes.any,
};

export { OrderFormProvider, OrderFormConsumer, OrderFormContext };
