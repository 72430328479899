import React from "react";

const NewsIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 30 30"
    fill="#999"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M25.1408 0H4.85916C2.1831 0 0 2.1831 0 4.85916V25.1408C0 27.8169 2.1831 30 4.85916 30H25.1408C27.8169 30 30 27.8169 30 25.1408V4.85916C30 2.1831 27.8169 0 25.1408 0ZM4.85916 1.76056H25.1408C26.831 1.76056 28.2042 3.1338 28.2042 4.82394V12.993H1.76056V4.85916C1.76056 3.16901 3.16901 1.76056 4.85916 1.76056ZM25.1408 28.2394H4.85916C3.16901 28.2394 1.79577 26.8662 1.79577 25.1761V14.7887H28.2394V25.1408C28.2394 26.831 26.831 28.2394 25.1408 28.2394Z" />
    <path d="M7.67609 10.6691C9.42629 10.6691 10.8451 9.25027 10.8451 7.50007C10.8451 5.74987 9.42629 4.33105 7.67609 4.33105C5.9259 4.33105 4.50708 5.74987 4.50708 7.50007C4.50708 9.25027 5.9259 10.6691 7.67609 10.6691Z" />
    <path d="M14.1901 6.23237H24.8943C25.3873 6.23237 25.7746 5.84504 25.7746 5.35208C25.7746 4.85913 25.3873 4.4718 24.8943 4.4718H14.1901C13.6971 4.4718 13.3098 4.85913 13.3098 5.35208C13.3098 5.84504 13.7323 6.23237 14.1901 6.23237Z" />
    <path d="M14.1901 10.7394H24.8943C25.3873 10.7394 25.7746 10.3521 25.7746 9.85916C25.7746 9.36621 25.3873 8.97888 24.8943 8.97888H14.1901C13.6971 8.97888 13.3098 9.36621 13.3098 9.85916C13.3098 10.3169 13.7323 10.7394 14.1901 10.7394Z" />
  </svg>
);

export default NewsIcon;
