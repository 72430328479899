import React, { useContext } from "react";
import { isEmpty } from "lodash";
import { UserContext } from "../../context/auth";
import Headline from "../Headline";
import "./style.css";

import DisplayError from "../../DisplayError";
import EmbedIframe from "./EmbedIframe";
import CustomerInstanceWrap from "./CustomerInstanceWrap";

const EmbedDashboard = (props) => {
  const { data, loading, errorMessage, isViewEnabled } =
    useContext(UserContext);

  return (
    <>
      <Headline>Reporting</Headline>
      {isViewEnabled && <CustomerInstanceWrap />}
      {!isEmpty(data) && !data.orgData.errors && !loading ? (
        <div className="embed--dashboard wrapper">
          <EmbedIframe {...props} />
        </div>
      ) : (
        <DisplayError {...errorMessage} />
      )}
    </>
  );
};

export default EmbedDashboard;
