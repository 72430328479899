const PlacementModel = () => {
  return {
    adunits: [],
    cap_type: null,
    created_by: null,
    created_by_name: null,
    daily: null,
    dayparts: { end_time: [], start_time: [], weekday: [] },
    deals: [],
    end_date: null,
    end_time: "",
    ext: null,
    id: "",
    lastmod: "",
    lifetime: null,
    name: "",
    networks: [],
    notes: "",
    owned_by: "",
    start_date: null,
    start_time: "",
    targeting: {
      geo: { country: [], dma_name: [], region: [], zip: [] },
      keywords: [],
      venue_openooh_category: [],
    },
    ts: "",
  };
};

export default PlacementModel;
