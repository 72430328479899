import React from "react";

import "./styles.css";

const PageNotFound = () => (
  <div className="page-not-found">
    <p>Page not found</p>
  </div>
);

export default PageNotFound;
