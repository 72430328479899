import React from "react";
import { datadogRum } from "@datadog/browser-rum";
import { Link } from "react-router-dom";
import {
  capitalize,
  join,
  includes,
  isEmpty,
  isNil,
  flatten as flat,
  mapValues,
  merge,
  omitBy,
  uniq,
  forEach,
  get,
  keys,
  map,
  isNull,
  has,
} from "lodash";
import { parseLinkHeader } from "@web3-storage/parse-link-header";
import { createBrowserHistory } from "history";
import { readString } from "react-papaparse";
import { toast } from "react-toastify";
import moment from "moment";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import { getLocationHref, getLocationPathname } from "./location";
import settings from "../settings";
import DataNotFound from "../components/PlaceExchange/images/no_adapprovals.png";
import ErrorMessages from "../components/PlaceExchange/DisplayError/ErrorMessages";
import { dayPartWeekDay } from "../components/PlaceExchange/components/OrderManagement/OrderDetails/PlacementMetaData/placementMapping";
import Authentication from "../components/PlaceExchange/Auth/Auth";
import ToastMsg from "../components/PlaceExchange/components/Utilities/ToastMsg";

const flatten = require("flat");
const { unflatten } = require("flat");

const getSearchParamValue = (urlSearchParam, urlSearchParamValue) =>
  urlSearchParam.value
    ? urlSearchParam.value(urlSearchParamValue)
    : urlSearchParamValue;

/*
 * Allows for placeholders in a string. Similar to Python's
 * format().
 *
 * Parameters:
 *  template - (string) Any '{}' is replaced by 'args'.
 *  args     - (...string) Values to be swapped into 'template'.
 *
 * Returns:
 *  (string) Formatted string.
 */
export const format = (template, ...args) => {
  let i = 0;
  return template.replace(/{}/g, () => args[i++]);
};

/*
 * Constructs a url based on the current window's url.
 *
 * Parameters:
 *  An args object that contains -
 *    searchParams    - (array) Search parameters to pass to url.
 *                      Objects that contain -
 *      key   - (string) The search parameter to add in the url.
 *      value - (string) Used to set the search paramter's value.
 *    url             - (string) A url. Supports '{}' used by format().
 *    urlSlugs        - (array) Slugs to be embedded in the url to be
 *                      generated. Objects that contain -
 *      key   - (string) The search parameter to lookup in the current
 *                window url.
 *    urlSearchParams - (array) Search parameters to pass through to url.
 *                      Objects that contain -
 *      key   - (string) The search parameter to lookup in the current
 *                window url.
 *      value - (function) Used to set the search paramter's value.
 *
 * Returns:
 *  (string) A url.
 */
const constructAPIEndPoint = (args) => {
  const params = args;
  const url = new URL(getLocationHref());

  params.urlSlugs = args.urlSlugs || [];
  const slugs = args.urlSlugs.map((urlSlug) =>
    url.searchParams.get(urlSlug.key)
  );
  const apiSearchParams = new URLSearchParams();

  if (args.searchParams) {
    args.searchParams.forEach((element) => {
      apiSearchParams.set(element.key, element.value);
    });
  } else if (args.urlSearchParams) {
    args.urlSearchParams.forEach((element) => {
      const apiSearchParamKey = element.key;
      const urlSearchParamValue = url.searchParams.get(apiSearchParamKey);

      if (urlSearchParamValue) {
        const apiSearchParamValue = getSearchParamValue(
          element,
          urlSearchParamValue
        );
        apiSearchParams.set(apiSearchParamKey, apiSearchParamValue);
      }
    });
  }

  return format("{}?{}", format(args.url, slugs), apiSearchParams.toString());
};

/*
 * Constructs an admin url.
 *
 * Parameters:
 *  An args object that contains -
 *    baseUrl - (string) A url. Defaults to 'settings.ADMIN_API_BASE_URL'.
 *
 * Note: For remaining args refer constructAPIEndPoint.
 *
 * Returns:
 *  (string) A url.
 */
export const constructAdminAPIEndPoint = (args) => {
  const baseUrl = args.baseUrl || settings.ADMIN_API_BASE_URL;
  return baseUrl + constructAPIEndPoint(args);
};

/*
 * Handles fetch requests for the given parameters.
 *
 * Parameters:
 *  body        - (string or object) Messages sent by the client to initiate an action
 *                on the server. Usually required for a post request.
 *                By default it adds JSON.stringify if 'Content-Type' is 'application/json'.
 *  credentials - (string) This indicates whether the user agent should
 *                send cookies from the other domain in the case of
 *                cross-origin requests (Possible values are omit, same-origin, include).
 *                Defaults to 'same-origin'
 *  getResponseHeaders - (bool) Required response headers in response.
 *  headers     - (object) A valid HTTP request header objects that contain -
 *    Accept        - Defaults to 'application/json'.
 *    Content-Type  - Defaults to 'application/json'.
 *    Authorization - An auth 0 token. Defaults to `Bearer ${Token}`.
 *  method      - (string) A valid HTTP request method (such as GET, POST etc.).
 *                 Defaults to 'GET'.
 *  responseType - (string) Required response type is Json or status code.
 *  url         - (string) The api url to be called.
 *
 * Returns:
 *  (object) A Promise.
 */
export const fetchHandler = (args) => {
  handleUserSession();

  const params = args;

  let headers = {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("px_access_token")}`,
  };

  if (!params.isMultipartFormData) {
    headers["Content-Type"] = "application/json";
  }

  params.headers = params.headers || {};
  headers = merge(headers, params.headers);

  const fetchBody = {
    method: args.method || "GET",
    credentials: args.credentials || "same-origin",
    headers,
    getResponseHeaders: args.getResponseHeaders || false,
    body:
      headers["Content-Type"] === "application/json"
        ? JSON.stringify(args.body)
        : args.body,
  };

  if (args.signal) {
    fetchBody.signal = args.signal;
  }

  const apiResponse = fetch(args.url, fetchBody)
    .then((response) => {
      if (args.responseType === "rawApiResponse") {
        return response;
      }
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 202 ||
        response.status === 204 ||
        response.status === 404 ||
        response.status === 401
      ) {
        let output;
        if (args.responseType === "status") {
          output = response.status;
          return output;
        }
        if (args.responseType === "headers") {
          return response.headers;
        }

        return response.json().then((json) => {
          if (args.getResponseHeaders) {
            output = {
              headers: response.headers,
              json,
            };
            // Just check if we get complete header response
            return output;
          }
          if (!args.getResponseHeaders) {
            output = json;
            return output;
          }
        });
      }
      throw response;
    })
    // TODO - Hook into Splunk.
    .catch((response) => {
      if (response.name === "AbortError" || response.status === 20) {
        return Promise.reject({ AbortError: "AbortError" });
      }
      console.log(
        `API failed with ${response.status} as ${response.statusText}`
      );
      const contentType = response.headers
        ? response.headers.get("content-type")
        : "";
      if (includes(contentType, "application/json")) {
        return response.json().then((value) =>
          Promise.reject({
            data: value,
            status: response.status,
          })
        );
      }

      const errorMessage = args.errorMessage
        ? args.errorMessage
        : ErrorMessages.API_FAILED_GENERIC;
      return Promise.reject(errorMessage);
    });

  return apiResponse;
};

/*
 * Format the given date in required format.
 *
 * Parameters:
 *  dateString - (string) Value which needs to be formatted.
 *               Supported value is a date in string format like "2019-05-02".
 *  format     - (string) The required date format. For eg. MM/DD/YYYY.
 *                Supported date formats are 'MM/DD/YY' and 'YYYY/MM/DD' as default.
 *
 * Returns:
 *  (string) Formatted Date string.
 */
export const getFormattedDate = (dateString, formatType) => {
  const date = new Date(dateString);
  const fullYear = date.getFullYear().toString();
  const year = fullYear.substr(-2);
  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;
  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;
  return formatType === "MM/DD/YY"
    ? `${month}/${day}/${year}`
    : `${fullYear}/${month}/${day}`;
};

export const history = createBrowserHistory();

/*
 * Returns a Date instance that is time shifted to UTC.
 *
 * Parameters:
 *  date - (object) An instance of Date.
 *         Ex. Mon Nov 18 2019 05:39:33 GMT+0530 (India Standard Time)
 *
 * Returns:
 *  (object) An instance of Date which would be time shifted to UTC.
 *  Ex. Mon Nov 18 2019 00:09:33 GMT+0530 (India Standard Time)
 */
export const dateInUtc = (date) => {
  const utcDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  return utcDate;
};

/*
 * Returns the unix timestamp of the Date instance passed to it.
 *
 * Parameters:
 *  date - (object) An instance of Date.
 *         Ex. Tue Nov 19 2019 05:30:00 GMT+0530 (India Standard Time)
 *
 * Returns:
 *  (integer) Unix timestamp of the Date instance passed to it.
 *  Ex. 1574121600000
 */
export const dateToUnixTimestamp = (date) => {
  const timestamp = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate()
  );
  return timestamp;
};

/*
 * Returns the sorted value based on width and height comparison.
 * Initially compares the widths and if equal it then compares height.
 *
 * Parameters:
 *  prev     - (string) Consist of height and width in the form 'widthxheight'.
 *              Ex. '300x250'
 *
 *  next     - (string) Consist of height and width in the form 'widthxheight'.
 *              Ex. '300x200'
 *
 *  sortType - (string) It specifies the type of sort. Acceptable values are 'asc' and 'desc'
 *              Ex. 'asc'
 * Returns:
 *  (number) Compares and returns the sorted value
 *  Ex. '300x200'
 */
export const sortDimensions = (prev, next, sortType) => {
  const prevWidth = prev.split("x")[0];
  const nextWidth = next.split("x")[0];
  const prevHeight = prev.split("x")[1];
  const nextHeight = next.split("x")[1];
  if (prevWidth !== nextWidth) {
    if (sortType === "asc") {
      return prevWidth - nextWidth;
    }
    return nextWidth - prevWidth; // desc
  }
  if (sortType === "asc") {
    return prevHeight - nextHeight;
  }
  return nextHeight - prevHeight; // desc
};

/*
 * Returns the HTML elements which show No search results found message, Icon.
 *
 * Returns:
 *  (function) which contains HTML structure
 * EX. Icon+Text
 */
export const noDataFoundText = () => (
  <>
    <img
      className="display-error__image"
      src={DataNotFound}
      alt="No search results found"
    />
    <p className="display-error__title display-error--no-margin">
      No search results found
    </p>
  </>
);

/*
 * Returns the api response count.
 *
 * Parameters:
 * response      - (array) which contains single or multiple objects
 *
 * Returns:
 *  (number) Total number of objects.
 */
export const getCount = (response) => {
  const xTotalCount = new Map(response.headers.entries()).get("x-total-count");
  return Number(xTotalCount);
};

/*
 * Returns the last page number when pagination filter is applied.
 *
 * Parameters:
 * response      - (array) which contains single or multiple objects
 *
 * Returns:
 *  (number) Last page.
 */
export const getLastPage = (response) => {
  if (response.headers.get("link")) {
    const linkHeader = response.headers.get("link");
    const parsed = parseLinkHeader(linkHeader);
    return Number(parsed["'last'"].page);
  }
};

/*
 * Returns a string representing the given string according to country specific conventions
 *
 * Parameters:
 * value      - (number) which needs to be formatted.
 * symbol      - (string) symbolic representation of currency.
 *
 * Returns:
 *  (string) Formatted string.
 */
export const currencyRepresentator = (value, symbol) =>
  `${symbol}${parseFloat(value).toFixed(2).toLocaleString()}`;

/*
 * Returns a flat array of object.
 *
 * Parameters:
 * fileFormat - (string) File extension. Supports default and csv.
 * data       - (array) which needs to be formatted.
 *
 * Returns:
 *  (array) Formatted array.
 */
export const modelFormatter = (fileFormat, data) => {
  if (fileFormat === "csv") {
    const flatModel = flatten(data);
    let replaceWithEmpty = mapValues(flatModel, () => "");
    replaceWithEmpty = unflatten(replaceWithEmpty);
    return replaceWithEmpty;
  }
  return data;
};

/*
 * Returns the flatten for array of object.
 *
 * Parameters:
 * data         - (array) which needs to be flatten.
 *
 * Returns:
 *  (object) Flatten array of objects.
 */

export const flattenData = (data) => {
  const allKeys = keys(get(data, [0], {}));
  const flattenObject = {};
  forEach(allKeys, (key) => {
    flattenObject[key] = map(data, key);
  });
  return flattenObject;
};

/*
 * Returns the array of object as per required for downloading.
 *
 * Parameters:
 * list         - (array) which needs to be formatted.
 * fileFormat   - (string) file extension. Supported format csv.
 * modelObj     - (object) used as reference to create a flat object.
 *
 * Returns:
 *  (array) Formatted array of objects.
 */
export const prepareCsvData = ({
  list,
  fileFormat,
  modelObj,
  jsonFields = [],
  dateTimePerseFields = [],
}) => {
  const flatModel = flatten(modelFormatter(fileFormat, modelObj));
  const setNewList = [];
  if (isEmpty(list)) {
    return [modelObj];
  }
  list.forEach((element) => {
    let listCopy = { ...element };
    dateTimePerseFields.map((item) => {
      if (listCopy[item]) {
        listCopy[item] = getUTCDateTime(listCopy[item]);
      }
    });
    jsonFields.map((item) => {
      if (!isEmpty(listCopy[item])) {
        listCopy[item] = JSON.stringify(listCopy[item]);
      }
      return null;
    });
    listCopy = flatten(listCopy, { safe: true });
    listCopy = omitBy(listCopy, isNil);
    listCopy = unflatten({ ...flatModel, ...listCopy });
    setNewList.push(listCopy);
  });
  return setNewList;
};

/*
 * Converts a comma separated string to an array.
 *
 * Parameters:
 * value         - (string) which needs to be converted.
 *
 * Returns:
 *  (array) An array of strings.
 */

export const commaSeparatedStringToArray = /* istanbul ignore next */ (
  value,
  trimLeadingSpaces = false
) => {
  let result;
  if (!isEmpty(value)) {
    result = trimLeadingSpaces
      ? value.split(/,\s|,/)
      : value.replace(/\s/g, "").split(",");
  } else {
    result = [];
  }
  return result;
};

/*
 * Returns formatted (string) bidfloor price with currency e.g. $1.
 *
 * Parameters:
 * bidfloor         - (string).
 * bidfloorcur      - (string).
 *
 * Returns:
 *  (string) Formatted string.
 */

export const bidFloorWithSymbol = (bidfloor, bidfloorcur) => {
  const currency = settings.BIDFLOORCUR_ARRAY_LOOKUP.filter(
    (element) => element.currency === (bidfloorcur || "USD")
  );
  const value = currencyRepresentator(bidfloor, currency[0].symbol);
  return value;
};

/*
 * Returns a reg ex string (escaped appropriately!) that can be used to
 * validate whether a given string is a valid URL.
 *
 * This is based off of Django's URLValidator() (see
 * https://docs.djangoproject.com/en/2.2/_modules/django/core/validators/)
 * for which (surprisingly!) no corresponding JS version exists today
 * despite requests in the wild (see
 * https://github.com/angular/angular.js/issues/6634#issuecomment-37839795).
 *
 * Useful in instances where there is a need to identify whether a URL
 * is valid same as the APIs, such as when plugging in validations on
 * Flatfile.
 *
 * Parameters:
 *  None.
 *
 * Returns:
 *  (string) A reg ex escaped string.
 */
export const urlRegEx = () => {
  // Note - backslashes "\" need to be double escaped "\\" since they
  // are escape characters in JS strings.

  // IP patterns.
  const ipv4Re =
    "(?:25[0-5]|2[0-4]\\d|[0-1]?\\d?\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|[0-1]?\\d?\\d)){3}";
  const ipv6Re = "\\[[0-9a-f:\\.]+\\]";

  // Host patterns.
  const hostNameRe = "[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?";
  // Max length for domain name labels is 63 characters per RFC 1034 sec. 3.1.
  const domainRe = "(?:\\.(?!-)[a-z0-9-]{1,63}[^-])*";
  const tldRe =
    "\\." + // dot
    "(?!-)" + // can't start with a dash
    "(?:[a-z-]{1,63}" + // domain label
    "|xn--[a-z0-9]{1,59})" + // or punycode label
    "[^-]" + // can't end with a dash
    "\\.?"; // may have a trailing dot

  // Note - unlike the Django version, unicode ranges are not embedded
  // since that seems unnecessary.
  const hostRe = `(${hostNameRe}${domainRe}${tldRe}|localhost)`;

  // Note - escape forward slashes "/" since they are the default
  // delimiters used in JS.
  const schemeRe = "^(?:http|ftp)s?:\\/\\/";
  const userPassAuthenticationRe = "(?:[^\\s:@/]+(?::[^\\s:@/]*)?@)?";
  const portRe = "(?::\\d{2,5})?";
  const resourcePathRe = "(?:[/?#][^\\s]*)?";

  // Note - "$" rather than "Z" is used to capture string termination
  // since "Z" is not supported in JS.
  return `^${schemeRe}${userPassAuthenticationRe}(?:${ipv4Re}|${ipv6Re}|${hostRe})${portRe}${resourcePathRe}$`;
};

/*
 * Splits comma separated string to array. Useful for cases where -
 * - commas happen to be present *in* the string, wrapped in double
 *    quotes, and should not be split
 * - trailing commas are present, which are ignored
 *
 * Parameters:
 *  inputString    - (string).
 *
 * Returns:
 *  (array) An array of strings.
 */
export const splitCommaSeparatedStringToArray = (inputString) => {
  if (Array.isArray(inputString)) {
    return inputString;
  }
  if (inputString) {
    /**
     * 1. Remove leading & trailing spaces
     * 2. Remove white spaces from string between comma and any letter.
     * 3. Drop trailing commas from start and end, so that users don't have to worry about dangling commas.
     */
    let sanitizedInputString = inputString
      .replace(/^\s+|\s+$/g, "")
      .replace(/^\s+|\s+$|\s*(?=([^\"]*\"[^\"]*\")*[^\"]*$),\s*/g, ",")
      .replace(/^,|,$/g, "");

    const results = readString(sanitizedInputString);
    return results.data[0];
  }
};

/*
 * Escape search string by wrapping the string within quotes. Useful for cases where -
 * - spaces happen to be present *in* the string, wrap in double
 *    quotes.
 * - double quotes are present, which are escaped by prefixing it with back slash(\).
 *
 * Parameters:
 *  inputString    - (string).
 *
 * Returns:
 *  (string) Spaces and inner quotes escaped string.
 */
export const escapeSearchText = (inputString) => {
  const escapeBackSlash = inputString.includes("\\")
    ? inputString.replace("\\", "\\\\")
    : inputString;
  const escapeQuote = escapeBackSlash.includes('"')
    ? escapeBackSlash.replace('"', '\\"')
    : escapeBackSlash;
  return `"${escapeQuote}"`;
};

/*
 * Calculates aspect ratio.
 *
 * Parameters:
 *  numerator     - (integer) width.
 *  denominator   - (integer) height.
 *
 * Returns:
 *  (array) width:height ratio.
 */
export const reduceRatio = (numerator, denominator) => {
  const ntor = numerator;
  const dtor = denominator;

  // from: http://pages.pacificcoast.net/~cazelais/euclid.html
  const gcd = (a, b) => {
    if (b === 0) return a;
    return gcd(b, a % b);
  };

  // take care of the simple case
  if (ntor === dtor) return "1:1";

  const divisor = gcd(+ntor, +dtor);
  const left = +(ntor / divisor);
  const right = +(dtor / divisor);

  return `${left}:${right}`;
};

/*
 * Initially calculates aspect ratio for each snapshot.
 * And then joins all unique aspect ratios in a string.
 *
 * Parameters:
 *  snapshots - (array) Video ad creatives.
 *                      Object that contain -
 *    h - (integer) Height of the snapshot generated for video ad.
 *    w - (integer) Width of the snapshot generated for video ad.
 *
 * Returns:
 *  (string) Comma seperated list of unique aspect ratios.
 */
export const getAspectRatios = (snapshots) => {
  const aspectRatioList = snapshots.map((element) => {
    const ratioValues = reduceRatio(Number(element.w), Number(element.h));
    return ratioValues;
  });
  return isEmpty(aspectRatioList) ? "-" : join(uniq(aspectRatioList));
};

/*
 * Track the user actions related information to Datadog.
 * It mainly shares the action name & the time required for action completion.
 *
 * Parameters:
 *  startTime - (integer) Time in ms when the action starts.
 *  endTime - (integer) Time in ms when the action ends.
 *  actionName - (string) Name to be displayed in the RUM's Actions view.
 */
export const customActionTrackerInDD = (startTime, endTime, actionName) => {
  const timeTaken = endTime - startTime;
  datadogRum.addAction(`${actionName}`, {
    actionTime: timeTaken,
  });
};

export const getDateFromUnixTimeStamp = (UnixTimeStamp, dateFormat) => {
  let humanDate = moment.unix(UnixTimeStamp).utc().format(dateFormat);
  let data = humanDate.split(" ");
  return data;
};

/*
 * format the dayparts and date fields
 *
 * Parameters:
 *  list
 *  placementModel
 */

const formatWeekDay = (record, placementModel) => {
  record["dayparts"] =
    get(record, "dayparts", []).length > 0
      ? flattenData(get(record, "dayparts"))
      : placementModel.dayparts;
  record["dayparts"].weekday =
    record["dayparts"].weekday.length > 0
      ? record["dayparts"].weekday.map(
          (value) => Object.keys(dayPartWeekDay)[value]
        )
      : record["dayparts"].weekday;
};

const formatDaypartsTime = (record, field) => {
  if (get(record, "dayparts", [])) {
    record["dayparts"][`${field}`] =
      record["dayparts"][`${field}`].length > 0
        ? record["dayparts"][`${field}`].map((value) =>
            moment(value, ["HH:mm:ss"]).format("h:mm A")
          )
        : [];
  }
};

export const formatDateAndDayparts = (list, placementModel) => {
  forEach(list, (record) => {
    formatWeekDay(record, placementModel);
    formatDaypartsTime(record, "end_time");
    formatDaypartsTime(record, "start_time");
    const startDateFormatted = !isNull(record["start_date"])
      ? getDateFromUnixTimeStamp(record["start_date"], "M/D/YYYY h:mm A")
      : null;
    const endDateFormatted = !isNull(record["end_date"])
      ? getDateFromUnixTimeStamp(record["end_date"], "M/D/YYYY h:mm A")
      : null;

    record["start_date"] = get(startDateFormatted, [0], "");
    record["start_time"] =
      get(startDateFormatted, [1], "") + " " + get(startDateFormatted, [2], "");
    record["end_date"] = get(endDateFormatted, [0], "");
    record["end_time"] =
      get(endDateFormatted, [1], "") + " " + get(endDateFormatted, [2], "");
  });
};

export const fixedEncodeURIComponent = (str) => {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16);
  });
};

/*
 * receive string of concatenated EIDs via inventory upload
 * and modify them to fit EID schema defined in Admin
 */

export const formatEids = (eids) => {
  if (eids.length > 0) {
    const eids_formatted = splitCommaSeparatedStringToArray(eids).reduce(
      (accumulator, eid) => {
        const [source, id] = eid.split(":").map((s) => s.toLowerCase());
        const existingItem = accumulator.find((item) => item.source === source);
        if (existingItem) {
          existingItem.uids.push({ id });
        } else {
          accumulator.push({ source, uids: [{ id }] });
        }
        return accumulator;
      },
      []
    );
    return eids_formatted;
  } else {
    return [];
  }
};

/*
 * Replace all the character of a string (from) char -> (to) value
 *
 * Parameters:
 *
 *    str    - (string) Input string in which we want to change some charcters.
 *    from   - (string/character) Which character we will be replace.
 *    to - (string/character) To which value it will be replace.
 *
 *
 * Returns:
 *  (string) Updated string in which all the (from) characters of the string
 *  replace with (to) value.
 *
 * Example
 * replaceAllCharacter("test%abc%",'%','%25')  -> test%25abc%25
 */

export const replaceAllCharacter = (str = "", from, to) => {
  return str.replace(new RegExp(`[${from}]`, "g"), to);
};

/*
 * This function will take a string
 * and if there are any '%'
 * special character (unDecodableURI) present in the string
 * it replace % with %25
 *
 * Parameters:
 *
 *    str    - (string) Input string in which we want to replace % to %25
 *
 *Returns:
 *
 *      (str)  from the onput string if there are any special character
 *      (unDecodableURI) present it will replace with %25
 *
 * example:
 *
 * decodeUrlContainPercent(sfaf%26%bdfb%26fbhf%%265) => sfaf%26%25bdfb%26fbhf%25%265
 */
export const decodeUrlContainPercent = (str) => {
  const strArr = str.split("");
  const percentIndexAll = [];
  const percentIndex = [];
  strArr.forEach((p, idx) => (p === "%" ? percentIndexAll.push(idx) : null));
  percentIndexAll.forEach((idx) => {
    try {
      decodeURIComponent(str.substring(idx, idx + 3));
    } catch (e) {
      percentIndex.push(idx);
    }
  });
  percentIndex.forEach((idx) => {
    strArr.splice(idx, 1, "%25");
  });
  return strArr.join("");
};

/*
 * Returns array of object using cursor pagination.
 *
 * Parameters:
 *  orgId - organization id.
 *  model - name of model (deals, networks etc)
 * Returns:
 *  Array of object which contains model data
 */

export const cursorPaginationApi = async (
  orgId,
  model,
  headers = {},
  params = [],
  isSignal
) => {
  let shouldFetchApi = true;
  let cursorValue = "";
  let modelData = [];
  const fetchPerPageModelData = (cursorValue) =>
    fetchHandler({
      url: constructAdminAPIEndPoint({
        url: `orgs/${orgId}/${model}`,
        searchParams: [
          { key: "pager", value: "cursor" },
          { key: "cursor", value: cursorValue },
          ...params,
        ],
      }),
      getResponseHeaders: true,
      headers: headers,
      signal: isSignal || "",
    });

  while (shouldFetchApi) {
    await fetchPerPageModelData(cursorValue)
      .then((response) => {
        if (response && response.headers.get("link")) {
          const linkHeader = response.headers.get("link");
          const parsed = parseLinkHeader(linkHeader);
          cursorValue = parsed["'next'"].cursor;
          shouldFetchApi = parsed["'next'"].url;
          modelData.push(response.json);
        } else {
          shouldFetchApi = false;
          throw ErrorMessages.API_FAILED_GENERIC;
        }
      })
      .catch(() => {
        shouldFetchApi = false;
        modelData.push({
          error: ErrorMessages.API_FAILED_GENERIC,
        });
      });
  }
  return flat(modelData);
};

/*
 * Returns the readable date time from the utc date string
 *
 * Parameters:
 * inputDate - utc date string
 * Returns:
 * date string
 */

export const getUTCDateTime = (inputDate) => {
  let settDateTime = "";
  if (inputDate) {
    if (typeof inputDate === "number") {
      settDateTime = getDateFromUnixTimeStamp(inputDate, "M/D/YYYY")[0];
    } else {
      settDateTime = moment(inputDate).isValid()
        ? moment(inputDate).utc().format("M/D/YYYY h:mm A")
        : inputDate;
    }
  }
  return settDateTime;
};

/*
 * Returns an html element which feature on hover a tooltip
 *
 * Parameters:
 * data - any string value
 * tooltipText - any string value
 * Returns:
 * an html span elememt wrapped by the Tooltip (custom message as per the tooltipText input).
 */

export const showCustomTooltip = (data, tooltipText) =>
  data === "-" ? (
    <span>{data}</span>
  ) : (
    <Tippy content={tooltipText} placement="bottom" delay={[500, 0]}>
      <span>{data}</span>
    </Tippy>
  );

/*
 * In case of model level insertion/updates, this function checks if a similar record already exist in system.
 * If it does then it returns the specific model object.
 *
 * Parameters:
 * orgId  - (string) Organization id for which records is to be checked
 * model  - (string) Name of the model. For example - `deals`, `placements`, etc.
 * name   - (string) Name of the record
 * slug   - (string) Passed in url as query_param to fetch a different set if datatype for a particular field
 *          Supported slugs are 'name'.
 *          If `slug=name` then it replaces the `id's` in the output response with `names.
 *
 * Returns:
 *  (object) JSON object
 */

export const checkIfModelDataExist = async (orgId, modelName, name, slug) => {
  return fetchHandler({
    method: "GET",
    url: constructAdminAPIEndPoint({
      url: `orgs/${orgId}/${modelName}/${fixedEncodeURIComponent(name)}`,
      searchParams: [
        { key: "slug_field", value: slug || "" },
        { key: "cache", value: false },
      ],
    }),
    headers: {
      "cache-control": "no-cache, no-store",
    },
  }).then((json) => json);
};

export const getPlacementDealsData = async (orgId, modelName, name) => {
  const baseUrl = settings.ADMIN_API_BASE_URL;
  const endpointUrl = `orgs/${orgId}/${modelName}?placement_name=${encodeURIComponent(
    name
  )}`;
  const url = `${baseUrl}${endpointUrl.replace(/^\//, "")}`;

  return fetchHandler({
    method: "GET",
    url: url,
    headers: {
      "cache-control": "no-cache, no-store",
    },
  }).then((json) => json);
};
/*
 * Track the user actions related information to Datadog.
 * It mainly shares the action name & custom json body required for action completion.
 *
 * Parameters:
 *  actionName - (string) Name to be displayed in the RUM's Actions view.
 *  actionBody  - (object) custom json body in the RUM's Action view.
 */

export const trackCustomActionsInDD = (actionName, actionBody) => {
  datadogRum.addAction(`${actionName}`, {
    actionBody,
  });
};

/*
 * Prepare object for fetch handler
 *
 * Parameters:
 *  method - (string) method type "GET"/"POST"/"PATCH"
 *  url - (string) the endpoint url for the request.
 *  body - (object) the request body we want to pass for the post or patch request.
 */

export const prepareArgsForFetchHandler = (method, url, reqBody) => ({
  headers: {
    "cache-control": "no-cache, no-store",
  },
  method: method,
  url: constructAdminAPIEndPoint({
    url: url,
  }),
  ...(reqBody && { body: reqBody }),
});

/*
 * Prepare object for error handling
 *
 * Parameters:
 *  modelText - (string) text related to model. For example "placements/deals/adunits"
 *
 * Returns:
 *  (object) JSON object
 */
export const getErrorMessage = (modelText) => {
  modelText = modelText || "records";
  const errorMessage = { ...ErrorMessages.API_FAILED_ERROR };
  errorMessage.title = errorMessage.title.replace("<<modelText>>", modelText);
  return errorMessage;
};

/*
 * Returns text related to model for error handling
 *
 * Parameters:
 *  datasetId - (string) dataset id. For example "2d350e95-af50-4ed6-9b22-9670df49acdd"
 *
 * Returns:
 *  string
 */
export const getDatasetText = (datasetId) => {
  const datasetsIdLookupList = {
    [settings.DATASETS_ID_LOOKUP.AUCTION_TYPE_ID]: "auction type",
    [settings.DATASETS_ID_LOOKUP.ADUNIT_INTEGRATION_TYPE_ID]:
      "ad unit integration type",
    [settings.DATASETS_ID_LOOKUP.ATTRIBUTE_ID]: "attribute",
    [settings.DATASETS_ID_LOOKUP.ADUNIT_STATUS_ID]: "ad unit status",
    [settings.DATASETS_ID_LOOKUP.ASSET_CATEGORY_ID]: "asset category",
    [settings.DATASETS_ID_LOOKUP.CONTRACT_PUB_ID]: "publisher contract",
    [settings.DATASETS_ID_LOOKUP.CATEGORY_ID]: "category",
    [settings.DATASETS_ID_LOOKUP.DEAL_WBUYER_ID]: "deal wbuyer",
    [settings.DATASETS_ID_LOOKUP.DEAL_NON_SANDBOX_WBUYER_ID]:
      "deal non sandbox wbuyer",
    [settings.DATASETS_ID_LOOKUP.MEASUREMENT_PROVIDER_ID]:
      "measurement provider",
    [settings.DATASETS_ID_LOOKUP.VENUE_CATEGORY_ID]: "venue category",
    [settings.DATASETS_ID_LOOKUP.DEAL_PRIORITY_ID]: "deal priority",
    [settings.DATASETS_ID_LOOKUP.CAP_TYPE_ID]: "cap type",
    [settings.DATASETS_ID_LOOKUP.COUNTRY_LIST_ID]: "country list",
  };
  return datasetsIdLookupList[datasetId] || "";
};

/*
 * Return the navigation link
 *
 * Parameters:
 *  cell: It represents the content that will be displayed inside the <Link> component.
    row: It contains information related to the current row in the table.
    url: Specify the URL of the page the link goes to.
 */
export const createLinkToTabularColumn = (cell, row, url) => {
  return (
    <Link to={url} data-index={row.name} className={`link${row.name}`}>
      {cell}
    </Link>
  );
};

/*
 * Redirect to login page if token expired.
 */
export const handleUserSession = (bufferTime = false) => {
  if (
    !Authentication.AuthFunc.isAuthenticated(bufferTime) &&
    window.sessionHistory.location.pathname !== "/login"
  ) {
    // This code is for redirect user returns back to the same page from where it redirected to the login page
    localStorage.setItem(
      "redirectURL",
      `${window.sessionHistory.location.pathname}${window.sessionHistory.location.search}`
    );
    localStorage.setItem("px_expires_at", 0);
    window.sessionHistory.push("/login");
  }
};

/*
 * Take a string value and return boolean equivalent value
 *
 * Parameters:
 *  str: (string) spells true or false case insensitive
 *
 * Returns:
 *  (bool) true/false
 */
export const strToBool = (str) => {
  if (str) {
    str = str.toString().toLowerCase();
  }
  return str === "true";
};

/*
 * Get content location key name for async upload
 *
 * Parameters:
 *  model: (string) model name
 *
 * Returns:
 *  string
 */
export const getContentLocationKey = (model = "adunits") => {
  return `px_${model}_async_upload_content_location`;
};

/*
 * Track the async upload file count related information to Datadog.
 *
 * Parameters:
 *  actionName - (string) Name to be displayed in the RUM's Actions view.
 */

export const trackAsyncUploadFileCountInDD = (actionName) => {
  const asyncUploadFileCount = 1;
  datadogRum.addAction(`${actionName}`, {
    asyncUploadFileCount: asyncUploadFileCount,
  });
};

/*
 * Show toaster notification to user.
 *
 * Parameters:
 *  isIcon - (bool) Icon to display or not.
 *  iconType - (array) Icon type default will be ["fa", "fa-exclamation-triangle"].
 *  notificationTitle - (string) Title of the notification.
 *  notificationDescription - (string) Description of the notification.
 *  autoClose - (bool) Automatically close or not.
 *  closeOnClick - (bool) Close on click of notification or not.
 */

export const notifyUser = (
  isIcon = true,
  iconType = ["fa", "fa-exclamation-triangle"],
  notificationTitle = "Auto-correcting locations for accuracy",
  notificationDescription = "If the Lat/Long for adunits don't match the provided city, country, DMA, or DMA code, we will validate and correct their locations for you.",
  autoClose = false,
  closeOnClick = false
) => {
  const timestamp = Date.now();
  return toast.warning(
    <ToastMsg
      isIcon={isIcon}
      icon={iconType}
      toastTitle={notificationTitle}
      toastSubTitle={notificationDescription}
    />,
    {
      toastId: `async-upload-warning-${timestamp}`,
      position: "top-center",
      autoClose: autoClose,
      closeOnClick: closeOnClick,
    }
  );
};

/*
 * Check if user already notified with perticular notification type or not.
 *
 * Parameters:
 *  notificationType - (string) Notification type to check
 *
 * Returns:
 *  (bool) true/false
 */

export const checkNotifiedUser = (notificationType) => {
  const notifyAdunitImportWarning = JSON.parse(
    localStorage.getItem("hiddenNotifications") || "{}"
  );
  const notifiedusers =
    notifyAdunitImportWarning[notificationType] &&
    notifyAdunitImportWarning[notificationType].length > 0
      ? notifyAdunitImportWarning[notificationType]
      : [];
  return (
    notifiedusers.length > 0 &&
    notifiedusers.indexOf(localStorage.getItem("px_user")) >= 0
  );
};

/*
 * Set user not notified with perticular notification type.
 *
 * Parameters:
 *  notificationType - (string) Notification type to check
 * Returns:
 *  (array) list of users in notificationType.
 */

export const setNotifiedUser = (notificationType) => {
  const notifyAdunitImportWarning = JSON.parse(
    localStorage.getItem("hiddenNotifications") || "{}"
  );

  const notifiedusers =
    notifyAdunitImportWarning[notificationType] &&
    notifyAdunitImportWarning[notificationType].length > 0
      ? notifyAdunitImportWarning[notificationType]
      : [];
  const updatedNotifiedUserList =
    notifiedusers.indexOf(localStorage.getItem("px_user")) >= 0
      ? notifiedusers
      : [...notifiedusers, localStorage.getItem("px_user")];
  localStorage.setItem(
    "hiddenNotifications",
    JSON.stringify({
      ...notifyAdunitImportWarning,
      [notificationType]: updatedNotifiedUserList,
    })
  );
  return updatedNotifiedUserList;
};
