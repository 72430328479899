import React, { PureComponent } from "react";
import { Icon } from "@px/px_design_system";

export default class TopSummary extends PureComponent {
  render() {
    const { isEditMode, text = "" } = this.props;
    return (
      isEditMode &&
      text && (
        <p className="form__summary">
          <Icon icon={["fas", "exclamation-triangle"]} size="1x" />
          {text}
        </p>
      )
    );
  }
}
