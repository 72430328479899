import React from "react";
import settings from "../../../../../settings";
import { Button, Icon } from "@px/px_design_system";

export default class DownloadDataDict extends React.Component {
  render() {
    return (
      <a
        href={`${settings.PORTAL_INVENTORY_REF_URL}`}
        download="PX_Inventory_Reference.xlsx"
        id="downloadRefData"
      >
        <Button
          buttontext="Download Data Dictionary"
          icon={<Icon icon={["fas", "arrow-down"]} size="1x" />}
        />
      </a>
    );
  }
}
