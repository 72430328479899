/* istanbul ignore file */

import React from "react";
import * as yup from "yup";
import "./style.css";
import info from "./icons/info.svg";
import {
  Accordion,
  AccordionBody,
  AccordionTitle,
  Form,
  ImageVideoUploader,
  Input,
  Section,
  getFieldValue,
  isFormDirty,
  setFieldValue,
  setFormError,
} from "@px/px_design_system";
import { HelpBody } from "../OrderManagement/Forms/Add/HelpBody";
import {
  checkIfModelDataExist,
  commaSeparatedStringToArray,
  constructAdminAPIEndPoint,
  fetchHandler,
  reduceRatio,
} from "../../../../utils";
import WithFeatureStatus from "../Utilities/WithFeatureStatus";
import { get, map } from "lodash";

class FormAd extends React.Component {
  state = {
    globalError: false,
    disableSubmit: false,
    isAdm: true,
    presignedURL: {},
  };

  preparePayload = (formData) => {
    /** if not creative upload then submit the form and create single as */
    if (this.state?.isAdm) {
      delete formData?.creatives;

      Promise.all([this.submitAds(formData)]).then(() => {
        const { modalRef } = this.props;
        this.setState({ disableSubmit: false });
        modalRef?.current?.toggle();
      });

      return;
    }

    /** Submit for `n` number of creative upload */
    const submitAds = map(formData?.creatives, (file) => {
      return this.submitAds({
        adm: "",
        adomain: formData.adomain,
        aspect_ratio: reduceRatio(Number(file.width), Number(file.height)),
        content_category: formData.content_category,
        h: file.height,
        mimes: file.type,
        name: file.s3FileName,
        w: file.width,
        s3FileUrl: file.s3FileUrl,
      });
    });

    Promise.all(submitAds).then(() => {
      const { modalRef } = this.props;
      this.setState({ disableSubmit: false });
      modalRef?.current?.toggle();
    });
  };

  submitAds = async (formData) => {
    const { multiSelectRef, modalRef, orgId } = this.props;
    const adPayload = { ...formData };
    adPayload["dadid"] = adPayload["name"];
    adPayload["audit"] = { status: 1 };
    adPayload["ext"] = {
      publisher: [orgId],
    };
    const creativeObject = {
      adm: adPayload["adm"],
      h: adPayload["h"],
      w: adPayload["w"],
      mimes: [adPayload["mimes"]],
    };
    if (adPayload["mimes"] === "video/mp4") {
      adPayload["creative"] = {
        video: creativeObject,
      };
      adPayload["creative"]["video"]["dur"] = adPayload["dur"]
        ? adPayload["dur"]
        : null;
    } else {
      adPayload["creative"] = {
        display: {
          ...creativeObject,
          ext: {
            file: adPayload["s3FileUrl"],
          },
        },
      };
    }
    if (adPayload["adomain"].length > 0) {
      adPayload["adomain"] = commaSeparatedStringToArray(adPayload["adomain"]);
    } else {
      delete adPayload.adomain;
    }
    delete adPayload.content_category;
    delete adPayload.adm;
    delete adPayload.h;
    delete adPayload.w;
    delete adPayload.mimes;
    delete adPayload.dur;
    delete adPayload.s3FileUrl;
    const checkDuplicateAds = await checkIfModelDataExist(
      orgId,
      "ads",
      adPayload?.name,
      "name"
    );
    if (checkDuplicateAds?.name) {
      setFormError("name", {
        type: "manual",
        message: "Ad name already exists.",
      });
      this.setState({ disableSubmit: false });
    } else {
      this.setState({
        disableSubmit: true,
        globalError: false,
      });
      const url = `orgs/${orgId}/ads`;
      const apiParams = {
        method: "POST",
        url: constructAdminAPIEndPoint({ url: url }),
        headers: { "cache-control": "no-cache, no-store" },
        body: adPayload,
      };
      return fetchHandler(apiParams)
        .then((response) => {
          multiSelectRef?.current?.createNewOption([
            get(response, "ads.0.name"),
          ]);
          // this.setState({ disableSubmit: false });
          // modalRef?.current?.toggle();
        })
        .catch((error) => {
          this.setState({ globalError: true });
          this.setState({ disableSubmit: false });
        });
    }
  };

  handleCancelCallback = () => {
    const { modalRef } = this.props;
    modalRef?.current?.toggle();
  };

  handleAdDimensionBlurEvent = () => {
    const width = getFieldValue("w");
    const height = getFieldValue("h");
    if (width && height) {
      setFieldValue("aspect_ratio", reduceRatio(Number(width), Number(height)));
    }
  };

  componentDidMount() {
    const { orgId } = this.props;
    fetchHandler({
      method: "GET",
      url: constructAdminAPIEndPoint({
        url: `portal/orgs/${orgId}/ads/asset-upload-url`,
      }),
      headers: { "cache-control": "no-cache, no-store" },
    })
      .then((response) => {
        console.log(response);
        this.setState({
          presignedURL: response,
        });
      })
      .catch((error) => {
        this.setState({ globalError: true });
        this.setState({ disableSubmit: false });
      });
  }

  render() {
    const { disableSubmit, globalError, isAdm, presignedURL } = this.state;
    const validationSchema = isAdm
      ? {
          name: yup
            .string()
            .max(100)
            .required("Name is a required field")
            .validateEmptyNameField("Name field can not be blank")
            .validateSpaceNameField(
              "Name should not have leading or trailing white spaces"
            ),
          w: yup.string().required("Width is a required field"),
          h: yup.string().required("Height is a required field"),
          adm: yup.string().required("Adm is a required field"),
          mimes: yup.string().required("Mimes is a required field"),
          // content_category: yup
          //   .string()
          //   .required("Content category is a required field"),
        }
      : {
          creatives: yup.array().min(1, "At-least 1 creative is required"),
          mimes: yup.string().required("Mimes is a required field"),
          // content_category: yup
          //   .string()
          //   .required("Content category is a required field"),
        };
    return (
      <>
        <div className="wrapper ad--form-container">
          <div className="ad--form--container">
            <h2 className="sub__headline--copy">Create an Ad</h2>
          </div>
          <div className="help--section">
            <Accordion>
              <AccordionTitle defaultOpen={true}>
                <img src={info} alt="info" /> How to create an Ad?
              </AccordionTitle>
              <AccordionBody>
                <HelpBody model="ads" />
              </AccordionBody>
            </Accordion>
          </div>
          <Form
            onSubmit={(formData) => this.preparePayload(formData)}
            canCelButtonCallback={this.handleCancelCallback}
            submitButtonText="Create Ad"
            tooltipTitle=""
            validationSchema={validationSchema}
            disableSubmit={disableSubmit}
            globalError={globalError}
          >
            <Section
              title={
                <WithFeatureStatus
                  text="Ad Information"
                  statusText="Beta"
                  statusColor="primary"
                />
              }
            ></Section>

            {isAdm ? (
              <Input
                type="text"
                name="name"
                label="Name"
                isRequired
                subTitle="Publisher defined name"
              />
            ) : (
              <></>
            )}

            <Input
              type="text"
              name="adomain"
              label="Adomain"
              subTitle="Enter the Ad domain"
            />

            {isAdm ? (
              <Input
                type="number"
                name="w"
                formGroupClassName="ad--dimension--width--input"
                label="Width"
                isRequired
                onBlur={this.handleAdDimensionBlurEvent}
              />
            ) : (
              <></>
            )}

            {isAdm ? (
              <Input
                type="number"
                name="h"
                formGroupClassName="ad--dimension--height--input"
                label="Height"
                isRequired
                onBlur={this.handleAdDimensionBlurEvent}
              />
            ) : (
              <></>
            )}

            <Input
              type="select"
              name="mimes"
              formGroupClassName="ad--mime"
              label="Mimes"
              subTitle="Select mimes"
              onChange={(eve) => {
                if (eve) {
                  this.setState({
                    isAdm: eve.target.value !== "image/jpeg | png",
                  });
                  setFieldValue("mimes", eve.target.value);
                }
              }}
            >
              <option value="" disabled selected hidden>
                Select Mimes
              </option>
              <option value="text/html">text/html</option>
              <option value="video/mp4">video/mp4</option>
              <option value="image/jpeg | png">image/jpeg | png</option>
            </Input>

            {isAdm ? (
              <Input
                type="textarea"
                name="adm"
                label="Adm"
                isRequired={isAdm ? true : false}
                placeholder="<html>"
                subTitle="Enter Ad location source tag"
              />
            ) : (
              <ImageVideoUploader
                name="creatives"
                params={presignedURL}
                title="Creatives"
                isRequired
              />
            )}
            {Boolean(isAdm && getFieldValue("mimes") === "video/mp4") ? (
              <Input
                type="number"
                name="dur"
                label="Duration"
                subTitle="Enter duration for video ad"
              />
            ) : (
              <></>
            )}
            <Input
              type="select"
              name="content_category"
              label="Content category"
              subTitle="Select appropriate content category"
            >
              <option value="" disabled selected hidden>
                Select Content Category
              </option>
              <option value="11">HTML</option>
              <option value="12">AMP HTML</option>
              <option value="13">Native</option>
              <option value="14">Dynamic Creative</option>
              <option value="501">XHTML_TEXT_AD</option>
              <option value="502">XHTML_BANNER_AD</option>
              <option value="503">JAVASCRIPT_AD</option>
              <option value="504">IFRAME</option>
            </Input>
          </Form>
        </div>
      </>
    );
  }
}

export default FormAd;
