/* istanbul ignore file */
import { isNil } from "lodash";

export const vlookup = (
  record,
  referenceFieldKey, // Field in the original record linking to another record
  lookupFieldKey, // Field in the linked record containing the value to be retrieved
  targetFieldKey, // Destination field in the original record for the retrieved value
  errorMessage
) => {
  const links = record.getLinks(referenceFieldKey);
  const lookupValue = links?.[0]?.[lookupFieldKey];

  const isNotNil = (val) => !isNil(val);

  if (isNotNil(lookupValue)) {
    record.set(targetFieldKey, lookupValue);
    record.addInfo(
      targetFieldKey,
      `${targetFieldKey} set based on ${referenceFieldKey}.`
    );
  }

  /* Set error if option does not match with lookup values */
  if (isNil(links) && isNotNil(record.data[referenceFieldKey])) {
    record.addError(targetFieldKey, errorMessage);
  }
};
