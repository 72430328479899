import React from "react";
import PropTypes from "prop-types";

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { UserProvider } from "../../context/auth";
import { handleUserSession } from "../../../../utils";
import { AsyncUploadProvider } from "../../context/AsyncUploadContext";

import "./style.css";

export default (BaseComponent) => {
  const WithLogin = (props) => {
    handleUserSession();

    return (
      <UserProvider {...props}>
        <AsyncUploadProvider>
          <Sidebar {...props} />
          <Header {...props} />
          <div className="main__wrapper sidenav__side-container">
            <BaseComponent {...props} />
          </div>
          <Footer />
        </AsyncUploadProvider>
      </UserProvider>
    );
  };

  WithLogin.propTypes = {
    history: PropTypes.shape(),
  };

  return WithLogin;
};
