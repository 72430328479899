import React, { useState, useEffect, useContext } from "react";
import { flatten, isEmpty } from "lodash";
import PropTypes from "prop-types";
import queryString from "query-string";
import { parseLinkHeader } from "@web3-storage/parse-link-header";

import settings from "../../../../settings";
import ErrorMessages from "../../DisplayError/ErrorMessages";
import {
  constructAdminAPIEndPoint,
  cursorPaginationApi,
  fetchHandler,
  fixedEncodeURIComponent,
  decodeUrlContainPercent,
  getErrorMessage,
} from "../../../../utils";

import { UserContext } from "../auth";

const AdApprovalSummaryContext = React.createContext();

const AdApprovalSummaryProvider = (props) => {
  let auditStatusValue = "1";
  let searchValue = "";
  let orderingValue = "-adapprovalaudit__lastmod";
  let viewAdApproval = 1;
  const auditStatuses = ["1", "2", "3", "2,3", "4", "all"];
  const { loading, userPermission, organization } = useContext(UserContext);
  const { children, location, history } = props;
  const [displayError, resetDisplayError] = useState(false);
  const [isRouteVerified, setRouteVerification] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [adApprovalQueueData, setAdApprovalQueueData] = useState([]);
  const [categoryList, setcategoryList] = useState([]);
  const [attributeList, setattributeList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [networkList, setNetworkList] = useState([]);
  const [isSingleAdApproval] = useState(!location.pathname.includes("queue"));
  const [totalAdApprovalCount, setTotalAdApprovalCount] = useState(0);
  const queryParams = queryString.parse(location.search);

  let activeTab;
  switch (queryParams && queryParams.audit__status) {
    case "4":
      activeTab = "rejected";
      break;
    case "2,3":
    case "2":
    case "3":
      activeTab = "approved";
      break;
    case "1":
      activeTab = "pending";
      break;
    default:
      activeTab = "all";
  }

  const verifyRouteParams = () => {
    const queryParamValue = {};
    const values = queryString.parse(location.search);

    const searchParam = values.search ? values.search : "";
    if (!isEmpty(searchParam)) queryParamValue.search = searchParam;

    const orderingParam = values.ordering
      ? values.ordering
      : "-adapprovalaudit__lastmod";
    queryParamValue.ordering = orderingParam;

    queryParamValue.audit__status =
      auditStatuses.indexOf(values.audit__status) > -1
        ? values.audit__status
        : "1";
    if (!isSingleAdApproval) queryParamValue.view = values.view || "1";

    history.push(`?${queryString.stringify(queryParamValue)}`);

    setRouteVerification(true);
  };

  const getSearchParam = () => {
    const values = queryString.parse(location.search);
    auditStatusValue = values.audit__status;
    orderingValue = values.ordering;
    searchValue = values.search;
    viewAdApproval = values.view;
  };

  const handleError = (error) => {
    resetDisplayError(true);
    setErrorMessage(error);
    setIsLoaded(true);
  };

  const getAdAdapprovalCount = (response) => {
    if (response.headers.get("link")) {
      const linkHeader = response.headers.get("link");
      const parsed = parseLinkHeader(linkHeader);
      setTotalAdApprovalCount(Number(parsed["'last'"].page));
      return Number(parsed["'last'"].page);
    }
  };

  const fetchDspOrgsData = (adApproval) => {
    const errorMessage = getErrorMessage("DSP name");

    return fetchHandler({
      url: constructAdminAPIEndPoint({
        url: `orgs/${adApproval.owned_by}/adapprovals/${fixedEncodeURIComponent(
          adApproval.name
        )}/dsp_details`,
      }),
      errorMessage: errorMessage,
    })
      .then((data) => {
        if (!isEmpty(data)) {
          const returnData = [
            {
              ...adApproval,
              dspOrgname: data.name,
            },
          ];
          return returnData;
        }
        throw ErrorMessages.NO_DSP_ORG_FOUND;
      })
      .catch((error) => {
        if (!error.data) {
          return Promise.reject(error);
        }
        return Promise.reject(errorMessage);
      });
  };

  const updateViewInURL = (view) => {
    const values = queryString.parse(location.search);
    values.view = view;
    history.push(`?${queryString.stringify(values)}`);
  };

  const navigateToPath = () => {
    history.push({
      pathname: `/orgs/${organization.id}/adapprovals/summary`,
      search: location.search,
    });
  };

  const fetchAdApprovalData = () => {
    let adApprovalName = location.pathname.split("/")[4];
    try {
      decodeURIComponent(adApprovalName);
    } catch (e) {
      let url_path = location.pathname.replace(`${adApprovalName}`, "");
      let reform_name = decodeUrlContainPercent(adApprovalName);
      history.push({
        pathname: `${url_path + encodeURIComponent(reform_name)}`,
        search: location.search,
      });
      adApprovalName = decodeUrlContainPercent(adApprovalName);
    }
    let obj = [
      { key: "page", value: viewAdApproval },
      { key: "page_size", value: 1 },
    ];
    let searchParams;
    const url = isSingleAdApproval
      ? `orgs/${organization.id}/adapprovals/${adApprovalName}`
      : `orgs/${organization.id}/adapprovals`;
    if (isSingleAdApproval) searchParams = [{ key: "cache", value: false }];
    if (!isSingleAdApproval) {
      obj = [{ key: "ordering", value: orderingValue }, ...obj];
      if (auditStatusValue !== "all") {
        obj = [{ key: "status", value: auditStatusValue }, ...obj];
      }
      if (searchValue) {
        obj = [{ key: "search", value: searchValue }, ...obj];
      }
      searchParams = obj;
    }

    const errorMessage = getErrorMessage("ad approval");
    const apiParams = {
      url: constructAdminAPIEndPoint({
        url,
        searchParams,
      }),
      getResponseHeaders: true,
      headers: {
        "cache-control": "no-cache, no-store",
      },
      errorMessage: errorMessage,
    };
    let count;
    return fetchHandler(apiParams)
      .then((response) => {
        count = getAdAdapprovalCount(response);
        if (viewAdApproval > count) {
          updateViewInURL(count);
        } else if (viewAdApproval < 1 || !/^[0-9]+$/.test(viewAdApproval)) {
          updateViewInURL(1);
        }

        if (
          (!isSingleAdApproval && !isEmpty(response.json)) ||
          (isSingleAdApproval && response.json.message !== "Not Found")
        ) {
          return fetchDspOrgsData(
            isSingleAdApproval ? response.json : response.json[0]
          );
        }

        if (
          isEmpty(response.json) ||
          (isSingleAdApproval && response.json.message === "Not Found")
        ) {
          navigateToPath();
        }

        throw ErrorMessages.NO_ADAPPROVAL;
      })
      .catch((error) => {
        if (!error.data) {
          return Promise.reject(error);
        }
        return Promise.reject(errorMessage);
      });
  };

  const fetchAllNetworks = async () => {
    let results = [];
    const headers = {
      "cache-control": "no-cache, no-store",
    };
    const searchParams = [{ key: "fields", value: "name" }];
    results = await cursorPaginationApi(
      organization.id,
      "networks",
      headers,
      searchParams
    );
    if (results[0] && results[0].hasOwnProperty("error")) {
      return Promise.reject(results[0].error);
    }
    return results;
  };

  const loadData = () => {
    // TODO - Would be nice to simplify/optimize this by parallelizing
    // things that need not wait.
    const fetchOrganizationData = fetchAdApprovalData();
    const errorMessageCategory = getErrorMessage("category");

    const fetchCategoryData = fetchHandler({
      url: constructAdminAPIEndPoint({
        url: `datasets/${settings.DATASETS_ID_LOOKUP.CATEGORY_ID}`,
      }),
      errorMessage: errorMessageCategory,
    })
      .then((data) => {
        if (data.members && data.members.length) return data.members;
        throw ErrorMessages.NO_CATEGORY_FOUND;
      })
      .catch((error) => {
        if (!error.data) return Promise.reject(error);
        return Promise.reject(errorMessageCategory);
      });

    const errorMessageAttribute = getErrorMessage("attribute");
    const fetchAttributeData = fetchHandler({
      url: constructAdminAPIEndPoint({
        url: `datasets/${settings.DATASETS_ID_LOOKUP.ATTRIBUTE_ID}`,
      }),
      errorMessage: errorMessageAttribute,
    })
      .then((data) => {
        if (data.members && data.members.length) return data.members;
        throw ErrorMessages.NO_ATTR_FOUND;
      })
      .catch((error) => {
        if (!error.data) return Promise.reject(error);
        return Promise.reject(errorMessageAttribute);
      });

    // fetch language data
    const errorMessageLanguage = getErrorMessage("language");
    const fetchLanguageData = fetchHandler({
      url: constructAdminAPIEndPoint({
        url: `datasets/${settings.DATASETS_ID_LOOKUP.LANGUAGE_ID}`,
      }),
      errorMessage: errorMessageLanguage,
    })
      .then((data) => {
        if (data.members && data.members.length) return data.members;
        throw ErrorMessages.NO_LANG_FOUND;
      })
      .catch((error) => {
        if (!error.data) return Promise.reject(error);
        return Promise.reject(errorMessageLanguage);
      });

    return Promise.all([
      fetchOrganizationData,
      fetchAllNetworks(),
      fetchCategoryData,
      fetchAttributeData,
      fetchLanguageData,
    ])
      .then(
        ([
          orgResponse,
          networkResponse,
          categoryResponse,
          attributeResponse,
          languageResponse,
        ]) => ({
          orgResponse,
          networkResponse,
          attributeResponse,
          categoryResponse,
          languageResponse,
        })
      )
      .then((res) => {
        if (res.orgResponse && res.categoryResponse && res.attributeResponse) {
          if (res.orgResponse[0].audit.status === 4) {
            res.orgResponse[0].activeStatus = "rejected";
          } else if (
            res.orgResponse[0].audit.status === 2 ||
            res.orgResponse[0].audit.status === 3
          ) {
            res.orgResponse[0].activeStatus = "approved";
          } else if (res.orgResponse[0].audit.status === 1) {
            res.orgResponse[0].activeStatus = "pending";
          }
          setcategoryList(res.categoryResponse);
          setNetworkList(res.networkResponse);
          setattributeList(res.attributeResponse);
          setLanguageList(res.languageResponse);
          setAdApprovalQueueData(res.orgResponse[0]);
          setIsLoaded(true);
        }
      })
      .catch((error) => Promise.reject(error));
  };

  const getAdApprovalData = () => {
    setIsLoaded(false);
    verifyRouteParams();
    if (isRouteVerified) {
      getSearchParam();
      loadData().catch((error) => {
        handleError(error);
      });
    }
  };

  useEffect(() => {
    if (!loading) {
      getAdApprovalData();
    }
  }, [loading, isRouteVerified]);

  return (
    <AdApprovalSummaryContext.Provider
      value={{
        activeTab,
        displayError,
        errorMessage,
        isLoaded,
        setIsLoaded,
        adApprovalQueueData,
        categoryList,
        attributeList,
        languageList,
        navigateToPath,
        networkList,
        userPermission,
        isSingleAdApproval,
        totalAdApprovalCount,
        getAdApprovalData,
        setRouteVerification,
      }}
    >
      {children}
    </AdApprovalSummaryContext.Provider>
  );
};
const AdApprovalSummaryConsumer = AdApprovalSummaryContext.Consumer;

AdApprovalSummaryProvider.propTypes = {
  children: PropTypes.shape(),
  location: PropTypes.shape(),
  history: PropTypes.shape(),
};

export {
  AdApprovalSummaryProvider,
  AdApprovalSummaryConsumer,
  AdApprovalSummaryContext,
};
