import React, { useState, useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import PropTypes from "prop-types";
import { PERSONAS } from "../../../../global-constants";
import { getDatasetData, getPersonaInfo } from "../../../../utils/datasetapi";
import getUserOrgData from "../../../../utils/userorgapi";
import fetchOrgContract from "../../../../utils/orgcontractapi";
import { constructAdminAPIEndPoint, fetchHandler } from "../../../../utils";
import location from "../../../../utils/location";
import ErrorMessages from "../../DisplayError/ErrorMessages";
import settings from "../../../../settings";
import { get } from "lodash";

const UserContext = React.createContext();

const checkPersona = (isBuyer, isPublisher, isPubPlanner) => {
  // If an org has both personas, prefer Publisher
  if (isPublisher) {
    const path = location.getLocationPathname();
    if (isBuyer && (path.includes("news") || path.includes("resources"))) {
      return PERSONAS.BUYER;
    }
    return PERSONAS.PUBLISHER;
  } else if (isBuyer) {
    return PERSONAS.BUYER;
  } else if (isPubPlanner) {
    return PERSONAS.PUB_PLANNER;
  }
  return PERSONAS.PUBLISHER;
};

const checkPersonaForDisplayError = (isBuyer, isPublisher, isPubPlanner) => {
  if (!isBuyer && !isPublisher && !isPubPlanner) {
    return [true, ErrorMessages.PX_ERROR.org_without_persona];
  } else {
    return [false, {}];
  }
};

const checkPermissions = (status, orgData, userName) => {
  if (
    status === 204 ||
    (orgData &&
      orgData.roles &&
      (orgData.roles.admin.includes(userName) ||
        orgData.roles.px_data.includes(userName) ||
        orgData.roles.write.includes(userName)))
  ) {
    return false;
  }
  return true;
};

const checkEnablePerms = (isSuperUser, orgData, userPermission) => {
  let enableImport = false;
  let enableView = false;
  let enablePlanningTool = false;

  /** Check for flatfile import perms */
  if (isSuperUser || !userPermission) {
    enableImport = true;
  }

  /** Check for looker analytics perms */
  if (
    isSuperUser ||
    settings.ANALYTICS_WHITELISTED_ORGS.includes(orgData.id) ||
    settings.ANALYTICS_WHITELISTED_ORGS.indexOf("") !== -1
  ) {
    enableView = true;
  }

  /** Check for GF planning tool perms */
  if (
    isSuperUser ||
    settings.GF_PLANNING_WHITELISTED_ORGS.includes(orgData.id) ||
    settings.GF_PLANNING_WHITELISTED_ORGS.indexOf("") !== -1
  ) {
    enablePlanningTool = true;
  }

  return {
    enableImport,
    enableView,
    enablePlanningTool,
  };
};

const checkPerView = (response) => {
  if (get(response.orgData, "orgContractData", []).length > 0) {
    if (
      response.orgData.orgContractData.some(
        (contract) =>
          contract.id === response.orgData.contract_id &&
          contract.perview_status === 1
      )
    ) {
      return true;
    }
  }
  return false;
};

const checkPubPlannerOrg = (orgId) => {
  let isPubPlannerOrg = false;
  if (
    settings.PUB_PLANNER_WHITELISTED_ORGS.includes(orgId) ||
    settings.PUB_PLANNER_WHITELISTED_ORGS.indexOf("") !== -1
  ) {
    isPubPlannerOrg = true;
  }
  return isPubPlannerOrg;
};

const UserProvider = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [validUser, setValidUser] = useState(true);
  // TODO to rename the isInvalidSuperUser variable and also places where its used
  const [isInvalidSuperUser, setSuperUser] = useState(false);
  const [organization, setOrganization] = useState({});
  const [isPlaceExchangeDataReady, resetPlaceexchangeDataState] =
    useState(false);
  const [userPermission, setUserPermissions] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [isImportEnabled, setIsImportEnabled] = useState(false);
  const [isViewEnabled, setIsViewEnabled] = useState(false);
  const [isPlanningToolEnabled, setIsPlanningToolEnabled] = useState(false);
  const [buyerDataset, setBuyerDataset] = useState([]);
  const [publisherDataset, setPublisherDataset] = useState([]);
  const [persona, setPersona] = useState("");
  const [isBuyer, setBuyer] = useState(false);
  const [isPublisher, setPublisher] = useState(false);
  const [isPubPlanner, setPubPlanner] = useState(false);
  const [isPerViewEnabled, setPerView] = useState(false);
  const userName = localStorage.getItem("px_user");
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  const { children } = props;

  const errorHandler = (userData, orgData, errorMessageParam) => {
    let error = errorMessageParam;
    if (!("id" in userData[0])) {
      error = ErrorMessages.PX_ERROR.incorrect_user_info;
      setValidUser(false);
    } else if ("orgs" in userData[0] && !userData[0].orgs.length) {
      error = ErrorMessages.PX_ERROR.empty_org;
      setValidUser(false);
    } else if (orgData === undefined || orgData.errors) {
      error = ErrorMessages.PX_ERROR.incorrect_org;
    }
    setDisplayError(true);
    setErrorMessage(error);
  };

  const checkErrorMessage = (userData, orgData) => {
    let errorMessageObj = ErrorMessages.DEFAULT_ERROR_MESSAGE;
    if (!userData.title) {
      errorHandler(userData, orgData, errorMessageObj);
    } else {
      errorMessageObj = userData;
      setDisplayError(true);
      setErrorMessage(errorMessageObj);
    }
  };

  const logOrgInfo = (orgId, orgName, isSandboxOrg) => {
    datadogRum.addRumGlobalContext("org", {
      id: orgId,
      name: orgName,
      isSandboxOrg: isSandboxOrg ? "true" : "false",
    });
  };
  if (data && data.orgData && data.orgData.config) {
    logOrgInfo(
      data.orgData.id,
      data.orgData.name,
      data.orgData.config.is_sandbox
    );
  }

  const logUserInfo = (user, isPxUser) => {
    datadogRum.setUser({
      id: user.id,
      name: user.name,
      email: user.email,
    });
  };
  if (data && data.userData) {
    localStorage.setItem("is_super_user", data.userData[1] === 204);
    logUserInfo(data.userData[0], data.userData[1] === 204);
  }

  const logPersonaInfo = (personaType) => {
    datadogRum.addRumGlobalContext("per", {
      type: personaType,
    });
  };

  const updateHistory = (prevPersona, newPersona, orgId) => {
    const { history } = props;
    const getPreviousURL = location.getLocationHref();
    const lastURLSegment = getPreviousURL.split("orgs/");
    if (lastURLSegment.length > 1) {
      if (prevPersona !== "Buyer" && newPersona === "Buyer") {
        history.push(`/orgs/${orgId}/resources/summary`);
      } else if (prevPersona !== "Publisher" && newPersona === "Publisher") {
        history.push(`/orgs/${orgId}/reporting/revenue/summary`);
      } else if (
        prevPersona !== "Pub Planner" &&
        newPersona === "Pub Planner"
      ) {
        history.push(`/orgs/${orgId}/reporting/campaign/activation_status`);
      } else {
        /* Persona is Publisher and Switching to a Publisher */
        const newURL = lastURLSegment[1].split("/");
        newURL[0] = orgId;
        history.push(`/orgs/${newURL.join("/")}`);
      }
    } else {
      /* istanbul ignore next */
      if (newPersona === "Buyer") {
        history.push(`/orgs/${orgId}/resources/summary`);
      } else if (newPersona === "Publisher") {
        history.push(`/orgs/${orgId}/reporting/revenue/summary`);
      } else if (newPersona === "Pub Planner") {
        history.push(`/orgs/${orgId}/reporting/campaign/activation_status`);
      }
    }
  };

  const updateRedirectUrl = (newPersona, orgId) => {
    let localStorageRedirectUrl = localStorage.getItem("redirectURL");
    /* istanbul ignore next */
    if (
      localStorageRedirectUrl &&
      newPersona === "Buyer" &&
      !localStorageRedirectUrl.includes("resources")
    ) {
      localStorage.removeItem("redirectURL");
      localStorage.setItem("redirectURL", `/orgs/${orgId}/resources/summary`);
    } else if (
      localStorageRedirectUrl &&
      newPersona === "Publisher" &&
      !localStorageRedirectUrl.includes("reporting/revenue/summary")
    ) {
      localStorage.removeItem("redirectURL");
      localStorage.setItem(
        "redirectURL",
        `/orgs/${orgId}/reporting/revenue/summary`
      );
    } else if (
      localStorageRedirectUrl &&
      newPersona === "Pub Planner" &&
      !localStorageRedirectUrl.includes("reporting/campaign/activation_status")
    ) {
      localStorage.removeItem("redirectURL");
      localStorage.setItem(
        "redirectURL",
        `/orgs/${orgId}/reporting/campaign/activation_status`
      );
    }
  };

  const updatePermissions = (status, orgData) => {
    const getUserPerms = checkPermissions(status, orgData, userName);
    setUserPermissions(getUserPerms);
    const enablePerms = checkEnablePerms(status === 204, orgData, getUserPerms);
    setIsImportEnabled(enablePerms.enableImport);
    setIsViewEnabled(enablePerms.enableView);
    setIsPlanningToolEnabled(enablePerms.enablePlanningTool);
  };

  const updateStates = (
    response,
    newOrganizationId,
    buyerDatasetData,
    publisherDatasetData
  ) => {
    setBuyerDataset(buyerDatasetData);
    setPublisherDataset(publisherDatasetData);
    if (response[1] === 204 || response?.userData?.[1] === 204) {
      setSuperUser(true);
    }
    if (
      response &&
      response.orgData !== undefined &&
      !("errors" in response.orgData) &&
      "userData" in response &&
      newOrganizationId !== null
    ) {
      const orgData = Array.isArray(response.orgData)
        ? response.orgData[0]
        : response.orgData;
      const personaInfo = getPersonaInfo(
        buyerDatasetData,
        publisherDatasetData,
        orgData.id
      );
      const isBuyer = !!(personaInfo && personaInfo.buyer);
      setBuyer(isBuyer);
      const isPublisher = !!(personaInfo && personaInfo.publisher);
      setPublisher(isPublisher);
      const isPubPlanner = checkPubPlannerOrg(orgData.id);
      setPubPlanner(isPubPlanner);
      const newPersona = checkPersona(isBuyer, isPublisher, isPubPlanner);
      setPersona(newPersona);
      logPersonaInfo(newPersona);
      const perViewCheck = checkPerView(response);
      setPerView(perViewCheck);
      setData(response);
      localStorage.setItem("px_default_org_id", orgData.id);
      localStorage.setItem("px_default_org_name", orgData.name);
      setOrganization(orgData);
      updatePermissions(
        response.userData ? response.userData[1] : 401,
        orgData
      );
      setLoading(false);
      const [shouldDisplayError, personaErrorMsg] = checkPersonaForDisplayError(
        isBuyer,
        isPublisher,
        isPubPlanner
      );
      setDisplayError(shouldDisplayError);
      setErrorMessage(personaErrorMsg);
      resetPlaceexchangeDataState(true);
      setValidUser(true);
      return newPersona; // hacky solution to provide newPersona when changeOrgValue calls updateStates
    } else if (
      response &&
      response.orgData !== undefined &&
      "errors" in response.orgData
    ) {
      const orgData = Array.isArray(response.orgData)
        ? response.orgData[0]
        : response.orgData;
      checkErrorMessage(response.userData, orgData);
      setOrganization({ name: response.userData[0].orgs[0] });
      setData(response);
    } else {
      checkErrorMessage(
        response.userData ? response.userData : response,
        response.orgData
      );
    }
  };

  const fetchUserData = async (organizationId) => {
    let response;
    let newOrganizationId = "";
    try {
      if (isAuthenticated) {
        // match does not contain proper values, so use location.getLocationHref() instead
        let splitUrl = location.getLocationHref().split("orgs/");

        if (splitUrl.length > 1) {
          newOrganizationId = splitUrl[1].split("/")[0];
        }

        newOrganizationId = organizationId || newOrganizationId;
        response = newOrganizationId
          ? await getUserOrgData(userName, newOrganizationId)
          : await getUserOrgData(userName);
        if (
          response &&
          (response[1] === 401 || get(response, "userData.[1]") === 401)
        ) {
          throw ErrorMessages.PX_ERROR.incorrect_user_info;
        }
      }
    } catch (e) {
      if (e) {
        setValidUser(false);
        checkErrorMessage(ErrorMessages.PX_ERROR.incorrect_user_info, {
          orgData: e,
        });
      }
    }

    const [buyerDatasetData, publisherDatasetData] =
      await getDatasetData().catch((e) => {
        if (e) {
          setErrorMessage(ErrorMessages.DEFAULT_ERROR_MESSAGE);
          return [];
        }
      });

    if (response && buyerDatasetData && publisherDatasetData) {
      updateStates(
        response,
        newOrganizationId,
        buyerDatasetData,
        publisherDatasetData
      );
    }
  };

  const changeOrgValue = async (event) => {
    // there is some weird issue with codeclimae. If you don't have else statement then its not covering the block. It does go inside the block however still says "uncovered", hence this is the temp patch work. Need to raise another PR to fix it.

    if (event.currentTarget.value === organization.name) {
    } else {
      setLoading(true);
      return fetchHandler({
        url: constructAdminAPIEndPoint({
          url: "orgs",
          searchParams: [
            {
              key: "name",
              value: event.currentTarget.value,
            },
          ],
        }),
        headers: { "cache-control": "no-cache, no-store" },
      })
        .then(async (response) => {
          if (response.length) {
            const { userData } = data;
            const prevPersona = persona;
            const newOrganizationId = response[0].id;
            let orgContractData = await fetchOrgContract(newOrganizationId);
            response[0]["orgContractData"] = orgContractData;
            const newPersona = updateStates(
              { userData, orgData: response[0] },
              newOrganizationId,
              buyerDataset,
              publisherDataset
            );
            updateHistory(prevPersona, newPersona, newOrganizationId);
            updateRedirectUrl(persona, newOrganizationId);
          } else throw ErrorMessages.NO_PUB_ORG_FOUND;
        })
        .catch((error) => Promise.reject(error));
    }
  };

  const changePersona = (event) => {
    setLoading(true);
    const prevPersona = persona;
    const newPersona = event.currentTarget.textContent.split(" ")[0];
    setPersona(newPersona);
    logPersonaInfo(newPersona);
    updateHistory(prevPersona, newPersona, data.orgData ? data.orgData.id : "");
    updateRedirectUrl(newPersona, data.orgData ? data.orgData.id : "");
    setLoading(false);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <UserContext.Provider
      value={{
        loading,
        data,
        changeOrgValue,
        displayError,
        errorMessage,
        isImportEnabled,
        isViewEnabled,
        isPlanningToolEnabled,
        organization,
        userPermission,
        isPlaceExchangeDataReady,
        resetPlaceexchangeDataState,
        validUser,
        isInvalidSuperUser,
        isBuyer,
        isPublisher,
        persona,
        changePersona,
        isPerViewEnabled,
        isPubPlanner,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const UserConsumer = UserContext.Consumer;

UserProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  match: PropTypes.shape(),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export {
  checkPersona,
  checkPersonaForDisplayError,
  checkPerView,
  UserProvider,
  UserConsumer,
  UserContext,
};
