/* istanbul ignore file */
import {
  flatten,
  forEach,
  includes,
  isEmpty,
  has,
  get,
  filter,
  keys,
} from "lodash";
import moment from "moment";

import {
  constructAdminAPIEndPoint,
  cursorPaginationApi,
  fetchHandler,
  commaSeparatedStringToArray,
  splitCommaSeparatedStringToArray,
} from "../../../../../utils";
import { GetGeo } from "../../../context/GetGeo/index";

export let venueList = [];
export let countryList = [];

// Return error object to importer
export const errorData = (errorMessage) => [
  {
    message: errorMessage,
    level: "error",
  },
];

// check whether date is future or not
export const isFutureDate = (inputDate) => {
  const dateToCompare = moment(inputDate);
  if (!isEmpty(inputDate)) {
    return !(moment().diff(dateToCompare) >= 0);
  }
};

export const dateCompare = (firstDate, secondDate) => {
  return moment(firstDate).isAfter(secondDate);
};

let isNetworkCalled = false;
let previousOrgId = "";
let networkIdNameList = [];
export const getNetwork = async (orgId, searchParams = []) => {
  if (!isNetworkCalled || previousOrgId !== orgId) {
    const headers = {
      "cache-control": "no-cache, no-store",
    };
    previousOrgId = orgId;
    await cursorPaginationApi(orgId, "networks", headers, searchParams).then(
      (json) => {
        isNetworkCalled = true;
        networkIdNameList = json.map((x) => {
          return { networkId: x.id, networkName: x.name };
        });
      }
    );
  }
  return networkIdNameList;
};

export const getGeoTargetingData = async (targeting) => {
  let promises = [];
  let result = [];
  if (has(targeting, "targeting.geo.country")) {
    let countryList = commaSeparatedStringToArray(
      get(targeting, "targeting.geo.country")
    );
    countryList.forEach((country) => {
      promises.push(GetGeo(country).catch((error) => result));
    });
    const apiResponse = await Promise.all(promises);
    apiResponse.forEach((data) => {
      if (!isEmpty(data)) result = [...result, data[0]];
    });
    return result;
  }
};

let serverResults = [];
export const getAvailableResources = async (orgId, payload) =>
  fetchHandler({
    method: "POST",
    url: constructAdminAPIEndPoint({
      url: `orgs/${orgId}/resources-available`,
    }),
    headers: {
      "cache-control": "no-cache, no-store",
    },
    body: payload,
  })
    .then((json) => {
      if (payload.show_ids_and_names) {
        serverResults = json.resources_available_ids_and_names;
        return json.resources_available_ids_and_names;
      } else if (payload.show_ids) {
        return json.resources_available_ids;
      } else if (payload.show_names) {
        return json;
      } else if (payload.show_fields) {
        return json.resources_available;
      } else {
        return json.resources_unavailable_count !== 0;
      }
    })
    .catch(() => (serverResults = ["API Failed"]));

export const setVenueList = (list) => (venueList = list);

export const validateVenueOpenOOHCategory = (list) => {
  if (!isEmpty(list)) {
    let error = false,
      errorList = "",
      errorMsg = "";
    const venueOpenOOHList = venueList.map((i) => i.label);
    const updatedList = commaSeparatedStringToArray(list);
    forEach(updatedList, (item) => {
      if (!venueOpenOOHList.includes(item)) {
        error = true;
        errorList = !isEmpty(errorList) ? `${errorList}, ${item}` : `${item}`;
      }
    });
    if (error) {
      errorMsg =
        commaSeparatedStringToArray(errorList).length === 1
          ? `${errorList} is not valid venue_openooh category`
          : `${errorList} are not valid venue_openooh category`;
      return errorData(errorMsg);
    }
  }
};

export const setCountryList = (list) => (countryList = list);

const generateErrorMessage = (errorList, type, erroType) => {
  let messge = "";
  let single = "";
  erroType === "duplicate"
    ? (messge = "duplicte input given.")
    : (messge = "not valid choice.");
  commaSeparatedStringToArray(errorList).length === 1
    ? (single = "is")
    : (single = "are");
  let errorMsg = `${errorList} ${single} ${messge}`;
  type == "country" || erroType === "duplicate"
    ? (errorMsg = errorMsg)
    : (errorMsg =
        errorMsg + " (not belongs to the input targeting.geo.country list).");
  return errorMsg;
};

const validateUnique = (list) =>
  filter(list, (val, index, iteratee) => includes(iteratee, val, index + 1));

const validateChoice = (updatedList, type, validateData) => {
  let wrongChoice = [];
  let possibleList = [];
  if (type === "country") {
    possibleList = countryList;
  } else {
    let arrayList = validateData.map((data) => data[`${type}`]);
    if (type === "dma") {
      arrayList = arrayList.map((dmaData) => keys(dmaData));
    }
    possibleList = flatten(arrayList);
  }
  forEach(updatedList, (item) => {
    if (!possibleList.includes(item)) {
      wrongChoice.push(item);
    }
  });
  return wrongChoice;
};

const dataValidationGeo = (updatedList, type, validateData, errorList) => {
  let duplicates = validateUnique(updatedList);
  if (duplicates.length >= 1) {
    errorList = duplicates.join(", ");
    return errorData(generateErrorMessage(errorList, type, "duplicate"));
  }
  let wrongChoice = validateChoice(updatedList, type, validateData);
  if (wrongChoice.length >= 1) {
    errorList = wrongChoice.join(", ");
    return errorData(generateErrorMessage(errorList, type, "not valid input"));
  }
};

export const validateGeoData = (list, type, validateData) => {
  const fieldName = type.split(".");
  type = fieldName[fieldName.length - 1];
  if (!isEmpty(list) && !isEmpty(validateData)) {
    let errorList = "";
    let updatedList;
    type === "dma"
      ? (updatedList = splitCommaSeparatedStringToArray(list))
      : (updatedList = commaSeparatedStringToArray(list));
    return dataValidationGeo(updatedList, type, validateData, errorList);
  }
};
