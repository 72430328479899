import { each, forEach, includes, isEmpty } from "lodash";

import { reduceRatio } from "../../../../../../../utils";

export const aspectRatioValidator = (record, field) => {
  let error = false;
  let ratioStr = record.get(field);
  const validRatios = ["16:9", "9:16", "4:3", "3:4"];
  const errorText = `Please provide a valid supported ratio. Available Aspect Ratios are:${validRatios}`;
  if (!isEmpty(ratioStr)) {
    const aspectRatios = ratioStr.split(",").map((value) => value.trim());
    forEach(aspectRatios, (ratio) => {
      if (!includes(validRatios, ratio)) {
        error = true;
      }
    });
  }
  record.validate(field, (value) => !error, errorText);
};

export const formatAspectRatios = (record, field) => {
  const aspectRatioList = record.get(field);
  let formattedRatios = "";
  if (aspectRatioList) {
    const ratios = aspectRatioList.split(",");
    each(ratios, (ratio, index) => {
      formattedRatios =
        index === ratios.length - 1
          ? `${formattedRatios}${formatAssetAspectRatio(ratio.trim())}`
          : `${formattedRatios}${formatAssetAspectRatio(ratio.trim())},`;
    });
  }
  record.set(field, formattedRatios);
};

const formatAssetAspectRatio = (ratio) => {
  let aspectRatio = ratio;
  if (!isEmpty(ratio) && ratio.includes(":")) {
    const splittedRatio = ratio.split(":");
    aspectRatio = `${splittedRatio[0].replace(
      /^0+/,
      ""
    )}:${splittedRatio[1].replace(/^0+/, "")}`;
  }
  return aspectRatio;
};

const calculateAspectRatio = (record, formattedValue) => {
  const aspectRatioValues = formattedValue.split(":");
  const actualAspectRatio = (
    Number(record.get("slot.w")) / Number(record.get("slot.h"))
  ).toFixed(2);
  const aspectRatioLhs = Number(aspectRatioValues[0]);
  const aspectRatioRhs = Number(aspectRatioValues[1]);

  const calculatedAspectRatio =
    aspectRatioRhs > 0
      ? (aspectRatioLhs / aspectRatioRhs).toFixed(2)
      : aspectRatioLhs.toFixed(2);
  const tenPercentOfActualAspectRatio = actualAspectRatio / 10;
  const minErrorMargin = (
    Number(actualAspectRatio) - Number(tenPercentOfActualAspectRatio)
  ).toFixed(2);
  const maxErrorMargin = (
    Number(actualAspectRatio) + Number(tenPercentOfActualAspectRatio)
  ).toFixed(2);

  return { calculatedAspectRatio, minErrorMargin, maxErrorMargin };
};

const isValidAspectRatio = (formattedValue, reducedRatio) => {
  const isValid = /^[0-9]+:{1}[0-9]+$/.test(formattedValue);
  if (!isValid) {
    return `Aspect ratio should be in integers separated by single colon format. The nearest ratio is ${reducedRatio}`;
  }
};

const aspectRatioCrossValidation = (record, field) => {
  const formattedAssetAspectRatio = formatAssetAspectRatio(record.get(field));
  record.set(field, formattedAssetAspectRatio);
  const slotWidth = Number(record.get("slot.w"));
  const slotHeight = Number(record.get("slot.h"));
  if (!isNaN(slotWidth) && !isNaN(slotHeight)) {
    const reducedRatio = reduceRatio(slotWidth, slotHeight);
    const { calculatedAspectRatio, minErrorMargin, maxErrorMargin } =
      calculateAspectRatio(record, formattedAssetAspectRatio);
    const errorText = isValidAspectRatio(
      formattedAssetAspectRatio,
      reducedRatio
    );
    if (!isEmpty(errorText)) return errorText;
    if (
      !(
        Number(minErrorMargin) <= Number(calculatedAspectRatio) &&
        Number(calculatedAspectRatio) <= Number(maxErrorMargin)
      )
    ) {
      return `${record.get(
        "asset.aspect_ratio"
      )}: Aspect ratio should match slot width/height.
        Please provide correct values for width, height, and aspect_ratio. The nearest ratio is ${reducedRatio}`;
    }
  }
};

export const assetAspectRatioValidator = (record, field) => {
  if (
    !isEmpty(record.get(field)) &&
    !isEmpty(record.get("slot.h")) &&
    !isEmpty(record.get("slot.w"))
  ) {
    const errorText = aspectRatioCrossValidation(record, field);
    record.validate(field, (value) => isEmpty(errorText), errorText);
  }
};
