export const auctionTypeChoices = () => ({
  1: "First Price",
  2: "Second Price",
  3: "Fixed Price",
});

export const statusChoices = () => ({
  paid: "paid",
  prepaid: "prepaid",
  bonus: "bonus",
  house: "house",
});

export const dealDefaultPriority = "Medium";

const dealMapping = (wbuyerOptions = {}, priorityOptions = []) => ({
  at: auctionTypeChoices(),
  status: statusChoices(),
  wbuyer: wbuyerOptions,
  priority: priorityOptions,
});

export default dealMapping;
