import React, { Fragment } from "react";
import { capitalize, join } from "lodash";
import PropTypes from "prop-types";
import CardList from "../../Utilities/CardList";
import PrepareSvg from "../../../svgs/PrepareSvg";
import DynamicCreativeIcon from "../../../svgs/DynamicCreativeIcon";

const AdDetails = (props) => {
  const { adApprovalData, catInfo } = props;

  const adDetailsList = [
    {
      cardTitle: "DSP:",
      cardText: adApprovalData.dspOrgname || "-",
    },
    {
      cardTitle: "Advertiser Domain:",
      cardText: join(adApprovalData.ad.adomain.map((item) => `${item}`)) || "-",
    },
    {
      cardTitle: "Ad Type:",
      cardText:
        adApprovalData.creative.type === "display" &&
        adApprovalData.ad.creative.display.subtype === 14 ? (
          <div className="dynamic-creative-icon">
            {capitalize(adApprovalData.creative.type)}
            <PrepareSvg iconComponent={DynamicCreativeIcon} />
          </div>
        ) : (
          capitalize(adApprovalData.creative.type) || "-"
        ),
    },
    {
      cardTitle: "Content Category:",
      cardText:
        join(catInfo.map((item) => ` ${item.key} - ${item.value}`)) || "-",
    },
  ];
  return (
    <Fragment>
      <CardList listOfCard={adDetailsList} />
    </Fragment>
  );
};

AdDetails.propTypes = {
  adApprovalData: PropTypes.shape(),
  catInfo: PropTypes.arrayOf(PropTypes.shape()),
};

export default AdDetails;
