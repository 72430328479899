import React from "react";
import PropTypes from "prop-types";
import { Badge } from "@px/px_design_system";

export default class WithFeatureStatus extends React.Component {
  render() {
    var visibility = "";
    if (new Date(this.props?.expirationDate) <= new Date()) {
      visibility = "d-none";
    }
    return (
      <div className="feature--warning">
        <span className="feature--warning__text">{this.props?.text}</span>{" "}
        <Badge
          className={`${this.props.badgeClassName} ${visibility}`}
          color={this.props.statusColor}
          pill
        >
          {this.props.statusText}
        </Badge>
      </div>
    );
  }
}

WithFeatureStatus.defaultProps = {
  statusText: "Coming Soon",
  statusColor: "warning",
  badgeClassName: "",
  expirationDate: "",
};

WithFeatureStatus.propTypes = {
  statusText: PropTypes.string,
  statusColor: PropTypes.string,
  badgeClassName: PropTypes.string,
  expirationDate: PropTypes.string,
};
