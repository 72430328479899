/* istanbul ignore file */
import {
  cloneDeep,
  isEmpty,
  omitBy,
  isFinite,
  isBoolean,
  size,
  each,
  includes,
  get,
} from "lodash";
import { eventBus } from "@px/px_design_system";
import pLimit from "p-limit";
import { deflate } from "pako";

import {
  prepareObjectToUpload,
  triggerDDActionEvent,
  uploadProgressBar,
} from "../../lib";
import {
  constructAdminAPIEndPoint,
  fetchHandler,
  fixedEncodeURIComponent,
  formatEids,
  trackAsyncUploadFileCountInDD,
  trackCustomActionsInDD,
} from "../../../../../../../utils";
import { getAvailableResources } from "../../fieldValidation";
import ErrorMessages from "../../../../../DisplayError/ErrorMessages";

/* Limiting browser concurrent requests especially for long running requests
 * Why 6 - Minimum number of concurrency supported by all browsers
 */
const limit = pLimit(6);

const flatten = require("flat");

let index = 0;
let uploadingInProgress = false;
let startTime = 0;
let endTime = 0;
let objectList = [];
let errorObjectList = [];
let objectListAvailableResource = [];
let objectListNonAvailableResource = [];
const asyncUploadModels = ["adunits"];
let uploadedFileName = "";

window.onbeforeunload = (event, isInProgress = uploadingInProgress) => {
  if (isInProgress) {
    const confirmationMessage =
      "Registration will be in inconsistent state if you refresh the page!";
    const winEvent = event || window.event;
    if (winEvent) {
      winEvent.returnValue = confirmationMessage;
    }
    return confirmationMessage;
  }
};

const validationAdUnit = (obj, key) => {
  const objAuctionNull = cloneDeep(obj);
  if (
    obj[key] &&
    isEmpty(
      omitBy(flatten(objAuctionNull[key]), (v) =>
        isBoolean(v) || isFinite(v) ? false : isEmpty(v)
      )
    )
  ) {
    objAuctionNull[key] = null;
    return objAuctionNull;
  }
  return obj;
};

const checkIfValid = (payload) => {
  let body = payload;
  body = validationAdUnit(body, "asset");
  body = validationAdUnit(body, "auction");
  body = validationAdUnit(body, "location");
  body = validationAdUnit(body, "measurement");
  body = validationAdUnit(body, "restrictions");
  body = validationAdUnit(body, "venue");
  return body;
};

const triggerSetDealPriorityAction = (model, payload) => {
  if (model === "deals") {
    trackCustomActionsInDD("set deal priority", {
      priority: get(payload, "priority"),
      priority_display: get(payload, "priority_display"),
      deal_name: get(payload, "name"),
    });
  }
};

const handleSuccessBlock = async (model, response, status) => {
  /* For successful adunits execution, as it does not contain json in the response object. */
  if (response.status === 204) {
    return;
  }
  return response.json().then((json) => {
    /* This condition checks if its a successful POST or PATCH request */
    if (response.status === status) {
      triggerSetDealPriorityAction(model, json);
    } else {
      throw json;
    }
  });
};

const replaceIdWithName = (value, model, orgData) => {
  if (!isEmpty(value)) {
    const payload = {
      model,
      ids: value,
      show_names: "true",
    };
    return getAvailableResources(orgData.id, payload);
  }
  return [];
};

const prepareErrorObject = async (errorJson, orgData) => {
  const adunits = await replaceIdWithName(errorJson.adunits, "AdUnit", orgData);
  const deals = await replaceIdWithName(errorJson.deals, "Deal", orgData);
  const networks = await replaceIdWithName(
    errorJson.networks,
    "Network",
    orgData
  );
  return {
    ...errorJson,
    adunits: adunits?.resources_available_names || [],
    deals: deals?.resources_available_names || [],
    networks: networks?.resources_available_names || [],
  };
};

const generateErrorObject = async (payload, error, model = "", orgData) => {
  let errObj = {
    ...payload,
    error_message: error?.errors || error?.title || "",
  };
  if (payload.start_date && model === "adunits") {
    errObj = {
      ...errObj,
      start_date: Math.floor(new Date(payload.start_date).getTime() / 1000),
    };
  }
  if (model === "placements") {
    errObj = await prepareErrorObject(errObj, orgData);
  }

  return errorObjectList.push(errObj);
};

const postAsyncUpload = async (
  itemList,
  flatModel,
  params,
  dealPriorityOptions
) => {
  const { abortController, asyncUploadContext, model, orgData } = params;
  let payload = [];

  itemList.forEach((item) => {
    if (model === "adunits") {
      item = checkIfValid(
        prepareObjectToUpload(item, flatModel, dealPriorityOptions)
      );

      if (item.eids) {
        item.eids = formatEids(item.eids);
      }
    }

    payload.push(item);
  });

  // compress payload
  payload = await deflate(JSON.stringify(payload));

  // Create blob from compressed payload
  const fileName = uploadedFileName.replace(".csv", ".json");
  const fileData = new File([payload], fileName, {
    type: "text/plain",
  });

  const filePayload = new FormData();
  filePayload.append("upload", fileData);

  const apiParams = {
    method: "POST",
    url: constructAdminAPIEndPoint({
      url: `orgs/${orgData.id}/${model}/bulk`,
      searchParams: [{ key: "date_format", value: "iso-format" }],
    }),
    headers: {
      "cache-control": "no-cache, no-store",
    },
    signal: abortController?.signal,
    body: filePayload,
    responseType: "headers",
    isMultipartFormData: true,
  };

  return fetchHandler(apiParams)
    .then((headers) => {
      trackAsyncUploadFileCountInDD(`set async ${model} upload count`);
      const contentLocation = headers.get("content-location");

      if (contentLocation) {
        /* /bulk endpoint sends content-location header in response. Save/update localStorage with uploaded filename and content location URL.
        These details will be used in notification and polling endpoint
        */
        const newcontentLocation = {
          fileName: uploadedFileName,
          contentLocation: constructAdminAPIEndPoint({
            url: `orgs/${orgData.id}/adunits/bulk${contentLocation}`,
          }),
        };

        const updateContentLocations =
          JSON.parse(
            localStorage.getItem(`px_${model}_async_upload_content_location`)
          ) || [];

        updateContentLocations.push(newcontentLocation);

        localStorage.setItem(
          `px_${model}_async_upload_content_location`,
          JSON.stringify(updateContentLocations)
        );

        asyncUploadContext.handleAsyncUpload();
      } else {
        throw ErrorMessages.NO_CONTENT_LOCATION_FOUND;
      }
    })
    .catch((error) => {
      generateErrorObject(payload, error);
    });
};
const patchItem = (item, progressBarUpdate, data, params) => {
  const { abortController, model, orgData, searchParams } = params;
  let payload = cloneDeep(item);
  if (model === "adunits") {
    payload = checkIfValid(payload);
    if (payload.eids) {
      payload.eids = formatEids(payload.eids);
    }
  } else if (model === "networks") {
    payload = validationAdUnit(payload, "branding");
    payload = validationAdUnit(payload, "restrictions");
  }
  const apiParams = {
    method: "PATCH",
    url: constructAdminAPIEndPoint({
      url: `orgs/${orgData.id}/${model}/${fixedEncodeURIComponent(
        payload.name
      )}/`,
      searchParams,
    }),
    headers: {
      "cache-control": "no-cache, no-store",
    },
    signal: abortController?.signal,
    body: payload,
    responseType: "rawApiResponse",
  };

  return fetchHandler(apiParams)
    .then((response) => handleSuccessBlock(model, response, 200))
    .catch((error) => generateErrorObject(payload, error, model, orgData));
};

const postItem = (item, progressBarUpdate, data, params) => {
  const { abortController, model, orgData, searchParams } = params;

  let payload = cloneDeep(item);
  if (model === "adunits") {
    payload = checkIfValid(payload);
    if (payload.eids) {
      payload.eids = formatEids(payload.eids);
    }
  } else if (model === "networks") {
    payload = validationAdUnit(payload, "branding");
    payload = validationAdUnit(payload, "restrictions");
  }

  const apiParams = {
    method: "POST",
    url: constructAdminAPIEndPoint({
      url: `orgs/${orgData.id}/${model}/`,
      searchParams,
    }),
    headers: {
      "cache-control": "no-cache, no-store",
    },
    signal: abortController?.signal,
    body: payload,
    responseType: "rawApiResponse",
  };

  return fetchHandler(apiParams)
    .then((response) => handleSuccessBlock(model, response, 201))
    .catch((error) => generateErrorObject(payload, error, model, orgData));
};

const getAsyncUploadMessage = (modelType) =>
  errorObjectList.length
    ? `${modelType} upload failed. Please try again.`
    : `${modelType} upload has started. Upon completion, you will be notified via email.`;

const errorMessage = (modelType, actionType = "uploaded") => {
  const dealsNotification =
    "Note that new deals have to be associated with inventory to start sending bid requests";
  const successFailText = errorObjectList.length
    ? `Successfully ${actionType} ${
        objectList.length - errorObjectList.length
      } ${modelType} and ${errorObjectList.length} ${modelType} failed.`
    : `Successfully ${actionType} ${objectList.length} ${modelType}.`;
  const errorMessage =
    modelType !== "Deals"
      ? successFailText
      : `${successFailText} ${dealsNotification}`;
  return errorMessage;
};

const upCall = async (data, progressBarUpdate, params, dealPriorityOptions) => {
  const { flatModel, model, type, orgData } = params;
  uploadingInProgress = true;
  index = 0;
  objectList = data;
  endTime = 0;
  startTime = 0;
  objectListAvailableResource = [];
  objectListNonAvailableResource = [];
  let modelAvailableResources = "";

  switch (model) {
    case "adunits":
      modelAvailableResources = "AdUnit";
      break;
    case "deals":
      modelAvailableResources = "Deal";
      break;
    case "networks":
      modelAvailableResources = "Network";
      break;
    case "placements":
      modelAvailableResources = "Placement";
      break;

    default:
      break;
  }
  let successReturnMsg = "";
  if (objectList.length === 0) {
  } else {
    startTime = performance.now();
    if (asyncUploadModels.includes(model)) {
      await postAsyncUpload(objectList, flatModel, params, dealPriorityOptions);
      successReturnMsg = await getAsyncUploadMessage(type);
      uploadingInProgress = false;
    } else {
      const availableResource = await getAvailableResources(orgData.id, {
        model: modelAvailableResources,
        names: data.map((item) => {
          return item.name;
        }),
        show_names: "true",
      });

      each(objectList, (resource) => {
        if (
          includes(availableResource.resources_available_names, resource.name)
        ) {
          objectListAvailableResource.push(resource);
        } else {
          objectListNonAvailableResource.push(resource);
        }
      });
      await (async () => {
        for (let job of objectListNonAvailableResource.map(
          (item) => () =>
            postItem(
              prepareObjectToUpload(item, flatModel, dealPriorityOptions),
              progressBarUpdate,
              data,
              params
            )
        )) {
          uploadProgressBar(progressBarUpdate, data, index++);
          await job();
        }
      })();

      await (async () => {
        for (let job of objectListAvailableResource.map(
          (item) => () =>
            patchItem(
              prepareObjectToUpload(item, flatModel, dealPriorityOptions),
              progressBarUpdate,
              data,
              params
            )
        )) {
          uploadProgressBar(progressBarUpdate, data, index++);
          await job();
        }
      })();

      endTime = performance.now();
      triggerDDActionEvent({
        endTime,
        startTime,
        uploadCount: objectListNonAvailableResource.length,
        updateCount: objectListAvailableResource.length,
        uploadType: model,
      });

      uploadingInProgress = false;

      successReturnMsg =
        type !== "Archive Deal"
          ? errorMessage(type)
          : errorMessage("deals", "archived and unarchived");
    }
  }

  const flatfileResponse = {
    successMessage: successReturnMsg,
    errorJson: errorObjectList,
  };

  errorObjectList = [];

  /* Update Sidebar Count */
  eventBus.dispatch("updateTable", {
    data: data,
    type: type,
  });

  return flatfileResponse;
};

export const modifyPayload = async ({
  flatFileValidData,
  progressBarUpdate,
  params,
  dealPriorityOptions = [],
  postProcessFields = [],
  wbuyerChoices = [],
  fileName = "Inventory_Ad_Units.csv",
}) => {
  const { orgData } = params;
  uploadedFileName = fileName;
  // TODO - Massage async vs non-async?
  const isAsync = get(postProcessFields, "[0].async", false);

  // For non-async post-processing of fields, execute immediately
  // and return early.
  if (!isAsync) {
    flatFileValidData.forEach((row) => {
      postProcessFields.forEach((postProcessField) => {
        const rowCopy = row;
        const valueToProcess =
          row[postProcessField.alternate] || row[postProcessField.key];
        rowCopy[postProcessField.key] = postProcessField.process(
          valueToProcess,
          wbuyerChoices
        );
      });
    });

    const successMsg = await upCall(
      flatFileValidData,
      progressBarUpdate,
      params,
      dealPriorityOptions
    );
    return successMsg;
  }

  // For async post-processing of fields.
  const data = await Promise.all(
    flatFileValidData.map(async (row) => {
      const rowCopy = row;

      // Grab all field requests/promises.
      const rowFields = [];
      for (const postProcessField of postProcessFields) {
        const valueToProcess =
          row[postProcessField.alternate] || row[postProcessField.key];
        rowFields.push(
          limit(() => postProcessField.process(valueToProcess, orgData.id))
        );
      }

      // Fire 'em all async for the row, and wait until they are resolved.
      const resolvedRowFields = await Promise.all(rowFields);

      // Plug back into relevant fields on the row.
      for (const [i, postProcessField] of postProcessFields.entries()) {
        if (size(resolvedRowFields[i])) {
          rowCopy[postProcessField.key] = resolvedRowFields[i];
        }
      }

      return rowCopy;
    })
  );

  const successMsg = await upCall(
    data,
    progressBarUpdate,
    params,
    dealPriorityOptions
  );
  return successMsg;
};
