/* istanbul ignore file */
import api from "@flatfile/api";
import { processRecords } from "@flatfile/util-common";
import { isEmpty } from "lodash";

import { modifyPayload } from "./apiCalls";
import { uploadProgressBar } from "../../lib";

const getFlatfileRecords = async (
  workbookId,
  updateProgreeBar = false,
  tickFunc
) => {
  const { data: workbookSheets } = await api.sheets.list({
    workbookId,
  });
  const flatFileRecords = [];
  for (const [_, element] of workbookSheets.entries()) {
    await processRecords(
      element.id,
      async (records, pageNumber, totalPageCount) => {
        records.map((item) => {
          const obj = {};
          for (const key in item.values) {
            Object.assign(obj, { [key]: item.values[key].value });
          }
          flatFileRecords.push(obj);
        });
        if (updateProgreeBar)
          uploadProgressBar(tickFunc, totalPageCount, pageNumber, true);
      }
    );
  }
  return flatFileRecords;
};

export const handleSubmit = async ({
  dealPriorityOptions,
  abortController,
  orgData,
  jobId,
  workbookId,
  flatModel,
  postProcessFields,
  model,
  type,
  wbuyerChoices,
  fileName,
  asyncUploadContext,
  restrictAPISubmission = false,
  asyncUpload = false,
}) => {
  try {
    /* Function to update progress bar */
    const tick = async (progress, info) => {
      return await api.jobs.ack(jobId, {
        progress,
        ...(info !== undefined && { info }),
      });
    };
    /*acknowledge job with 1%*/
    tick(1, "Uploading...");

    /* Need to manually update the progress bar in case of async upload or associate adunits */
    const manualUpdateProgressBar = restrictAPISubmission || asyncUpload;
    const records = await getFlatfileRecords(
      workbookId,
      manualUpdateProgressBar,
      tick
    );

    if (restrictAPISubmission) return records;

    const flatFileValidData = records.filter((item) => !isEmpty(item.name));

    /* API call to upload the records */
    const jobComplete = await modifyPayload({
      flatFileValidData,
      progressBarUpdate: tick,
      params: {
        abortController,
        flatModel,
        model,
        orgData: orgData,
        type,
        asyncUploadContext,
      },
      dealPriorityOptions,
      postProcessFields,
      wbuyerChoices,
      fileName,
    });
    // Make changes after cells in a Sheet have been updated

    if (jobComplete?.errorJson.length) {
      throw jobComplete;
    }

    await api.jobs.complete(jobId, {
      outcome: {
        acknowledge: true,
        message: jobComplete?.successMessage,
        next: {
          type: "wait",
        },
      },
    });
    return jobComplete?.errorJson;
  } catch (error) {
    await api.jobs.fail(jobId, {
      outcome: {
        acknowledge: true,
        message: error?.successMessage,
      },
    });
    return error?.errorJson;
  }
};
