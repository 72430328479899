import React from "react";
import { MemoryRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { datadogRum } from "@datadog/browser-rum";
import { ToastContainer } from "react-toastify";
import { upperCase } from "lodash";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import MainRoutes from "./routes";
import settings from "./settings";

/**
 * In Webpack version 5, Webpack no longer automatically polyfill's Node.js API's if they are not natively supported anymore.
 * The browser environment does not support Buffer natively, therefore we now need to add a third party Buffer.
 */
window.Buffer = window.Buffer || require("buffer").Buffer;

require("./components/PlaceExchange/images/favicon.ico");

/* Setting the very first URL hitting into browser except /login and root URL.
  DO NOT reset it anywhere */
const page = window.location.pathname + window.location.search;
const urlRegEx = new RegExp("^[/]$|login|no-user-org");
const isValidRedirect = !urlRegEx.test(page);

if (isValidRedirect) {
  localStorage.setItem("redirectURL", page);
}

datadogRum.init({
  applicationId: settings.DATADOG_RUM_APPLICATION_ID,
  clientToken: settings.DATADOG_RUM_CLIENT_TOKEN,
  env: settings.PORTAL_ENV,
  service: settings.PORTAL_SERVICE,
  version: settings.PORTAL_VERSION,
  trackInteractions: true,
  allowedTracingOrigins: [settings.PORTAL_URL, settings.ADMIN_API_BASE_URL],
  /* Decode url query params before sending to datadog. For now restricting to Adunits as per use in PAD-3015 */
  beforeSend: (event) => {
    if (
      event.view?.url.includes("adunits") &&
      event.resource?.url.includes("adunits")
    ) {
      event.resource.url = decodeURIComponent(event.resource.url);
    }
  },
});

/** Enable DD session replay. More details https://docs.datadoghq.com/real_user_monitoring/session_replay/ */
if (upperCase(settings.PORTAL_ENV) === "PROD") {
  datadogRum.startSessionReplayRecording();
}

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <MemoryRouter>
    <>
      <ToastContainer />
      <MainRoutes />
    </>
  </MemoryRouter>
);
