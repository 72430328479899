import { forEach, includes, isEmpty, without } from "lodash";

import { commaSeparatedStringToArray } from "../../../../../../../utils";

export const weekdaysValidator = (record, field) => {
  const validWeekDays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const weekDayValues = commaSeparatedStringToArray(record.get(field)).map(
    (value) => value.trim()
  );
  let error = true;

  forEach(weekDayValues, (day) => {
    if (!includes(validWeekDays, day)) {
      error = false;
    }
  });
  if (!error) {
    record.validate(
      field,
      (value) => error,
      `Please provide a valid weekday value. Available weekday values are:${validWeekDays}`
    );
  }
};

export const daypartFieldsValidator = (record, field) => {
  const weekDayValues = commaSeparatedStringToArray(
    record.get("dayparts.weekday")
  );
  const startTimeFieldValues = commaSeparatedStringToArray(
    record.get("dayparts.start_time")
  );
  const endTimeFieldValues = commaSeparatedStringToArray(
    record.get("dayparts.end_time")
  );
  const isDaypartsFieldNotEmpty =
    !isEmpty(record.get("dayparts.weekday")) &&
    !isEmpty(record.get("dayparts.start_time")) &&
    !isEmpty(record.get("dayparts.end_time"));
  const isDaypartsFieldEqualLength =
    weekDayValues.length === startTimeFieldValues.length &&
    weekDayValues.length === endTimeFieldValues.length;
  if (isDaypartsFieldNotEmpty) {
    if (!isDaypartsFieldEqualLength) {
      record.validate(
        field,
        (value) => false,
        "Dayparts.weekday, dayparts.start_time and dayparts.end_time all should contain same number of items"
      );
    }
    return;
  }
  const daypartsField = [
    "dayparts.weekday",
    "dayparts.start_time",
    "dayparts.end_time",
  ];
  const excludeFieldList = without(daypartsField, field);
  if (
    isEmpty(record.get(field)) &&
    (!isEmpty(record.get(excludeFieldList[0])) ||
      !isEmpty(record.get(excludeFieldList[1])))
  ) {
    record.validate(
      field,
      (value) => false,
      `${field} cannot be empty if the ${excludeFieldList[0]} or ${excludeFieldList[1]} present`
    );
  }
};
