import React from "react";
import { Button, Icon, JsonToCsv } from "@px/px_design_system";
import PropTypes from "prop-types";

const ToastMsg = (props) => {
  const { csvProps, isDownloadable, isIcon, toastTitle, toastSubTitle, icon } =
    props;

  return (
    <div className="toast--body">
      {isIcon && <Icon icon={icon} size="3x" />}
      <div className="toast-description">
        <div>
          <strong>{toastTitle}</strong>
          <div className="sub--title">{toastSubTitle}</div>
        </div>
        <div className="toast-action">
          {isDownloadable && (
            <JsonToCsv {...csvProps} filenameWithDateTime>
              <Button
                buttontext={"Download CSV"}
                icon={<Icon icon={["fas", "arrow-down"]} size="1x" />}
                className="display--icon"
              />
            </JsonToCsv>
          )}
        </div>
      </div>
    </div>
  );
};

ToastMsg.defaultProps = {
  isIcon: true,
  icon: ["far", "check-circle"],
  toastTitle: "Header Text",
  isDownloadable: false,
};

ToastMsg.propTypes = {
  csvProps: PropTypes.shape({
    json: PropTypes.array,
  }),
  isDownloadable: PropTypes.bool,
  isIcon: PropTypes.bool,
  icon: PropTypes.array,
  toastTitle: PropTypes.string,
  toastSubTitle: PropTypes.string,
};

export default ToastMsg;
