/* istanbul ignore file */
import { FlatfileListener } from "@flatfile/listener";
import { recordHook, bulkRecordHook } from "@flatfile/plugin-record-hook";
import { includes, toLower, values } from "lodash";

import { getAvailableResources } from "../../../Utilities/FlatFileComponent/fieldValidation";
import ErrorMessages from "../../../../DisplayError/ErrorMessages";
import {
  beforeAfterSpaceValidation,
  countLengthValidator,
  digitBeforeValidator,
  lengthValidator,
  listFieldValidator,
  regexValidator,
  textRangeValidator,
  trimWhitespacesAndSetRecord,
} from "../../../Utilities/FlatFileComponent/validations/common";

import { handleSubmit } from "../../../Utilities/FlatFileComponent/Platform/listeners/submit";
import dealModel from "../../../../models/Deal";
import { postProcessFields } from "./customValidationFields";
import { modelFormatter } from "../../../../../../utils";
import { vlookup } from "../../../Utilities/FlatFileComponent/utils/vlookup";
import { insertSheetData } from "../../../Utilities/FlatFileComponent/utils/insertSheetData";
import { regex } from "../../../Utilities/FlatFileComponent/utils/regexConstants";

const flatten = require("flat");

const dealModelFlatDefault = flatten(modelFormatter("default", dealModel));

let errorJson = [];

export const listenerDeals = (
  orgData,
  priorityOptions,
  flatfileClose,
  abortController,
  wbuyerChoices
) =>
  FlatfileListener.create((listener) => {
    const { id: orgId } = orgData;

    listener.on("workbook:created", async (event) => {
      /* Track space_id in the console, to be able to share it with Flatfile team while reporting issues */
      const spaceId = event?.context?.spaceId;
      console.log("spaceId", spaceId);

      /* Insert the data in reference workbook */
      const wbuyerChoiceArray = values(wbuyerChoices);
      const wbuyer = wbuyerChoiceArray.map((element) => ({
        wbuyer_name: { value: element },
      }));
      insertSheetData(event, "Deals", "wbuyer_sheet", wbuyer);
    });

    listener.use(
      /** Get all the deal name into array, so that we can perform case sensitive validation with resource-available API endpoint */
      bulkRecordHook("deal__sheet", async (records) => {
        const deal_names = [];
        records.map((record) => {
          deal_names.push(record.get("name"));
        });

        const payload = {
          model: "Deal",
          names: deal_names,
          show_names: "true",
          is_case_sensitive: "false",
          show_fields: ["archived", "name"],
        };

        const getExistingDeals = await getAvailableResources(orgId, payload);
        const availableNamesList = getExistingDeals.resources_available_names;

        /** Deal name validation for case sensetive */
        records.map((record) => {
          /* Trim spaces if a cell only includes whitespaces */
          trimWhitespacesAndSetRecord(record);

          const dealName = record.get("name");
          const exactDealExist = includes(availableNamesList, dealName);
          const toLowerDealExist =
            availableNamesList &&
            includes(
              availableNamesList.map((el) => toLower(el)),
              toLower(dealName)
            );

          if (
            dealName &&
            Array.isArray(getExistingDeals) &&
            includes(getExistingDeals, "API Failed")
          ) {
            return { key: "name", value: ErrorMessages.API_FAILED_MESSAGE };
          } else {
            let changeValues = [];
            if (!exactDealExist && toLowerDealExist) {
              record.addError(
                "name",
                "Deal name with different character case exist in the organization. Please enter a unique name."
              );
            }
          }
          return record;
        });
      })
    );

    listener.use(
      recordHook("deal__sheet", async (record) => {
        /** Put default values */
        const priorityValue = record.get("priority");
        if (!priorityValue) record.set("priority", "30");

        const wbuyerError =
          "The selected DSP is not a valid choice. Please select a value from existing options.";
        vlookup(record, "wbuyer", "wbuyer_name", "wbuyer", wbuyerError);
        const wbuyerDefault = record.get("wbuyer");
        if (!wbuyerDefault) record.set("wbuyer", "All DSPs");

        /* name */
        lengthValidator(record, "name", 100);
        beforeAfterSpaceValidation(record, "name");

        /* bidfloor */
        countLengthValidator(record, "bidfloor", 6);
        digitBeforeValidator(record, "bidfloor", 4);
        regexValidator(
          record,
          "bidfloor",
          regex.positiveNumberWithTwoDecimal.pattern,
          regex.positiveNumberWithTwoDecimal.errorMsg
        );

        /* notes */
        textRangeValidator(record, "notes", 2, 4000);

        /* wadomain */
        listFieldValidator(record, "wadomain");

        /* wseat */
        listFieldValidator(record, "wseat");

        return record;
      })
    );

    /* Empty listener to prevent hooks from running indefinetely */
    listener.use(
      recordHook("wbuyer_sheet", async (record) => {
        return record;
      })
    );

    listener.filter({ job: "workbook:submitActionDeals" }, (configure) => {
      configure.on("job:ready", async ({ context: { jobId, workbookId } }) => {
        errorJson = await handleSubmit({
          priorityOptions,
          abortController,
          orgData,
          jobId,
          workbookId,
          flatModel: dealModelFlatDefault,
          postProcessFields,
          model: "deals",
          type: "deals",
          wbuyerChoices,
        });
      });
    });

    listener.on("job:outcome-acknowledged", (event) => {
      // Handle the outcome-acknowledged event here
      flatfileClose(errorJson || []);
    });
  });
