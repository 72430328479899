import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { isEmpty, capitalize } from "lodash";
import {
  SideNavMenu,
  SideNavMenuItem,
  SideNavSubMenu,
  SideNav,
  Badge,
  eventBus,
  SFchat,
  Icon,
} from "@px/px_design_system";

import globalConstants from "../../../../global-constants";
import PrepareSvg from "../../svgs/PrepareSvg";
import { AdApprovalIcon } from "../../svgs/AdApprovalIcon";
import InventoryIcon from "../../svgs/InventoryIcon";
import MeasurementIcon from "../../svgs/MeasurementIcon";
import NewsIcon from "../../svgs/NewsIcon";
import OrderIcon from "../../svgs/OrderIcon";
import ResourcesIcon from "../../svgs/ResourcesIcon";
import RevenueIcon from "../../svgs/RevenueIcon";
import HelpIcon from "../../svgs/HelpIcon";
import PlanningIcon from "../../svgs/Planning";
import { UserContext } from "../../context/auth";
import { GetCount } from "../../context/GetCount";
import settings from "../../../../settings";
import { getLogoURL } from "./helpers";
import "./style.css";

const useRenderSidebar = (params) => {
  const {
    data,
    orgObject,
    userName,
    userEmail,
    userId,
    location,
    persona,
    count,
    isPerViewEnabled,
    isPlanningToolEnabled,
  } = params;

  const isStaff = userEmail.includes("@placeexchange.com");
  let customerType;
  if (isStaff) {
    customerType = "Staff";
  } else if (persona === "Buyer") {
    customerType = "DSP";
  } else {
    customerType = "Publisher";
  }

  const isSuperUser = !isEmpty(data) && data.userData[1] === 204;

  const onOpenChange = () => {
    document.querySelectorAll(".pro-sub-menu").forEach((item) => {
      if (item.classList.contains("open")) {
        item.querySelector(".pro-inner-item").click();
      }
    });
  };

  let helpArticleLink;
  if (location.pathname.split("/")[3] === "reporting") {
    // check if curr reporting is for pub sales or publisher
    helpArticleLink =
      persona === "Pub Planner"
        ? globalConstants.HELP_CENTER["reporting_for_pub_sales"]
        : globalConstants.HELP_CENTER["reporting"];
  } else {
    helpArticleLink =
      globalConstants.HELP_CENTER[location.pathname.split("/")[3]];
  }

  const logoUrl = getLogoURL(persona, orgObject?.id);

  return (
    <SideNav
      betatag={false}
      logoUrl={logoUrl}
      NavLink={NavLink}
      footerContent={
        <SideNavMenu>
          <SideNavMenuItem icon={<PrepareSvg iconComponent={HelpIcon} />}>
            <a
              href={helpArticleLink}
              target="_blank"
              rel="noopener noreferrer"
              className="icon-nav"
            >
              Help Center
            </a>

            {userEmail && (
              <SFchat
                domainName={
                  window.REACT_APP_IS_PROD_ENABLED === "False"
                    ? "placeexchange--devfull"
                    : "placeexchange"
                }
                siteUrl={
                  window.REACT_APP_IS_PROD_ENABLED === "False"
                    ? "https://devfull-place-exchange.cs220.force.com"
                    : "https://placeexchange.my.salesforce-sites.com/internal"
                }
                deploymentId={
                  window.REACT_APP_IS_PROD_ENABLED === "False"
                    ? "5728H0000004CDU"
                    : "5726g0000001ZC6"
                }
                orgID={
                  window.REACT_APP_IS_PROD_ENABLED === "False"
                    ? "00D8H0000008fw6"
                    : "00D6g0000024Uw5"
                }
                buttonId={
                  window.REACT_APP_IS_PROD_ENABLED === "False"
                    ? "5738H0000004CBa"
                    : "5736g0000001aEA"
                }
                instanceID={
                  window.REACT_APP_IS_PROD_ENABLED === "False"
                    ? "la5-c1cs-ia5"
                    : "la1-core1.sfdc-lywfpd"
                }
                eswLiveAgentDevName="Chat_Agents"
                email={userEmail}
                displayHelpButton={true}
                language=""
                featuresType="LiveAgent"
                helpCentreLink="https://help.placeexchange.com/s/"
              />
            )}
          </SideNavMenuItem>
        </SideNavMenu>
      }
    >
      {persona === "Publisher" && (
        <>
          <SideNavMenu>
            <SideNavSubMenu
              onOpenChange={onOpenChange}
              defaultOpen={
                location.pathname.includes("reporting") ? true : false
              }
              title="Reports"
              icon={<PrepareSvg iconComponent={RevenueIcon} />}
              className={`${
                location.pathname.includes("reporting") ? "active" : ""
              }`}
            >
              <SideNavMenuItem>
                <NavLink
                  to={
                    isEmpty(data)
                      ? `/orgs/no-user-org/reporting/revenue/summary`
                      : `/orgs/${orgObject.id}/reporting/revenue/summary`
                  }
                >
                  <span className="sub-menu__text">Revenue Summary</span>
                </NavLink>
              </SideNavMenuItem>
              <SideNavMenuItem>
                <NavLink
                  to={
                    isEmpty(data)
                      ? `/orgs/no-user-org/reporting/revenue/daily`
                      : `/orgs/${orgObject.id}/reporting/revenue/daily`
                  }
                >
                  <span className="sub-menu__text">Revenue By Day</span>
                </NavLink>
              </SideNavMenuItem>
              <SideNavMenuItem>
                <NavLink
                  to={
                    isEmpty(data)
                      ? `/orgs/no-user-org/reporting/revenue/breakout`
                      : `/orgs/${orgObject.id}/reporting/revenue/breakout`
                  }
                >
                  <span className="sub-menu__text">Revenue Breakout</span>
                </NavLink>
              </SideNavMenuItem>
              <SideNavMenuItem>
                <NavLink
                  to={
                    isEmpty(data)
                      ? `/orgs/no-user-org/reporting/health/breakout`
                      : `/orgs/${orgObject.id}/reporting/health/breakout`
                  }
                >
                  <span className="sub-menu__text">Inventory Health</span>
                </NavLink>
              </SideNavMenuItem>
              <SideNavMenuItem>
                <NavLink
                  to={
                    isEmpty(data)
                      ? `/orgs/no-user-org/reporting/deal/monitoring`
                      : `/orgs/${orgObject.id}/reporting/deal/monitoring`
                  }
                >
                  <span className="sub-menu__text">Deal Monitoring</span>
                </NavLink>
              </SideNavMenuItem>
              <SideNavMenuItem>
                <NavLink
                  to={
                    isEmpty(data)
                      ? `/orgs/no-user-org/reporting/campaign/troubleshooting`
                      : `/orgs/${orgObject.id}/reporting/campaign/troubleshooting`
                  }
                >
                  <span className="sub-menu__text">
                    Campaign Troubleshooting
                  </span>
                </NavLink>
              </SideNavMenuItem>
            </SideNavSubMenu>
          </SideNavMenu>
          <SideNavMenu>
            <SideNavSubMenu
              onOpenChange={onOpenChange}
              defaultOpen={
                location.pathname.includes("adapprovals") ? true : false
              }
              title="Ad Approvals"
              icon={<PrepareSvg iconComponent={AdApprovalIcon} />}
              className={`${
                location.pathname.includes("adapprovals") ? "active" : ""
              }`}
            >
              <SideNavMenuItem>
                <NavLink
                  isActive={(match, location) =>
                    location.search.includes("?audit__status=1")
                  }
                  to={
                    isEmpty(data)
                      ? `/orgs/no-user-org/adapprovals/summary?audit__status=1`
                      : `/orgs/${orgObject.id}/adapprovals/summary?audit__status=1`
                  }
                >
                  <span className="sub-menu__text">Pending Review</span>

                  <Badge color="primary" pill>
                    {count?.["adapprovals/pending"]}
                  </Badge>
                </NavLink>
              </SideNavMenuItem>
              <SideNavMenuItem>
                <NavLink
                  isActive={(match, location) =>
                    location.search.includes("?audit__status=2")
                  }
                  to={
                    isEmpty(data)
                      ? `/orgs/no-user-org/adapprovals/summary?audit__status=2,3`
                      : `/orgs/${orgObject.id}/adapprovals/summary?audit__status=2,3`
                  }
                >
                  <span className="sub-menu__text">Approved</span>

                  <Badge color="secondary" pill>
                    {count?.["adapprovals/approved"]}
                  </Badge>
                </NavLink>
              </SideNavMenuItem>
              <SideNavMenuItem>
                <NavLink
                  isActive={(match, location) =>
                    location.search.includes("?audit__status=4")
                  }
                  to={
                    isEmpty(data)
                      ? `/orgs/no-user-org/adapprovals/summary?audit__status=4`
                      : `/orgs/${orgObject.id}/adapprovals/summary?audit__status=4`
                  }
                >
                  <span className="sub-menu__text">Rejected</span>

                  <Badge color="secondary" pill>
                    {count?.["adapprovals/rejected"]}
                  </Badge>
                </NavLink>
              </SideNavMenuItem>
              <SideNavMenuItem>
                <NavLink
                  isActive={(match, location) =>
                    location.search.includes("?audit__status=all")
                  }
                  to={
                    isEmpty(data)
                      ? `/orgs/no-user-org/adapprovals/summary?audit__status=all`
                      : `/orgs/${orgObject.id}/adapprovals/summary?audit__status=all`
                  }
                >
                  <span className="sub-menu__text">All Ads</span>

                  <Badge color="secondary" pill>
                    {isEmpty(count)
                      ? ""
                      : count?.["adapprovals/pending"] +
                        count?.["adapprovals/approved"] +
                        count?.["adapprovals/rejected"]}
                  </Badge>
                </NavLink>
              </SideNavMenuItem>
            </SideNavSubMenu>
          </SideNavMenu>
          <SideNavMenu>
            <SideNavSubMenu
              onOpenChange={onOpenChange}
              defaultOpen={
                location.pathname.includes("inventory") ? true : false
              }
              title="Inventory"
              icon={<PrepareSvg iconComponent={InventoryIcon} />}
              className={`${
                location.pathname.includes("inventory") ? "active" : ""
              }`}
            >
              <SideNavMenuItem>
                <NavLink
                  isActive={(match, location) =>
                    location.pathname.includes("networks")
                  }
                  to={
                    isEmpty(data)
                      ? `/orgs/no-user-org/inventory/summary/networks`
                      : `/orgs/${orgObject.id}/inventory/summary/networks`
                  }
                >
                  <span className="sub-menu__text">Networks</span>

                  <Badge color="secondary" pill>
                    {count?.networks}
                  </Badge>
                </NavLink>
              </SideNavMenuItem>
              <SideNavMenuItem>
                <NavLink
                  isActive={(match, location) =>
                    location.pathname.includes("adunits")
                  }
                  to={
                    isEmpty(data)
                      ? `/orgs/no-user-org/inventory/summary/adunits`
                      : `/orgs/${orgObject.id}/inventory/summary/adunits`
                  }
                >
                  <span className="sub-menu__text">Ad Units</span>

                  <Badge color="secondary" pill>
                    {count?.adunits}
                  </Badge>
                </NavLink>
              </SideNavMenuItem>
            </SideNavSubMenu>
          </SideNavMenu>

          {isPlanningToolEnabled === true && (
            <SideNavMenu>
              <SideNavMenuItem
                icon={<PrepareSvg iconComponent={PlanningIcon} />}
              >
                <NavLink
                  to="//planning.placeexchange.com/?redirectSource=portal"
                  target="_blank"
                >
                  <span className="sub-menu__text">
                    Plans
                    <span className="text--right__icon">
                      <Icon icon={["fas", "arrow-up-right-from-square"]} />
                    </span>
                  </span>
                </NavLink>
              </SideNavMenuItem>
            </SideNavMenu>
          )}

          <SideNavMenu>
            <SideNavSubMenu
              onOpenChange={onOpenChange}
              defaultOpen={
                location.pathname.includes("deals") ||
                location.pathname.includes("orders")
                  ? true
                  : false
              }
              title="Campaigns"
              icon={<PrepareSvg iconComponent={OrderIcon} />}
              className={`${
                location.pathname.includes("deals") ||
                location.pathname.includes("orders")
                  ? "active"
                  : ""
              }`}
            >
              <SideNavMenuItem>
                <NavLink
                  isActive={(match, location) =>
                    location.pathname.includes("orders")
                  }
                  to={
                    isEmpty(data)
                      ? `/orgs/no-user-org/orders/summary/placement`
                      : `/orgs/${orgObject.id}/orders/summary/placement`
                  }
                >
                  <span className="sub-menu__text">Placements</span>
                  <Badge color="secondary" pill>
                    {count?.placements}
                  </Badge>
                </NavLink>
              </SideNavMenuItem>
              <SideNavMenuItem>
                <NavLink
                  isActive={(match, location) =>
                    location.pathname.includes("deals")
                  }
                  to={
                    isEmpty(data)
                      ? `/orgs/no-user-org/deals/summary`
                      : `/orgs/${orgObject.id}/deals/summary`
                  }
                >
                  <span className="sub-menu__text">Deals</span>
                  <Badge color="secondary" pill>
                    {count?.deals}
                  </Badge>
                </NavLink>
              </SideNavMenuItem>
            </SideNavSubMenu>
          </SideNavMenu>
        </>
      )}
      {persona === "Publisher" &&
        (isSuperUser === true || isPerViewEnabled === true) && (
          <>
            <SideNavMenu>
              <SideNavSubMenu
                onOpenChange={onOpenChange}
                defaultOpen={
                  location.pathname.includes("measurement") ? true : false
                }
                title="Measurement"
                icon={
                  <PrepareSvg
                    iconComponent={MeasurementIcon}
                    viewBoxDimension="0 0 50 50"
                  />
                }
                className={`${
                  location.pathname.includes("measurement") ? "active" : ""
                }`}
              >
                <SideNavMenuItem>
                  <NavLink
                    isActive={(match, location) =>
                      location.pathname.includes("geosegments")
                    }
                    to={
                      isEmpty(data)
                        ? `/orgs/no-user-org/measurement/summary/geosegments`
                        : `/orgs/${orgObject.id}/measurement/summary/geosegments`
                    }
                  >
                    <span className="sub-menu__text">Geo Segments</span>

                    <Badge color="secondary" pill>
                      {count?.geosegments}
                    </Badge>
                  </NavLink>
                </SideNavMenuItem>
              </SideNavSubMenu>
            </SideNavMenu>
          </>
        )}
      {persona === "Buyer" && (
        <>
          <SideNavMenu>
            <SideNavMenuItem
              icon={
                <PrepareSvg
                  iconComponent={ResourcesIcon}
                  viewBoxDimension="0 0 16 16"
                />
              }
              active={location.pathname.includes("resources") ? true : false}
            >
              <NavLink
                to={
                  isEmpty(data)
                    ? `/orgs/no-user-org/resources/summary`
                    : `/orgs/${orgObject.id}/resources/summary`
                }
              >
                <span className="sub-menu__text">Resources</span>
              </NavLink>
            </SideNavMenuItem>
          </SideNavMenu>
          <SideNavMenu>
            <SideNavMenuItem
              icon={
                <PrepareSvg
                  iconComponent={NewsIcon}
                  viewBoxDimension="0 0 16 16"
                />
              }
              active={location.pathname.includes("news") ? true : false}
            >
              <NavLink
                to={
                  isEmpty(data)
                    ? `/orgs/no-user-org/news/summary`
                    : `/orgs/${orgObject.id}/news/summary`
                }
              >
                <span className="sub-menu__text">News</span>
              </NavLink>
            </SideNavMenuItem>
          </SideNavMenu>
        </>
      )}
      {persona === "Pub Planner" && (
        <>
          <SideNavMenu>
            <SideNavSubMenu
              onOpenChange={onOpenChange}
              defaultOpen={
                location.pathname.includes("reporting") ? true : false
              }
              title="Reports"
              icon={<PrepareSvg iconComponent={RevenueIcon} />}
              className={`${
                location.pathname.includes("reporting") ? "active" : ""
              }`}
            >
              <SideNavMenuItem>
                <NavLink
                  to={
                    isEmpty(data)
                      ? `/orgs/no-user-org/reporting/campaign/activation_status`
                      : `/orgs/${orgObject.id}/reporting/campaign/activation_status`
                  }
                >
                  <span className="sub-menu__text">
                    Campaign Activation Status
                  </span>
                </NavLink>
              </SideNavMenuItem>
              <SideNavMenuItem>
                <NavLink
                  to={
                    isEmpty(data)
                      ? `/orgs/no-user-org/reporting/campaign/delivery_status`
                      : `/orgs/${orgObject.id}/reporting/campaign/delivery_status`
                  }
                >
                  <span className="sub-menu__text">
                    Campaign Delivery Status
                  </span>
                </NavLink>
              </SideNavMenuItem>
            </SideNavSubMenu>
          </SideNavMenu>
          <SideNavMenu>
            <SideNavMenuItem icon={<PrepareSvg iconComponent={PlanningIcon} />}>
              <NavLink
                to="//planning.placeexchange.com/?redirectSource=portal"
                target="_blank"
              >
                <span className="sub-menu__text">
                  Plans
                  <span className="text--right__icon">
                    <Icon icon={["fas", "arrow-up-right-from-square"]} />
                  </span>
                </span>
              </NavLink>
            </SideNavMenuItem>
          </SideNavMenu>
        </>
      )}
    </SideNav>
  );
};

const Sidebar = (props) => {
  let countList = {};
  const [count, setCount] = useState({});
  const { location } = props;
  const { data, persona, isPerViewEnabled, isPlanningToolEnabled } =
    useContext(UserContext);
  const { orgData, userData } = data;
  const orgObject = Array.isArray(orgData) ? orgData[0] : orgData;
  const userName = Array.isArray(userData)
    ? capitalize(userData[0].name.substring(0, userData[0].name.indexOf(".")))
    : "";
  const userEmail = Array.isArray(userData) ? userData[0].email : "";
  const userId = Array.isArray(userData) ? userData[0].id : "";
  const params = {
    data,
    location,
    orgObject,
    persona,
    userName,
    userEmail,
    userId,
    count,
    isPerViewEnabled,
    isPlanningToolEnabled,
  };

  const getSideNavCount = async () => {
    await Promise.all([
      GetCount("placements", orgObject.id, [
        { key: "fields", value: "id" },
        { key: "integration_type", value: 0 },
        { key: "page", value: 1 },
        { key: "page_size", value: 1 },
      ]),
      GetCount("deals", orgObject.id, [
        { key: "page", value: 1 },
        { key: "page_size", value: 1 },
      ]),
      GetCount("adunits", orgObject.id, [
        { key: "fields", value: "id" },
        { key: "page", value: 1 },
        { key: "page_size", value: 1 },
      ]),
      GetCount("networks", orgObject.id, [
        { key: "fields", value: "id" },
        { key: "page", value: 1 },
        { key: "page_size", value: 1 },
      ]),
      GetCount("adapprovals/pending", orgObject.id, [
        { key: "status", value: 1 },
        { key: "page", value: 1 },
        { key: "page_size", value: 1 },
      ]),
      GetCount("adapprovals/approved", orgObject.id, [
        { key: "status", value: "2,3" },
        { key: "page", value: 1 },
        { key: "page_size", value: 1 },
      ]),
      GetCount("adapprovals/rejected", orgObject.id, [
        { key: "status", value: 4 },
        { key: "page", value: 1 },
        { key: "page_size", value: 1 },
      ]),
      GetCount("geosegments", orgObject.id, [
        { key: "fields", value: "id" },
        { key: "page", value: 1 },
        { key: "page_size", value: 1 },
      ]),
    ]).then((result) => {
      result.forEach((item) => {
        countList = { ...countList, ...item };
      });
      setCount(countList);
    });
  };

  useEffect(() => {
    eventBus.on("updateTable", () => {
      if (!isEmpty(orgObject)) getSideNavCount();
    });
    if (!isEmpty(orgObject)) {
      getSideNavCount();
    }
  }, [data, location.pathname]);

  return useRenderSidebar(params);
};

Sidebar.propTypes = {
  location: PropTypes.shape(),
};

export default React.memo(Sidebar);
