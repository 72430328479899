import { constructAdminAPIEndPoint, fetchHandler } from "..";

const fetchOrgContract = (id) =>
  fetchHandler({
    url: constructAdminAPIEndPoint({
      url: `orgs/${id}/contracts`,
    }),
  }).then((response) => {
    return response;
  });

export default fetchOrgContract;
