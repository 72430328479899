import React from "react";

const UnarchiveIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="23"
    viewBox="0 0 25 23"
    fill="#fff"
  >
    <path
      d="M20.9301 6.08867L17.9094 4.31517C17.782 4.22022 17.5584 4.14026 17.3138 4.08454C17.0694 4.02884 16.7875 3.99951 16.5 3.9999L8.5 4C8.21269 3.99962 7.95312 4.02379 7.68632 4.08454C7.44192 4.14019 7.24472 4.21999 7.11704 4.31483L4.06983 6.08861V6.0951C4.02619 6.12693 4.00222 6.16198 4 6.19768V17.1047C4.00044 17.3421 4.25668 18.3321 4.71221 18.5C5.16787 18.6678 5.78586 18.7622 6.43013 18.7624H18.5699C19.2142 18.7622 19.8321 18.6678 20.2878 18.5C20.7433 18.3321 20.9995 17.342 21 17.1047V6.19768C20.9988 6.16209 20.9762 6.12704 20.9337 6.0951C20.9311 6.09281 20.9327 6.09057 20.9301 6.08867ZM7.80294 5.01695C7.84037 4.98953 7.91671 4.97226 8 4.97237L16.8399 5C16.9235 4.99984 17 5.01728 17.037 5.04491L18.5 5.9535L6.5 5.9535L7.80294 5.01695ZM19.6742 17.1046C19.6737 17.2125 19.5572 17.3159 19.3502 17.3922C19.1431 17.4685 18.8624 17.5114 18.5697 17.5116H6.4299C6.13712 17.5114 5.85646 17.4685 5.64936 17.3922C5.44239 17.3159 5.32581 17.2125 5.32537 17.1046V7H19.6743L19.6742 17.1046Z"
      fill="#fff"
    />
    <path
      d="M12.0028 15.7956C12.1349 15.9264 12.3136 16 12.5 16C12.6864 16 12.8651 15.9264 12.9972 15.7956L15.8112 12.9952C15.9784 12.8164 16.0401 12.5639 15.9739 12.3287C15.9074 12.0937 15.7228 11.9099 15.4866 11.8438C15.2503 11.7779 14.9965 11.8393 14.8169 12.0057L13.2035 13.6103V9.70009C13.2035 9.4499 13.0694 9.21878 12.8518 9.09376C12.6341 8.96875 12.3659 8.96875 12.1482 9.09376C11.9306 9.21878 11.7965 9.4499 11.7965 9.70009V13.6103L10.1831 12.0057C10.0035 11.8393 9.74974 11.7779 9.5134 11.8438C9.27723 11.9099 9.09255 12.0937 9.02614 12.3287C8.95987 12.5639 9.02158 12.8164 9.18882 12.9952L12.0028 15.7956Z"
      fill="#fff"
    />
    <line
      x1="0.75"
      y1="-0.75"
      x2="22.3924"
      y2="-0.75"
      transform="matrix(0.777793 -0.62852 0.715007 0.699118 4 21)"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <line
      x1="0.75"
      y1="-0.75"
      x2="22.3924"
      y2="-0.75"
      transform="matrix(0.777793 -0.62852 0.715007 0.699118 4 19.5449)"
      stroke="#6C757D"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
);

export default UnarchiveIcon;
