import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import { UserContext, UserProvider } from "../context/auth/index";

const setRedirectUrl = (redirectUrl) => {
  localStorage.setItem("redirectURL", redirectUrl);
};

const setLocalStorage = (data, isBuyer, isPublisher, isPubPlanner) => {
  if (data && ("orgData" in data || "userData" in data)) {
    // If the saved redirectURL happens to have a bad url (such as
    // /null/dashboard), get rid of it.
    let localStorageRedirectUrl = localStorage.getItem("redirectURL");
    if (localStorageRedirectUrl && localStorageRedirectUrl.includes("null")) {
      localStorage.removeItem("redirectURL");
      localStorageRedirectUrl = null;
    }

    // Determine  if this is a buyer or pub org, set redirect url appropriately
    if (!localStorageRedirectUrl && isPubPlanner) {
      setRedirectUrl(
        `/orgs/${data.orgData[0].id}/reporting/campaign/activation_status`
      );
    } else if (!localStorageRedirectUrl && isBuyer && !isPublisher) {
      setRedirectUrl(`/orgs/${data.orgData[0].id}/resources/summary`);
    } else if (!localStorageRedirectUrl) {
      setRedirectUrl(`/orgs/${data.orgData[0].id}/reporting/revenue/summary`);
    }

    localStorage.setItem("px_default_org_id", data.orgData[0].id);
    localStorage.setItem("px_default_org_name", data.orgData[0].name);
    return localStorage.getItem("redirectURL");
  }
  return "";
};

const AuthData = () => {
  const appContext = useContext(UserContext);
  const { data, validUser, isBuyer, isPublisher, isPubPlanner } = appContext;
  let redirectTo = setLocalStorage(data, isBuyer, isPublisher, isPubPlanner);

  if (!validUser) {
    redirectTo = "/orgs/no-user-org/reporting/revenue/summary";
  }
  return <>{redirectTo.length !== 0 && <Redirect to={redirectTo} />}</>;
};

const Login = () => (
  <UserProvider>
    <AuthData />
  </UserProvider>
);

export { setLocalStorage, Login };
