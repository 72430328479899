import React from "react";

const MegaPhoneIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 24 24"
    fill="#B5BABE"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.8561 0C20.8578 0 20.0191 0.685113 19.7814 1.60708L19.7402 1.77024L10.2293 4.90193H3.77733C3.49311 4.90193 3.26854 5.12738 3.26854 5.41248V6.56077L3.02379 6.61252C1.30356 6.96341 0 8.48715 0 10.3135C0 12.1399 1.30268 13.6637 3.02379 14.0145L3.26854 14.0663V15.2163C3.26854 15.5014 3.49223 15.7251 3.77733 15.7251H4.99404L6.28795 22.1964C6.48445 23.1798 7.35553 23.8947 8.35908 23.8947C9.69948 23.8947 10.6916 22.6789 10.4285 21.3674L9.29946 15.7269H10.2302L19.7411 18.8586L19.7823 19.0217C20.0218 19.9428 20.8587 20.6271 21.8569 20.6271C23.0412 20.6271 24 19.6683 24 18.484V2.14569C24 0.961439 23.0412 0.00263169 21.8569 0.00263169L21.8561 0ZM21.8561 1.02109C22.4727 1.02109 22.9798 1.52637 22.9798 2.14306V18.4796C22.9798 19.0963 22.4727 19.6033 21.8561 19.6033C21.2394 19.6033 20.7323 19.0963 20.7323 18.4796V2.14306C20.7323 1.52637 21.2402 1.02109 21.8561 1.02109ZM19.713 2.85274V17.7726L10.8223 14.8479V5.7774L19.3104 2.98607L19.713 2.85274ZM4.28788 5.92127H9.80124V14.7023H4.28788V6.2283V5.92127ZM3.26854 7.56607V13.0575L2.85888 12.9119C1.65181 12.4838 1.02635 11.3908 1.02548 10.3127C1.0246 9.23455 1.65006 8.14065 2.85888 7.71169L3.26854 7.56607ZM6.0318 15.7234H8.25907L9.42929 21.5657C9.56351 22.235 9.0398 22.871 8.35645 22.871C7.83801 22.871 7.38799 22.5008 7.28535 21.9938L6.10461 16.0901L6.0318 15.7234Z"
      fill="#6C757D"
    />
  </svg>
);

export default MegaPhoneIcon;
