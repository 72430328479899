import React from "react";
import PropTypes from "prop-types";
import { Button, Badge, ToolTips } from "@px/px_design_system";

import "./style.css";

const iconStyle = {
  style: {
    color: "#ffffff",
    width: "1rem",
    height: "1rem",
    marginRight: "0.625rem",
  },
};

const rejectButtonProps = {
  style: {
    backgroundColor: "#dc3545",
    color: "#ffffff",
    borderColor: "#dc3545",
  },
  buttontext: "Reject",
  icontype: ["far", "times-circle"],
  iconstyle: iconStyle.style,
};

const approveButtonProps = {
  style: {
    backgroundColor: "#28a745",
    borderColor: "#28a745",
    color: "#ffffff",
  },
  buttontext: "Approve",
  icontype: ["far", "check-circle"],
  iconstyle: iconStyle.style,
};

const ChangeStatus = ({
  changeStatusOfAd,
  confirmApproval,
  adId,
  disableStatusUpdate,
  activeStatus,
  tooltipMessageObject,
  isSnapshotFailed,
  checkDefaultState,
  confirmReject,
}) => {
  const isButtonDisabled = () =>
    disableStatusUpdate ||
    activeStatus === "approved" ||
    isSnapshotFailed ||
    checkDefaultState;

  const statusColor = {
    approved: "success",
    rejected: "danger",
    pending: "secondary",
  };

  return (
    <div className="changeStatus border-bottom">
      <div className="changeStatus__info-section">
        <h2>
          Place Exchange Ad Approval ID
          <span>{adId}</span>
        </h2>
        <Badge
          className="approval__status"
          color={statusColor[activeStatus]}
          pill
        >
          {activeStatus}
        </Badge>
      </div>

      <div className="changeStatus__action-section">
        <ToolTips
          disabled={disableStatusUpdate || activeStatus === "rejected"}
          text={
            disableStatusUpdate || activeStatus === "rejected"
              ? tooltipMessageObject.rejetMessage
              : ""
          }
        >
          <Button
            id="rejectBtn"
            {...rejectButtonProps}
            disabled={disableStatusUpdate || activeStatus === "rejected"}
            onClick={() => confirmReject(true)}
          />
        </ToolTips>
        <ToolTips
          disabled={isButtonDisabled()}
          text={isButtonDisabled() ? tooltipMessageObject.approveMessage : ""}
        >
          <Button
            id="approveBtn"
            {...approveButtonProps}
            disabled={isButtonDisabled()}
            onClick={() => confirmApproval(true)}
          />
        </ToolTips>
      </div>
    </div>
  );
};

ChangeStatus.propTypes = {
  adId: PropTypes.string,
  changeStatusOfAd: PropTypes.func,
  confirmApproval: PropTypes.func,
  confirmReject: PropTypes.func,
  disableStatusUpdate: PropTypes.bool,
  activeStatus: PropTypes.string,
  isSnapshotFailed: PropTypes.bool,
  checkDefaultState: PropTypes.bool,
  tooltipMessageObject: PropTypes.shape(),
};

export default ChangeStatus;
