const adApprovalModel = {
  ad__name: "",
  ad__organization__name: "",
  ad__adomain: "",
  ad__creative__kind: "",
  ad__lang_display: "",
  id: "",
  ts: "",
  adapprovalaudit__lastmod: "",
};

export default adApprovalModel;
