import {
  constructAdminAPIEndPoint,
  fetchHandler,
  getCount,
} from "../../../../utils";

export const GetCount = (type, orgId, params) => {
  let countObj = {};

  const args = () => ({
    headers: {
      "cache-control": "no-cache, no-store",
    },
    getResponseHeaders: true,
    url: constructAdminAPIEndPoint({
      url: `orgs/${orgId}/${type.split("/")[0]}`,
      searchParams: params,
    }),
  });
  return fetchHandler(args()).then((response) => {
    countObj[type] = getCount(response);
    return countObj;
  });
};
