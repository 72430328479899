import React, { useState } from "react";
import { MultiSelect } from "@px/px_design_system";

const DaypartForm = (props) => {
  const [daypartsOptions, setDaypartsOptions] = useState(props?.daysList);
  const [daypartsValue, setDaypartsValue] = useState(props?.value);

  return (
    <MultiSelect
      defaultValue={[]}
      placeholder="Select day(s)"
      isMulti={true}
      isSearchable={true}
      closeMenuOnSelect={false}
      options={daypartsOptions}
      isValidNewOption={() => false}
      name={props?.name}
      label="Weekday(s)"
      getValue={(value) => {
        setDaypartsValue(value);
      }}
      key={5}
      errors={props?.errors}
      getValues={props?.getValues}
      history={props?.history}
      location={props?.location}
      match={props?.match}
      onBlur={props?.onBlur}
      onChange={props?.onChange}
      setValue={props?.setValue}
      value={daypartsValue}
      isClearable={true}
    />
  );
};

export default DaypartForm;
