import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { Spinner } from "@px/px_design_system";

import AdApprovalList from "./components/PlaceExchange/components/AdApprovalList";
import AdApprovalSummary from "./components/PlaceExchange/components/AdApprovalSummary";
import Deal from "./components/PlaceExchange/components/DealManagement";
import EmbedDashboard from "./components/PlaceExchange/components/EmbedDashboard";
import FormAddAdunit from "./components/PlaceExchange/components/InventoryManagement/Forms/Add";
import FormAddDeal from "./components/PlaceExchange/components/DealManagement/Forms/Add";
import FormAddOrder from "./components/PlaceExchange/components/OrderManagement/Forms/Add";
import Inventory from "./components/PlaceExchange/components/InventoryManagement";
import News from "./components/PlaceExchange/components/News";
import Orders from "./components/PlaceExchange/components/OrderManagement";
import PageNotFound from "./components/PageNotFound";
import PubPlannerReporting from "./components/PlaceExchange/components/EmbedDashboard/PubPlannerReporting";
import Resources from "./components/PlaceExchange/components/Resources";
import { UserContext } from "./components/PlaceExchange/context/auth";

import DisplayError from "./components/PlaceExchange/DisplayError";
import Measurement from "./components/PlaceExchange/components/Measurement";

const spinnerProps = {
  className: "spinner-icon",
  color: "primary",
  loadingtext: "Loading",
};

const pubRoutes = [
  {
    urlPath: "reporting/:reportingType/:reportingSubType",
    component: EmbedDashboard,
    isExact: true,
  },
  {
    urlPath: "reporting",
    component: EmbedDashboard,
    isExact: false,
  },
  {
    urlPath: "adapprovals/summary",
    component: AdApprovalList,
    isExact: true,
  },
  {
    urlPath: "adapprovals/queue",
    component: AdApprovalSummary,
    isExact: true,
  },
  {
    urlPath: "adapprovals/:approval_id",
    component: AdApprovalSummary,
    isExact: true,
  },
  {
    urlPath: "inventory/summary",
    component: () => <Redirect to={`./summary/adunits`} />,
    isExact: true,
  },
  {
    urlPath: "inventory/summary/:inventoryType",
    component: Inventory,
    isExact: true,
  },
  {
    urlPath: "inventory/adunit/:adunitAction",
    component: FormAddAdunit,
    isExact: true,
  },
  {
    urlPath: "deals/summary",
    component: Deal,
    isExact: true,
  },
  {
    urlPath: "deals/:dealAction",
    component: FormAddDeal,
    isExact: true,
  },
  {
    urlPath: "orders/summary",
    component: () => <Redirect to={`./summary/placement`} />,
    isExact: true,
  },
  {
    urlPath: ":deals/placement/:placementAction",
    component: FormAddOrder,
    isExact: true,
  },
  {
    urlPath: "orders/summary/:orderType",
    component: Orders,
    isExact: true,
  },
  {
    urlPath: "orders/placement/:placementAction",
    component: FormAddOrder,
    isExact: true,
  },
  {
    urlPath: "measurement/summary",
    component: () => <Redirect to={`./summary/geosegments`} />,
    isExact: true,
  },
  {
    urlPath: "measurement/summary/:measurementType",
    component: Measurement,
    isExact: true,
  },
];

const buyerRoutes = [
  {
    urlPath: "news/summary",
    component: News,
    isExact: true,
  },
  {
    urlPath: "resources/summary",
    component: Resources,
    isExact: true,
  },
];

const pubPlannerRoutes = [
  {
    urlPath: "reporting/campaign/:reportingStatus",
    component: PubPlannerReporting,
    isExact: true,
  },
];

const ChildRoutes = (props) => {
  const { match } = props;
  const { isPublisher, isBuyer, isPubPlanner, displayError, errorMessage } =
    useContext(UserContext);

  const baseUrl = match.path;
  const availableRoutes = [];
  if (isPublisher) pubRoutes.forEach((val) => availableRoutes.push(val));
  if (isBuyer) buyerRoutes.forEach((val) => availableRoutes.push(val));
  if (isPubPlanner)
    pubPlannerRoutes.forEach((val) => availableRoutes.push(val));

  return (
    <Switch>
      {availableRoutes.map(({ urlPath, component, isExact }) => {
        if (isExact) {
          return (
            <Route path={`${baseUrl}${urlPath}`} component={component} exact />
          );
        } else {
          return <Route path={`${baseUrl}${urlPath}`} component={component} />;
        }
      })}
      {availableRoutes.length ? (
        <Route path="*" component={PageNotFound} />
      ) : displayError ? (
        <DisplayError {...errorMessage} />
      ) : (
        <Spinner {...spinnerProps} />
      )}
    </Switch>
  );
};

ChildRoutes.propTypes = {
  match: PropTypes.shape(),
};

export default ChildRoutes;
