/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from "react";
import { forEach, get, isEmpty } from "lodash";
import {
  Spinner,
  Button,
  Filter,
  Icon,
  MultiSelect,
  textTruncate,
  ToolTips,
} from "@px/px_design_system";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { PlatformFlatfile } from "../../Utilities/FlatFileComponent/Platform/index";
import { adunits__sheet } from "./AdUnitMetaData/blueprint";
import { listenerAdunits } from "./AdUnitMetaData/listener";
import DisplayError from "../../../DisplayError";
import PaginatedDownloadCsv from "../../Utilities/PaginatedDownloadCsv";
import DownloadDataDict from "../../Utilities/DownloadDataDict";
import ToastMsg from "../../Utilities/ToastMsg";
import TableView from "../../Utilities/TableView";
import {
  fixedEncodeURIComponent,
  noDataFoundText,
  prepareCsvData,
  sortDimensions,
  showCustomTooltip,
  createLinkToTabularColumn,
} from "../../../../../utils";
import ToolTipMessages from "../../Utilities/ToolTip/TooltipMessages";
import adUnitModel from "../../../models/AdUnit";
import networkModel from "../../../models/Network";
import { AsyncUploadContext } from "../../../context/AsyncUploadContext";
import { networks__sheet } from "./NetworkMetaData/blueprint";
import { listenerNetworks } from "./NetworkMetaData/listener";

import "./style.css";

const InventoryDetails = ({
  adUnitsList,
  networksList,
  inventoryData,
  displayError,
  errorMessage,
  fetchAdunits,
  history,
  inventoryDetails,
  inventoryMapping,
  isLoaded,
  isDataFetch,
  loading,
  page,
  prepareInventoryList,
  setLoader,
  sizePerPageValue,
  totalAdUnits,
  totalSize,
  triggerLoadData,
  userPermission,
  isSuperUser,
  userData,
  orgData,
  tabName,
  fetchNetworks,
  totalNetworks,
  totalNetworksSize,
  isDatasetLoaded,
  setFilterParams,
  filterParams,
  fetchAdunitsData,
}) => {
  const asyncUploadContext = useContext(AsyncUploadContext);
  /* Include Currency field in existing inventoryMapping object */
  const choiceFields = {
    ...inventoryMapping,
    floorCurrency: {
      [orgData.config.allowed_currency]: orgData.config.allowed_currency,
      "": "",
    },
  };
  const abortController = new AbortController();
  const [keyField] = useState("id");
  const [finalInventory, setFinalInventory] = useState([]);
  const [isFinalAdUnitsReady, setFinalAdUnitsReady] = useState(false);

  const assetSizeFormatter = (cell) => {
    let val = cell;
    if (cell === null || cell === undefined) {
      val = "-";
    }
    return cell ? `${val}"` : `${val}` || "-";
  };

  const privateAuctionText = (
    <>
      Private Auction Status{" "}
      <ToolTips
        text={
          <>
            Inactive - All bids are accepted
            <br />
            Active - Bids are restricted to the specified deals.
          </>
        }
      >
        <Icon
          id="info-icon"
          icon={["fas", "info-circle"]}
          size="1x"
          color={("Hex", "#6c757d")}
        />
      </ToolTips>
    </>
  );

  const linkToAdUnit = (cell, row) =>
    createLinkToTabularColumn(
      cell,
      row,
      `../adunit/edit?name=${fixedEncodeURIComponent(row.name)}`
    );

  const adunitColumn = [
    {
      dataField: "name",
      text: "Adunit Name",
      formatter: linkToAdUnit,
    },
    {
      dataField: "network__name",
      text: "Network Name",
    },
    {
      dataField: "dimensions",
      text: "Dimensions",
      sortColumnName: "slot__w,slot__h",
      sortFunc: sortDimensions,
    },
    {
      dataField: "asset__size",
      text: "Asset Size",
      formatter: assetSizeFormatter,
    },
    {
      dataField: "status_display",
      text: "Ad Unit Status",
    },
    {
      dataField: "venue__openooh_category_display",
      text: "Open OOH Venue Category",
    },
    {
      dataField: "private_auction_display",
      text: privateAuctionText,
    },
    {
      dataField: "asset__category_display",
      text: "Asset Category",
    },
    {
      dataField: "lastmod",
      text: "Last Modified",
      formatter: (data) => showCustomTooltip(data, "Time shown in UTC"),
    },
    {
      dataField: "id",
      text: "Id",
      formatter: textTruncate,
    },
  ];
  const sortAdunitColumns = {
    positions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    sort: true,
  };

  const networkColumn = [
    {
      dataField: "name",
      text: "Network Name",
    },
    {
      dataField: "private_auction_display",
      text: privateAuctionText,
    },
    {
      dataField: "auction__bidfloor",
      text: "Bid Floor",
      align: "right",
      headerAlign: "right",
    },
    {
      dataField: "adunits_count",
      text: "Ad Unit Count",
      align: "right",
      headerAlign: "right",
    },
    {
      dataField: "lastmod",
      text: "Last Modified",
      formatter: (data) => showCustomTooltip(data, "Time shown in UTC"),
    },
    {
      dataField: "id",
      text: "Id",
      formatter: textTruncate,
    },
  ];
  const sortNetworkColumns = {
    positions: [0, 1, 2, 3, 4, 5],
    sort: true,
  };

  const searchableAdunitColumn = ["id", "name", "asset__size"];
  const searchableNetworkColumn = ["id", "name"];

  const downloadAdunits = (
    urlParams = [],
    setCount = false,
    setSize = false
  ) => {
    const uniqueData = [
      ...urlParams
        .reduce((map, obj) => map.set(obj["key"], obj), new Map())
        .values(),
    ];
    return fetchAdunitsData(uniqueData, setCount, setSize, false);
  };

  let inventoryObj = {
    columns: adunitColumn,
    csvFileName: "Inventory_Ad_Units",
    fetchApiData: fetchAdunits,
    downloadAdunits: downloadAdunits,
    totalCount: totalAdUnits,
    isDisableDownloadCsvBtn: !isDatasetLoaded,
    sortColumns: sortAdunitColumns,
    totalDataSize: totalSize,
    buttonText: "Import Ad Units",
    modelObject: adUnitModel,
    searchableColumn: searchableAdunitColumn,
    inventoryResponse: "adunitsResponse",
    inventoryList: adUnitsList,
    /*
      venue.category_display - Moved to Open OOH category, this will be depricated in future.
      A few other fields are already removed by passing ?omit= to the API (Ref - adUnitsOmitFields var).
    */
    removeColumns: [
      "asset.category_display",
      "venue.category_display",
      "venue.openooh_category_display",
    ],
    flatFileModel: "adunits",
    tooltipDatasetsErrorMsg: ToolTipMessages.DATASETS_API_ERROR.title,
    tooltipNoDataMsg: ToolTipMessages.DOWNLOAD_CSV.title,
    tooltipNoPermissionMsg: ToolTipMessages.IMPORT_ADUNIT.noPermission,
    tooltipUpsertMsg: ToolTipMessages.IMPORT_ADUNIT.upsert,
    removeColumnsFromFailedCsv: [
      "allowed_pct",
      "created_by",
      "asset.dynamic_creative",
      "ext",
      "id",
      "lastmod",
      "organization",
      "owned_by",
      "placements",
      "ts",
      "venue.hash",
    ],
  };

  if (tabName === "networks") {
    inventoryObj = {
      columns: networkColumn,
      csvFileName: "Inventory_Networks",
      fetchApiData: fetchNetworks,
      downloadAdunits: fetchNetworks,
      totalCount: totalNetworks,
      isDisableDownloadCsvBtn: !isDatasetLoaded,
      sortColumns: sortNetworkColumns,
      totalDataSize: totalNetworksSize,
      buttonText: "Import Networks",
      modelObject: networkModel,
      searchableColumn: searchableNetworkColumn,
      inventoryResponse: "networksResponse",
      inventoryList: networksList,
      // A few fields are removed by passing ?omit= to the API (Ref - networksOmitFields var).
      flatFileModel: "networks",
      tooltipDatasetsErrorMsg: ToolTipMessages.DATASETS_API_ERROR.title,
      tooltipNoDataMsg: ToolTipMessages.DOWNLOAD_CSV.title,
      tooltipNoPermissionMsg: ToolTipMessages.IMPORT_NETWORK.noPermission,
      tooltipUpsertMsg: ToolTipMessages.IMPORT_NETWORK.upsert,
      removeColumnsFromFailedCsv: [
        "allowed_pct",
        "created_by",
        "dynamic_creative",
        "ext",
        "id",
        "lastmod",
        "owned_by",
        "placements",
        "ts",
        "asset.mimes",
        "asset.aspect_ratio",
        "ad_formats",
        "aspect_ratios",
      ],
    };
  }

  useEffect(() => {
    csvDataToDownload(inventoryData);
  }, [loading, isLoaded, isFinalAdUnitsReady]);

  const getJsonListToStr = (objList, fieldName) => {
    let jsonToStr = "";
    forEach(objList, (jsonObj) => {
      if (fieldName === "ad_formats") {
        jsonToStr += `${jsonObj.w}x${jsonObj.h},`;
      } else if (fieldName === "aspect_ratios") {
        jsonToStr += `${jsonObj.wratio}:${jsonObj.hratio},`;
      }
    });
    return jsonToStr.slice(0, -1);
  };

  const csvDataToDownload = (data, setStates = true) => {
    forEach(data, (inventoryRecord) => {
      const record = inventoryRecord;
      const adFormats = get(record, "ad_formats");
      if (adFormats != null) {
        record.ad_formats = getJsonListToStr(adFormats, "ad_formats");
      }
      const aspectRatios = get(record, "aspect_ratios");
      if (aspectRatios != null) {
        record.aspect_ratios = getJsonListToStr(aspectRatios, "aspect_ratios");
      }
      const eids = get(record, "eids");
      if (!isEmpty(eids) && typeof eids[0] === "object" && !isEmpty(eids[0])) {
        const eids_list = [];
        eids.forEach((eid) => {
          eid.uids.forEach((uid) => {
            eids_list.push(`${eid.source}:${uid.id}`);
          });
        });
        record.eids = eids_list;
      }
    });
    const params = {
      list: data,
      fileFormat: "csv",
      modelObj: inventoryObj.modelObject,
      jsonFields: ["ext"],
      dateTimePerseFields: ["lastmod", "start_date", "ts"],
    };
    const getCsvData = prepareCsvData(params);
    /* istanbul ignore next */
    if (!setStates) {
      /* This is to prevent setting state during flatfile error csv upload. State setting would mix the normal download csv flow. */
      return getCsvData;
    } else {
      setFinalInventory(getCsvData);
      setFinalAdUnitsReady(true);
    }
  };

  /* istanbul ignore next */
  const registerInventory = () => {
    setFilterParams([]);
    setLoader(false);
    triggerLoadData();
  };

  /* istanbul ignore next */
  const flatfileClose = (errorJson = []) => {
    abortController.abort();
    /* Following error message visible for networks model*/
    if (inventoryObj.flatFileModel !== "adunits" && errorJson.length) {
      const props = {
        icon: ["fas", "exclamation-triangle"],
        toastTitle: `Please find the failed ${inventoryObj.flatFileModel} by clicking the 'Download CSV' button`,
        toastSubTitle: `You can use the downloaded csv to re-upload the failed ${inventoryObj.flatFileModel}`,
        isDownloadable: true,
        csvProps: {
          json: csvDataToDownload(errorJson, false),
          moveToFirstPosition: ["name", "error_message"],
          removeColumns: inventoryObj.removeColumnsFromFailedCsv,
          renameHeaders: { name: "network_name" },
          filename: "Failed_Networks",
        },
      };
      toast.error(<ToastMsg {...props} />, {
        toastId: "failed-networks",
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
      });
    }
    registerInventory();
  };

  const spinnerProps = {
    className: "spinner-icon",
    color: "primary",
    loadingtext: "Loading",
  };
  const defaultSorted = [
    {
      dataField: "lastmod",
      order: "desc",
    },
  ];

  const importAdUnitProps = {
    style: {
      backgroundColor: "#2D6BFF",
      borderColor: "#2D6BFF",
      color: "#ffffff",
    },
    buttontext: `${inventoryObj.buttonText}`,
    icon: <Icon icon={["fas", "plus"]} size="1x" />,
  };

  let disableDownloadText = "";
  if (!isDatasetLoaded) {
    disableDownloadText = inventoryObj.tooltipDatasetsErrorMsg;
  }

  let importTooltipText = inventoryObj.tooltipUpsertMsg;
  if (userPermission) {
    importTooltipText = inventoryObj.tooltipNoPermissionMsg;
  } else if (!isDatasetLoaded) {
    importTooltipText = inventoryObj.tooltipDatasetsErrorMsg;
  }

  const getDropdownOptions = (dataObj) => {
    let dropdownOptions = [];
    for (let [key, value] of Object.entries(dataObj)) {
      dropdownOptions.push({ value: key, label: value });
    }
    return dropdownOptions;
  };

  const updateTableData = (tableData) => {
    setFilterParams(tableData);
  };

  const flatfileProps =
    tabName === "networks"
      ? {
          name: "networks",
          buttonText: "Import Networks",
          fields: networks__sheet(choiceFields),
          listener: listenerNetworks(orgData, flatfileClose, abortController),
          submitActionName: "submitActionNetworks",
        }
      : {
          fields: adunits__sheet(choiceFields),
          name: "adunits",
          listener: listenerAdunits(
            orgData,
            flatfileClose,
            abortController,
            asyncUploadContext,
            isSuperUser
          ),
          submitActionName: "submitActionAdunits",
          buttonText: "Import Ad Units",
        };

  return (
    <>
      <div
        className={`adunit--inventory-list  ${
          displayError ? "no--data--found" : ""
        } ${isLoaded ? "wrapper" : "wrapper--no-border"}`}
      >
        {(!isLoaded || loading) && <Spinner {...spinnerProps} />}
        {isLoaded && !loading && (
          <div
            className={`adunit--inventory-container inventory-container-${tabName}  ${
              isSuperUser ? "super--user" : "non--super--user"
            }`}
          >
            <h2 className="adunit__headline adunit__headline--copy sub__headline--count">
              {tabName === "adunits" ? <>Ad Units</> : <>Networks</>}
            </h2>
            {tabName === "adunits" && (
              <div className="table__wrapper">
                <Filter
                  defaultValues={{ status: [] }}
                  updateFilteredData={updateTableData}
                >
                  <MultiSelect
                    name="status"
                    options={getDropdownOptions(inventoryMapping.status)}
                    isMulti
                    placeholder="All status"
                    isClearable
                    label="Status"
                    isValidNewOption={() => false}
                  />
                </Filter>
              </div>
            )}
            <div className="buttons">
              <DownloadDataDict />
              <PaginatedDownloadCsv
                componentName="Inventory"
                fetchData={inventoryObj.downloadAdunits}
                csvDataToDownload={csvDataToDownload}
                tabPillCount={inventoryObj.totalCount}
                disableDownloadCsv={inventoryObj.isDisableDownloadCsvBtn}
                disableDownloadText={disableDownloadText}
                finalData={finalInventory}
                moveToFirstPosition={["name"]}
                removeColumns={inventoryObj.removeColumns}
                renameHeaders={
                  tabName === "networks"
                    ? { name: "network_name" }
                    : { name: "adunit_name" }
                }
                filename={inventoryObj.csvFileName}
                tabName={tabName}
                /*
                 * Prop here is used to force a component to mount and remount by simply changing its value.
                 * Refer https://www.nikgraf.com/blog/using-reacts-key-attribute-to-remount-a-component for more info.
                 */
                key={tabName}
                /** Format `aspect_ratios` & `asset.aspect_ratio` columns in excel file.
                 * It works based on the column index in file.
                 */
                columnIndex={[3, 4]}
                dataSize={tabName === "networks" ? totalNetworks : totalAdUnits}
                filterParams={filterParams}
              />
              <div className="import__button">
                <ToolTips
                  disabled={userPermission || !isDatasetLoaded}
                  text={importTooltipText}
                >
                  <div id="flatfile--adunits">
                    {!userPermission && isDatasetLoaded ? (
                      <PlatformFlatfile {...flatfileProps} />
                    ) : (
                      <Button
                        type="button"
                        {...importAdUnitProps}
                        disabled={true}
                      />
                    )}
                  </div>
                </ToolTips>
              </div>
            </div>

            <TableView
              history={history}
              rowDetails={inventoryDetails}
              prepareList={prepareInventoryList}
              columns={inventoryObj.columns}
              data={inventoryObj.inventoryList}
              fetchData={inventoryObj.fetchApiData}
              keyField={keyField}
              noDataFoundText={
                displayError ? (
                  <DisplayError {...errorMessage} />
                ) : (
                  noDataFoundText()
                )
              }
              page={page}
              searchableColumn={inventoryObj.searchableColumn}
              sizePerPageValue={sizePerPageValue}
              sortColumn={inventoryObj.sortColumns}
              totalSize={inventoryObj.totalDataSize}
              updateList={inventoryObj.inventoryResponse}
              activeTab={tabName}
              isDataFetched={isDataFetch}
              /*
               * Prop here is used to force a component to mount and remount by simply changing its value.
               * Refer https://www.nikgraf.com/blog/using-reacts-key-attribute-to-remount-a-component for more info.
               */
              key={tabName}
              defaultSorted={defaultSorted}
              searchInfoText={
                tabName === "adunits"
                  ? ToolTipMessages.SEARCH_INFO_TEXT.adunit_inventory
                  : ToolTipMessages.SEARCH_INFO_TEXT.network_inventory
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

InventoryDetails.propTypes = {
  adUnitsList: PropTypes.arrayOf(PropTypes.object),
  networksList: PropTypes.arrayOf(PropTypes.object),
  inventoryData: PropTypes.arrayOf(PropTypes.object),
  displayError: PropTypes.bool,
  errorMessage: PropTypes.shape(),
  fetchAdunits: PropTypes.func,
  fetchNetworks: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  inventoryDetails: PropTypes.shape(),
  inventoryMapping: PropTypes.shape(),
  isLoaded: PropTypes.bool,
  isSuperUser: PropTypes.bool,
  loading: PropTypes.bool,
  orgName: PropTypes.string,
  orgData: PropTypes.shape(),
  page: PropTypes.number,
  prepareInventoryList: PropTypes.func,
  setLoader: PropTypes.func,
  sizePerPageValue: PropTypes.number,
  totalAdUnits: PropTypes.number,
  totalSize: PropTypes.number,
  triggerLoadData: PropTypes.func,
  tabName: PropTypes.string,
  totalNetworks: PropTypes.number,
  totalNetworksSize: PropTypes.number,
  userPermission: PropTypes.bool,
  userData: PropTypes.shape(),
};

export default InventoryDetails;
