/* istanbul ignore file */
import api from "@flatfile/api";

export const insertSheetData = async (
  event,
  workBookName,
  sheetName,
  sheetData
) => {
  if (!event.context || !event.context.workbookId) {
    console.error("Event context or workbookId missing");
    return;
  }

  const workbookId = event.context.workbookId;
  let workbook;
  try {
    workbook = await api.workbooks.get(workbookId);
  } catch (error) {
    console.error("Error getting workbook:", error.message);
    return;
  }

  const workbookName =
    workbook.data && workbook.data.name ? workbook.data.name : "";

  if (workbookName.includes(workBookName)) {
    const sheets =
      workbook.data && workbook.data.sheets ? workbook.data.sheets : [];

    const sheetInfo = sheets.find((s) => s.config?.slug?.includes(sheetName));

    if (sheetInfo && Array.isArray(sheetData)) {
      const sheetId = sheetInfo.id;
      try {
        await api.records.insert(sheetId, sheetData);
      } catch (error) {
        console.error("Error inserting data:", error.message);
      }
    }
  }
};
