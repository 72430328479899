import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { DropDownMenu, Spinner, VideoPlayer, Icon } from "@px/px_design_system";
import { sortBy, uniq, upperCase, each, toLower } from "lodash";
import Image from "react-graceful-image";

import { constructAdminAPIEndPoint, fetchHandler } from "../../../../../utils";
import ErrorMessages from "../../../DisplayError/ErrorMessages";
import DisplayError from "../../../DisplayError";

import "./style.css";

const imageLoadHandler = (
  setImageLoaded,
  selectedScale,
  props,
  setImageVideoURL,
  setSnapshotError,
  setSnapshotErrorMsg
) => {
  const handleImageLoaded = () => {
    setImageLoaded(true);
    if (selectedScale.split(" - ")[2] === "1.0") {
      const { onSnaspshotLoad } = props;
      onSnaspshotLoad(false);
    }
  };

  const handleImageErrored = () => {
    setImageLoaded(true);
    setImageVideoURL("");
    setSnapshotError(true);
    setSnapshotErrorMsg(ErrorMessages.SNAPSHOT_LOADING);
    if (selectedScale.split(" - ")[2] === "1.0") {
      const { onSnaspshotLoad } = props;
      onSnaspshotLoad(true);
    }
  };
  return { handleImageLoaded, handleImageErrored };
};

const dropdownHandler = (
  snapshots,
  dropdownFinalList,
  setDropdownFinalList,
  setSelectedScale,
  getImageVideoURL,
  selectedScale,
  setImageLoaded,
  setAudioAvailble,
  setSnapshotErrorMsg,
  setSnapshotError,
  props
) => {
  const prepareSnapshotDropdown = () => {
    let dropdownHeaders = [];
    const dropdownValues = [];

    each(snapshots, (snapshot) => {
      const scalOrBitrate =
        snapshot.mime.split("/")[0] === "video"
          ? ` @ ${snapshot.bitrate}Kbps`
          : ` - ${snapshot.scaling_factor}`;
      dropdownHeaders.push(snapshot.mime.split("/")[1]);
      dropdownValues.push(
        `${upperCase(snapshot.mime.split("/")[1]).replace(/ /g, "")} - ${
          snapshot.w
        }x${snapshot.h}${scalOrBitrate}`
      );
    });

    dropdownHeaders = sortBy(uniq(dropdownHeaders));

    each(dropdownHeaders, (mimeType) => {
      dropdownFinalList.push({
        type: upperCase(mimeType).replace(/ /g, ""),
        Values: dropdownValues.filter((mimes) =>
          mimes.includes(upperCase(mimeType).replace(/ /g, ""))
        ),
      });
    });

    setDropdownFinalList(dropdownFinalList);
    setSelectedScale(dropdownFinalList[0].Values[0]);
    getImageVideoURL(dropdownFinalList[0].Values[0]);
  };

  const onSnapShotChangeAlert = (value) => {
    const splitValue = value.split(" - ");
    const { onSnapshotChange } = props;
    const getMatchingSnapshot = snapshots.filter(
      (snapshot) =>
        snapshot.mime.split("/")[0] === "video" &&
        splitValue[1].includes(snapshot.bitrate)
    );
    if (getMatchingSnapshot[0] !== undefined)
      onSnapshotChange(getMatchingSnapshot[0]);
  };

  const onSnapshotDropdownChange = (event) => {
    if (event.target.innerText !== selectedScale) {
      onSnapShotChangeAlert(event.target.innerText);
      setImageLoaded(false);
      setSnapshotErrorMsg("");
      setSnapshotError(false);
      setSelectedScale(event.target.innerText);
      getImageVideoURL(event.target.innerText);
    }
  };
  return { prepareSnapshotDropdown, onSnapshotDropdownChange };
};

const VideoLoadHandler = (props, setVidoPlayerError) => {
  const { onSnaspshotLoad } = props;
  const handleVideoError = () => {
    setVidoPlayerError(true);
    onSnaspshotLoad(true);
  };
  const onVideoLoadedmetadata = () => {
    setVidoPlayerError(false);
    onSnaspshotLoad(false);
  };
  return { handleVideoError, onVideoLoadedmetadata };
};

const getMatchingSnapData = (value, snapshots) => {
  const splitValue = value.split(" - ");
  const getMatchingSnapshot = snapshots.filter((snapshot) =>
    snapshot.mime.split("/")[0] === "video"
      ? snapshot.mime === `video/${toLower(splitValue[0])}` &&
        splitValue[1].includes(`${snapshot.w}x${snapshot.h}`) &&
        splitValue[1].includes(snapshot.bitrate)
      : snapshot.mime === `image/${toLower(splitValue[0])}` &&
        snapshot.scaling_factor === `${splitValue[2]}`
  );
  return getMatchingSnapshot[0];
};

const getSnapUrl = (snapData) =>
  snapData.mime.split("/")[0] === "video" ? snapData.curl : snapData.iurl;

const getImgVidUrl = (value, setImageVideoURL, snapshots) => {
  setImageVideoURL("");
  const matchedSnapData = getMatchingSnapData(value, snapshots);
  if (matchedSnapData !== undefined) {
    const snapUrl = getSnapUrl(matchedSnapData);
    setImageVideoURL(`${snapUrl}`);
  }
};

const renderSnapshot = (
  isImageLoaded,
  spinnerProps,
  dropdownFinalList,
  selectedScale,
  onSnapshotDropdownChange,
  snapshotError,
  imageVideoURL,
  scalingImageRef,
  snapshotErrorMsg,
  handleImageLoaded,
  handleImageErrored,
  snapshotsType,
  handleVideoError,
  onVideoLoadedmetadata,
  isVideoPlayerHasError,
  getDownloadUrl,
  isAudioAvailble
) => (
  <div className="snapshots border-bottom">
    <>
      {!isImageLoaded && snapshotsType === "display" ? (
        <Spinner {...spinnerProps} />
      ) : null}
      <div className="scaling-info">
        <span className="scaling-info__metadata">
          Select a Snapshot to Preview:
        </span>
        <span className="scaling-info__dropdown">
          <DropDownMenu
            dropDownItems={dropdownFinalList}
            selectedValue={selectedScale}
            changeValue={onSnapshotDropdownChange}
          />
        </span>

        <div
          className={`download--image-video ${
            isVideoPlayerHasError || snapshotError ? "disable--download" : ""
          }`}
        >
          <a
            href="#."
            className={`btn btn-secondary blob--download ${
              isVideoPlayerHasError || snapshotError ? "disabled" : ""
            }`}
            onClick={getDownloadUrl}
          >
            <Icon icon={["fas", "download"]} size="1x" />
          </a>
        </div>
      </div>

      <div className={`scaling-wrapper ${snapshotError ? " with--error" : ""}`}>
        <div
          className={`scaling-image ${
            snapshotsType === "video" ? "scaling-video" : ""
          } custom-scrollbar`}
          ref={scalingImageRef}
        >
          {snapshotError && (
            <DisplayError {...snapshotErrorMsg} typeOfError="inline-error" />
          )}

          {snapshotsType === "video" && imageVideoURL !== "" && (
            <>
              <VideoPlayer
                fill
                hasAudio={isAudioAvailble}
                sources={[
                  {
                    src: `${imageVideoURL}`,
                    type: "video/mp4",
                  },
                ]}
                onVideoError={handleVideoError}
                onVideoMetadata={onVideoLoadedmetadata}
                changeVideoSrc={`${imageVideoURL}`}
              />
            </>
          )}
          {snapshotsType === "display" && imageVideoURL !== "" && (
            <Image
              src={imageVideoURL}
              alt={selectedScale}
              retry={{ count: 10, delay: 2 }} // If image load fails, then it will retry for max 10 times at the interval of 2secs.
              onLoad={handleImageLoaded}
              onError={handleImageErrored}
              className={`snapshot__img ${snapshotError ? "d-none" : ""}`}
              key={imageVideoURL}
            />
          )}
        </div>
      </div>
    </>
  </div>
);

const getDownloadVideoUrl = (imageVideoURL) => (event) => {
  event.preventDefault();
  const apiParams = {
    method: "GET",
    url: constructAdminAPIEndPoint({
      url: "downloadable_url",
      searchParams: [{ key: "url", value: imageVideoURL }],
    }),
  };
  fetchHandler(apiParams)
    .then((res) => {
      const a = document.createElement("a");
      a.download = "download";
      a.href = res.url;
      a.click();
      a.remove();
    })
    .catch(() => {});
};

const Snapshots = (props) => {
  const { snapshots, location, snapshotsType, adApprovalData } = props;
  const [snapshotError, setSnapshotError] = useState(false);
  const [snapshotErrorMsg, setSnapshotErrorMsg] = useState("");
  const [selectedScale, setSelectedScale] = useState("");
  const [isImageLoaded, setImageLoaded] = useState(false);
  const [dropdownFinalList, setDropdownFinalList] = useState([]);
  const [imageVideoURL, setImageVideoURL] = useState("");
  const [isVideoPlayerHasError, setVidoPlayerError] = useState(false);
  const [isAudioAvailble, setAudioAvailble] = useState(false);
  const scalingImageRef = useRef(null);
  const { handleImageLoaded, handleImageErrored } = imageLoadHandler(
    setImageLoaded,
    selectedScale,
    props,
    setImageVideoURL,
    setSnapshotError,
    setSnapshotErrorMsg
  );
  const { handleVideoError, onVideoLoadedmetadata } = VideoLoadHandler(
    props,
    setVidoPlayerError
  );

  const getImageVideoURL = (value) => {
    getImgVidUrl(value, setImageVideoURL, snapshots, snapshotsType);
  };
  const { prepareSnapshotDropdown, onSnapshotDropdownChange } = dropdownHandler(
    snapshots,
    dropdownFinalList,
    setDropdownFinalList,
    setSelectedScale,
    getImageVideoURL,
    selectedScale,
    setImageLoaded,
    setAudioAvailble,
    setSnapshotErrorMsg,
    setSnapshotError,
    props
  );
  const spinnerProps = {
    className: "spinner-icon",
    color: "primary",
    loadingtext: "Loading",
  };

  const getDownloadUrl = getDownloadVideoUrl(imageVideoURL);

  useEffect(() => {
    snapshots.sort((a, b) =>
      a.mime.split("/")[0] === "video"
        ? `${a.bitrate}`.localeCompare(b.bitrate)
        : a.scaling_factor.localeCompare(b.scaling_factor)
    );
    if (!snapshots.length) {
      setSnapshotErrorMsg(ErrorMessages.NO_SNAPSHOT);
      setSnapshotError(true);
      setImageLoaded(true);
    }
    if (snapshotsType === "video" && adApprovalData)
      setAudioAvailble(adApprovalData.ad.creative.video.ext.has_audio);
    if (snapshots.length) {
      prepareSnapshotDropdown();
    }
  }, [location.pathname]);

  return renderSnapshot(
    isImageLoaded,
    spinnerProps,
    dropdownFinalList,
    selectedScale,
    onSnapshotDropdownChange,
    snapshotError,
    imageVideoURL,
    scalingImageRef,
    snapshotErrorMsg,
    handleImageLoaded,
    handleImageErrored,
    snapshotsType,
    handleVideoError,
    onVideoLoadedmetadata,
    isVideoPlayerHasError,
    getDownloadUrl,
    isAudioAvailble
  );
};

Snapshots.propTypes = {
  adApprovalData: PropTypes.objectOf(Object),
  snapshots: PropTypes.arrayOf(Object),
  onSnaspshotLoad: PropTypes.func,
  onSnapshotChange: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default Snapshots;
