import React, { useState, useEffect } from "react";
import { Spinner } from "@px/px_design_system";

import {
  constructAdminAPIEndPoint,
  getErrorMessage,
} from "../../../../../utils";
import DisplayError from "../../../DisplayError";
import "../../Utilities/commons/buyerStyles/style.css";
import ErrorMessages from "../../../DisplayError/ErrorMessages";

const spinnerProps = {
  className: "spinner-icon",
  color: "primary",
  loadingtext: "Loading",
};

const NewsDetails = () => {
  const [articles, setArticles] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [isError, setError] = useState(false);

  useEffect(() => {
    fetch(
      constructAdminAPIEndPoint({
        url: `news`,
      }),
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("px_access_token")}`,
        },
      }
    )
      .then((response) => response.text())
      .then((data) => {
        const xml = new window.DOMParser().parseFromString(data, "text/xml");
        const items = xml.querySelectorAll("item");
        const articlesToAdd = [];
        items.forEach((el) => {
          const link = el.querySelector("link").innerHTML;
          const title = el
            .querySelector("title")
            .innerHTML.replace(/&amp;/g, "&");
          const dateText = el.querySelector("pubDate").innerHTML;
          const date = new Date(dateText);

          if (title.includes("PX OOH Outlook")) {
            articlesToAdd.push(
              <li>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {title}
                </a>
                <p className="item-date-info">{date.toDateString()}</p>
              </li>
            );
          }
        });
        setArticles(articlesToAdd);
        setLoaded(true);
      })
      .catch((error) => {
        setError(true);
        return error;
      });
  }, []);

  return (
    <div className={`news-list wrapper`}>
      {!isLoaded && !isError && <Spinner {...spinnerProps} />}
      {isError && <DisplayError {...getErrorMessage("news")} />}
      {isLoaded && (
        <div className="buyer-view-container">
          <ul className="buyer-view-link-list">
            {articles.length > 0 ? (
              articles
            ) : (
              <DisplayError {...ErrorMessages.NO_NEWS} />
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default NewsDetails;
