import React from "react";

const ResourcesIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 30 30"
    fill="#999"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6364 19.091C17.3817 19.091 18.0001 19.7094 18.0001 20.4547C18.0001 21.2184 17.3817 21.8184 16.6364 21.8184H13.9091C13.1454 21.8184 12.5454 21.2184 12.5454 20.4547C12.5454 19.7094 13.1454 19.091 13.9091 19.091V15.109C13.1454 15.109 12.5454 14.4907 12.5454 13.7454C12.5454 13 13.1454 12.3817 13.9091 12.3817H15.2727C16.0181 12.3817 16.6364 13 16.6364 13.7454V19.091Z"
      fill="#999999"
    />
    <path
      d="M16.4 9.74528C16.4 10.6 15.709 11.309 14.8363 11.309C13.9817 11.309 13.291 10.6 13.291 9.74528C13.291 8.89062 13.982 8.19995 14.8363 8.19995C15.709 8.19995 16.4 8.89095 16.4 9.74528Z"
      fill="#999999"
    />
    <path
      d="M27.2727 15C27.2727 11.6 25.891 8.54533 23.6727 6.32733C21.4543 4.091 18.3817 2.72733 15 2.72733C11.6 2.72733 8.54533 4.091 6.309 6.32733C4.091 8.54533 2.72733 11.6 2.72733 15C2.72733 18.4 4.091 21.4547 6.309 23.6727C8.54533 25.909 11.6 27.2727 15 27.2727C18.3817 27.2727 21.4547 25.909 23.6727 23.6727C25.891 21.4547 27.2727 18.4 27.2727 15ZM30 15C30 23.291 23.2727 30 15 30C6.709 30 0 23.291 0 15C0 6.709 6.709 0 15 0C23.2727 0 30 6.709 30 15Z"
      fill="#999999"
    />
  </svg>
);

export default ResourcesIcon;
