import { difference, forEach, includes, isEmpty } from "lodash";

import {
  commaSeparatedStringToArray,
  splitCommaSeparatedStringToArray,
} from "../../../../../../../utils";
import { getAvailableResources } from "../../fieldValidation";

export const entityValidator = (record, field, entityLengthCount = 35) => {
  const entityNames = record.get(field);
  let isNameValid = true,
    isMaxLengthExceed = false,
    maxLengthExceedKeywordList = [];
  const entityNameList = commaSeparatedStringToArray(entityNames);
  const entityNameRegex = /^[a-zA-Z0-9-_]+$/;
  forEach(entityNameList, (entity) => {
    if (!entityNameRegex.test(entity)) {
      isNameValid = false;
    }
    if (entity.length > entityLengthCount) {
      maxLengthExceedKeywordList.push(entity);
      isMaxLengthExceed = true;
    }
  });
  if (!isNameValid) {
    record.validate(
      field,
      (value) => false,
      `${field} can accept these [a-zA-Z0-9_-] set of characters`
    );
  }
  if (isMaxLengthExceed) {
    record.validate(
      field,
      (value) => false,
      `${field} cannot contain more than 35 characters. please fix keyword(s) - ${maxLengthExceedKeywordList.join(
        ","
      )}`
    );
  }
};

export const keywordValidator = async (record, field, orgId) => {
  const keyListWithoutQuotes = ["targeting.keywords", "keywords"];
  const inputList = includes(keyListWithoutQuotes, field)
    ? commaSeparatedStringToArray(record.get(field))
    : splitCommaSeparatedStringToArray(record.get(field));
  if (!isEmpty(inputList)) {
    const payload = {
      model: "Keyword",
      names: inputList,
      show_names: "true",
    };
    let validChoices = await getAvailableResources(orgId, payload);
    let invalidList = difference(
      inputList,
      validChoices.resources_available_names
    );
    if (!isEmpty(invalidList)) {
      return `${invalidList.join(
        ", "
      )} Keyword does not exists in this organization. Once the records successfully submitted then these new keywords gets created in the organization`;
    }
  }
};
