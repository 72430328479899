import React from "react";
import PropTypes from "prop-types";

import "./style.css";

const Headline = (props) => {
  const { children } = props;
  return <p className="headline--copy">{children}</p>;
};

Headline.propTypes = {
  children: PropTypes.string,
};

export default Headline;
