/* istanbul ignore file */

import { isEmpty } from "lodash";

import { splitCommaSeparatedStringToArray } from "../../../../../../utils";
import { getAvailableResources } from "../../../Utilities/FlatFileComponent/fieldValidation";

const replaceNameWithId = (value, model, orgId, single) => {
  if (!isEmpty(value)) {
    const payload = {
      model,
      names: !single ? splitCommaSeparatedStringToArray(value) : value,
      show_ids: "true",
    };
    return getAvailableResources(orgId, payload);
  }
  return [];
};

const postProcessFields = [
  {
    async: true,
    key: "adunits",
    process: (value, orgId) => replaceNameWithId(value, "AdUnit", orgId),
  },
  {
    async: true,
    key: "deals",
    process: (value, orgId) => replaceNameWithId(value, "Deal", orgId),
  },
  {
    async: true,
    key: "networks",
    process: (value, orgId) => replaceNameWithId(value, "Network", orgId),
  },
];

export { postProcessFields, replaceNameWithId };
