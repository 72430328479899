import React from "react";
import { Card } from "@px/px_design_system";

export const CardDetails = (props) => {
  const { title, text, isEditMode } = props;

  return (
    isEditMode && (
      <div className="card__form">
        {title.map((item, index) => {
          return <Card cardTitle={item} cardText={text[index]} />;
        })}
      </div>
    )
  );
};
