import React, { Component } from "react";
import { JsonToCsv } from "@px/px_design_system";
import { parseLinkHeader } from "@web3-storage/parse-link-header";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import ToastMsg from "../ToastMsg";
import WithTooltip from "../DownloadCsv/WithTooltip";
import {
  customActionTrackerInDD,
  handleUserSession,
} from "../../../../../utils";

import "./style.css";

class PaginatedDownloadCsv extends Component {
  constructor(props) {
    super(props);
    this.getCSVData = this.getCSVData.bind(this);
  }

  downloadSizePerPage = this.props.tabName === "adunits" ? 3000 : 2000;
  downloadExcelSheetSize = 40000;
  isDataReady = false;

  mounted = false;

  startTime = 0;
  endTime = 0;

  state = {
    downloadType: "",
    isDownloadInProgress: false,
    downloadProgress: 0,
  };

  page = 0;
  totalPages = 0;

  componentDidMount() {
    this.mounted = true;
  }

  /** Required to abort ongoing network requests when view/org is switched. */
  componentWillUnmount() {
    this.mounted = false;
  }

  fetchCsvData = (value, useCursor = false, filterParams = []) => {
    const { fetchData } = this.props;
    let searchParams = [{ key: "page_size", value: this.downloadSizePerPage }];
    if (!useCursor) {
      searchParams = [...searchParams, { key: "page", value: value }];
    } else {
      searchParams = [
        ...searchParams,
        { key: "pager", value: "cursor" },
        { key: "cursor", value: value },
        ...filterParams,
      ];
    }
    return fetchData(searchParams);
  };

  async getCSVData(buttonText = "") {
    const {
      tabPillCount,
      csvDataToDownload,
      tabName,
      componentName,
      filterParams = [],
      dataSize,
    } = this.props;
    this.startTime = performance.now();
    this.setState({
      downloadType: buttonText,
      isDownloadInProgress: true,
      downloadProgress: 1, // start greater than 0 to give visual cue
    });
    const props = {
      icon: ["fas", "info-circle"],
      toastTitle: `Downloading results based on the filters applied`,
    };
    if (filterParams.length && filterParams.find((item) => item["value"])) {
      toast.info(<ToastMsg {...props} />, {
        toastId: "download--info",
        position: "top-center",
      });
    }
    let promises = [];
    const totalpages = Math.ceil(tabPillCount / this.downloadSizePerPage);
    // Except for Ad Approvals view, download CSV using `page` as a query param.
    // TODO: if nothing else uses offset-based pagination, refactor to remove this code block
    if (
      tabName !== "adunits" &&
      tabName !== "networks" &&
      tabName !== "deals" &&
      tabName !== "geosegments" &&
      tabName !== "placements" &&
      componentName !== "AdApprovals"
    ) {
      for (let i = 1; i < totalpages + 1; i++) {
        // TODO - Remove await, if not required
        // eslint-disable-next-line no-await-in-loop
        if (this.mounted) {
          await this.fetchCsvData(i).then((res) => {
            promises.push(res.data);
            // Update progress as each page is fetched
            this.setState((prevState) => ({
              downloadProgress: (i / totalpages) * 100, // Update progress based on the current page
            }));
          });
        }
      }
    } else {
      /*
       * Download CSV using the cursor pagination method, i.e, by passing
       * `pager` and `cursor` query params to the API as opposed to `page`.
       */
      let cursorValue = "";
      let retryCount = 0;
      let isFetchAdunitData = true;
      let setDataSize = dataSize === 0 ? 1 : dataSize;

      this.totalPages = Math.ceil(
        parseInt(setDataSize) / this.downloadExcelSheetSize
      );

      const downloadExcel = () => {
        /*
         * In terms of `Download as Excel` we are downloading Excel files according to `downloadExcelSheetSize`
         * e.g. Suppose 50k records and downloadExcelSheetSize is 10k then 5 Excel files will get downloaded.
         */
        let flatArray = [].concat(...promises);
        if (flatArray.length >= this.downloadExcelSheetSize || !cursorValue) {
          let excelData = flatArray.splice(0, this.downloadExcelSheetSize);
          promises = [flatArray];
          this.isDataReady = true;
          csvDataToDownload(excelData);
          /* eslint-disable */
          flatArray.length && !cursorValue
            ? ((this.page += 1), csvDataToDownload(flatArray))
            : null;
          setTimeout(() => {
            this.isDataReady = false;
          }, 250);
        }
      };

      while (isFetchAdunitData && this.mounted) {
        await this.fetchCsvData(cursorValue, true, filterParams)
          .then(async (res) => {
            if (res && res.headers && res.headers.get("link")) {
              // Update progress as each page is fetched
              this.page += 1;
              this.setState((prevState) => ({
                downloadProgress: (this.page / totalpages) * 100,
              }));
              const linkHeader = res.headers.get("link");
              const parsed = parseLinkHeader(linkHeader);
              cursorValue = parsed["'next'"].cursor;
              isFetchAdunitData = parsed["'next'"].url;
              retryCount = 0;
              promises.push(res.data);
              buttonText === "Download as Excel" && downloadExcel();
            } else {
              /*
               * During the download process, if an api seems to fail, retry that same api call once.
               * But if it again fails then terminate the entire download process.
               */
              if (retryCount === 1) {
                this.isDataReady = false;
                isFetchAdunitData = false;
                this.setState({ isDownloadInProgress: false });
                alert(
                  `Something went wrong.  ${
                    this.page
                      ? `Only ${this.page} of ${this.totalPages} files got downloaded.`
                      : `Please try again after some time.`
                  }`
                );
              } else {
                retryCount += 1;
              }
            }
          })
          .catch((error) => {
            /* Pass empty object if there are no records found. */
            if (error.subTitle.startsWith("There are currently 0")) {
              promises.push([]);
              isFetchAdunitData = false;
              buttonText === "Download as Excel" && downloadExcel();
            }
          });
      }
    }

    this.setState((prevState) => ({
      downloadProgress: 0, // reset download progress
    }));

    if (buttonText === "Download CSV" || buttonText === "Download as CSV") {
      const flatArray = [].concat(...promises);
      csvDataToDownload(flatArray);
      if (this.state.isDownloadInProgress) this.isDataReady = true;
      this.setState({
        isDownloadInProgress: false,
      });
      let timerCSV = setTimeout(() => {
        const isHrefAttributes = document.querySelector(".paginated-csv a");
        if (
          isHrefAttributes &&
          (buttonText === "Download CSV" || buttonText === "Download as CSV")
        ) {
          const downloadLink = isHrefAttributes.href;
          const downloadAttr = isHrefAttributes.download;
          const targetValue = isHrefAttributes.target;
          const link = document.createElement("a");
          link.setAttribute("href", downloadLink);
          link.setAttribute("download", downloadAttr);
          link.setAttribute("target", targetValue);
          link.click();
          link.remove();
          this.isDataReady = false;
        }
        clearTimeout(timerCSV);
      }, 250);
    } else {
      this.setState(
        {
          isDownloadInProgress: false,
        },
        () => {
          this.page = 0;
        }
      );
    }
    this.endTime = performance.now();
    this.page = 0;
    customActionTrackerInDD(
      this.startTime,
      this.endTime,
      `click on ${buttonText}`
    );
  }

  showLimitExceedsToastMsg(truncatedColumnList) {
    const toastMsgData = {
      toastTitle: "Downloaded file contains partial data",
      toastSubTitle: (
        <>
          Since Excel has a character limit of 32,767 chars per cell,{" "}
          <b>{truncatedColumnList?.join(", ")}</b> columns have been truncated.
          Read{" "}
          <a
            href="https://help.placeexchange.com/s/article/Placements"
            target="_blank"
            rel="noreferrer"
          >
            this article
          </a>{" "}
          for more information. if the user want to view complete data then
          download the file as csv and open in a text editor (e.g. Notepad /
          TextEdit)
        </>
      ),
      icon: ["fa", "info-circle"],
    };
    toast.warning(<ToastMsg {...toastMsgData} />, {
      toastId: "limit-exceed-warning",
      position: "top-center",
      autoClose: false,
    });
  }

  render() {
    const {
      columnIndex,
      componentName,
      disableDownloadCsv,
      filename,
      finalData,
      moveToFirstPosition = [],
      removeColumns = [],
      renameHeaders = {},
      tabName = "",
      disableDownloadText = "",
    } = this.props;
    let fileNameWithPageNo = `${filename}_${this.page}_of_${this.totalPages}`;
    const gaEventData = {
      category: `Download ${componentName} CSV from ${window.location.pathname}`,
      action: `Download ${componentName} CSV `,
      label: "Download",
    };
    const { downloadType, isDownloadInProgress } = this.state;
    return (
      <>
        <div className={"paginated-csv"}>
          {isDownloadInProgress && tabName === "geosegments" && (
            <div className="progress-bar-container">
              <span style={{ width: `${this.state.downloadProgress}%` }}></span>
            </div>
          )}
          {this.isDataReady && (
            <JsonToCsv
              moveToFirstPosition={moveToFirstPosition}
              removeColumns={removeColumns}
              json={finalData}
              filename={
                downloadType === "Download CSV" ||
                downloadType === "Download as CSV"
                  ? filename
                  : fileNameWithPageNo
              }
              filenameWithDateTime
              renameHeaders={renameHeaders}
              downloadType={downloadType}
              columnIndex={columnIndex}
              withoutExcelBlock={true}
              showLimitExceedsToastMsg={this.showLimitExceedsToastMsg}
            >
              <WithTooltip
                data={disableDownloadCsv}
                text={disableDownloadText}
                id="paginatedCsv"
                className={`paginated-csv__btn${
                  isDownloadInProgress ? "--in-progress disabled" : ""
                }`}
                gaEventData={gaEventData}
              />
            </JsonToCsv>
          )}
          <WithTooltip
            className={`paginated-csv__btn${
              isDownloadInProgress ? "--in-progress disabled" : ""
            }`}
            data={disableDownloadCsv}
            text={disableDownloadText}
            gaEventData={gaEventData}
            handleClick={(text) => {
              // Verify user session
              handleUserSession(true);

              !this.state.isDownloadInProgress
                ? this.getCSVData(text)
                : () => {};
            }}
            id="paginatedCsv"
            tabName={tabName}
            componentName={componentName}
            isDownloadInProgress={isDownloadInProgress}
          />
        </div>
      </>
    );
  }
}

PaginatedDownloadCsv.propTypes = {
  componentName: PropTypes.string,
  fetchData: PropTypes.func,
  finalData: PropTypes.arrayOf(PropTypes.object),
  tabPillCount: PropTypes.number,
  disableDownloadCsv: PropTypes.bool,
  csvDataToDownload: PropTypes.func,
  moveToFirstPosition: PropTypes.arrayOf(PropTypes.string),
  removeColumns: PropTypes.arrayOf(PropTypes.string),
  filename: PropTypes.string,
  renameHeaders: PropTypes.shape(),
  tabName: PropTypes.string,
};

export default PaginatedDownloadCsv;
