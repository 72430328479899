import { isEmpty } from "lodash";
import * as yup from "yup";

yup.addMethod(yup.string, "validateEmptyNameField", function (errorMessage) {
  return this.test(`test-empty-field`, errorMessage, function (value) {
    return isEmpty(value.trim()) ? false : true;
  });
});

yup.addMethod(yup.string, "validateSpaceNameField", function (errorMessage) {
  return this.test(
    `test-leading-trailing-space`,
    errorMessage,
    function (value) {
      return !value.charAt(0).trim().length ||
        !value.charAt(value.length - 1).trim().length
        ? false
        : true;
    }
  );
});

const validateBidFloor = yup.lazy((value) =>
  value === ""
    ? yup.string().required("Price is a required field")
    : yup
        .number()
        .required("Price is a required field")
        .min(0)
        .test(
          "is-valid-bidfloor",
          "Ensure that there are no more than 4 digit before the decimal point.",
          (value) => (value + "").match(/^(\d{0,4}\.(\d{0,2})|\d{0,4})?$/)
        )
);

export { validateBidFloor };
