const dealModel = {
  allowed_pct: null,
  archived: "",
  at: null,
  bidfloor: "",
  bidfloorcur: "",
  contract_id: "",
  created_by: "",
  ext: null,
  id: "",
  lastmod: "",
  name: "",
  notes: "",
  owned_by: "",
  placements: [],
  priority: null,
  status: "",
  token: "",
  ts: null,
  wadomain: [],
  wbuyer: [""],
  wbuyer_display: [""],
  wseat: [],
};

export default dealModel;
