/* istanbul ignore file */

import {
  capitalize,
  cloneDeep,
  has,
  identity,
  isEmpty,
  mapValues,
  pickBy,
  omit,
  get,
  forEach,
  intersection,
  keys,
} from "lodash";
import moment from "moment";
import { datadogRum } from "@datadog/browser-rum";

import {
  commaSeparatedStringToArray,
  splitCommaSeparatedStringToArray,
} from "../../../../../utils";
import { dayPartWeekDay } from "../../OrderManagement/OrderDetails/PlacementMetaData/placementMapping";
import { venueList, getGeoTargetingData } from "./fieldValidation";

import "./style.css";

const flatten = require("flat");
const { unflatten } = require("flat");

let geoTargetingApiData;
let geoTargetingApiDataAllRow = [];

export const getGeoCoutryData = async (targeting) => {
  geoTargetingApiData = await getGeoTargetingData(pickBy(targeting, identity));
  geoTargetingApiData?.forEach((data) => {
    let newdata = geoTargetingApiDataAllRow.find(
      (element) => element.country === data.country
    );
    if (newdata === undefined) geoTargetingApiDataAllRow.push(data);
  });

  return geoTargetingApiData;
};

const prepareOptions = (field, mappingModel, humanReadableOptions = false) => {
  const mappingObj = mappingModel[field];
  const optionsArray = [];
  for (const key in mappingObj) {
    if (Object.prototype.hasOwnProperty.call(mappingObj, key)) {
      optionsArray.push({
        value: key,
        label: humanReadableOptions ? mappingObj[key] : key,
      });
    }
  }
  return optionsArray;
};

// Trim leading zeros
const trimZeros = (ratio) => {
  let aspectRatio = ratio;
  if (!isEmpty(ratio) && ratio.includes(":")) {
    const splittedRatio = ratio.split(":");
    aspectRatio = `${splittedRatio[0].replace(
      /^0+/,
      ""
    )}:${splittedRatio[1].replace(/^0+/, "")}`;
  }
  return aspectRatio;
};

// Date Formatter to ISO-8061
const formatDate = (uploaderObject) => {
  const objectToUpload = cloneDeep(uploaderObject);
  objectToUpload["asset.aspect_ratio"] = trimZeros(
    uploaderObject["asset.aspect_ratio"]
  );
  if (
    !isEmpty(uploaderObject.start_date) &&
    !moment(uploaderObject.start_date, "YYYY-MM-DD", true).isValid()
  ) {
    const isoDate = moment(objectToUpload.start_date).format("YYYY-MM-DD");
    objectToUpload.start_date = isoDate === "Invalid date" ? null : isoDate;
  }
  return objectToUpload;
};

const getFormattedDateandTime = (inputDate) => {
  let settDateTime = "";
  if (inputDate) {
    settDateTime = moment(inputDate).isValid()
      ? moment(inputDate).format("YYYY-MM-DD HH:mm:ss")
      : inputDate;
  }
  return settDateTime;
};

const getUnixTimeStamp = (datAndTime) => {
  let unixFormat = !isEmpty(datAndTime)
    ? (
        moment.utc(datAndTime, "YYYY-MM-DD HH:mm:ss").valueOf() / 1000
      ).toString()
    : null;
  return unixFormat;
};

const dateAndTimeConversion = (uploaderObject, objectToUpload, date, time) => {
  let dateAndTime = `${get(uploaderObject, date)} ${get(uploaderObject, time)}`;
  objectToUpload[date] = getUnixTimeStamp(getFormattedDateandTime(dateAndTime));
};

// Combine date and time together and convert it to UnixTimeStamp
const formatTimeWithDate = (uploaderObject) => {
  let objectToUpload = cloneDeep(uploaderObject);
  const startDateAndTimeNotEmpty =
    !isEmpty(uploaderObject.start_date) && !isEmpty(uploaderObject.start_time);
  const endDateAndTimeNotEmpty =
    !isEmpty(uploaderObject.end_date) && !isEmpty(uploaderObject.end_time);

  if (startDateAndTimeNotEmpty) {
    dateAndTimeConversion(
      uploaderObject,
      objectToUpload,
      "start_date",
      "start_time"
    );
  }

  if (endDateAndTimeNotEmpty) {
    dateAndTimeConversion(
      uploaderObject,
      objectToUpload,
      "end_date",
      "end_time"
    );
  }

  objectToUpload = omit(objectToUpload, ["start_time", "end_time"]);
  return objectToUpload;
};

const isDaypartFieldExists = (uploaderObject) => {
  const isExist =
    uploaderObject.hasOwnProperty("dayparts.weekday") &&
    uploaderObject.hasOwnProperty("dayparts.start_time") &&
    uploaderObject.hasOwnProperty("dayparts.end_time");
  return isExist;
};

const formatTimeTwentyFourHourClock = (inputTime) => {
  if (moment(inputTime, ["h:mmA"], true).isValid()) {
    return moment(inputTime, ["h:mmA"]).format("HH:mm:ss");
  } else {
    return inputTime;
  }
};

const formatDaypartFields = (uploaderObject) => {
  let objectToUpload = cloneDeep(uploaderObject);
  if (isDaypartFieldExists(uploaderObject)) {
    const dypartFields = [
      "dayparts.weekday",
      "dayparts.start_time",
      "dayparts.end_time",
    ];
    let daypartFieldsValue = [];
    let weekDayValue = commaSeparatedStringToArray(
      get(objectToUpload, "dayparts.weekday", "")
    ).map((value) => dayPartWeekDay[value.toLowerCase()]);
    const startTimeValue = commaSeparatedStringToArray(
      get(objectToUpload, "dayparts.start_time", "")
    );
    const endTimeValue = commaSeparatedStringToArray(
      get(objectToUpload, "dayparts.end_time", "")
    );
    const isDaypartFieldNotEmpty =
      !isEmpty(weekDayValue) &&
      !isEmpty(startTimeValue) &&
      !isEmpty(endTimeValue);
    const isDaypartFieldsEqualLength =
      weekDayValue.length === startTimeValue.length &&
      weekDayValue.length === endTimeValue.length;

    if (isDaypartFieldNotEmpty && isDaypartFieldsEqualLength) {
      for (let i = 0; i < weekDayValue.length; i++) {
        daypartFieldsValue.push({
          weekday: weekDayValue[i],
          start_time: formatTimeTwentyFourHourClock(startTimeValue[i]),
          end_time: formatTimeTwentyFourHourClock(endTimeValue[i]),
        });
      }
    }
    Object.keys(objectToUpload).forEach((key) => {
      if (dypartFields.includes(key)) delete objectToUpload[key];
    });
    objectToUpload["dayparts"] = daypartFieldsValue;
  }
  return objectToUpload;
};

const formatDealPriorityFieldPayload = (
  uploaderObject,
  dealPriorityOptions
) => {
  let objectToUpload = cloneDeep(uploaderObject);
  if (has(objectToUpload, "priority")) {
    const selectedPriority = get(objectToUpload, "priority");
    objectToUpload["priority"] = Number(selectedPriority);
  }
  return objectToUpload;
};

const fetchSelectedCountrydata = (type, countryName) => {
  if (geoTargetingApiDataAllRow && geoTargetingApiDataAllRow.length >= 1) {
    return geoTargetingApiDataAllRow.find(
      (data) => data.country === countryName
    )[`${type}`];
  }
};

const getIntersectionData = (inputData, type, countryName) => {
  let apiData = fetchSelectedCountrydata(type, countryName);
  if (type === "dma") {
    let apiData_dma = keys(apiData);
    let interSecDemaName = intersection(inputData, apiData_dma);
    //code to convert dma value to key.
    let interSecDemaCode = interSecDemaName.map(
      (inpDmaName) => apiData[inpDmaName]
    );
    return interSecDemaCode;
  }
  return intersection(inputData, apiData);
};

const createGeoStructure = (countries) =>
  commaSeparatedStringToArray(countries).map((country) => {
    return { country: country };
  });

const formatGeoTargetingPayload = (objectToUpload) => {
  let formatedGeoTargetingList = createGeoStructure(
    objectToUpload["targeting.geo.country"]
  );
  formatedGeoTargetingList = formatedGeoTargetingList.map((geo) => {
    const regionValues = get(objectToUpload, "targeting.geo.region", "");
    const zipValues = get(objectToUpload, "targeting.geo.zip", "");
    const dmaValues = get(objectToUpload, "targeting.geo.dma", "");
    let intersectionRegion = getIntersectionData(
      commaSeparatedStringToArray(regionValues),
      "region",
      geo.country
    );
    let intersectionZip = getIntersectionData(
      commaSeparatedStringToArray(zipValues),
      "zip",
      geo.country
    );
    let intersectionDma_code = getIntersectionData(
      splitCommaSeparatedStringToArray(dmaValues),
      "dma",
      geo.country
    );
    return {
      ...geo,
      region: intersectionRegion,
      zip: intersectionZip,
      dma_code: intersectionDma_code,
    };
  });
  return formatedGeoTargetingList;
};

const formatvenueTargetingPayload = (objectToUpload) => {
  let venueListIds = [];
  const venueOpenOOHValue = get(
    objectToUpload,
    "targeting.venue_openooh_category",
    ""
  );
  const getVenueList = venueList;
  forEach(commaSeparatedStringToArray(venueOpenOOHValue), (list) => {
    if (getVenueList.find((i) => i.label === list))
      venueListIds.push(getVenueList.find((i) => i.label === list).value);
  });
  return venueListIds;
};

const deleteDuplicateKeysForTargeting = (objectToUpload) => {
  delete objectToUpload["targeting.geo.country"];
  delete objectToUpload["targeting.geo.region"];
  delete objectToUpload["targeting.geo.zip"];
  delete objectToUpload["targeting.geo.dma"];
  delete objectToUpload["targeting.venue_openooh_category"];
  delete objectToUpload["targeting.keywords"];
};

const isInputDataPresent = (inputData, fieldName) => {
  return (
    inputData &&
    has(inputData, `${fieldName}`) &&
    get(inputData, `${fieldName}`) !== null &&
    get(inputData, `${fieldName}`) !== ""
  );
};

const formatPlacementTargetingPayload = (uploaderObject) => {
  let objectToUpload = cloneDeep(uploaderObject);
  //formatting for targeting.venue_openooh
  if (has(objectToUpload, "targeting.venue_openooh_category")) {
    objectToUpload["targeting"] = {
      venue_openooh_category: formatvenueTargetingPayload(objectToUpload),
    };
  }
  //formatting geo targeting
  if (has(objectToUpload, "targeting.geo.country")) {
    if (isInputDataPresent(objectToUpload, "targeting.geo.country")) {
      objectToUpload["targeting"] = {
        ...objectToUpload["targeting"],
        geo: formatGeoTargetingPayload(objectToUpload),
      };
    } else {
      objectToUpload["targeting"] = { ...objectToUpload["targeting"], geo: [] };
    }
  }
  //formatting for targeting.keywords
  if (has(objectToUpload, "targeting.keywords")) {
    objectToUpload["targeting"] = {
      ...objectToUpload["targeting"],
      keywords: commaSeparatedStringToArray(
        get(objectToUpload, "targeting.keywords")
      ),
    };
  }
  deleteDuplicateKeysForTargeting(objectToUpload);
  return objectToUpload;
};

const prepareObjectToUpload = (uploaderObject, model, dealPriorityOptions) => {
  if (uploaderObject) {
    let uploadObject = formatDate(uploaderObject);
    let updatedUploadObject = formatTimeWithDate(uploadObject);
    updatedUploadObject = formatDaypartFields(updatedUploadObject);
    updatedUploadObject = formatDealPriorityFieldPayload(
      updatedUploadObject,
      dealPriorityOptions
    );
    updatedUploadObject = formatPlacementTargetingPayload(updatedUploadObject);
    if (has(updatedUploadObject, "keywords")) {
      updatedUploadObject["keywords"] = commaSeparatedStringToArray(
        updatedUploadObject["keywords"]
      );
    }
    // The default value for the asset.screen_count field is 1, so in the adunit model file, it is also set to 1.
    // Since the Pub's can download an empty CSV, it should contain an empty value in adunit model file.
    // This approach is used to handle the default value of 1.
    if (
      has(updatedUploadObject, "asset.screen_count") &&
      isEmpty(updatedUploadObject["asset.screen_count"])
    ) {
      updatedUploadObject["asset.screen_count"] = "1";
    }
    const uploadObjectFlat = mapValues(
      flatten(updatedUploadObject, { safe: true }),
      (val) => (val === null || val === undefined ? "" : val)
    );
    const uploadObjectFlatWithEmpty = pickBy(
      uploadObjectFlat,
      (value) => value === ""
    );

    const flatModel = cloneDeep(model);
    const flatModelWithoutEmpty = pickBy(flatModel, (value) => value !== "");

    const mergedEmptyNullProperties = mapValues(
      uploadObjectFlatWithEmpty,
      (value, key) => {
        if (has(flatModelWithoutEmpty, key)) {
          return flatModelWithoutEmpty[key];
        }

        return "";
      }
    );
    updatedUploadObject = unflatten({
      ...uploadObjectFlat,
      ...mergedEmptyNullProperties,
    });
    return updatedUploadObject;
  }
};

const triggerDDActionEvent = ({
  endTime,
  startTime,
  uploadCount,
  updateCount,
  uploadType,
}) => {
  // time taken (in milliseconds) to upload
  const timeTaken = endTime - startTime;
  if (uploadCount !== 0 || updateCount !== 0) {
    datadogRum.addAction(`click on ${capitalize(uploadType)} upload`, {
      uploadCount: uploadCount,
      updateCount: updateCount,
      uploadTime: timeTaken,
    });
  }
};

/* Remove when flatfile v2 is deprecated */
const uploadProgress = (progressBarUpdate, data, index) => {
  if (data) {
    progressBarUpdate(
      `${Math.round((index / data.length) * 100)} % uploaded...`
    );
  }
};

const uploadProgressBar = (
  progressBarUpdate,
  data,
  index,
  isBatchProcessEnabled = false
) => {
  const progressValue = isBatchProcessEnabled
    ? Math.round((index / data) * 100)
    : Math.round((index / data.length) * 100);
  if (data) {
    progressBarUpdate(progressValue, "Uploading...");
  }
};

export {
  prepareOptions,
  prepareObjectToUpload,
  triggerDDActionEvent,
  uploadProgress,
  uploadProgressBar,
};
