import React from "react";
import PropType from "prop-types";
import * as yup from "yup";
import {
  Accordion,
  AccordionBody,
  AccordionTitle,
  Form,
  Input,
  Section,
  setFormError,
  Table,
  TableWithForm,
  DynamicForm,
  isFormDirty,
  InputGroup,
  MultiSelect,
  setFieldValue,
  triggerFieldValidation,
  Button,
  Modal,
  Icon,
} from "@px/px_design_system";
import {
  filter,
  flatten,
  forEach,
  get,
  has,
  isEmpty,
  isNil,
  map,
} from "lodash";
import { toast } from "react-toastify";

import {
  constructAdminAPIEndPoint,
  checkIfModelDataExist as checkIfPlacementExist,
  customActionTrackerInDD,
  fetchHandler,
  fixedEncodeURIComponent,
  trackCustomActionsInDD,
} from "../../../../../../utils";
import { replaceNameWithId } from "../../OrderDetails/PlacementMetaData/customValidationFields";
import FlatfileAdunits from "./FlatfileAdunits";
import { HelpBody } from "./HelpBody";
import Headline from "../../../Headline";
import TopSummary from "./TopSummary.js";
import { OrderFormContext } from "./OrderFormContext";
import adunit_nodata from "./icons/adunit_nodata.svg";
import deals_nodata from "./icons/deals_nodata.svg";
import info from "./icons/info.svg";
import { CardDetails } from "./CardDetails.js";
import { triggerDDActionEvent } from "../../../Utilities/FlatFileComponent/lib";
import { Prompt } from "react-router-dom";
import ToastMsg from "../../../Utilities/ToastMsg";
import WithFeatureStatus from "../../../Utilities/WithFeatureStatus";
import GeoForm from "./GeoForm";
import WeekdayDaypartForm from "./WeekdayDaypartForm";
import { createKeywords } from "../../../../context/OrderManagement";
import EmbedIframe from "../../../EmbedDashboard/EmbedIframe";
import settings from "../../../../../../settings";
import ToolTipMessages from "../../../Utilities/ToolTip/TooltipMessages";
import "../../../Utilities/Form/validations";
import { MultiSelectWithForm } from "@px/px_design_system/lib/components/Forms";
import FormAd from "../../../Ads/FormAd";
import { validateBidFloor } from "../../../Utilities/Form/validations";
import { getLocationHref } from "../../../../../../utils/location";

const moment = require("moment-timezone");

let uploadCount = 0;
let updateCount = 0;
let startTime = 0;
let endTime = 0;
const MAX_CAP_LIMIT = 999999999999999;

export default class FormOrder extends React.Component {
  static contextType = OrderFormContext;
  context = this.context;

  myRef = React.createRef(null);
  modalRef = React.createRef(null);
  state = {
    dealsOptions: [],
    globalError: false,
    disableSubmit: this.context.isEditMode && !this.context.isImportEnabled,
    isSubmiting: true,
    isRedirectFromDealView: this.props.match.params.deals === "deals",
    dealPath: `../summary${
      this.context.searchValue
        ? `?search=${fixedEncodeURIComponent(this.context.searchValue)}`
        : ""
    }`,
    region: [],
    zip: [],
    dma_code: [],
    cap_type: this.context.formDefaultValues.cap_type
      ? this.context.capTypeOptions.find(
          (i) => i.value === this.context.formDefaultValues.cap_type
        )
      : null,
    geoFormSize: 1,
    helpSectionType: "",
    isDisableButton: true,
    campaign_type: isEmpty(
      get(this.context.formDefaultValues, "direct_sold_deals", [])
    )
      ? "programmatic-campaign"
      : "direct-sold-campaign",
    selectedAds: get(this.context.formDefaultValues, "ads", []),
  };

  updateAccordian() {
    const { isEditMode } = this.context;
    window.innerWidth < 767
      ? this.setState({
          shouldAccordionOpen: false,
        })
      : this.setState({
          shouldAccordionOpen: !isEditMode,
        });
  }

  componentDidMount() {
    const { isRedirectFromDealView } = this.state;
    this.updateAccordian();
    window.addEventListener("resize", this.updateAccordian.bind(this));
    if (isRedirectFromDealView) {
      customActionTrackerInDD(
        startTime,
        endTime,
        "Placements viewed through Deal"
      );
    }

    if (this.context.isDirectSoldPublisher) {
      this.handleCampaignToggleHelpBody("Programmatic");
    }
  }

  componentWillUnmount() {
    uploadCount = 0;
    updateCount = 0;
  }

  formatDate = (date) => {
    return moment(
      new Date(moment(date).format("YYYY-MM-DDTHH:mm:ss.[000Z]")).toUTCString()
    ).unix();
  };

  submitPlacement = async (formData) => {
    const { campaign_type } = this.state;
    const { isDirectSoldPublisher } = this.context;
    const formFinalData = { ...formData };
    formFinalData["start_date"] = this.formatDate(formFinalData["start_date"]);
    formFinalData["end_date"] = this.formatDate(formFinalData["end_date"]);
    if (campaign_type === "programmatic-campaign") {
      formFinalData["deals"] = get(formFinalData, "deals", []).map(
        (element) => element.label
      );
    } else {
      formFinalData["deals"] = get(formFinalData, "direct_sold_deals", []).map(
        (element) => element.label
      );
    }
    if (has(formFinalData, "targeting")) {
      let geoArray = map(formFinalData?.targeting, "geo", []);
      if (!isEmpty(geoArray)) {
        map(geoArray, (item, index) => {
          geoArray[index] = {};
          geoArray[index]["country"] = get(item, "country", [])?.value || [];
          geoArray[index]["region"] = get(item, "region", [])?.map(
            (i) => i.value
          );
          geoArray[index]["dma_code"] = get(item, "dma_code", [])?.map(
            (i) => i.value
          );
          geoArray[index]["zip"] = get(item, "zip", [])?.map((i) => i.value);
        });
      }

      // remove geo object if empty
      geoArray = filter(geoArray, (item) => {
        if (!isEmpty(item.country)) return item;
      });

      formFinalData["targeting"] = {
        geo: geoArray,
        venue_openooh_category: get(
          formFinalData,
          "venue_openooh_category",
          []
        ).map((venue) => venue.value),
        keywords: get(formFinalData, "keywords", []).map((data) => data.value),
      };
      delete formFinalData.venue_openooh_category;
      delete formFinalData.keywords;
    }

    // Create new individual record from formFinalData.dayparts to support API
    let dayParts = [];
    forEach(formFinalData.dayparts, (dayPart) => {
      forEach(dayPart.weekday, (weekday) => {
        dayParts.push({
          end_time: dayPart.end_time,
          start_time: dayPart.start_time,
          weekday: weekday.value,
        });
      });
    });

    formFinalData.dayparts = dayParts;
    delete formFinalData.startEndDate;
    if (isDirectSoldPublisher) {
      delete formFinalData.ads;
      delete formFinalData.direct_sold_deals;
      /* istanbul ignore if */
      if (campaign_type === "programmatic-campaign") {
        delete formFinalData.priority;
        delete formFinalData.price;
      }
    }
    const { orgId, isEditMode } = this.context;
    const replacewithID = [];
    this.setState({ disableSubmit: true, isSubmiting: false });
    forEach(formFinalData, (value, key) => {
      replacewithID.push(this.replaceWithID(key, formFinalData, value, orgId));
    });

    await Promise.all(replacewithID);

    const checkDuplicatePlacement = await checkIfPlacementExist(
      orgId,
      "placements",
      formData?.name,
      "name"
    );
    if (checkDuplicatePlacement?.name && !isEditMode) {
      setFormError("name", {
        type: "manual",
        message: "Placement name already exists.",
      });
      this.setState({ disableSubmit: false });
    } else {
      startTime = performance.now();
      this.submitFormData(formFinalData);
    }
  };

  async replaceWithID(key, formFinalData, value, orgId) {
    switch (key) {
      case "adunits":
        formFinalData[key] = await replaceNameWithId(
          map(value, "adunits"),
          "AdUnit",
          orgId,
          true
        );
        return formFinalData[key];
      case "networks":
        formFinalData[key] = await replaceNameWithId(
          value.map((network) => network.label),
          "Network",
          orgId,
          true
        );
        return formFinalData[key];
      case "deals":
        formFinalData[key] = await replaceNameWithId(
          value,
          "Deal",
          orgId,
          true
        );
        return formFinalData[key];
      default:
        break;
    }
  }

  submitPlacementFormData(dataForm) {
    const { orgId, isEditMode, formDefaultValues } = this.context;
    const placementURL = isEditMode
      ? `orgs/${orgId}/placements/${fixedEncodeURIComponent(
          formDefaultValues?.name
        )}`
      : `orgs/${orgId}/placements`;
    const placementAPIParams = {
      method: isEditMode ? "PATCH" : "POST",
      url: constructAdminAPIEndPoint({ url: placementURL }),
      headers: { "cache-control": "no-cache, no-store" },
      body: dataForm,
    };
    return fetchHandler(placementAPIParams)
      .then(() => {
        trackCustomActionsInDD("placement targeting", {
          targeting: {
            name: dataForm?.name,
            venue_openooh_category: dataForm?.targeting?.venue_openooh_category,
            country: map(dataForm?.targeting?.geo, "country"),
            region: flatten(map(dataForm?.targeting?.geo, "region")),
            zip: flatten(map(dataForm?.targeting?.geo, "zip")),
            dma_code: flatten(map(dataForm?.targeting?.geo, "dma_code")),
            network_list: dataForm?.networks,
            keywords: dataForm?.targeting?.keywords,
          },
        });
        const { history } = this.props;
        const { dealPath, isRedirectFromDealView } = this.state;
        endTime = performance.now();
        isEditMode ? updateCount++ : uploadCount++;
        if (isRedirectFromDealView) {
          customActionTrackerInDD(
            startTime,
            endTime,
            "Placements updated through Deal"
          );
        } else {
          triggerDDActionEvent({
            endTime,
            startTime,
            uploadCount,
            updateCount,
            uploadType: "placements",
          });
        }
        const props = {
          toastTitle: `${dataForm?.name} Placement ${
            !isEditMode ? "Added" : "Updated"
          } Successfully!`,
          toastSubTitle:
            "You can modify the placement details at any time by navigating to this placement on the Order Management page placement section.",
        };
        toast.success(<ToastMsg {...props} />, {
          toastId: "order-success",
          position: "top-center",
        });
        this.setState({ disableSubmit: false });
        isRedirectFromDealView
          ? history.push(`${dealPath}`)
          : history.push(`../summary/placements`);
      })
      .catch(() => {
        this.setState({ globalError: true });
        this.setState({ disableSubmit: false });
      });
  }

  checkIfDirectDeal = (deal) => {
    return deal.filter((d) => d.token?.startsWith("managed_service"));
  };

  submitDeals = (dataForm, dealAPIParams) => {
    return fetchHandler(dealAPIParams).then((response) => {
      this.submitPlacementFormData({ ...dataForm, deals: [response.id] });
    });
  };

  async submitFormData(dataForm) {
    const { orgId, formDefaultValues, allowedCurrency, orgName } = this.context;
    const { campaign_type, selectedAds } = this.state;
    this.setState({ globalError: false });
    // create or update deal for direct sold
    if (campaign_type === "direct-sold-campaign") {
      let dealBody = {};
      const associatedAds = selectedAds.map((i) => i.label);
      const directDeal = this.checkIfDirectDeal(
        formDefaultValues.direct_sold_deals
      );
      let dealUrl = "";
      if (!isEmpty(directDeal)) {
        dealUrl = `orgs/${orgId}/deals/${fixedEncodeURIComponent(
          directDeal[0]?.name
        )}`;
        dealBody = {
          priority: dataForm.priority.value,
          bidfloor: dataForm.price,
          wadomain: associatedAds,
        };
      } else {
        dealUrl = `orgs/${orgId}/deals`;
        dealBody = {
          name: `${dataForm.name}_deal`,
          bidfloorcur: allowedCurrency,
          priority: dataForm.priority.value,
          bidfloor: dataForm.price,
          wadomain: associatedAds,
          at: 3,
          token: `managed_service_${orgName}_${dataForm.name}_deal`,
        };
      }

      const locationHref = getLocationHref();
      let wbuyer = [];
      /* istanbul ignore next */
      if (locationHref.includes("app.placeexchange.com")) {
        wbuyer = ["97d5e0a5-9f66-4539-b26d-adb2e70a885d"];
      } else if (locationHref.includes("app.dev.placeexchange.com")) {
        wbuyer = ["0b041bf9-9f64-46dc-bbf3-6faed812bd34"];
      }
      dealBody.wbuyer = wbuyer;

      let dealAPIParams = {
        method: !isEmpty(directDeal) ? "PATCH" : "POST",
        url: constructAdminAPIEndPoint({ url: dealUrl }),
        headers: { "cache-control": "no-cache, no-store" },
        body: dealBody,
      };
      return this.submitDeals(dataForm, dealAPIParams).catch(
        /* istanbul ignore next */ (error) => {
          if (error.status === 409) {
            const dealName = dealBody.name;
            dealUrl = `orgs/${orgId}/deals/${fixedEncodeURIComponent(
              dealName
            )}`;
            dealBody = {
              priority: dataForm.priority.value,
              bidfloor: dataForm.price,
              wadomain: associatedAds,
            };
            dealAPIParams = {
              method: "PATCH",
              url: constructAdminAPIEndPoint({ url: dealUrl }),
              headers: { "cache-control": "no-cache, no-store" },
              body: dealBody,
            };
            return this.submitDeals(dataForm, dealAPIParams);
          }
        }
      );
    }
    this.submitPlacementFormData(dataForm);
  }

  NoDataAdUnits(title, body) {
    return (
      <div className="no__data">
        <div className="no__data--icon">
          <img
            src={title === "Ad Units" ? adunit_nodata : deals_nodata}
            alt="nodata_adunit"
          />
        </div>
        <div className="no__data--content">
          <p>Select {title}</p>
          <span>{body}</span>
        </div>
      </div>
    );
  }

  isUserAuthenticated = () => {
    return localStorage.getItem("isAuthenticated");
  };

  handleCapTypeChange = (e) => {
    if (e === null) {
      setFieldValue("daily", "");
      setFieldValue("lifetime", "");
      triggerFieldValidation("daily");
      triggerFieldValidation("lifetime");
      triggerFieldValidation("start_date");
      triggerFieldValidation("end_date");
    }
    setFieldValue("cap_type", get(e, "value", ""));
    this.setState({
      cap_type: e,
    });
  };

  renderCapField = (cap_type, label, name, formGroupClassName, placeholder) =>
    get(cap_type, "value", "") === "Budget" ? (
      <InputGroup
        type="number"
        label={label}
        prepend
        prependText="$"
        name={name}
        formGroupClassName={formGroupClassName}
        placeholder={cap_type === null ? "Unlimited cap" : placeholder}
      />
    ) : (
      <Input
        type="number"
        label={label}
        name={name}
        formGroupClassName={formGroupClassName}
        placeholder={cap_type === null ? "Unlimited cap" : placeholder}
      />
    );

  isCapfieldEmpty = (capFieldValue) => {
    return isNil(capFieldValue) || capFieldValue === "";
  };

  getCapFieldCommonValidation = (field) => {
    const parentThis = this;
    return yup
      .number()
      .moreThan(0, `${field} cap must be greater than 0`)
      .max(
        MAX_CAP_LIMIT,
        `${field} cap must be less than or equal to ${MAX_CAP_LIMIT}`
      )
      .nullable(true)
      .transform((_, val) => (val ? Number(val) : null))
      .test(
        "is-required",
        "If the cap type is present then daily or lifetime will be required",
        function (value) {
          const requiredFieldCondition =
            field === "daily"
              ? this.parent.cap_type &&
                parentThis.isCapfieldEmpty(this.parent.lifetime)
              : this.parent.cap_type &&
                parentThis.isCapfieldEmpty(this.parent.daily);
          if (requiredFieldCondition) {
            if (parentThis.isCapfieldEmpty(value)) return false;
          }
          return true;
        }
      )
      .test(
        "is-decimal",
        `${field} cap should accept decimal number with 2 decimal places`,
        (value) => {
          if (value)
            return (value + "").match(
              "^\\s*(?=.*[0-9])\\d*(?:\\.\\d{1,2})?\\s*$"
            );
          return true;
        }
      );
  };

  getDateFieldCommonValidation = (field) => {
    const errorMsg =
      field === "end_date"
        ? "End date should be greater"
        : "Start date should be Smaller";
    const errorType = field === "end_date" ? "is-greater" : "is-smaller";
    return yup
      .date()
      .nullable(true)
      .test(errorType, errorMsg, function (value) {
        const validationField =
          field === "end_date" ? this.parent.start_date : this.parent.end_date;
        if (validationField !== null) {
          if (field === "end_date")
            return moment(value).isSameOrAfter(moment(validationField));
          else return moment(value).isSameOrBefore(moment(validationField));
        } else {
          return true;
        }
      });
  };

  getDateFieldValidationForSuperUser = (field) => {
    const parentThis = this;
    return parentThis
      .getDateFieldCommonValidation(field)
      .test(
        "is-required",
        "If cap type, daily cap or lifetime cap is present then start date and end date will be required",
        function (value) {
          const { cap_type, daily, lifetime } = this.parent;
          if (
            cap_type &&
            (!parentThis.isCapfieldEmpty(daily) ||
              !parentThis.isCapfieldEmpty(lifetime))
          ) {
            if (!value) return false;
          }
          return true;
        }
      );
  };

  getDaypartHeader = () => (
    <Section>
      <label className="form__label">Dayparting</label>
      <p>
        Limit ad display to particular days and time duration (expressed in
        local time at the location of the screen)
      </p>
    </Section>
  );

  getDaypartControls = () => {
    const weekdayList = [];
    const { formDefaultValues } = this.context;

    if (formDefaultValues?.dayparts) {
      formDefaultValues.dayparts.forEach((item) => {
        weekdayList.push(...item.weekday);
      });
    }

    return (
      <DynamicForm name="dayparts" addLabel="Add Daypart" isDynamicForm>
        <WeekdayDaypartForm
          name="weekday"
          daypartsValue={weekdayList}
          daysList={this.context.daysList}
        />

        <Input type="time" name="start_time" label="Start Time" isRequired />
        <Input type="time" name="end_time" label="End Time" isRequired />
      </DynamicForm>
    );
  };

  getDynamicFormSize = (geoFormSize) => {
    if (geoFormSize !== this.state.geoFormSize)
      this.setState({
        geoFormSize,
      });
  };

  disableButton = (status) => {
    this.setState({
      isDisableButton: status,
    });
  };

  handleCampaignToggleHelpBody = (campaignTypeText) => {
    this.setState({
      helpSectionType: campaignTypeText,
    });
  };

  handleCampaignTypeChange = (event) => {
    if (event.target.checked) {
      this.setState({
        campaign_type: event.target.value,
      });
    }
  };

  renderCampaignHelpSectionButtons = (buttonText) => {
    const { helpSectionType } = this.state;
    return (
      <Button
        color="link"
        buttontext={buttonText}
        className={`selected-button ${
          helpSectionType === buttonText ?? "selected-button"
        }`}
        onClick={() => this.handleCampaignToggleHelpBody(buttonText)}
      />
    );
  };

  render() {
    const {
      isSubmiting,
      shouldAccordionOpen,
      dealPath,
      disableSubmit,
      globalError,
      isRedirectFromDealView,
      cap_type,
      geoFormSize,
      isDisableButton,
      helpSectionType,
      campaign_type,
    } = this.state;
    const {
      dealsOptions,
      formDefaultValues,
      isEditMode,
      networkList,
      singlePlacementData,
      capTypeOptions,
      venueList,
      countryList,
      keywordList,
      orgId,
      isBudgetingEnabled,
      isDirectSoldPublisher,
      adsList,
    } = this.context;
    const parentThis = this;
    let isDeal;
    if (isDirectSoldPublisher && campaign_type === "direct-sold-campaign") {
      isDeal = formDefaultValues.direct_sold_deals.length;
    } else {
      isDeal = formDefaultValues.deals.length;
    }
    let indicatorText = "";
    if (!isDeal) {
      indicatorText = "No deal is selected for this Placement";
    }

    yup.addMethod(
      yup.array,
      "uniqueProperty",
      function (propertyPath, message) {
        return this.test("unique", message, function (list) {
          const errors = [];
          if (!isEmpty(list))
            list.forEach((item, index) => {
              const propertyValue = get(item, propertyPath);

              if (
                propertyValue &&
                filter(list, [propertyPath, propertyValue]).length > 1
              ) {
                errors.push(
                  this.createError &&
                    this.createError({
                      path: `${this.path}[${index}].${propertyPath}`,
                      message,
                    })
                );
              }
            });

          if (!isEmpty(errors)) {
            return new yup.ValidationError(errors);
          }

          return true;
        });
      }
    );

    return (
      <>
        <Headline>Placement</Headline>
        <div
          className={`embed--dashboard wrapper order--form-container super--user`}
        >
          <h2 className="sub__headline--copy">
            {isEditMode
              ? `Edit - Placement ID - ${singlePlacementData.id}`
              : "Add Placement"}

            <TopSummary text={indicatorText} isEditMode={isEditMode} />
          </h2>
          {shouldAccordionOpen !== undefined && (
            <div className="help--section">
              <Accordion>
                <AccordionTitle defaultOpen={shouldAccordionOpen}>
                  <img src={info} alt="info" />{" "}
                  {isEditMode
                    ? `Modifying/Pausing/Resuming ${
                        isDirectSoldPublisher
                          ? "Programmatic/Direct Sold Placement Delivery"
                          : "Placement Delivery"
                      }`
                    : isDirectSoldPublisher
                    ? "How to create a Programmatic/Direct Sold Placement?"
                    : "How to Create Placements?"}
                </AccordionTitle>
                <AccordionBody>
                  {isDirectSoldPublisher ? (
                    <div className="direct-sold--accordion--body">
                      <div className="campaign-buttons">
                        {this.renderCampaignHelpSectionButtons("Programmatic")}
                        <span>&#8739;</span>
                        {this.renderCampaignHelpSectionButtons("Direct Sold")}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <HelpBody
                    isEditMode={isEditMode}
                    model="placements"
                    helpSectionType={helpSectionType}
                  />
                  <p className="help--article">
                    For more information or suggested practices, see{" "}
                    <a
                      href="https://help.placeexchange.com/s/article/Placements"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Placements
                    </a>{" "}
                    in the Help Center.
                  </p>
                </AccordionBody>
              </Accordion>
            </div>
          )}
          {isSubmiting && (
            <Prompt
              message={() => {
                return isFormDirty() ? "Are you sure you want to leave?" : true;
              }}
            />
          )}
          {isEditMode && (
            <EmbedIframe
              {...this.props}
              reportId="delivery_overview"
              placement_id={singlePlacementData.id}
            />
          )}
          <Form
            isUserAuthenticated={this.isUserAuthenticated}
            globalError={globalError}
            onSubmit={(formData) => this.submitPlacement(formData)}
            defaultValues={formDefaultValues}
            validationSchema={{
              name: yup
                .string()
                .max(100)
                .required("Name is a required field")
                .validateEmptyNameField("Name field can not be blank")
                .validateSpaceNameField(
                  "Name should not have leading or trailing white spaces"
                ),
              notes: yup.string().max(4000),
              end_date: this.getDateFieldValidationForSuperUser("end_date"),
              start_date: this.getDateFieldValidationForSuperUser("start_date"),
              dayparts: yup.array().of(
                yup.object().shape(
                  {
                    weekday: yup
                      .array()
                      .of(
                        yup.object().shape({
                          label: yup.string(),
                          value: yup.string(),
                        })
                      )
                      .transform((currentValue) => {
                        return !currentValue ? [] : currentValue;
                      })
                      .test(
                        "is-required",
                        "Please select weekday.",
                        function (value) {
                          return !value || value.length === 0 ? false : true;
                        }
                      ),
                    start_time: yup
                      .string()
                      .required("Please select start time."),
                    end_time: yup
                      .string()
                      .required("Please select end time.")
                      .test(
                        "is-greater",
                        "End time should be greater",
                        function (value) {
                          const { start_time } = this.parent;
                          return moment(value, "HH:mm").isSameOrAfter(
                            moment(start_time, "HH:mm")
                          );
                        }
                      ),
                  },
                  ["start_time", "end_time"]
                )
              ),

              targeting: yup
                .array()
                .of(
                  yup.object().shape(
                    {
                      "geo.country": yup.string(),
                    },
                    ["geo.country"]
                  )
                )
                .uniqueProperty(
                  "geo.country",
                  "Geo targeting is already added, please choose another country."
                ),
              cap_type: yup
                .string()
                .nullable(true)
                .test(
                  "is-required",
                  "Cap type is required if daily or lifetime is present",
                  function (value) {
                    const { daily, lifetime } = this.parent;
                    if (
                      !parentThis.isCapfieldEmpty(daily) ||
                      !parentThis.isCapfieldEmpty(lifetime)
                    ) {
                      if (!value) return false;
                    }
                    return true;
                  }
                ),
              daily: this.getCapFieldCommonValidation("daily").test(
                "is-less",
                "Daily cap cannot exceed lifetime cap",
                function (value) {
                  const { lifetime } = this.parent;
                  if (lifetime && value) {
                    if (Number(value) > Number(lifetime)) {
                      return false;
                    }
                  }
                  return true;
                }
              ),
              lifetime: this.getCapFieldCommonValidation("lifetime"),
              price:
                this.state.campaign_type === "direct-sold-campaign"
                  ? validateBidFloor
                  : null,
              priority:
                this.state.campaign_type === "direct-sold-campaign"
                  ? yup.object().required()
                  : null,
            }}
            validationSchemaDependencies={[
              ["start_date", "end_date"],
              ["cap_type", "daily"],
              ["cap_type", "lifetime"],
              ["daily", "lifetime"],
            ]}
            submitButtonText={`${
              isEditMode ? "Update Placement" : "Add Placement"
            }`}
            redirectUrl={
              !isRedirectFromDealView ? "../summary/placements" : dealPath
            }
            disableSubmit={disableSubmit}
            tooltipTitle={ToolTipMessages.UPDATE_PLACEMENTS.noPermission}
            displayTooltip={this.context.isImportEnabled}
            watchFields={["dayparts", "targeting"]}
            mode="all"
            {...this.props}
          >
            <Section title="Placement Information" />
            <Input
              type="text"
              name="name"
              label="Name"
              placeholder="Enter name"
              isRequired
            />
            <Input
              type="textarea"
              name="notes"
              label="Notes"
              placeholder="Additional context or notes"
            />

            {isDirectSoldPublisher ? (
              <>
                <Section>
                  <label className="form__label">Campaign Type</label>
                  <p>Select the type of campaign you wish to create.</p>
                </Section>
                <div className="input--form__wrapper">
                  <Input
                    type="radio"
                    value="programmatic-campaign"
                    name="campaign_type"
                    label="Programmatic Campaign"
                    checked={campaign_type === "programmatic-campaign"}
                    onChange={this.handleCampaignTypeChange}
                  />
                  <Input
                    type="radio"
                    value="direct-sold-campaign"
                    name="campaign_type"
                    label={
                      <WithFeatureStatus
                        text="Direct Sold"
                        statusText="Beta"
                        statusColor="primary"
                      />
                    }
                    checked={campaign_type === "direct-sold-campaign"}
                    onChange={this.handleCampaignTypeChange}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
            <CardDetails
              title={["Created By"]}
              text={[singlePlacementData.created_by_name || "-"]}
              isEditMode={isEditMode}
            />
            <Section title="Dates" withAboveBorder={true}></Section>
            <Input
              type="datetime-local"
              formGroupClassName="budget__pacing-start-date"
              max={`${settings.INT32_MAX_DATE}`}
              name="start_date"
              label="Start Date & Time (UTC)"
            />
            <Input
              type="datetime-local"
              formGroupClassName="budget__pacing-end-date"
              max={`${settings.INT32_MAX_DATE}`}
              name="end_date"
              label="End Date & Time (UTC)"
            />
            {this.getDaypartHeader()}
            {this.getDaypartControls()}
            <div className="clearfix"></div>
            {isBudgetingEnabled || isDirectSoldPublisher ? (
              <Section>
                <label className="form__label">
                  <WithFeatureStatus
                    text="Set Placement Caps"
                    statusText="Beta"
                    statusColor="primary"
                  />
                </label>
                <p>Daily and lifetime caps for duration of placement</p>
              </Section>
            ) : (
              <></>
            )}
            {isBudgetingEnabled || isDirectSoldPublisher ? (
              <MultiSelect
                name="cap_type"
                label="Cap Type"
                formGroupClassName="cap-type-field"
                value={cap_type}
                options={capTypeOptions}
                isClearable
                placeholder="Budget"
                getValue={(val) => {
                  this.handleCapTypeChange(val);
                }}
              />
            ) : (
              <></>
            )}
            {isBudgetingEnabled || isDirectSoldPublisher ? (
              this.renderCapField(
                cap_type,
                "Daily Cap",
                "daily",
                "dailylimit-field",
                "If not specified, no cap applied"
              )
            ) : (
              <></>
            )}
            {isBudgetingEnabled || isDirectSoldPublisher ? (
              this.renderCapField(
                cap_type,
                "Lifetime Cap",
                "lifetime",
                "lifetimelimit-field",
                "If not specified, no cap applied"
              )
            ) : (
              <></>
            )}
            {isBudgetingEnabled || isDirectSoldPublisher ? (
              <div className="clearfix"></div>
            ) : (
              <></>
            )}
            <Section title="Targeting" withAboveBorder={true}>
              <p>
                Requests will be included based on the selected values.Unless
                otherwise noted,for a request to be valid,all selected criteria
                must match.A placement selecting no specific criteria will match
                all requests.
              </p>
            </Section>
            {this.LabelWithFeatureStatus(
              "Geographic Locations",
              'Target requests from any location(s). Providing multiple locations will result in requests matching any of those locations to be included (e.g. specifying "California" as State and "10001" as Postal Code will match requests from both California and Zip Code 10001).',
              "2023-06-17"
            )}
            <DynamicForm
              name="targeting"
              addLabel="Add Geo"
              className="dynamic__form--geo"
              isDynamicForm
              getDynamicFormSize={this.getDynamicFormSize}
              isDisableButton={isDisableButton}
            >
              <GeoForm
                name="geo"
                {...this.props}
                countryList={countryList}
                geoFormSize={geoFormSize}
                disableButton={this.disableButton}
              />
            </DynamicForm>
            <Section>
              <label className="form__label">
                <WithFeatureStatus
                  text="Venue Categories"
                  statusText="New"
                  statusColor="success"
                  expirationDate="2023-06-17"
                />
              </label>
              <p>
                Target requests from any{" "}
                <a
                  href="https://github.com/openooh/venue-taxonomy/blob/main/specification-1.1.md"
                  target="_blank"
                  rel="noreferrer"
                >
                  venue(s)
                </a>{" "}
                (e.g. Outdoor Billboards). The children venue categories will
                also be included when parents are selected.
              </p>
            </Section>
            <MultiSelect
              placeholder="All venue categories"
              isMulti={true}
              isSearchable={true}
              closeMenuOnSelect={false}
              options={venueList}
              isClearable
              isValidNewOption={() => false}
              name="venue_openooh_category"
              copyPastWarningMsg="Values don’t apply to inventory currently registered in Place Exchange"
            />
            {this.LabelWithFeatureStatus(
              "Keywords",
              "Target requests matching any keywords (e.g. PG-13).",
              "2023-06-17"
            )}
            <MultiSelect
              placeholder="All associated keywords"
              isMulti={true}
              isSearchable={true}
              closeMenuOnSelect={false}
              options={keywordList}
              isClearable
              name="keywords"
              pattern="^[a-zA-Z0-9_\-]+$"
              patternError="Keywords can only contain alphabets, numbers, -, _"
              asyncFunToCrtOption={(input) => createKeywords(input, orgId)}
              allowAll={true}
              validateMaxLength={true}
              maxLength={35}
              maxLengthErrorMsg="Keywords cannot contain more than 35 characters."
            />
            {this.sectionWithChild(
              "",
              "",
              "Networks",
              "Target requests from any network(s).",
              false
            )}
            <MultiSelect
              placeholder="All Networks"
              formGroupClassName="network-select"
              isMulti={true}
              isSearchable={true}
              closeMenuOnSelect={false}
              options={networkList}
              isClearable
              isValidNewOption={() => false}
              name="networks"
            />

            <Section>
              <label className="form__label">Ad Units</label>
              <p>Target requests from specific adunit(s).</p>
            </Section>
            <TableWithForm name="adunits">
              <Table
                noDataFoundText={this.NoDataAdUnits(
                  "Ad Units",
                  "To select ad units, keep ad units list handy and click on “Select Ad Unit” button above."
                )}
                noSearchFoundText="No search result found!"
                columns={[
                  {
                    dataField: "adunits",
                    text: "Adunit Name",
                  },
                ]}
                data={formDefaultValues.adunits}
                keyField="adunits"
                sortColumn={{
                  positions: [0],
                  sort: true,
                }}
                isPagination={true}
                showTotal={true}
                sizePerPageValue={10}
                isSearch={true}
                searchableColumn={["adunits"]}
                isSelectable={true}
                name="adunits"
                showFlatfile={<FlatfileAdunits />}
              />
            </TableWithForm>

            {campaign_type === "programmatic-campaign" ? (
              this.sectionWithChild(
                "",
                "Associated Deals",
                "Deals",
                "Mapped deals will be appended on BidRequests for selected inventory"
              )
            ) : (
              <Section
                title={
                  <WithFeatureStatus
                    text="Associate Ads"
                    statusText="Beta"
                    statusColor="primary"
                  />
                }
                withAboveBorder={true}
              ></Section>
            )}
            {campaign_type === "programmatic-campaign" ? (
              this.sectionWithChild(
                "",
                "",
                "Deal Not Yet Set Up?",
                <ul>
                  <li>
                    If the deal you wish to associate with this placement is not
                    yet created, continue to add the placement. You can then
                    create the deal in the Deals section, and come to this
                    placement to include it.
                  </li>
                  {formDefaultValues.deals?.some((obj) =>
                    obj.hasOwnProperty("color")
                  ) && (
                    <li>
                      <strong>Note:</strong> One or more associated deals are
                      archived and highligted in orange.
                    </li>
                  )}
                </ul>,
                false,
                "deal-info-section"
              )
            ) : (
              <></>
            )}
            {campaign_type === "programmatic-campaign" ? (
              <MultiSelect
                placeholder="Select Deals"
                formGroupClassName="deals-select"
                isMulti={true}
                isSearchable={true}
                closeMenuOnSelect={false}
                options={dealsOptions}
                isClearable
                isValidNewOption={() => false}
                name="deals"
              />
            ) : (
              <MultiSelectWithForm>
                <MultiSelect
                  ref={this.myRef}
                  placeholder="Select ads"
                  isMulti={true}
                  isSearchable={true}
                  closeMenuOnSelect={false}
                  options={adsList}
                  isClearable
                  isValidNewOption={() => false}
                  name="ads"
                  label="Ads"
                  defaultValue={this.state.selectedAds}
                  subTitle="Narrow requests to those from any Ad(s)"
                  getSelectedValues={(values) => {
                    this.setState({ selectedAds: values });
                  }}
                  showForm={
                    <Modal
                      ref={this.modalRef}
                      launchButton={
                        <Button
                          buttontext={"Add"}
                          style={{
                            backgroundColor: "#2D6BFF",
                            borderColor: "#2D6BFF",
                          }}
                          icon={<Icon icon={["fas", "plus"]} size="1x" />}
                        />
                      }
                      buttonLabel="Add"
                      modalBody={
                        <FormAd
                          multiSelectRef={this.myRef}
                          modalRef={this.modalRef}
                          orgId={orgId}
                        />
                      }
                      name="Ads"
                      className="modal-size"
                    />
                  }
                />
              </MultiSelectWithForm>
            )}
            {campaign_type === "direct-sold-campaign" ? (
              <Section
                title={
                  <WithFeatureStatus
                    text="Price & Priority"
                    statusText="Beta"
                    statusColor="primary"
                  />
                }
                withAboveBorder={true}
              ></Section>
            ) : (
              <></>
            )}

            {campaign_type === "direct-sold-campaign" ? (
              <Input
                type="number"
                name="price"
                label="Price"
                subTitle="Minimum bid for an impression expressed in CPM"
                isRequired
              />
            ) : (
              <></>
            )}

            {campaign_type === "direct-sold-campaign" ? (
              <MultiSelect
                name="priority"
                label="Priority"
                subTitle="Set the priority of the direct sold campaign"
                options={[
                  {
                    label: "Guaranteed",
                    value: 10,
                  },
                ]}
                defaultValue={10}
                isRequired
              />
            ) : (
              <></>
            )}
          </Form>
        </div>
      </>
    );
  }

  sectionWithChild(
    number,
    title,
    label,
    bodyCopy,
    withAboveBorder = true,
    formBodyClassName = ""
  ) {
    return (
      <Section
        numberVal={number}
        withAboveBorder={withAboveBorder}
        title={title}
        formBodyClassName={formBodyClassName}
      >
        <label className="form__label">{label}</label>
        <p>{bodyCopy}</p>
      </Section>
    );
  }

  LabelWithFeatureStatus(
    label,
    helperText,
    expirationDate = "",
    statusText = "New",
    statusColor = "success"
  ) {
    return (
      <Section>
        <label className="form__label">
          <WithFeatureStatus
            text={label}
            statusText={statusText}
            statusColor={statusColor}
            expirationDate={expirationDate}
          />
        </label>
        <p>{helperText}</p>
      </Section>
    );
  }
}

const contextTypes = {
  formDefaultValues: PropType.any,
  isEditMode: PropType.any,
  orgId: PropType.any,
  networkList: PropType.any,
  singlePlacementData: PropType.any,
  isBudgetingEnabled: PropType.bool,
  capTypeOptions: PropType.any,
};
FormOrder.contextTypes = {
  ...contextTypes,
  isDirectSoldPublisher: PropType.bool,
};
