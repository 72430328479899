import React from "react";
import { AdBlockDetectedWrapper } from "adblock-detect-react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Icon,
  Navbar,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  Input,
  isFormDirty,
} from "@px/px_design_system";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import Authentication from "../../Auth/Auth";
import LogoutIcon from "../../svgs/LogoutIcon";
import MegaPhoneIcon from "../../svgs/MegaPhoneIcon";
import ScreenIcon from "../../svgs/ScreenIcon";
import UserIcon from "../../images/user_icon.png";
import WarningIcon from "../../images/warning_icon.png";
import { UserContext } from "../../context/auth";

import "./styles.css";

const gravatar = require("gravatar");

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.logout = this.logout.bind(this);
    this.toggle = this.toggle.bind(this);
    this.changePersonaCloseMenu = this.changePersonaCloseMenu.bind(this);
  }

  static contextType = UserContext;

  toggle() {
    const { isOpen = false } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  }

  logout() {
    const { logout } = Authentication.AuthFunc;
    logout();
  }

  changePersonaCloseMenu(event) {
    const { changePersona } = this.context;
    changePersona(event);
    this.toggle();
  }

  sortOrgs(prev, next) {
    if (prev === next) {
      return 0;
    }
    return prev < next ? -1 : 1;
  }

  isFormFieldsDirty = () => {
    return isFormDirty();
  };

  handleorgChange = (event) => {
    const { changeOrgValue } = this.context;
    const { location } = this.props;
    if (this.isFormFieldsDirty() && !location?.pathname.includes("summary")) {
      if (window.confirm("Are you sure you want to leave?")) {
        changeOrgValue(event);
      }
    } else {
      changeOrgValue(event);
    }
  };

  render() {
    const {
      data,
      changeOrgValue,
      organization,
      errorMessage,
      isBuyer,
      isPublisher,
    } = this.context;
    const isSwitchViewEnabled = isBuyer && isPublisher;
    let dropDownProps = {};
    let userName = "";
    const { userData } = data;
    if (!isEmpty(data) && organization) {
      if (userData) {
        const { name = "" } = userData[0];
        userName = name;
      }
      const sortedOrgsList =
        userData && userData.length !== 0
          ? userData[0].orgs.sort((prev, next) => this.sortOrgs(prev, next))
          : [];
      dropDownProps = {
        dropDownItems: sortedOrgsList,
        selectedValue:
          isEmpty(errorMessage) || errorMessage.showOrgInDropdown
            ? organization.name
            : "",
        changeValue: changeOrgValue,
      };
    }
    let switchViewOptions;
    if (isSwitchViewEnabled) {
      const switchViewData = [
        {
          class: "buyer-switch",
          Icon: MegaPhoneIcon,
          text: "Buyer View",
        },
        {
          class: "publisher-switch",
          Icon: ScreenIcon,
          text: "Publisher View",
        },
      ];
      const switchViewOptionsComp = [];

      for (const profile of switchViewData) {
        switchViewOptionsComp.push(
          <DropdownItem
            className={profile.class}
            onClick={this.changePersonaCloseMenu}
          >
            <div className="menu-items-wrapper">
              <div className="menu-item-icon">
                <profile.Icon />
              </div>
              <div className="menu-item-text">{profile.text}</div>
            </div>
          </DropdownItem>
        );
      }

      switchViewOptions = <div>{switchViewOptionsComp}</div>;
    }
    return (
      <div className="header--wrapper sidenav__side-container">
        <Navbar dark expand="md" fixed="top">
          <NavbarBrand className="organization-dropdown">
            <Input
              type="select"
              name="select"
              onChange={(event) => {
                this.handleorgChange(event);
              }}
              value={dropDownProps.selectedValue || ""}
            >
              {!isEmpty(dropDownProps) &&
                dropDownProps.dropDownItems.map((org) => {
                  return <option>{org}</option>;
                })}
            </Input>
          </NavbarBrand>
          <div className="navbar-collapse">
            <Nav className="ml-auto" navbar>
              <div className="dropdown-nav">
                <UncontrolledDropdown nav inNavbar className="logout-dropdown">
                  <DropdownToggle nav>
                    {userData && (
                      <img
                        className="user-profile-icon"
                        src={gravatar.url(userData[0].email)}
                        alt="User Icon"
                      />
                    )}
                    {userName}
                    <Icon
                      className="caret-down"
                      icon={["fas", "chevron-down"]}
                    />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="update--userprofile">
                      <div className="menu-items-wrapper">
                        <div className="menu-item-text">
                          <img
                            className="user-item-icon"
                            src={UserIcon}
                            alt="User Icon"
                          />
                        </div>
                        <div className="menu-item-icon">
                          <a
                            href="https://en.gravatar.com/emails"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Update profile picture
                          </a>
                        </div>
                      </div>
                    </DropdownItem>
                    {switchViewOptions}
                    <DropdownItem className="logout" onClick={this.logout}>
                      <div className="menu-items-wrapper">
                        <div className="menu-item-text">
                          <LogoutIcon />
                        </div>
                        <div className="menu-item-icon">Log Out</div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Nav>
          </div>
        </Navbar>
        <AdBlockDetectedWrapper>
          <div className="ad-block-parent">
            <img
              className="warning-icon"
              src={WarningIcon}
              alt="Warning Icon"
            />
            <div>
              <div className="warning">Ad Blocker Detected!</div>
              <div className="warning-message">
                Ad Blockers may prevent some features from working on the
                Publisher Portal. Please whitelist the Publisher Portal or
                disable your Ad Blocker while on the platform.
              </div>
            </div>
          </div>
        </AdBlockDetectedWrapper>
      </div>
    );
  }
}

Header.contextType = UserContext;

Header.propTypes = {
  orgname: PropTypes.arrayOf(PropTypes.string),
  username: PropTypes.string,
  orgid: PropTypes.string,
  changeOrgValue: PropTypes.func,
  orgsList: PropTypes.arrayOf(PropTypes.string),
  displayError: PropTypes.bool,
  match: PropTypes.shape(),
  location: PropTypes.shape(),
};

export default Header;
