import React, { createContext, useContext, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { isEmpty, isNull } from "lodash";

import { UserContext } from "../auth";
import { fetchHandler, getContentLocationKey } from "../../../../utils";
import settings from "../../../../settings";
import ToastMsg from "../../components/Utilities/ToastMsg";

const AsyncUploadContext = createContext();

const adunitContentLocationKey = getContentLocationKey();
const toastPrarams = {
  position: "top-center",
  autoClose: false,
  closeOnClick: false,
};

// Get notification message for success / fail
const getToastMessageOptions = (toastType = "success", fileNames) => {
  const toastTitle =
    toastType === "fail"
      ? "Ad Units upload failed"
      : "Ad Units upload completed";
  const toastSubTitle =
    toastType === "fail"
      ? `Information about the upload - ${fileNames} may have been sent to you via email. If not, please try again! You can also reach out to your Partnership Manager, or email help@placeexchange.com and our staff can assist you`
      : `Information about the upload - ${fileNames} has been sent to you via email.`;

  return {
    toastTitle,
    toastSubTitle,
  };
};

// Call polling API to check upload status
const getAsyncUploadAPIResponse = (contentLocationUrl) =>
  fetchHandler({
    url: contentLocationUrl,
    getResponseHeaders: true,
    headers: {
      "cache-control": "no-cache, no-store",
    },
    responseType: "rawApiResponse",
  });

/*
 * Function iterates over all content location URLs (store in localStorage), and get upload status of all content locations.
 * Decide status based on below status codes
 *   - 200: in progress
 *   - 303: completed
 *   - 409 → failed red pop-up
 *
 * Parameters:
 *  contentLocations: (array) an array of objects
 *
 * Returns:
 *  (object) add file names in successFiles, failFiles, inProgressFiles based on completed / failed / in-progress.
 *  Also return retry-after to set delay to call future polling API
 */
const getContentLocationUploadStatus = async (contentLocations, timeoutId) => {
  const uploadResult = {
    successFiles: [],
    failFiles: [],
    inProgressFiles: [],
    retryAfter: null,
  };
  await Promise.all(
    contentLocations.map(async (item) => {
      const { contentLocation, fileName } = item;
      try {
        const response = await getAsyncUploadAPIResponse(contentLocation);
        let statusCode = response?.status;

        if (statusCode === 200) {
          const retryAfter = parseInt(response.headers.get("retry-after"));
          uploadResult.inProgressFiles.push(item);
          uploadResult.retryAfter = retryAfter;
        } else if (statusCode === 303) {
          uploadResult.successFiles.push(fileName);
        } else if (statusCode === 409) {
          uploadResult.failFiles.push(fileName);
        } else {
          localStorage.removeItem(adunitContentLocationKey);
          clearTimeout(timeoutId.current);
        }
      } catch (error) {
        /* istanbul ignore next */
        uploadResult.failFiles.push(fileName);
      }
    })
  );

  return uploadResult;
};

const AsyncUploadProvider = (props) => {
  const { organization } = useContext(UserContext);
  const { children } = props;

  const timeoutId = useRef(null);
  const orgId = organization?.id;
  useEffect(() => {
    handleAsyncUpload();

    return () => {
      clearTimeout(timeoutId.current);
    };
  }, [orgId]);

  /* Update/remove content location from localStorage based on upload status.
   * Remove record if upload completed or failed, otherwise keep it is for in progress.
   */
  const updateContentLocationInLocalStorage = (
    contentLocations,
    uploadResult
  ) => {
    const updatedContentLocations = contentLocations.filter((item) => {
      const { fileName } = item;
      return (
        !uploadResult.successFiles.includes(fileName) &&
        !uploadResult.failFiles.includes(fileName)
      );
    });
    if (isEmpty(updatedContentLocations) || isNull(uploadResult.retryAfter)) {
      localStorage.removeItem(adunitContentLocationKey);
      clearTimeout(timeoutId.current);
    } else {
      localStorage.setItem(
        adunitContentLocationKey,
        JSON.stringify(updatedContentLocations)
      );
    }
  };

  // This function will be called for in-progress uploads. Call handleAsyncUpload() function after given interval (1 min) to get upload status.
  const scheduleAsyncUpload = (retryAfter) => {
    clearTimeout(timeoutId.current);

    const timeoutNumber = setTimeout(() => {
      handleAsyncUpload();
    }, (retryAfter || settings.ASYNC_UPLOAD_POLLING_TIME) * 1000);

    timeoutId.current = timeoutNumber;
  };

  const handleAsyncUpload = async () => {
    const contentLocations = JSON.parse(
      localStorage.getItem(adunitContentLocationKey)
    );

    if (!orgId || !contentLocations) return;

    const uploadResult = await getContentLocationUploadStatus(
      contentLocations,
      timeoutId
    );
    const { successFiles, failFiles, inProgressFiles, retryAfter } =
      uploadResult;

    const timestamp = Date.now();

    /* Display toast message based on these variables successFiles, failFiles, inProgressFiles
     * successFiles: success notification
     * failFiles: fail notification
     * inProgressFiles: No notification, call scheduleAsyncUpload() function.
     */
    if (!isEmpty(successFiles)) {
      toast.success(
        <ToastMsg
          {...getToastMessageOptions("success", successFiles.toString())}
        />,
        {
          toastId: `async-upload-success-${timestamp}`,
          ...toastPrarams,
        }
      );
    }

    if (!isEmpty(failFiles)) {
      toast.error(
        <ToastMsg {...getToastMessageOptions("fail", failFiles.toString())} />,
        {
          toastId: `async-upload-fail-${timestamp}`,
          ...toastPrarams,
        }
      );
    }

    if (!isEmpty(inProgressFiles)) {
      scheduleAsyncUpload(retryAfter);
    }

    updateContentLocationInLocalStorage(contentLocations, uploadResult);
  };

  return (
    <AsyncUploadContext.Provider value={{ handleAsyncUpload }}>
      {children}
    </AsyncUploadContext.Provider>
  );
};

AsyncUploadProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export { AsyncUploadProvider, AsyncUploadContext };
