import { forEach, get, has, keys } from "lodash";
import React from "react";

const getDropdownOptions = (dataObj) => {
  const dropdownOptions = [];
  for (let optionData of dataObj) {
    dropdownOptions.push(
      <option value={optionData.value} key={optionData.value}>
        {optionData.label}
      </option>
    );
  }
  return dropdownOptions;
};

const formatTargetingAPIResponse = (list, venueList) => {
  forEach(list, (placement) => {
    const venueOpenoohCategories = [];
    let targetingCountry = [];
    let targetingRegion = [];
    let targetingZip = [];
    let targetingDmaCode = [];
    if (has(placement, "targeting.venue_openooh_category")) {
      forEach(
        get(placement, "targeting.venue_openooh_category", []),
        (category) => {
          venueOpenoohCategories.push(
            venueList.find((i) => i.value === category).label
          );
        }
      );
      placement["targeting.venue_openooh_category"] = venueOpenoohCategories;
    }
    if (has(placement, "targeting.keywords")) {
      placement["targeting.keywords"] = get(
        placement,
        "targeting.keywords",
        []
      );
    }
    if (has(placement, "targeting.geo")) {
      forEach(get(placement, "targeting.geo", []), (geoData) => {
        if (has(geoData, "country")) {
          targetingCountry = [...targetingCountry, get(geoData, "country")];
        }
        if (has(geoData, "region")) {
          targetingRegion = [...targetingRegion, ...get(geoData, "region")];
        }
        if (has(geoData, "zip")) {
          targetingZip = [...targetingZip, ...get(geoData, "zip")];
        }
        if (has(geoData, "dma")) {
          targetingDmaCode = [
            ...targetingDmaCode,
            ...keys(get(geoData, "dma")),
          ];
        }
      });
      placement["targeting.geo.country"] = targetingCountry;
      placement["targeting.geo.dma_name"] = targetingDmaCode;
      placement["targeting.geo.region"] = targetingRegion;
      placement["targeting.geo.zip"] = targetingZip;
    }
    //Delete targeting object to eliminate column repitation on download csv.
    delete placement.targeting;
  });
};

const mapVenueIdswithVenueName = (venueData, venueList) => {
  const venueIds = [];
  forEach(venueData, (venue) => {
    const selectedVenueList = venueList.find((i) => i.value === venue);
    if (selectedVenueList) venueIds.push(selectedVenueList);
  });
  return venueIds;
};

export {
  getDropdownOptions,
  formatTargetingAPIResponse,
  mapVenueIdswithVenueName,
};
