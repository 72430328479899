import React, { useEffect, useRef, useState } from "react";
import { Spinner, textTruncate } from "@px/px_design_system";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import AddPlacements from "./AddPlacements";
import DisplayError from "../../../DisplayError";
import GuidelineSection from "../../GuidelineSection";
import placementModel from "../../../models/Placement";
import PaginatedDownloadCsv from "../../Utilities/PaginatedDownloadCsv";
import ToolTipMessages from "../../Utilities/ToolTip/TooltipMessages";
import {
  prepareCsvData,
  noDataFoundText,
  formatDateAndDayparts,
  fixedEncodeURIComponent,
  showCustomTooltip,
  createLinkToTabularColumn,
} from "../../../../../utils";
import TableView from "../../Utilities/TableView";
import { PlatformFlatfile } from "../../Utilities/FlatFileComponent/Platform";
import { placements__sheet } from "./PlacementMetaData/blueprint";
import { listenerPlacements } from "./PlacementMetaData/listener";
import { formatTargetingAPIResponse } from "../../Utilities/FormComponent";
import ToastMsg from "../../Utilities/ToastMsg";

import "./style.css";

const linkToPlacement = (cell, row) =>
  createLinkToTabularColumn(
    cell,
    row,
    `../placement/edit?name=${fixedEncodeURIComponent(row.name)}`
  );

const column = [
  {
    dataField: "name",
    text: "Placement Name",
    formatter: linkToPlacement,
  },
  {
    dataField: "deals_count",
    text: "Deal Count",
  },
  {
    dataField: "lastmod",
    text: "Last Modified",
    formatter: (data) => showCustomTooltip(data, "Time shown in UTC"),
  },
  {
    dataField: "id",
    text: "Id",
    formatter: textTruncate,
  },
];

const sortColumns = {
  positions: [0, 1, 2, 3],
  sort: true,
};

const spinnerProps = {
  className: "spinner-icon",
  color: "primary",
  loadingtext: "Loading",
};

const OrderDetails = ({ history, isImportEnabled, orgData, states }) => {
  const abortController = new AbortController();
  const platformRef = useRef(null);
  const [columns] = useState([...column]);
  const [finalPlacements, setFinalPlacements] = useState([]);
  const [isFinalPlacementReady, setFinalPlacementReady] = useState(false);
  const [keyField] = useState("id");
  const [searchableColumn] = useState(["name", "id"]);
  const [sortColumn] = useState(sortColumns);
  const {
    placementsList,
    displayError,
    fetchPlacements,
    isLoaded,
    prepareList,
    rowDetails,
    sizePerPageValue,
    totalPlacements,
    totalSize,
    page,
    loading,
    capTypeOptions,
    venueList,
    countryList,
    resourceAvailable,
    isBudgetingEnabled,
    setLoader,
    triggerLoadData,
  } = states;

  const defaultSorted = [
    {
      dataField: "lastmod",
      order: "desc",
    },
  ];

  const csvParams = {
    fileFormat: "csv",
    modelObj: placementModel(),
    jsonFields: ["ext"],
    dateTimePerseFields: ["lastmod", "ts"],
  };

  const csvDataToDownload = (list, setStates = true) => {
    formatTargetingAPIResponse(list, venueList);
    formatDateAndDayparts(list, placementModel());
    const getCsvData = prepareCsvData({
      list,
      ...csvParams,
    });
    /* istanbul ignore next */
    if (!setStates) {
      return getCsvData;
    } else {
      setFinalPlacements(getCsvData);
      setFinalPlacementReady(true);
    }
  };

  useEffect(() => {
    const { placementDetails } = states;
    csvDataToDownload(placementDetails);
  }, [loading, isLoaded, isFinalPlacementReady]);

  /* istanbul ignore next */
  const registerPlacements = () => {
    setLoader(false);
    triggerLoadData();
  };

  /* istanbul ignore next */
  const flatfileClose = async (errorJson = []) => {
    abortController.abort();
    if (errorJson.length) {
      const props = {
        icon: ["fas", "exclamation-triangle"],
        toastTitle: `Please find the failed placements by clicking the 'Download CSV' button`,
        toastSubTitle: `You can use the downloaded csv to re-upload the failed placements`,
        isDownloadable: true,
        csvProps: {
          json: csvDataToDownload(errorJson, false),
          moveToFirstPosition: ["name", "error_message"],
          removeColumns: [
            "cap_type",
            "created_by",
            "created_by_name",
            "daily",
            "ext",
            "id",
            "lastmod",
            "lifetime",
            "owned_by",
            "ts",
            "asset.aspect_ratio",
          ],
          renameHeaders: { name: "placement_name" },
          filename: "Failed_Placements",
        },
      };
      toast.error(<ToastMsg {...props} />, {
        toastId: "failed-placements",
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
      });
    }
    registerPlacements();
  };

  const importProps = {
    isImportEnabled,
    resourceAvailable,
  };
  const tableProps = {
    history,
    rowDetails,
    prepareList,
    columns,
    data: placementsList,
    fetchData: fetchPlacements,
    keyField,
    noDataFoundText:
      displayError && totalPlacements === 0 ? (
        <DisplayError {...states.errorMessage} />
      ) : (
        noDataFoundText()
      ),
    page,
    searchableColumn,
    sizePerPageValue,
    sortColumn,
    totalSize,
    updateList: "placementsResponse",
    isDataFetched: true,
    defaultSorted,
    searchInfoText: ToolTipMessages.SEARCH_INFO_TEXT.placements,
  };

  return (
    <div
      className={`placement-list ${displayError ? "no--data--found" : ""} ${
        isLoaded ? "wrapper" : "wrapper--no-border"
      }`}
    >
      {(!isLoaded || loading) && <Spinner {...spinnerProps} />}
      {isLoaded && !loading && (
        <div className="placement-container">
          <h2 className="placement__headline placement__headline--copy sub__headline--count">
            <>Placements</>
          </h2>
          <GuidelineSection>
            <li>
              Updating inventory or deals on a placement can affect campaign
              delivery.
            </li>
            <li>Updates can take up to 3 hours to reflect in ad serving.</li>
          </GuidelineSection>
          <div className="placement__buttons">
            <PaginatedDownloadCsv
              filename="Orders_Placements"
              tabName="placements"
              componentName="Placement"
              csvDataToDownload={csvDataToDownload}
              fetchData={fetchPlacements}
              tabPillCount={totalPlacements}
              finalData={finalPlacements}
              moveToFirstPosition={["name"]}
              renameHeaders={{
                name: "placement_name",
                adunits: "adunit_names",
                deals: "deal_names",
                networks: "network_names",
              }}
              // A few fields are removed by passing ?omit= to the API (Ref - placementsOmitFields var).
              removeColumns={["targeting.geo", "cap_type", "daily", "lifetime"]}
              dataSize={totalPlacements}
            />
            <AddPlacements
              {...importProps}
              history={history}
              platformRef={platformRef}
            />
          </div>
          <TableView {...tableProps} />

          <PlatformFlatfile
            hideButton={true}
            name="placements"
            platformRef={platformRef}
            submitActionName="submitActionPlacements"
            fields={placements__sheet(capTypeOptions, isBudgetingEnabled)}
            listener={listenerPlacements(
              orgData,
              abortController,
              flatfileClose,
              isBudgetingEnabled,
              venueList,
              countryList
            )}
          />
        </div>
      )}
    </div>
  );
};

OrderDetails.propTypes = {
  displayError: PropTypes.bool,
  errorMessage: PropTypes.shape(),
  fetchPlacements: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  isLoaded: PropTypes.bool,
  isImportEnabled: PropTypes.bool,
  listOfObjects: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  orgData: PropTypes.shape(),
  page: PropTypes.number,
  placementDetails: PropTypes.arrayOf(PropTypes.object),
  placementsList: PropTypes.arrayOf(PropTypes.object),
  prepareList: PropTypes.func,
  rowDetails: PropTypes.shape(),
  sizePerPageValue: PropTypes.number,
  states: PropTypes.shape(),
  totalPlacements: PropTypes.number,
  totalSize: PropTypes.number,
};

export default OrderDetails;
