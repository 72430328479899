import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { forEach } from "lodash";
import PropTypes from "prop-types";
import queryString from "query-string";

import { UserContext } from "../auth";
import {
  constructAdminAPIEndPoint,
  escapeSearchText,
  fetchHandler,
  getCount,
  getLastPage,
  getErrorMessage,
} from "../../../../utils";
import dealMapping from "../../components/DealManagement/DealDetails/DealsMetaData/dealMapping";
import ErrorMessages from "../../DisplayError/ErrorMessages";
import settings from "../../../../settings";

const DealContext = createContext();

const updateStates = (response, responseType, params, error = {}) => {
  const {
    setDealDetails,
    setDisplayError,
    setLoader,
    setIsDataFetch,
    setRowDetails,
  } = params;
  if (responseType === "success") {
    setDealDetails(response.dealsResponse.data);
    setDisplayError(false);
  } else {
    setDisplayError(true);
  }
  setLoader(true);
  setIsDataFetch(true);
  setRowDetails(response);
};

const initialLoad = (params) => {
  const { fetchFilteredDeals, queryParams, urlParams } = params;
  let urlParameters = urlParams;
  if (queryParams.search) {
    urlParameters = [
      ...urlParameters,
      { key: "search", value: escapeSearchText(queryParams.search) },
    ];
  }
  const paramsToFetchFirstDeal = [
    { key: "page", value: 1 },
    { key: "page_size", value: 1 },
  ];
  return Promise.all([
    queryParams.search
      ? fetchFilteredDeals(paramsToFetchFirstDeal, true, false)
      : null,
    fetchFilteredDeals(urlParameters, !queryParams.search, true),
  ])
    .then(([firstDealResponse, dealsResponse]) => ({
      firstDealResponse,
      dealsResponse,
    }))
    .then((response) => {
      if (response.dealsResponse.data) {
        updateStates(response, "success", params);
      }
    })
    .catch((error) => Promise.reject(error));
};

const fetchDataOnLoad = (params) => {
  initialLoad(params).catch((error) => {
    if (!("AbortError" in error)) {
      const errorResponse = {
        dealsResponse: { data: [] },
      };
      updateStates(errorResponse, "error", params, error);
    }
  });
};

const args = ({ urlParams, orgId, controllerRef, isSignal }) => ({
  getResponseHeaders: true,
  headers: {
    "cache-control": "no-cache, no-store",
  },
  signal: isSignal ? controllerRef.current?.signal : "",
  url: constructAdminAPIEndPoint({
    url: `orgs/${orgId}/deals`,
    searchParams: [...urlParams, { key: "slug_field", value: "name" }],
  }),
});

const successBlock = ({
  response,
  setCount,
  setSize,
  setTotalDeals,
  setTotalSize,
  wbuyerOptions,
  priorityOptions,
  isPager,
}) => {
  // update auction type / wbuyer to display name instead of code in csv
  forEach(response.json, (deal) => {
    deal.at = dealMapping()["at"][deal.at];
    deal.wbuyer = dealMapping(wbuyerOptions, priorityOptions)["wbuyer"][
      deal.wbuyer
    ];
  });
  if (setCount) {
    const count = getCount(response);
    setTotalDeals(count);
  }
  if (setSize) {
    const size = getCount(response);
    setTotalSize(size);
  }

  if (response.json.length) {
    return {
      data: response.json,
      last: !isPager ? getLastPage(response) : 0,
      headers: response.headers,
    };
  }

  throw ErrorMessages.NO_DEALS;
};

export const fetchWbuyerResponse = (setWbuyerOptions = () => {}) => {
  const wbuyerMapping = { "All DSPs": "All DSPs" };
  return fetchHandler({
    url: constructAdminAPIEndPoint({
      url: `datasets/${settings.DATASETS_ID_LOOKUP.DEAL_NON_SANDBOX_WBUYER_ID}`,
      searchParams: [{ key: "cache", value: false }],
    }),
  })
    .then((data) => {
      data.members.forEach((wbuyer) => {
        wbuyerMapping[wbuyer.key] = wbuyer.value;
      });
      setWbuyerOptions(wbuyerMapping);
      return wbuyerMapping;
    })
    .catch((error) => {
      if (error.status === 403) {
        setWbuyerOptions(wbuyerMapping);
        return wbuyerMapping;
      }
    });
};

export const fetchDealPrirityResponse = (
  setPriorityOptions = () => {},
  isInvalidSuperUser
) => {
  const dealPriorityMapping = [];
  return fetchHandler({
    url: constructAdminAPIEndPoint({
      url: `datasets/${settings.DATASETS_ID_LOOKUP.DEAL_PRIORITY_ID}`,
    }),
  })
    .then((data) => {
      data.members.forEach((prioirty) => {
        dealPriorityMapping.push({
          label: prioirty.value,
          value: prioirty.key,
        });
      });
      setPriorityOptions(dealPriorityMapping);
      return dealPriorityMapping;
    })
    .catch((error) => {
      if (error.status === 403) {
        setPriorityOptions([]);
        return dealPriorityMapping;
      }
    });
};

const DealProvider = (props) => {
  const { loading, organization, isInvalidSuperUser } = useContext(UserContext);
  const [dealDetails, setDealDetails] = useState([]);
  const [displayError, setDisplayError] = useState(false);
  const [wbuyerOptions, setWbuyerOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [rowDetails, setRowDetails] = useState({});
  const [isLoaded, setLoader] = useState(false);
  const [totalDeals, setTotalDeals] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [isDataFetch, setIsDataFetch] = useState(false);
  const [filterParams, setFilterParams] = useState([]);
  const [urlParams, seturlParams] = useState([
    { key: "page", value: 1 },
    { key: "page_size", value: 15 },
    { key: "ordering", value: "-lastmod" },
  ]);
  const { children, location } = props;
  const controllerRef = useRef(null);
  const orgId = organization.id;
  const page = 1;
  const sizePerPageValue = 15;
  const queryParams = queryString.parse(location.search);

  const fetchFilteredDeals = (
    urlParams = [],
    setCount = false,
    setSize = false
  ) => {
    const mergedArray = [...filterParams, ...urlParams];
    const uniqueData = [
      ...mergedArray
        .reduce((map, obj) => map.set(obj["key"], obj), new Map())
        .values(),
    ];
    return fetchDeals(uniqueData, setCount, setSize, true);
  };

  const fetchDeals = (
    urlParams = [],
    setCount = false,
    setSize = false,
    signal
  ) => {
    const errorMessage = getErrorMessage("deals");
    let isPager = urlParams.find(
      (obj) => obj.key === "pager" && obj.value === "cursor"
    );
    let apiArgs = args({
      urlParams,
      orgId,
      controllerRef,
      isSignal: signal,
    });
    apiArgs.errorMessage = errorMessage;

    return fetchHandler(apiArgs)
      .then((response) =>
        successBlock({
          response,
          setCount,
          setSize,
          setTotalDeals,
          setTotalSize,
          wbuyerOptions,
          priorityOptions,
          isPager,
        })
      )
      .catch((error) => {
        if (!error.data) return Promise.reject(error);
        return Promise.reject(errorMessage);
      });
  };

  const input = {
    fetchFilteredDeals,
    page,
    sizePerPageValue,
  };
  const triggerLoadData = () => {
    setIsDataFetch(loading);
    const params = {
      queryParams,
      setDealDetails,
      setDisplayError,
      setLoader,
      setIsDataFetch,
      setRowDetails,
      ...input,
      urlParams,
    };
    fetchDataOnLoad(params);
  };

  useEffect(() => {
    if (filterParams.length) {
      const controller = new AbortController();
      controllerRef.current = controller;
      triggerLoadData();
    }
    return () => {
      setIsDataFetch(!loading);
      controllerRef.current?.abort();
    };
  }, [filterParams]);

  useEffect(() => {
    if (!loading) {
      setLoader(false);
      setFilterParams([{ key: "archived", value: "false" }]);
    }
  }, [loading, organization]);

  useEffect(() => {
    fetchWbuyerResponse(setWbuyerOptions);
    fetchDealPrirityResponse(setPriorityOptions, isInvalidSuperUser);
  }, []);

  const states = {
    dealDetails,
    displayError,
    wbuyerOptions,
    priorityOptions,
    rowDetails,
    isLoaded,
    loading,
    setLoader,
    totalDeals,
    totalSize,
    triggerLoadData,
    ...input,
    filterParams,
    setFilterParams,
    isDataFetch,
    fetchDeals,
  };
  return (
    <DealContext.Provider value={{ states }}>{children}</DealContext.Provider>
  );
};

const DealConsumer = DealContext.Consumer;

DealProvider.propTypes = {
  location: PropTypes.shape({
    push: PropTypes.func,
    search: PropTypes.string,
  }),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export { DealProvider, DealConsumer, DealContext };
