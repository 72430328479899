/* istanbul ignore file */
import React from "react";
import PropType from "prop-types";
import { Icon } from "@px/px_design_system";

import { UserContext } from "../../../../context/auth";
import { PlatformFlatfile } from "../../../Utilities/FlatFileComponent/Platform/index.js";
import { listener } from "./listener.js";

import "./style.css";

export default class FlatfileAdunits extends React.PureComponent {
  static contextType = UserContext;
  context = this.context;

  render() {
    const { data } = this.context;
    const { orgData } = data;
    return (
      <PlatformFlatfile
        name="adunits"
        fields={[
          {
            name: "Associate Adunit Sheet",
            slug: "associate_adunits__sheet",
            readonly: false,
            fields: [
              {
                label: "Adunit Name(s)",
                type: "string",
                key: "adunits",
                description:
                  "Include AdUnits that will be targeted by the placement, in addition to selected networks if any.",
                constraints: [{ type: "unique" }],
              },
            ],
          },
        ]}
        listener={listener(orgData)}
        submitActionName="submitActionAssociateAdunits"
        buttonText="Select Adunit(s)"
        icon={<Icon icon={["far", "check-circle"]} size="1x" />}
      />
    );
  }
}

FlatfileAdunits.contextTypes = {
  data: PropType.any,
};
