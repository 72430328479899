import React from "react";

const ScreenIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 24 20"
    fill="#B5BABE"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 16.5V0H24V16.5H0ZM22.5 1.5H1.5V15H22.5V1.5ZM16.5 19.5H7.5V18H16.5V19.5Z"
      fill="#6C757D"
    />
  </svg>
);

export default ScreenIcon;
