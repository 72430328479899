import React from "react";

const icon = (id, fill = "#999") => (
  <>
    <defs>
      <path id={id} d="M0 0h30v30H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="ad-approval-mask" fill="#fff">
        <use xlinkHref="#ad-approval" />
      </mask>
      <path
        fill={fill}
        fillRule="nonzero"
        d="M9.078 12.256l-2.022 2.022 6.5 6.5L28 6.333l-2.022-2.022-12.422 12.422-4.478-4.477zM25.11 25.11H4.89V4.89h14.444V2H4.89A2.897 2.897 0 0 0 2 4.889V25.11C2 26.7 3.3 28 4.889 28H25.11C26.7 28 28 26.7 28 25.111V13.556h-2.889V25.11z"
        mask="url(#ad-approval-mask)"
      />
    </g>
  </>
);

const LaunchAdApprovalIcon = <>{icon("launch-ad-approval", "#fff")}</>;

const AdApprovalIcon = <>{icon("ad-approval")}</>;

export { AdApprovalIcon, LaunchAdApprovalIcon };
