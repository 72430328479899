import React from "react";
import PropTypes from "prop-types";

import { Icon } from "@px/px_design_system";

import "./style.css";
import { AdApprovalSummaryConsumer } from "../../../context/AdApprovalSummaryContext";

const TopNavigation = (props) => {
  const { prevAd, currentAdApproval, adApprovalTotalCount, nextAd, activeTab } =
    props;

  return (
    <AdApprovalSummaryConsumer>
      {({ isSingleAdApproval }) => (
        <div className="adApproval--queue-wrapper">
          {!isSingleAdApproval && (
            <div className="adApproval--top-nav">
              <Icon
                icon={["fas", "chevron-left"]}
                style={{ color: "rgba(128, 128, 128, 1)" }}
                size="2x"
                className="icon--chevron-left"
                onClick={currentAdApproval !== 1 ? prevAd : null}
                disabled={currentAdApproval === 1}
              />
              <Icon
                icon={["fas", "chevron-right"]}
                style={{ color: "rgba(128, 128, 128, 1)" }}
                size="2x"
                className="icon--chevron-right"
                onClick={
                  currentAdApproval !== adApprovalTotalCount ? nextAd : null
                }
                disabled={currentAdApproval === adApprovalTotalCount}
              />
            </div>
          )}

          <div className="adApproval--queue-content">
            <div className="queue--header-copy">
              <span>
                {isSingleAdApproval
                  ? "Ad Approval"
                  : `${currentAdApproval} of ${adApprovalTotalCount}`}
              </span>
            </div>
            <p className="queue--body-copy">
              {isSingleAdApproval
                ? "Ad selected in your table"
                : `Ads with ${activeTab} approval status`}
            </p>
          </div>
        </div>
      )}
    </AdApprovalSummaryConsumer>
  );
};

TopNavigation.propTypes = {
  prevAd: PropTypes.func,
  nextAd: PropTypes.func,
  adApprovalTotalCount: PropTypes.number,
  currentAdApproval: PropTypes.number,
  activeTab: PropTypes.string,
};

export default TopNavigation;
