export const regex = {
  positiveNumberWithTwoDecimal: {
    pattern: "^(?:\\d*\\.\\d{1,2}|\\d+)$",
    errorMsg:
      "It should be a valid positive number with maximum 2 decimal places.",
  },
  number: {
    pattern: "^-?\\d*\\.?\\d+$",
    errorMsg: "A valid number is required.",
  },
  numberWithTwoDecimal: {
    pattern: "^(-?\\d*\\.?\\d{1,2}|\\d+)$",
    errorMsg: "A valid number is required with max 2 decimal places.",
  },
  nonZeroNumberWithMaxTwoDecimal: {
    pattern: "^(?:1(\\.0{1,2})?|[1-9]\\d*(\\.\\d{1,2})?)$",
    errorMsg:
      "Value should be equal or greater than 1 with maximum 2 decimal places.",
  },
  numberGreaterOrEqualTo0: {
    pattern: "^\\d*\\.?\\d+$",
    errorMsg: "It should be a valid number and greater than or equal to 0.",
  },
  numberGreaterOrEqualToOne: {
    pattern: "^[1-9]\\d*$",
    errorMsg: "It should be a valid integer and equal or greater than 1.",
  },
  boolean: {
    pattern: "^(true|false|1|0|yes|no)$",
    errorMsg: "A valid boolean required",
  },
};
