import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { forEach, forIn, get, isNil, set } from "lodash";

import { UserContext } from "../auth";
import {
  bidFloorWithSymbol,
  constructAdminAPIEndPoint,
  escapeSearchText,
  fetchHandler,
  getCount,
  getLastPage,
  customActionTrackerInDD,
  getUTCDateTime,
  getErrorMessage,
  getDatasetText,
} from "../../../../utils";
import ErrorMessages from "../../DisplayError/ErrorMessages";
import settings from "../../../../settings";
import { eventBus } from "@px/px_design_system";

const InventoryContext = createContext();
let setTotalAdunitsCount = 0;
let setTotalNetworksCount = 0;
const adUnitsFields = [
  "id",
  "name",
  "network_name",
  "status_display",
  "slot",
  "asset",
  "eids",
  "venue",
  "private_auction_display",
  "lastmod",
];
/*
    ad_exposure - This controls whether viewsheds get auto-created in order to collect
    observations and is a AdOps only control, and is not visible to Pubs.
    deals - Complex field.
  */
const adUnitsOmitFields = [
  "ad_exposure",
  "private_auction_display",
  "status_display",
  "viewshed_name",
];

const networksFields = [
  "name",
  "private_auction_display",
  "auction",
  "adunits_count",
  "id",
  "lastmod",
];
/*
    ad_exposure - This controls whether viewsheds get auto-created in order to collect
    observations and is a AdOps only control, and is not visible to Pubs.
    deals - Complex field.
  */
const networksOmitFields = [
  "ad_exposure",
  "adunits_count",
  "private_auction_display",
];

const fetchDatasets = (apiUrl, errorMessage, controllerRef) => {
  const errorMsg = getErrorMessage(getDatasetText(apiUrl));

  return fetchHandler({
    url: constructAdminAPIEndPoint({
      url: `datasets/${apiUrl}`,
    }),
    signal: controllerRef.current?.signal,
    errorMessage: errorMsg,
  })
    .then((response) => {
      if (response.members && response.members.length) {
        return response.members;
      }

      throw errorMessage;
    })
    .catch((error) => {
      if (!error.data) return Promise.reject(error);
      return Promise.reject(errorMsg);
    });
};

const modifyDatasetResponse = (fieldResponse, addEmptyOption = false) => {
  const modifiedObject = addEmptyOption ? { "": "" } : {};
  fieldResponse.forEach((data) => {
    modifiedObject[data.key] = data.value;
  });
  return modifiedObject;
};

// In adUnit/network, Update dataset Code with dataset values in the applicable dataSet fields.
const updateDataSetFields = (data, inventoryMapping) => {
  forIn(inventoryMapping, (value, dataSetKey) => {
    // get the dataset code from Adunit ex: 1.1 for venue category
    const dataSetCode = get(data, dataSetKey);
    if (!isNil(dataSetCode)) {
      // update data(adunit/network) with related dataset value where code is present Ex: replace 1.1 with AIRBORNE
      set(data, dataSetKey, get(value, dataSetCode));
    }
  });
};

const InventoryProvider = (props) => {
  const defaultInventoryMapping = {
    "auction.at": {},
    "asset.category": {},
    integration_type: {},
    "measurement.provider": {},
    private_auction: {
      "": "",
      0: "Inactive",
      1: "Active",
    },
    status: {},
  };
  const { loading, organization, userPermission } = useContext(UserContext);

  const [inventoryData, setInventoryData] = useState([]);
  const [adUnitsList, setAdunitsList] = useState([]);
  const [networksList, setNetworksList] = useState([]);
  const [displayError, setDisplayError] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [inventoryDetails, setInventoryDetails] = useState({});
  const [inventoryMapping, setInventoryMapping] = useState({
    ...defaultInventoryMapping,
  });
  const [isLoaded, setLoader] = useState(false);
  const [isDataFetch, setIsDataFetch] = useState(false);
  const [totalAdUnits, setTotalAdunits] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [tabItems, setTabItems] = useState([]);
  const { children, location, history, match } = props;
  const controllerRef = useRef(null);
  const orgName = organization.name;
  const orgId = organization.id;
  const page = 1;
  const sizePerPageValue = 15;
  const queryParams = queryString.parse(location.search);
  const [tabName, setTabName] = useState(
    match.params.inventoryType || "adunits"
  );
  const [totalNetworks, setTotalNetworks] = useState(0);
  const [totalNetworksSize, setTotalNetworkSize] = useState(0);
  const [isDatasetLoaded, setDatasetLoad] = useState(false);
  const [filterParams, setFilterParams] = useState([]);
  const [urlParams, seturlParams] = useState([]);
  const paramsToFetchFirstRecord = [
    { key: "page", value: 1 },
    { key: "page_size", value: 1 },
  ];

  let startTime = 0;
  let endTime = 0;
  let inventoryObject = {
    urlParams: [],
    setCount: false,
    setSize: false,
    inventoryFields: networksFields,
    inventoryOmitFields: networksOmitFields,
    apiPath: "networks",
    setTotalInventory: setTotalNetworks,
    setTotalInventorySize: setTotalNetworkSize,
  };

  eventBus.on("updatedTableFilters", (search) => {
    seturlParams(search);
  });

  const setInventoryTabHeader = (callback) => {
    const tempTabItems = [
      {
        title: "Ad Units",
        badgeValue: setTotalAdunitsCount,
        isBadge: true,
        callbackHandler: callback.bind(this, "adunits"),
      },
      {
        title: "Networks",
        badgeValue: setTotalNetworksCount,
        isBadge: true,
        callbackHandler: callback.bind(this, "networks"),
      },
    ].filter(Boolean);
    setTabItems(tempTabItems);
  };

  /* Gets the value at path of object. If the resolved value is undefined,
   * the defaultValue is returned in its place.
   * Params -
   *   object - (Object) The object to query.
   *   path - (Array|string) The path of the property to get.
   *   [defaultValue] - (*) The value returned for undefined resolved values.
   *
   * Refer - https://lodash.com/docs/4.17.15#get
   */
  const checkNestedPropertyExistence = (obj, path) =>
    get(obj, path, "-") || "-";

  const prepareAdunitsRowObject = (details, searchValue) => {
    let adunitTableData = [];
    if (details.adunitsResponse && details.adunitsResponse.data.length) {
      adunitTableData = details.adunitsResponse.data.map((item) => {
        const rowObject = {
          id: item.id || "-",
          name: item.name || "-",
          network__name: item.network_name || "-",
          dimensions: item.slot ? `${item.slot.w}x${item.slot.h}` : "-",
          asset__size: item.asset ? item.asset.size : null,
          asset__category_display: checkNestedPropertyExistence(
            item,
            "asset.category_display"
          ),
          venue__openooh_category_display: checkNestedPropertyExistence(
            item,
            "venue.openooh_category_display"
          ),
          private_auction_display: item.private_auction_display || "-",
          status: item.status || "-",
          status_display: item.status_display || "-",
          lastmod: get(item, "lastmod")
            ? getUTCDateTime(get(item, "lastmod"))
            : "-",
        };
        return rowObject;
      });
    } else {
      setErrorMessage(ErrorMessages.NO_ADUNITS);
      setDisplayError(!searchValue);
      adunitTableData = [];
    }
    return adunitTableData;
  };

  const prepareNetworksRowObject = (details, searchValue) => {
    let networksTableData = [];
    if (details.networksResponse && details.networksResponse.data.length) {
      networksTableData = details.networksResponse.data.map((item) => {
        const networkRowObject = {
          id: item.id || "-",
          name: item.name || "-",
          private_auction_display: item.private_auction_display || "-",
          auction__bidfloor:
            item.auction && item.auction.bidfloor
              ? bidFloorWithSymbol(
                  item.auction.bidfloor,
                  item.auction.bidfloorcur
                )
              : "-",
          adunits_count: item.adunits_count,
          lastmod: get(item, "lastmod")
            ? getUTCDateTime(get(item, "lastmod"))
            : "-",
        };
        return networkRowObject;
      });
    } else {
      networksTableData = [];
      setErrorMessage(ErrorMessages.NO_NETWORKS);
      setDisplayError(!searchValue);
    }
    return networksTableData;
  };

  const prepareRowObject = (details) => {
    const viewQueryParams = new URLSearchParams(window.location.search);
    const searchValue = viewQueryParams.get("search");
    if (tabName === "adunits") {
      setAdunitsList(prepareAdunitsRowObject(details, searchValue));
    } else {
      setNetworksList(prepareNetworksRowObject(details, searchValue));
    }
  };

  const prepareUrl = (inventoryType) => {
    const viewQueryParams = new URLSearchParams(window.location.search);
    const searchValue = viewQueryParams.get("search");
    setTabName(inventoryType);
    const url =
      searchValue && searchValue !== "" ? `?search=${searchValue}` : "";
    if (!loading)
      history.push(`/orgs/${orgId}/inventory/summary/${inventoryType}${url}`);
  };

  const prepareInventoryList = (details) => {
    prepareRowObject(details);
  };

  const getModelText = (apiPath) => {
    return apiPath === "adunits" ? "ad units" : "networks";
  };

  const fetchInventorys = (inventoryObject, isSignal) => {
    let isPager = inventoryObject.urlParams.find(
      (obj) => obj.key === "pager" && obj.value === "cursor"
    );

    !isPager
      ? (inventoryObject.urlParams = [
          ...inventoryObject.urlParams,
          { key: "fields", value: inventoryObject.inventoryFields },
        ]) // Fetch fields required for Networks table view
      : (inventoryObject.urlParams = [
          ...inventoryObject.urlParams,
          { key: "omit", value: inventoryObject.inventoryOmitFields },
        ]); // Omit fields not required for Networks export

    const errorMessage = getErrorMessage(getModelText(inventoryObject.apiPath));

    return fetchHandler({
      url: constructAdminAPIEndPoint({
        url: `orgs/${orgId}/${inventoryObject.apiPath}`,
        searchParams: [...inventoryObject.urlParams],
      }),
      getResponseHeaders: true,
      headers: {
        "cache-control": "no-cache, no-store",
      },
      signal: isSignal ? controllerRef.current?.signal : "",
      errorMessage: errorMessage,
    })
      .then((response) => {
        if (inventoryObject.setCount) {
          const count = getCount(response);
          inventoryObject.setTotalInventory(count);
          inventoryObject.apiPath === "adunits"
            ? (setTotalAdunitsCount = count)
            : (setTotalNetworksCount = count);
          setInventoryTabHeader(prepareUrl);
        }

        if (inventoryObject.setSize) {
          inventoryObject.setTotalInventorySize(getCount(response));
        }

        forEach(response.json, (network) => {
          updateDataSetFields(network, inventoryMapping);
        });

        return {
          data: response.json,
          /*
           * pager represents cursor pagination method being used &
           * in that there is no concept of page nos. Hence assigning last to 0.
           */
          last: !isPager ? getLastPage(response) : 0,
          headers: response.headers,
        };
      })
      .catch((error) => {
        // Condition added here to support retry during Download CSV using Cursor pagination method.
        if (!isPager) {
          if (!error.data && queryParams.search) return Promise.reject(error);
          return Promise.reject(errorMessage);
        }
      });
  };

  const fetchNetworks = (urlParams = [], setCount = false, setSize = false) => {
    const networksData = fetchInventorys(
      {
        ...inventoryObject,
        urlParams: urlParams,
        setCount: setCount,
        setSize: setSize,
      },
      true
    );
    return networksData;
  };

  const fetchAdunits = (urlParams = [], setCount = false, setSize = false) => {
    const mergedArray = [...filterParams, ...urlParams];
    const uniqueData = [
      ...mergedArray
        .reduce((map, obj) => map.set(obj["key"], obj), new Map())
        .values(),
    ];
    return fetchAdunitsData(uniqueData, setCount, setSize, true);
  };

  const fetchAdunitsData = (uniqueData, setCount, setSize, signal) => {
    const adunitsData = fetchInventorys(
      {
        urlParams: uniqueData,
        setCount,
        setSize,
        inventoryFields: adUnitsFields,
        inventoryOmitFields: adUnitsOmitFields,
        apiPath: "adunits",
        setTotalInventory: setTotalAdunits,
        setTotalInventorySize: setTotalSize,
      },
      signal
    );
    return adunitsData;
  };

  const getDatasetApiData = () =>
    Promise.all([
      fetchDatasets(
        settings.DATASETS_ID_LOOKUP.ASSET_CATEGORY_ID,
        ErrorMessages.NO_ASSET_CATEGORY_FOUND,
        controllerRef
      ),
      fetchDatasets(
        settings.DATASETS_ID_LOOKUP.ADUNIT_STATUS_ID,
        ErrorMessages.NO_ADUNIT_STATUS_FOUND,
        controllerRef
      ),
      fetchDatasets(
        settings.DATASETS_ID_LOOKUP.VENUE_CATEGORY_ID,
        ErrorMessages.NO_VENUE_CATEGORY_FOUND,
        controllerRef
      ),
      fetchDatasets(
        settings.DATASETS_ID_LOOKUP.AUCTION_TYPE_ID,
        ErrorMessages.NO_AUCTION_TYPE_FOUND,
        controllerRef
      ),
      fetchDatasets(
        settings.DATASETS_ID_LOOKUP.ADUNIT_INTEGRATION_TYPE_ID,
        ErrorMessages.NO_ADUNIT_INTEGRATION_TYPE_ID,
        controllerRef
      ),
      fetchDatasets(
        settings.DATASETS_ID_LOOKUP.MEASUREMENT_PROVIDER_ID,
        ErrorMessages.NO_MEASUREMENT_PROVIDER_FOUND,
        controllerRef
      ),
    ])
      .then(
        ([
          assetCategoryResponse,
          adUnitStatusResponse,
          venueCategoryResponse,
          auctionTypeResponse,
          adUnitIntegrationTypeResponse,
          measurementProviderResponse,
        ]) => ({
          assetCategoryResponse,
          adUnitStatusResponse,
          venueCategoryResponse,
          auctionTypeResponse,
          adUnitIntegrationTypeResponse,
          measurementProviderResponse,
        })
      )
      .then((response) => {
        setInventoryMapping({
          ...inventoryMapping,
          "auction.at": modifyDatasetResponse(response.auctionTypeResponse),
          "asset.category": modifyDatasetResponse(
            response.assetCategoryResponse,
            true
          ),
          integration_type: modifyDatasetResponse(
            response.adUnitIntegrationTypeResponse
          ),
          "measurement.provider": modifyDatasetResponse(
            response.measurementProviderResponse
          ),
          status: modifyDatasetResponse(response.adUnitStatusResponse),
          "venue.openooh_category": modifyDatasetResponse(
            response.venueCategoryResponse
          ),
        });
        setDatasetLoad(true);
      })
      .catch((error) => Promise.reject(error));

  const fetchInventoryCounts = () =>
    Promise.all([
      !queryParams.search &&
        tabName === "networks" &&
        fetchAdunits(paramsToFetchFirstRecord, !queryParams.search, true),
      !queryParams.search &&
        tabName === "adunits" &&
        fetchNetworks(paramsToFetchFirstRecord, !queryParams.search, true),
      queryParams.search &&
        tabName === "networks" &&
        fetchAdunits(paramsToFetchFirstRecord, true, false),
      queryParams.search &&
        tabName === "adunits" &&
        fetchNetworks(paramsToFetchFirstRecord, true, false),
    ])
      .then(() => {
        setInventoryTabHeader(prepareUrl);
      })
      .catch((error) => Promise.reject(error));

  const triggerLoadData = () => {
    let params = urlParams;
    if (queryParams.search) {
      queryParams.search = escapeSearchText(queryParams.search);
      params = [...params, { key: "search", value: queryParams.search }];
    }
    return Promise.all([
      tabName === "adunits" && fetchAdunits(params, !queryParams.search, true),
      tabName === "networks" &&
        fetchNetworks(params, !queryParams.search, true),
      queryParams.search &&
        tabName === "adunits" &&
        fetchAdunits(paramsToFetchFirstRecord, true, false),
      queryParams.search &&
        tabName === "networks" &&
        fetchNetworks(paramsToFetchFirstRecord, true, false),
    ])
      .then(
        ([adunitsResponse, networksResponse, adunitscount, networksCount]) => ({
          adunitsResponse,
          networksResponse,
          adunitscount,
          networksCount,
        })
      )
      .then((response) => {
        setInventoryData(
          response.adunitsResponse ? response.adunitsResponse.data : []
        );
        setLoader(true);
        setIsDataFetch(true);
        setDisplayError(false);
        setInventoryDetails(response);
        prepareInventoryList(response);
        endTime = performance.now();
        customActionTrackerInDD(startTime, endTime, `click on ${tabName} tab`);
      })
      .catch((error) => Promise.reject(error));
  };

  const triggerInventoryCounts = () => {
    fetchInventoryCounts().catch((error) => {
      if (!("AbortError" in error)) {
        const errorResponse = {
          adunitsResponse: { data: [] },
          networksResponse: { data: [] },
        };
        prepareInventoryList(errorResponse);
        setLoader(true);
        setDisplayError(true);
        setErrorMessage(error);
        setInventoryDetails(errorResponse);
      }
    });
  };

  const triggerDatasetApiData = () => {
    getDatasetApiData().catch((error) => {
      if (!("AbortError" in error)) {
        setDisplayError(true);
        setErrorMessage(error);
        const errorResponse = {
          adunitsResponse: { data: [] },
          networksResponse: { data: [] },
        };
        prepareInventoryList(errorResponse);
        setInventoryDetails(errorResponse);
      }
    });
  };

  const fetchInventoryData = () => {
    triggerLoadData().catch((error) => {
      if (!("AbortError" in error)) {
        const errorResponse = {
          [`${tabName}Response`]: { data: [], last: 0 },
        };
        prepareInventoryList(errorResponse);
        setLoader(true);
        setDisplayError(true);
        setErrorMessage(error);
        setInventoryDetails(errorResponse);
      }
    });
  };

  useEffect(() => {
    if (!loading) {
      seturlParams([
        { key: "page", value: 1 },
        { key: "page_size", value: 15 },
        { key: "ordering", value: "-lastmod" },
      ]);
      setTotalSize(0);
      setTotalNetworkSize(0);
      setTabName(match.params.inventoryType);
      setFilterParams([]);
      setLoader(false);
      triggerInventoryCounts();
    }
  }, [loading, props.location.pathname]);

  useEffect(() => {
    prepareUrl(tabName);
    triggerDatasetApiData();
  }, []);

  useEffect(() => {
    if (!loading) {
      const controller = new AbortController();
      controllerRef.current = controller;
      setIsDataFetch(loading);
      fetchInventoryData();
      startTime = performance.now();
    }
    return () => {
      setIsDataFetch(!loading);
      controllerRef.current?.abort();
    };
  }, [loading, tabName, filterParams]);

  const states = {
    inventoryData,
    adUnitsList,
    networksList,
    displayError,
    errorMessage,
    fetchAdunits,
    fetchNetworks,
    inventoryDetails,
    inventoryMapping,
    isLoaded,
    isDataFetch,
    loading,
    orgName,
    orgId: organization.id,
    page,
    prepareInventoryList,
    setLoader,
    sizePerPageValue,
    totalAdUnits,
    totalSize,
    triggerLoadData,
    userPermission,
    tabName,
    tabItems,
    totalNetworks,
    totalNetworksSize,
    isDatasetLoaded,
    filterParams,
    setFilterParams,
    fetchAdunitsData,
  };

  return (
    <InventoryContext.Provider value={{ states }}>
      {children}
    </InventoryContext.Provider>
  );
};

const InventoryConsumer = InventoryContext.Consumer;

InventoryProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  location: PropTypes.shape({
    push: PropTypes.func,
    search: PropTypes.string,
    page: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      inventoryType: PropTypes.string,
    }),
  }),
};

export { InventoryProvider, InventoryConsumer, InventoryContext };
