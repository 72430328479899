import { forEach, isEmpty, without } from "lodash";
import moment from "moment";

import { commaSeparatedStringToArray } from "../../../../../../../utils";
import { dateCompare } from "../../fieldValidation";

// Method to get formatted date
// User can enter any format so this will convert that date to standard ISO
export const getFormattedTime = (inputTime) => {
  let setTime = "";
  if (inputTime) {
    setTime = moment(
      inputTime.split(" ")[0],
      ["h", "h:m", "hA", "h:ma", "h:mA"],
      true
    ).isValid()
      ? moment(inputTime, ["h:m A"]).format("h:mm A")
      : inputTime;
  }
  return setTime;
};

export const getFormattedTimeForList = (inputTimeList) => {
  let formattedList = "";
  if (inputTimeList) {
    let listWithArray = inputTimeList.split(",");
    formattedList = listWithArray
      .map((value) => {
        return getFormattedTime(value.trim());
      })
      .join(",");
  }
  return formattedList;
};

export const timeValidator = (record, field) => {
  if (!isEmpty(record.get(field))) {
    const timeValidationRegex = /^(0?[1-9]|1[012])(:[0-5][0-9]) [APap][mM]$/;
    const errorMessage =
      "Please enter a valid time (supported format 12 hours: h:m am/pm)";
    let error = true;
    record
      .get(field)
      .split(",")
      .forEach((time) => {
        if (!timeValidationRegex.test(time.trim())) {
          error = false;
        }
      });
    if (!error) {
      record.validate(field, (value) => error, errorMessage);
    }
  }
};

export const dateTimeFieldValidator = (record, field) => {
  const startDate = record.get("start_date");
  const endDate = record.get("end_date");

  const startTime = getFormattedTime(record.get("start_time"));
  const endTime = getFormattedTime(record.get("end_time"));
  record.set("start_time", startTime);
  record.set("end_time", endTime);

  const isStartTimeGreater = dateCompare(
    moment(startTime, "h:mm A"),
    moment(endTime, "h:mm A")
  );

  let error = true;
  if (moment(startDate).isSame(endDate) && isStartTimeGreater) {
    error = false;
  }
  record.validate(
    field,
    (value) => error,
    "start_time cannot be greater than end_time"
  );
};

export const crossDateTimeValidator = (record, field) => {
  let error = true;
  if (
    field === "start_date" &&
    !isEmpty(record.get("end_date")) &&
    !isEmpty(record.get("start_date"))
  ) {
    if (dateCompare(record.get("start_date"), record.get("end_date"))) {
      error = false;
    }
  }
  record.validate(
    field,
    (value) => error,
    "start_date can not be greater than end_date"
  );

  const dateFields = ["end_date", "end_time", "start_date", "start_time"];
  const excludeField = without(dateFields, field);

  const excludedFieldisNotEmpty =
    !isEmpty(record.get(excludeField[0])) ||
    !isEmpty(record.get(excludeField[1])) ||
    !isEmpty(record.get(excludeField[2]));

  if (isEmpty(record.get(field)) && excludedFieldisNotEmpty) {
    record.validate(
      field,
      (value) => false,
      `${field} can not be empty if ${excludeField[0]}, ${excludeField[1]} or ${excludeField[2]} present`
    );
  }
};

export const minMaxTimeValidator = (record, field) => {
  const startTimeValues = getFormattedTime(record.get("dayparts.start_time"));
  const endTimeValues = getFormattedTime(record.get("dayparts.end_time"));
  const startTimeFieldValues = commaSeparatedStringToArray(startTimeValues);
  const endTimeFieldValues = commaSeparatedStringToArray(endTimeValues);

  if (startTimeFieldValues.length === endTimeFieldValues.length) {
    forEach(startTimeFieldValues, (startTime, index) => {
      if (
        dateCompare(
          moment(startTime, "h:mm A"),
          moment(endTimeFieldValues[index], "h:mm A")
        )
      ) {
        record.validate(
          field,
          (value) => false,
          "dayparts.start_time can not be greater than dayparts.end_time"
        );
      }
    });
  }
};
