import React, { useContext } from "react";
import { isEmpty } from "lodash";

import {
  InventoryContext,
  InventoryProvider,
} from "../../context/InventoryManagement";
import { UserContext } from "../../context/auth";
import DisplayError from "../../DisplayError";
import InventoryDetails from "./InventoryDetails";
import Headline from "../Headline";

const InventoryManagement = (props) => {
  const inventory = useContext(InventoryContext);
  const { data, errorMessage, loading } = useContext(UserContext);
  const { states } = inventory;

  const isSuperUser = !isEmpty(data) && data.userData[1] === 204;

  return (
    <>
      <Headline>Inventory</Headline>
      {!isEmpty(data) && !data.orgData.errors && !loading ? (
        <InventoryDetails
          {...states}
          {...props}
          isSuperUser={isSuperUser}
          {...data}
        />
      ) : (
        <DisplayError {...errorMessage} />
      )}
    </>
  );
};

const Inventory = (props) => (
  <InventoryProvider {...props}>
    <InventoryManagement {...props} />
  </InventoryProvider>
);

export const InventoryManagementComponent = InventoryManagement;
export default Inventory;
