import React, { useContext } from "react";
import { isEmpty } from "lodash";

import { UserContext } from "../../context/auth";
import NewsDetails from "./NewsDetails";
import DisplayError from "../../DisplayError";
import Headline from "../Headline";

const News = () => {
  const { data, errorMessage, loading } = useContext(UserContext);

  return (
    <>
      <Headline>News</Headline>
      {!isEmpty(data) && !data.orgData.errors && !loading ? (
        <NewsDetails />
      ) : (
        <DisplayError {...errorMessage} />
      )}
    </>
  );
};

export default News;
