// istanbul ignore file
import { isEmpty, split, map, parseInt, toLower } from "lodash";
import { splitCommaSeparatedStringToArray } from "../../../../../../utils";

const listFields = [
  "asset.mimes",
  "restrictions.badomain",
  "restrictions.battr",
  "restrictions.bbundle",
  "restrictions.bbuyer",
  "restrictions.bcat",
  "restrictions.bcid",
  "restrictions.bcrid",
  "restrictions.bseat",
  "restrictions.btype",
  "restrictions.wlang",
];

const commaSeparatedStringToJson = (
  commaSeparatedString,
  indexDelimiter,
  fieldName
) => {
  //add_formats fild changing to lower case so that "X" trqansform to "x".
  commaSeparatedString = toLower(commaSeparatedString);
  let objList = [];
  if (!isEmpty(commaSeparatedString)) {
    objList = map(split(commaSeparatedString, ","), (element) => {
      let jsonObj = {};
      const index = element.indexOf(indexDelimiter);
      if (fieldName === "ad_formats") {
        jsonObj = {
          w: parseInt(element.substr(0, index)),
          h: parseInt(element.substr(index + 1)),
        };
      } else if (fieldName === "aspect_ratios") {
        jsonObj = {
          wratio: parseInt(element.substr(0, index)),
          hratio: parseInt(element.substr(index + 1)),
        };
      }
      return jsonObj;
    });
  }
  return objList;
};

const postProcessFields = listFields.map((item) => ({
  key: item,
  process: (value) => splitCommaSeparatedStringToArray(value) || [],
}));

postProcessFields.push(
  {
    key: "ad_formats",
    process: (value) => commaSeparatedStringToJson(value, "x", "ad_formats"),
  },
  {
    key: "aspect_ratios",
    process: (value) => commaSeparatedStringToJson(value, ":", "aspect_ratios"),
  }
);

export { postProcessFields };
