/* istanbul ignore file */

import validate from "uuid-validate";
import api from "@flatfile/api";
import { FlatfileListener } from "@flatfile/listener";
import { bulkRecordHook, recordHook } from "@flatfile/plugin-record-hook";
import { isEmpty, uniq } from "lodash";

import { handleSubmit } from "../../../Utilities/FlatFileComponent/Platform/listeners/submit";
import adunitModel from "../../../../models/AdUnit";
import {
  checkNotifiedUser,
  modelFormatter,
  notifyUser,
  setNotifiedUser,
} from "../../../../../../utils";
import {
  beforeAfterSpaceValidation,
  lengthValidator,
  regexValidator,
  minMaxLimitValidator,
  digitBeforeValidator,
  dateValidator,
  crossColumnExistValidator,
  formatBooleanFields,
  requiredWithValidator,
  uuidValidation,
  listFieldValidator,
  checkValueExistInList,
  trimWhitespacesAndSetRecord,
  countLengthValidator,
  int32MaxDateValidation,
  textRangeValidator,
} from "../../../Utilities/FlatFileComponent/validations/common";
import {
  aspectRatioValidator,
  assetAspectRatioValidator,
  formatAspectRatios,
} from "../../../Utilities/FlatFileComponent/validations/Adunits/aspectRatio";
import {
  adFormatValidator,
  eidsValidator,
  statusFieldValidator,
  validateSlotMinMaxDuration,
  validateStartDateWithStatusCol,
} from "../../../Utilities/FlatFileComponent/validations/Adunits/index";
import {
  entityValidator,
  keywordValidator,
} from "../../../Utilities/FlatFileComponent/validations/Placements/keyword";
import { postProcessFields } from "./customValidationFields";
import { validateNetworkFields } from "../../../Utilities/FlatFileComponent/validations/Adunits/networkField";
import { getAvailableResources } from "../../../Utilities/FlatFileComponent/fieldValidation";
import { regex } from "../../../Utilities/FlatFileComponent/utils/regexConstants";

const flatten = require("flat");

const adunitModelFlatDefault = flatten(modelFormatter("default", adunitModel));
let errorJson = [],
  fileName = "Inventory_Ad_Units.csv";

export const listenerAdunits = (
  orgData,
  flatfileClose,
  abortController,
  asyncUploadContext,
  isSuperUser
) =>
  FlatfileListener.create((listener) => {
    /* Track space_id in the console, to be able to share it with Flatfile team while reporting issues. */
    listener.on("workbook:created", async ({ context: { spaceId } }) => {
      console.log("spaceId", spaceId);
    });

    listener.on("job:completed", async (props) => {
      const userAlreadyNotified = checkNotifiedUser("adUnitLocationCorrection");
      // Show warning message for adunit geo location validation when user logged in first time from the browser and try to upload adunits
      if (
        props.payload.operation &&
        props.payload.operation === "map" &&
        !userAlreadyNotified
      ) {
        setNotifiedUser("adUnitLocationCorrection");
        notifyUser(
          true,
          "warning",
          "Auto-correcting locations for accuracy",
          "If the Lat/Long for adunits don't match the provided city, country, DMA, or DMA code, we will validate and correct their locations for you.",
          false,
          false
        );
      }
    });

    listener.on("file:created", async ({ context: { fileId } }) => {
      fileName = (await api.files.get(fileId))?.data?.name;
    });

    listener.use(
      bulkRecordHook("adunits__sheet", async (records) => {
        const networkIDs = records
          .filter(
            (adunitRecord) =>
              !isEmpty(adunitRecord.get("network_id")) &&
              validate(adunitRecord.get("network_id"))
          )
          .map((record) => record.get("network_id"));
        const getExistingNetworks = !isEmpty(networkIDs)
          ? await getAvailableResources(orgData.id, {
              model: "Network",
              ids: networkIDs,
              show_ids_and_names: "true",
            })
          : [];
        let getExistingAdunitsWithStatus;
        if (!isSuperUser) {
          const adunitNameList = records
            .map((adunitRecord) => adunitRecord.get("name"))
            .filter((name) => !isEmpty(name));
          const payload = {
            model: "AdUnit",
            names: uniq(adunitNameList),
            show_fields: ["status", "name"],
          };
          getExistingAdunitsWithStatus = payload.names.length
            ? await getAvailableResources(orgData ? orgData.id : "", payload)
            : [];
        }
        records.map(async (adunitRecord) => {
          /* Trim spaces if a cell only includes whitespaces */
          trimWhitespacesAndSetRecord(adunitRecord);
          /* network_id and name validate */
          validateNetworkFields(adunitRecord, getExistingNetworks);
          /* status field validate for non super user */
          statusFieldValidator(
            adunitRecord,
            "status",
            getExistingAdunitsWithStatus,
            isSuperUser
          );
          return adunitRecord;
        });
      })
    );

    listener.use(
      recordHook("adunits__sheet", async (record) => {
        const INT_MAX_LIMIT = 2147483647;
        const locationRequiredFields = [
          "location.altitude",
          "location.bearing",
          "location.city",
          "location.country",
          "location.dma",
          "location.dma_code",
          "location.method",
          "location.region",
          "location.speed",
          "location.vertical_accuracy",
          "location.zip",
        ];
        const venueFields = ["venue.address", "venue.name"];

        /* Name validations */
        lengthValidator(record, "name", 100);
        beforeAfterSpaceValidation(record, "name");
        /* Ad format validator */
        adFormatValidator(record, "ad_formats", INT_MAX_LIMIT);
        /* aspect ratio validator */
        formatAspectRatios(record, "aspect_ratios");
        aspectRatioValidator(record, "aspect_ratios");
        /* asset.aspect_ratio validator */
        assetAspectRatioValidator(record, "asset.aspect_ratio");
        /* asset.capability.audio validator */
        formatBooleanFields(record, "asset.capability.audio");
        regexValidator(
          record,
          "asset.capability.audio",
          regex.boolean.pattern,
          regex.boolean.errorMsg
        );
        /* asset.capability.banner validator */
        formatBooleanFields(record, "asset.capability.banner");
        regexValidator(
          record,
          "asset.capability.banner",
          regex.boolean.pattern,
          regex.boolean.errorMsg
        );
        /* asset.capability.video validator */
        formatBooleanFields(record, "asset.capability.video");
        regexValidator(
          record,
          "asset.capability.video",
          regex.boolean.pattern,
          regex.boolean.errorMsg
        );
        /* asset.capability.perview validator */
        formatBooleanFields(record, "asset.capability.perview");
        regexValidator(
          record,
          "asset.capability.perview",
          regex.boolean.pattern,
          regex.boolean.errorMsg
        );
        /* asset.image_url validator */
        lengthValidator(record, "asset.image_url", 1000);
        /* asset.mimes validator */
        checkValueExistInList(record, "asset.mimes");
        /* asset.name validator */
        lengthValidator(record, "asset.name", 100);
        /* asset.screen_count validator */
        minMaxLimitValidator(record, "asset.screen_count", 1, INT_MAX_LIMIT);
        /* asset.size validator */
        regexValidator(
          record,
          "asset.size",
          "^-?\\d+$",
          "Asset size should be in inches, so please add a valid integer value."
        );
        minMaxLimitValidator(record, "asset.size", 5, INT_MAX_LIMIT);
        /* auction.alt_bidfloors.banner validator */
        regexValidator(
          record,
          "auction.alt_bidfloors.banner",
          regex.positiveNumberWithTwoDecimal.pattern,
          regex.positiveNumberWithTwoDecimal.errorMsg
        );
        countLengthValidator(record, "auction.alt_bidfloors.banner", 6);
        digitBeforeValidator(record, "auction.alt_bidfloors.banner", 4);
        /* auction.alt_bidfloors.video validator */
        regexValidator(
          record,
          "auction.alt_bidfloors.video",
          regex.positiveNumberWithTwoDecimal.pattern,
          regex.positiveNumberWithTwoDecimal.errorMsg
        );
        countLengthValidator(record, "auction.alt_bidfloors.video", 6);
        digitBeforeValidator(record, "auction.alt_bidfloors.video", 4);
        /* auction.at validator */
        requiredWithValidator(record, "auction.at", [
          "auction.alt_bidfloors.banner",
          "auction.alt_bidfloors.video",
          "auction.bidfloor",
          "auction.bidfloorcur",
        ]);
        /* auction.bidfloor validator */
        regexValidator(
          record,
          "auction.bidfloor",
          regex.positiveNumberWithTwoDecimal.pattern,
          regex.positiveNumberWithTwoDecimal.errorMsg
        );
        countLengthValidator(record, "auction.bidfloor", 6);
        digitBeforeValidator(record, "auction.bidfloor", 4);
        /* eids validator */
        eidsValidator(record, "eids");
        /* keywords validator */
        entityValidator(record, "keywords", 35);
        const errorMessage = await keywordValidator(
          record,
          "keywords",
          orgData.id
        );
        record.addWarning("keywords", errorMessage);
        /* location.altitude validator */
        regexValidator(
          record,
          "location.altitude",
          regex.numberWithTwoDecimal.pattern,
          regex.numberWithTwoDecimal.errorMsg
        );
        countLengthValidator(record, "location.altitude", 6);
        /* location.bearing validator */
        regexValidator(
          record,
          "location.bearing",
          regex.numberWithTwoDecimal.pattern,
          regex.numberWithTwoDecimal.errorMsg
        );
        countLengthValidator(record, "location.bearing", 5);
        minMaxLimitValidator(record, "location.bearing", 0, 360);
        /* location.city validator */
        lengthValidator(record, "location.city", 100);
        /* location.country validator */
        lengthValidator(record, "location.country", 100);
        /* location.dma_code validator */
        regexValidator(
          record,
          "location.dma_code",
          "^(-|)[0-9]\\d*$",
          "It should be valid integer number."
        );
        minMaxLimitValidator(record, "location.dma_code", 1, 999);
        /* location.horizontal_accuracy validator */
        requiredWithValidator(record, "location.horizontal_accuracy", [
          ...locationRequiredFields,
          "location.lat",
          "location.lon",
        ]);
        regexValidator(
          record,
          "location.horizontal_accuracy",
          "^(\\d*\\.?\\d{1,6}|\\d+)$",
          "It should be a positive number with maximum 6 decimal places."
        );
        digitBeforeValidator(record, "location.horizontal_accuracy", 3);
        countLengthValidator(record, "location.horizontal_accuracy", 9);
        /* location.lat validator */
        requiredWithValidator(record, "location.lat", [
          ...locationRequiredFields,
          "location.horizontal_accuracy",
          "location.lon",
        ]);
        regexValidator(
          record,
          "location.lat",
          regex.number.pattern,
          regex.number.errorMsg
        );
        minMaxLimitValidator(record, "location.lat", -90, 90);
        /* location.lon validator */
        requiredWithValidator(record, "location.lon", [
          ...locationRequiredFields,
          "location.horizontal_accuracy",
          "location.lat",
        ]);
        regexValidator(
          record,
          "location.lon",
          regex.number.pattern,
          regex.number.errorMsg
        );
        minMaxLimitValidator(record, "location.lon", -180, 180);
        /* location.method validator */
        lengthValidator(record, "location.method", 100);
        /* location.region validator */
        lengthValidator(record, "location.region", 100);
        /* location.speed validator */
        regexValidator(
          record,
          "location.speed",
          "^(?:\\d*\\.\\d{1,6}|\\d+)$",
          "It should a valid positive number with maximum 6 decimal places."
        );
        countLengthValidator(record, "location.speed", 8);
        /* location.vertical_accuracy validator */
        regexValidator(
          record,
          "location.vertical_accuracy",
          regex.numberGreaterOrEqualTo0.pattern,
          regex.numberGreaterOrEqualTo0.errorMsg
        );
        countLengthValidator(record, "location.vertical_accuracy", 9);
        digitBeforeValidator(record, "location.vertical_accuracy", 3);
        /* location.zip validator */
        lengthValidator(record, "location.zip", 10);
        /* measurement.duration validator */
        regexValidator(
          record,
          "measurement.duration",
          regex.positiveNumberWithTwoDecimal.pattern,
          regex.positiveNumberWithTwoDecimal.errorMsg
        );
        countLengthValidator(record, "measurement.duration", 6);
        /* measurement.imp_four_week validator */
        regexValidator(
          record,
          "measurement.imp_four_week",
          regex.number.pattern,
          regex.number.errorMsg
        );
        /* measurement.imp_x validator */
        regexValidator(
          record,
          "measurement.imp_x",
          regex.numberGreaterOrEqualTo0.pattern,
          regex.numberGreaterOrEqualTo0.errorMsg
        );
        /* measurement.segment validator */
        lengthValidator(record, "measurement.segment", 100);
        /* measurement_config.ads_per_hour validator */
        regexValidator(
          record,
          "measurement_config.ads_per_hour",
          regex.positiveNumberWithTwoDecimal.pattern,
          regex.positiveNumberWithTwoDecimal.errorMsg
        );
        digitBeforeValidator(record, "measurement_config.ads_per_hour", 4);
        /* network_id validator */
        requiredWithValidator(record, "network_id", ["network_name"]);
        uuidValidation(record, "network_id");
        /* network_name validator */
        requiredWithValidator(record, "network_name", ["network_id"]);
        /* notes validator */
        textRangeValidator(record, "notes", 2, 4000);
        /* planning.base_rate validator */
        regexValidator(
          record,
          "planning.base_rate",
          regex.positiveNumberWithTwoDecimal.pattern,
          regex.positiveNumberWithTwoDecimal.errorMsg
        );
        countLengthValidator(record, "planning.base_rate", 6);
        digitBeforeValidator(record, "planning.base_rate", 4);
        /* restrictions.battr validator */
        checkValueExistInList(record, "restrictions.battr");
        /* restrictions.bcat validator */
        checkValueExistInList(record, "restrictions.bcat");
        /* restrictions.wlang validator */
        checkValueExistInList(record, "restrictions.wlang");
        /* restrictions.btype validator */
        checkValueExistInList(record, "restrictions.btype");
        /* restrictions.badomain validator */
        listFieldValidator(record, "restrictions.badomain");
        /* restrictions.bbundle validator */
        listFieldValidator(record, "restrictions.bbundle");
        /* restrictions.bbuyer validator */
        listFieldValidator(record, "restrictions.bbuyer");
        /* restrictions.bcid validator */
        listFieldValidator(record, "restrictions.bcid");
        /* restrictions.bcrid validator */
        listFieldValidator(record, "restrictions.bcrid");
        /* restrictions.bseat validator */
        listFieldValidator(record, "restrictions.bseat");
        /* slot.h validator */
        regexValidator(
          record,
          "slot.h",
          regex.numberGreaterOrEqualToOne.pattern,
          regex.numberGreaterOrEqualToOne.errorMsg
        );
        minMaxLimitValidator(record, "slot.h", 1, INT_MAX_LIMIT);
        /* slot.max_duration validator */
        regexValidator(
          record,
          "slot.max_duration",
          regex.nonZeroNumberWithMaxTwoDecimal.pattern,
          regex.nonZeroNumberWithMaxTwoDecimal.errorMsg
        );
        digitBeforeValidator(record, "slot.max_duration", 4);
        validateSlotMinMaxDuration(record, "slot.max_duration");
        /* slot.min_duration validator */
        regexValidator(
          record,
          "slot.min_duration",
          regex.nonZeroNumberWithMaxTwoDecimal.pattern,
          regex.nonZeroNumberWithMaxTwoDecimal.errorMsg
        );
        digitBeforeValidator(record, "slot.min_duration", 4);
        /* slot.w validator */
        regexValidator(
          record,
          "slot.w",
          regex.numberGreaterOrEqualToOne.pattern,
          regex.numberGreaterOrEqualToOne.errorMsg
        );
        minMaxLimitValidator(record, "slot.w", 1, INT_MAX_LIMIT);
        /* start date validator */
        dateValidator(record, "start_date");
        validateStartDateWithStatusCol(record, "start_date");
        crossColumnExistValidator(record, "status", "1", "start_date");
        int32MaxDateValidation(record, "start_date");
        /* venue.address validator */
        lengthValidator(record, "venue.address", 200);
        /* venue.name validator */
        lengthValidator(record, "venue.name", 200);
        /* venue.openooh_category validator */
        requiredWithValidator(record, "venue.openooh_category", venueFields);
        return record;
      })
    );

    listener.filter({ job: "workbook:submitActionAdunits" }, (configure) => {
      configure.on("job:ready", async ({ context: { jobId, workbookId } }) => {
        errorJson = await handleSubmit({
          abortController,
          orgData,
          jobId,
          workbookId,
          flatModel: adunitModelFlatDefault,
          postProcessFields,
          model: "adunits",
          type: "adunits",
          fileName,
          asyncUploadContext,
          asyncUpload: true,
        });
      });
    });

    listener.on("job:outcome-acknowledged", (event) => {
      // Handle the outcome-acknowledged event here
      flatfileClose(errorJson || []);
    });
  });
