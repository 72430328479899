import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { get } from "lodash";

import { UserContext } from "../auth";
import {
  constructAdminAPIEndPoint,
  escapeSearchText,
  fetchHandler,
  getCount,
  getLastPage,
  customActionTrackerInDD,
  getUTCDateTime,
  getErrorMessage,
} from "../../../../utils";
import ErrorMessages from "../../DisplayError/ErrorMessages";

const MeasurementContext = createContext();
let setTotalGeosegmentsCount = 0;

const MeasurementProvider = (props) => {
  const { loading, organization, userPermission } = useContext(UserContext);
  const [measurementData, setMeasurementData] = useState([]);
  const [geosegmentsList, setGeosegmentsList] = useState([]);
  const [displayError, setDisplayError] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [measurementDetails, setMeasurementDetails] = useState({});
  const [isLoaded, setLoader] = useState(false);
  const [isDataFetch, setIsDataFetch] = useState(false);
  const [totalGeosegments, setTotalGeosegments] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [tabItems, setTabItems] = useState([]);
  const { children, location, history, match } = props;
  const controllerRef = useRef(null);
  const orgName = organization.name;
  const orgId = organization.id;
  const page = 1;
  const sizePerPageValue = 15;
  const queryParams = queryString.parse(location.search);
  const [tabName, setTabName] = useState(
    match.params.measurementType || "geosegments"
  );
  const [isDatasetLoaded, setDatasetLoad] = useState(false);
  const paramsToFetchFirstRecord = [
    { key: "page", value: 1 },
    { key: "page_size", value: 1 },
  ];

  let startTime = 0;
  let endTime = 0;

  const prepareGeosegmentsRowObject = (details, searchValue) => {
    let geosegmentsTableData = [];
    if (
      details.geosegmentsResponse &&
      details.geosegmentsResponse.data.length
    ) {
      geosegmentsTableData = details.geosegmentsResponse.data.map((item) => {
        const geosegmentRowObject = {
          name: item.name,
          adunit_id: item.adunit_id,
          provider_name: item.provider_name,
          segment_name: item.segment_name,
          geo_code: item.geo_code,
          geo_name: item.geo_name,
          geo_type: item.geo_type,
          lastmod: get(item, "lastmod")
            ? getUTCDateTime(get(item, "lastmod"))
            : "-",
          id: item.id,
        };
        return geosegmentRowObject;
      });
    } else {
      geosegmentsTableData = [];
      setErrorMessage(ErrorMessages.NO_GEOSEGMENTS);
      setDisplayError(!searchValue);
    }
    return geosegmentsTableData;
  };

  const prepareRowObject = (details) => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchValue = urlParams.get("search");
    if (tabName === "geosegments") {
      setGeosegmentsList(prepareGeosegmentsRowObject(details, searchValue));
    }
  };

  const prepareUrl = (measurementType) => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchValue = urlParams.get("search");
    setTabName(measurementType);
    const url =
      searchValue && searchValue !== "" ? `?search=${searchValue}` : "";
    if (!loading)
      history.push(
        `/orgs/${orgId}/measurement/summary/${measurementType}${url}`
      );
  };

  const prepareMeasurementList = (details) => {
    prepareRowObject(details);
  };

  const fetchMeasurements = (measurementObject) => {
    const errorMessage = getErrorMessage("measurements");

    let isPager = measurementObject.urlParams.find(
      (obj) => obj.key === "pager" && obj.value === "cursor"
    );

    return fetchHandler({
      url: constructAdminAPIEndPoint({
        url: `orgs/${orgId}/${measurementObject.apiPath}`,
        searchParams: [...measurementObject.urlParams],
      }),
      getResponseHeaders: true,
      headers: {
        "cache-control": "no-cache, no-store",
      },
      signal: controllerRef.current?.signal,
      errorMessage: errorMessage,
    })
      .then((response) => {
        setDatasetLoad(true);
        if (measurementObject.setCount) {
          const count = getCount(response);
          measurementObject.setTotalMeasurement(count);
          if (measurementObject.apiPath === "geosegments_enriched")
            setTotalGeosegmentsCount = count;
        }

        if (measurementObject.setSize) {
          measurementObject.setTotalMeasurementSize(getCount(response));
        }

        return {
          data: response.json,
          last: !isPager ? getLastPage(response) : 0,
          headers: response.headers,
        };
      })
      .catch((error) => {
        if (!isPager) {
          if (!error.data && queryParams.search) return Promise.reject(error);
          return Promise.reject(errorMessage);
        }
      });
  };

  const fetchGeosegments = (
    urlParams = [],
    setCount = false,
    setSize = false
  ) => {
    const geosegmentsData = fetchMeasurements({
      urlParams,
      setCount,
      setSize,
      apiPath: "geosegments_enriched",
      setTotalMeasurement: setTotalGeosegments,
      setTotalMeasurementSize: setTotalSize,
    });
    return geosegmentsData;
  };

  const triggerLoadData = () => {
    let urlParams = [
      { key: "page", value: page },
      { key: "page_size", value: sizePerPageValue },
      { key: "ordering", value: "-lastmod" },
    ];
    if (queryParams.search) {
      queryParams.search = escapeSearchText(queryParams.search);
      urlParams = [...urlParams, { key: "search", value: queryParams.search }];
    }
    return Promise.all([
      tabName === "geosegments" &&
        fetchGeosegments(urlParams, !queryParams.search, true),
      queryParams.search &&
        tabName === "geosegments" &&
        fetchGeosegments(paramsToFetchFirstRecord, true, false),
    ])
      .then(([geosegmentsResponse, geosegmentsCount]) => ({
        geosegmentsResponse,
        geosegmentsCount,
      }))
      .then((response) => {
        setMeasurementData(
          response.geosegmentsResponse ? response.geosegmentsResponse.data : []
        );
        setLoader(true);
        setIsDataFetch(true);
        setDisplayError(false);
        setMeasurementDetails(response);
        prepareMeasurementList(response);
        endTime = performance.now();
        customActionTrackerInDD(startTime, endTime, `click on ${tabName} tab`);
      })
      .catch((error) => Promise.reject(error));
  };

  const fetchMeasurementData = () => {
    triggerLoadData().catch((error) => {
      if (!("AbortError" in error)) {
        const errorResponse = {
          [`${tabName}Response`]: { data: [], last: 0 },
        };
        prepareMeasurementList(errorResponse);
        setLoader(true);
        setDisplayError(true);
        setErrorMessage(error);
        setMeasurementDetails(errorResponse);
      }
    });
  };

  useEffect(() => {
    if (!loading) {
      setTabName(match.params.measurementType);
      setLoader(false);
    }
  }, [loading, props.location.pathname]);

  useEffect(() => {
    prepareUrl(tabName);
  }, []);

  useEffect(() => {
    if (!loading) {
      const controller = new AbortController();
      controllerRef.current = controller;
      setIsDataFetch(false);
      fetchMeasurementData();
      startTime = performance.now();
    }
    return () => {
      setGeosegmentsList([]);
      controllerRef.current?.abort();
    };
  }, [loading, tabName]);

  const states = {
    measurementData,
    geosegmentsList,
    displayError,
    errorMessage,
    fetchGeosegments,
    measurementDetails,
    isLoaded,
    isDataFetch,
    loading,
    orgName,
    orgId: organization.id,
    page,
    prepareMeasurementList,
    setLoader,
    sizePerPageValue,
    totalGeosegments,
    totalSize,
    triggerLoadData,
    userPermission,
    tabName,
    tabItems,
    isDatasetLoaded,
  };

  return (
    <MeasurementContext.Provider value={{ states }}>
      {children}
    </MeasurementContext.Provider>
  );
};

const MeasurementConsumer = MeasurementContext.Consumer;

MeasurementProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  location: PropTypes.shape({
    push: PropTypes.func,
    search: PropTypes.string,
    page: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      measurementType: PropTypes.string,
    }),
  }),
};

export { MeasurementProvider, MeasurementConsumer, MeasurementContext };
