/* istanbul ignore file */
import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { capitalize, get, isEmpty, join, map } from "lodash";
import {
  Button,
  Form,
  Icon,
  Input,
  Modal,
  MultiSelect,
  Section,
  Spinner,
  ToolTips,
  VideoPlayer,
} from "@px/px_design_system";
import PropTypes from "prop-types";
import queryString from "query-string";
import { toast } from "react-toastify";

import DisplayError from "../../DisplayError";
import {
  AdApprovalListContext,
  AdApprovalListProvider,
} from "../../context/AdApprovalContext";
import { UserContext } from "../../context/auth";
import { LaunchAdApprovalIcon } from "../../svgs/AdApprovalIcon";
import PrepareSvg from "../../svgs/PrepareSvg";
import {
  prepareCsvData,
  showCustomTooltip,
  getUTCDateTime,
  fixedEncodeURIComponent,
  replaceAllCharacter,
  constructAdminAPIEndPoint,
  fetchHandler,
} from "../../../../utils";
import PaginatedDownloadCsv from "../Utilities/PaginatedDownloadCsv";
import ToolTipMessages from "../Utilities/ToolTip/TooltipMessages";
import { Table } from "../Utilities/Table";
import Headline from "../Headline";
import adApprovalModel from "../../models/AdApproval";
import ToastMsg from "../Utilities/ToastMsg";

import "./style.css";

const AdApprovalListComponent = (props) => {
  const { organization, userPermission } = useContext(UserContext);
  const { errorMessageNoCat } = useContext(AdApprovalListContext);
  const [finalAdApprovals, setFinalAdApprovals] = useState([]);
  const [searchResultCount, setSearchResultCount] = useState(0);
  const [isBulkSubmit, setBulkSubmit] = useState(false);
  const { history, location } = props;
  const thumbRef = useRef([]);
  const formModalRef = useRef([]);

  const {
    isLoaded,
    statusArray,
    fetchCsvData,
    csvColumnFormation,
    creativeIcon,
    totalAdApprovals,
    tabName,
    categoryData,
    networkList,
  } = useContext(AdApprovalListContext);

  const createLinkToTableDomain = (cell) => {
    let cellValue = cell;
    if (typeof cell !== "string") {
      cellValue = cell.toString();
    }

    const cellArray = cellValue.split(", ");
    const cellArrayLength = cellArray.length;
    const links = cellArray.map((link, index) => (
      <React.Fragment key={cellArrayLength + link}>
        <Link key={cellArray.length++} to={`//${link}`} target="_blank">
          {link}
        </Link>
        {cellArrayLength - 1 !== index && ", "}
      </React.Fragment>
    ));
    return !isEmpty(cell) ? links : "-";
  };

  const launchAdApprovalProps = {
    style: {
      backgroundColor: "#2D6BFF",
      borderColor: "#2D6BFF",
      color: "#ffffff",
    },
    buttontext: "Launch Approval Queue",
    icon: <PrepareSvg iconComponent={LaunchAdApprovalIcon} />,
  };

  const rejectButtonProps = {
    style: {
      backgroundColor: "#dc3545",
      color: "#ffffff",
      borderColor: "#dc3545",
    },
    buttontext: "Reject",
    icon: <Icon icon={["far", "times-circle"]} size="2x" />,
  };

  const approveButtonProps = {
    style: {
      backgroundColor: "#28a745",
      borderColor: "#28a745",
      color: "#ffffff",
    },
    buttontext: "Approve",
    icon: <Icon icon={["far", "check-circle"]} size="2x" />,
  };

  const csvDataToDownload = (adApprovalData) => {
    const listData = adApprovalData.map((item, index) =>
      csvColumnFormation(item, index)
    );
    const params = {
      list: listData,
      fileFormat: "csv",
      modelObj: adApprovalModel,
    };
    const getCsvData = prepareCsvData(params);
    setFinalAdApprovals(getCsvData);
  };

  const spinnerProps = {
    className: "spinner-icon",
    color: "primary",
    loadingtext: "Loading",
  };

  const appendParams = (auditStatus) => {
    const values = queryString.parse(props?.history?.location.search);
    const params = `?audit__status=${auditStatus}&ordering=${values.ordering}`;
    return !isEmpty(values.search)
      ? `${params}&search=${values.search}`
      : params;
  };

  const navigateToPath = (appendUrl) => {
    appendUrl = replaceAllCharacter(appendUrl, "%", "%25");
    const auditStatus = queryString.parse(location.search).audit__status;
    history.push({
      pathname: `/orgs/${organization.id}/adapprovals/${fixedEncodeURIComponent(
        appendUrl
      )}`,
      search: appendParams(auditStatus),
    });
  };

  const createLinkToTableId = (cell, row) => {
    const approvalName = get(row.original, "name", "");
    return (
      <Link
        to={`../adapprovals/${fixedEncodeURIComponent(
          replaceAllCharacter(approvalName, "%", "%25")
        )}${appendParams(statusArray.toString())}`}
        data-index={row.id}
        className={`link${row.id}`}
      >
        {cell}
      </Link>
    );
  };

  const bulkAdApproval = async (selectedRows, fieldsToUpdate) => {
    const getAllAdName = Object.keys(selectedRows);

    const updateAllAdApprovals_Promise = getAllAdName.map(async (name) => {
      const apiParams = {
        method: "PATCH",
        body: {
          ...fieldsToUpdate,
        },
        url: constructAdminAPIEndPoint({
          url: `orgs/${organization.id}/adapprovals/${fixedEncodeURIComponent(
            name
          )}`,
        }),
      };

      return fetchHandler(apiParams)
        .then(() => {
          return "success";
        })
        .catch(() => {
          toast.warning(
            <ToastMsg
              icon={["fas", "exclamation-triangle"]}
              toastTitle="Something went wrong!"
              toastSubTitle={`Failed to ${
                fieldsToUpdate?.audit?.status === 4 ? "reject" : "approve"
              } some ads. Please try again.`}
            />,
            {
              toastId: "failed-ads",
              position: "top-center",
              autoClose: false,
            }
          );
        });
    });

    await Promise.all(updateAllAdApprovals_Promise).then((res) => {
      navigateToPath("refreshSummary");
    });
  };

  const fallbackValue = (cell) => {
    return !isEmpty(cell) ? cell : "-";
  };

  const PreviewImage = (props) => {
    return (
      <Modal
        launchButton={props.children}
        modalBody={props.children}
        name="Ads"
        className="adapproval--preview"
      />
    );
  };

  const handleCancelCallback = (name) => {
    formModalRef?.current[name]?.toggle();
  };

  const ApproveRejectModal = ({ button, name, getSelectedRows }) => {
    return (
      <Modal
        ref={(el) => (formModalRef.current[name] = el)}
        launchButton={button}
        modalBody={
          <>
            <Form
              onSubmit={async (formData) => {
                const prepFormData = { ...formData };
                if (name === "approve") {
                  prepFormData.approved_networks =
                    formData?.approved_networks?.map((item) => item.value) ||
                    [];
                  prepFormData.is_approved_on_all_networks =
                    !formData?.approved_networks?.length;
                } else {
                  delete prepFormData.approved_networks;
                }

                setBulkSubmit(true);
                await bulkAdApproval(getSelectedRows, prepFormData);
              }}
              defaultValues={{
                audit: {
                  status: name === "approve" ? 3 : 4,
                  feedback: "",
                },
                approved_networks: [],
              }}
              submitButtonText={`Confirm ${capitalize(name)}`}
              canCelButtonCallback={() => handleCancelCallback(name)}
              submitButtonStyle={name === "approve" ? "success" : "danger"}
              displayTooltip={true}
            >
              <Section title={`${capitalize(name)} Ads?`} />
              <Input
                label="Feedback"
                type="textarea"
                name="audit.feedback"
                placeholder="Additional context about the Ad Approval. Please note it might be shared with the DSP."
              />

              {name === "approve" ? (
                <Section>
                  <label className="form__label">Select Networks</label>
                  <p>
                    To approve this Ad only for specific Networks, please select
                    Networks below. By doing so, you are giving explicit consent
                    on behalf of your Organization to run this Ad on the
                    selected Networks.
                  </p>
                </Section>
              ) : (
                <></>
              )}

              {name === "approve" ? (
                <MultiSelect
                  placeholder="All Networks"
                  formGroupClassName="network-select"
                  isMulti={true}
                  closeMenuOnSelect={false}
                  options={networkList?.map((element) => ({
                    label: element.name,
                    value: element.name,
                  }))}
                  isClearable
                  isValidNewOption={() => false}
                  name="approved_networks"
                  isSearchable={true}
                  menuPlacement="top"
                />
              ) : (
                <></>
              )}
            </Form>
          </>
        }
        name={name}
        className="adapproval--status"
      />
    );
  };

  let remoteColumns = [
    {
      accessorKey: "ad.name",
      header: "DSP Creative ID",
      dataField: "ad__name",
      Cell: ({ cell, row }) => {
        return createLinkToTableId(cell.getValue(), row);
      },
    },
    {
      accessorKey: "ad.ext.owned_by_name",
      header: "DSP",
      dataField: "ad__organization__name",
      Cell: ({ cell }) => {
        return fallbackValue(cell.getValue());
      },
    },
    {
      accessorKey: "ad.adomain",
      header: "Advertiser Domain",
      dataField: "ad__adomain",
      Cell: ({ cell }) => {
        return createLinkToTableDomain(cell.getValue());
      },
    },
    {
      accessorKey: "ad.creative.ext.type",
      header: "Ad Type",
      dataField: "ad__creative__kind",
      Cell: ({ cell, row }) => {
        return creativeIcon(cell.getValue(), row);
      },
    },
    {
      accessorKey: "creative.snapshots.0.w",
      header: "Size (w x h)",
      dataField: "ad__size",
      enableSorting: false,
      Cell: ({ cell, row }) => {
        return row.original?.creative?.snapshots[0]?.w
          ? `${row.original?.creative?.snapshots[0]?.w} x ${row.original?.creative?.snapshots[0]?.h}`
          : "-";
      },
    },
    {
      accessorKey: "ad.cat",
      header: "Content Category",
      dataField: "ad__cat",
      enableSorting: false,
      Cell: ({ cell, row }) => {
        const categoryListData = row.original?.ad?.cat.map((ref) =>
          categoryData.find((element) => element?.key === ref)
        );
        return (
          join(
            categoryListData.map((item) => ` ${item?.key} - ${item?.value}`)
          ) || "-"
        );
      },
    },
    {
      accessorKey: "ad.lang_display",
      header: "Language",
      dataField: "ad__lang_display",
      Cell: ({ cell }) => {
        return fallbackValue(cell.getValue());
      },
    },
    {
      accessorKey: "id",
      header: "Ad Approval ID",
      dataField: "id",
      Cell: ({ cell }) => {
        return fallbackValue(cell.getValue());
      },
    },
    {
      accessorKey: "ts",
      header: "Date Created",
      dataField: "ts",
      Cell: ({ cell }) => {
        return showCustomTooltip(
          getUTCDateTime(cell.getValue()),
          "Time shown in UTC"
        );
      },
    },
    {
      accessorKey: "audit.lastmod",
      header: "Last Modified",
      dataField: "adapprovalaudit__lastmod",
      Cell: ({ cell }) => {
        return showCustomTooltip(
          getUTCDateTime(cell.getValue()),
          "Time shown in UTC"
        );
      },
    },
    {
      accessorKey: "creative.snapshots.0",
      header: "Preview",
      dataField: "preview",
      enableSorting: false,
      Cell: ({ cell, row }) => {
        if (
          row.original?.creative?.snapshots[0]?.curl ||
          row.original?.creative?.snapshots[0]?.iurl
        )
          if (row.original?.creative?.type === "video") {
            return (
              <div className="snapshot--thumb snapshot--thumb--video">
                <VideoPlayer
                  fill
                  sources={[
                    {
                      src: `${row.original?.creative?.snapshots[0]?.curl}`,
                      type: "video/mp4",
                    },
                  ]}
                  changeVideoSrc={`${cell.getValue()}`}
                />
              </div>
            );
          } else {
            return (
              <div className="snapshot--thumb">
                <PreviewImage>
                  <img
                    src={`${row.original?.creative?.snapshots[0]?.iurl}`}
                    alt=""
                    ref={(el) => (thumbRef.current[row.index] = el)}
                  />
                </PreviewImage>
              </div>
            );
          }
        return <>No preview available.</>;
      },
    },
  ];

  return (
    <>
      {!isEmpty(errorMessageNoCat) ? (
        <DisplayError {...errorMessageNoCat} />
      ) : (
        <div
          className={`ad--approval-list wrapper ${
            isLoaded ? "wrapper--no-border" : ""
          }`}
        >
          {(isLoaded || isBulkSubmit) && <Spinner {...spinnerProps} />}
          {!isLoaded && (
            <div className="ad--approval-container">
              <h2 className="deal__headline deal__headline--copy sub__headline--count">
                <>{tabName}</>
              </h2>
              <Table
                enableColumnPinning={true}
                initialStateOptions={{
                  columnPinning: { left: ["creative.snapshots.0"] },
                }}
                enableRowSelection={true}
                table_columns={remoteColumns}
                orgId={organization.id}
                name="adapprovals"
                getRowId={(originalRow) => {
                  return originalRow.name;
                }}
                extraUrlParams={[
                  {
                    key: "status",
                    value:
                      statusArray.toString() !== "all"
                        ? statusArray.toString()
                        : "",
                  },
                ]}
                history={history}
                defaultSort={[
                  {
                    id: "audit.lastmod",
                    desc: true,
                  },
                ]}
                setSearchResultCount={setSearchResultCount}
                renderTopToolbarCustomActions={({ table }) => {
                  return (
                    <div className="buttons">
                      <PaginatedDownloadCsv
                        componentName="AdApprovals"
                        fetchData={fetchCsvData}
                        csvDataToDownload={csvDataToDownload}
                        disableDownloadCsv={totalAdApprovals === 0}
                        disableDownloadText={
                          totalAdApprovals === 0
                            ? ToolTipMessages.DOWNLOAD_CSV.title
                            : ""
                        }
                        finalData={finalAdApprovals}
                        removeColumns={["serial"]}
                        filename={tabName}
                        renameHeaders={{
                          ad__name: "dsp_creative_id",
                          ad__organization__name: "dsp",
                          ad__adomain: "advertiser_domain",
                          ad__creative__kind: "ad_type",
                          ad__lang_display: "language",
                          id: "ad_approval_id",
                          ts: "created",
                          adapprovalaudit__lastmod: "lastmod",
                        }}
                        key={tabName}
                        dataSize={totalAdApprovals}
                      />
                      <ToolTips
                        className=""
                        disabled={
                          totalAdApprovals === 0 || searchResultCount === 0
                        }
                        text={
                          totalAdApprovals === 0 || searchResultCount === 0
                            ? ToolTipMessages.LAUNCH_APPROVAL_NO_DATA.title
                            : ""
                        }
                      >
                        <Button
                          type="button"
                          id="launchApprovalQueueId"
                          {...launchAdApprovalProps}
                          onClick={() => navigateToPath("queue")}
                          className="launch--button"
                          disabled={
                            totalAdApprovals === 0 || searchResultCount === 0
                          }
                        />
                      </ToolTips>

                      {/** Approve action button */}
                      <ToolTips
                        disabled={
                          userPermission ||
                          isEmpty(table.getState().rowSelection) ||
                          statusArray === "2,3" ||
                          Object.keys(table.getState().rowSelection)?.length >
                            15
                        }
                        text={
                          userPermission
                            ? ToolTipMessages.READ_ONLY.title
                            : statusArray === "2,3"
                            ? "Ads are already approved"
                            : isEmpty(table.getState().rowSelection)
                            ? "Select the ads to approve"
                            : Object.keys(table.getState().rowSelection)
                                ?.length > 15
                            ? "Upto 15 approvals allowed"
                            : "Approve selected ads"
                        }
                      >
                        <ApproveRejectModal
                          id="approveBtn"
                          button={
                            <Button
                              type="button"
                              id="approveBtn"
                              {...approveButtonProps}
                              className="approve--button"
                              disabled={
                                userPermission ||
                                isEmpty(table.getState().rowSelection) ||
                                statusArray === "2,3" ||
                                Object.keys(table.getState().rowSelection)
                                  ?.length > 15
                              }
                            />
                          }
                          getSelectedRows={table.getState().rowSelection}
                          name="approve"
                        />
                      </ToolTips>

                      <ToolTips
                        className=""
                        disabled={
                          userPermission ||
                          isEmpty(table.getState().rowSelection) ||
                          statusArray === "4" ||
                          Object.keys(table.getState().rowSelection)?.length >
                            15
                        }
                        text={
                          userPermission
                            ? ToolTipMessages.READ_ONLY.title
                            : statusArray === "4"
                            ? "Ads are already rejected"
                            : isEmpty(table.getState().rowSelection)
                            ? "Select the ads to reject"
                            : Object.keys(table.getState().rowSelection)
                                ?.length > 15
                            ? "Upto 15 rejections allowed"
                            : "Reject selected ads"
                        }
                      >
                        <ApproveRejectModal
                          id="rejectBtn"
                          button={
                            <Button
                              type="button"
                              id="rejectBtn"
                              {...rejectButtonProps}
                              className="reject--button"
                              disabled={
                                userPermission ||
                                isEmpty(table.getState().rowSelection) ||
                                statusArray === "4" ||
                                Object.keys(table.getState().rowSelection)
                                  ?.length > 15
                              }
                            />
                          }
                          name="reject"
                          getSelectedRows={table.getState().rowSelection}
                        />
                      </ToolTips>
                    </div>
                  );
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

AdApprovalListComponent.propTypes = {
  children: PropTypes.node,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  computedMatch: PropTypes.shape({
    params: PropTypes.object,
  }),
  location: PropTypes.shape({
    push: PropTypes.func,
    search: PropTypes.string,
  }),
};

const AdApprovalList = (props) => {
  const { match } = props;
  const { data, errorMessage, loading } = useContext(UserContext);

  return (
    <>
      <Headline>Ad Approvals</Headline>
      {!isEmpty(data) && !data.orgData.errors && !loading ? (
        <AdApprovalListProvider
          key={match.params.id}
          {...props}
          isAdApprovalDetails={false}
        >
          <AdApprovalListComponent {...props} />
        </AdApprovalListProvider>
      ) : (
        <DisplayError {...errorMessage} />
      )}
    </>
  );
};

AdApprovalList.propTypes = {
  match: PropTypes.shape(),
};

export const AdApprovalListProviders = AdApprovalListComponent;
export default AdApprovalList;
