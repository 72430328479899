import React, { useContext } from "react";
import Headline from "../Headline";
import { UserContext } from "../../context/auth";
import { isEmpty } from "lodash";
import DisplayError from "../../DisplayError";
import EmbedIframePubPlanner from "./EmbedIframePubPlanner";
import CustomerInstanceWrap from "./CustomerInstanceWrap";

const PubPlannerReporting = (props) => {
  const { data, loading, errorMessage, isViewEnabled } =
    useContext(UserContext);

  return (
    <>
      <Headline>Reporting</Headline>
      {isViewEnabled && <CustomerInstanceWrap />}
      {!isEmpty(data) && !data.orgData.errors && !loading ? (
        <div className="embed--dashboard wrapper">
          <EmbedIframePubPlanner {...props} />
        </div>
      ) : (
        <DisplayError {...errorMessage} />
      )}
    </>
  );
};

export default PubPlannerReporting;
