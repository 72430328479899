/* Include validation functions which are used in all views */
/* Validations added in this file are - 
/* - lengthValidator
/* - beforeAfterSpaceValidation
/* - textRangeValidator
/* - uuidValidation
/* - isGreaterThanValidator
/* - regexValidator
/* - minMaxLimitValidator
/* - digitBeforeValidator
/* - dateValidator
/* - crossColumnExistValidator
/* - isFutureDate
/* - formatBooleanFields
/* - requiredWithValidator
/* - listFieldValidator
/* - checkValueExistInList
/* - countLengthValidator
*/

import { inRange, isEmpty, isNil, isNull, replace, without } from "lodash";
import moment from "moment";
import { splitCommaSeparatedStringToArray } from "../../../../../../utils";

import { dateCompare } from "../fieldValidation";
import getAvailableChoices from "../../../InventoryManagement/InventoryDetails/AdUnitMetaData/adunitFieldData";
import settings from "../../../../../../settings";

const validate = require("uuid-validate");

export const trimWhitespacesAndSetRecord = (record) => {
  Object.keys(record.data).forEach((key) => {
    if (
      typeof record.get(key) === "string" &&
      record.get(key) !== "" &&
      record.get(key).trim() === ""
    ) {
      record.set(key, record.get(key).trim());
      record.data[key] = record.get(key).trim();
    } else {
      record.set(key, record.get(key));
    }
  });
};

export const lengthValidator = (record, field, limit) => {
  if (!isEmpty(record.get(field))) {
    record.validate(
      field,
      (value) => value.length < limit,
      `Maximum length of ${limit} exceeded`
    );
  }
};

export const countLengthValidator = (record, field, limit) => {
  const value = record.get(field);
  if (!isEmpty(value) && replace(value, ".", "").length > limit) {
    record.validate(
      field,
      (value) => false,
      `Ensure that there are no more than ${limit} digits in total.`
    );
  }
};

export const beforeAfterSpaceValidation = (record, field) => {
  if (!isEmpty(record.get(field)))
    record.validate(
      field,
      (value) => new RegExp("^((?!^\\s+|\\s+$).)*$").test(value),
      "Space is not allowed before OR after the name"
    );
};

export const textRangeValidator = (record, field, minLength, maxlength) => {
  const value = record.get(field);
  if (value && value.length) {
    record.validate(
      field,
      (value) => !(value.length < minLength || value.length > maxlength),
      `Character length should be between ${minLength} and ${maxlength}`
    );
  }
};

// Validate UUID format
export const uuidValidation = (record, field) => {
  const uuid = record.get(field);
  if (!isEmpty(uuid)) {
    record.validate(field, (value) => validate(uuid), "Invalid UUID.");
  }
};

export const isGreaterThanValidator = (record, field, limit) => {
  const value = record.get(field);
  if (value && Number(value) > Number(limit)) {
    record.validate(
      field,
      (value) => false,
      `Ensure this value is less than or equal to ${limit}.`
    );
  }
};

export const regexValidator = (record, field, regex, errorText) => {
  if (!isEmpty(record.get(field)))
    record.validate(field, (value) => new RegExp(regex).test(value), errorText);
};

export const minMaxLimitValidator = (record, field, minLimit, maxLimit) => {
  if (!isEmpty(record.get(field))) {
    const errorMessage = `A value should be in between ${minLimit} and ${maxLimit}`;
    record.validate(
      field,
      (value) => inRange(Number(value), minLimit, maxLimit + 1),
      errorMessage
    );
  }
};

export const digitBeforeValidator = (record, field, limit) => {
  const value = record.get(field);
  if (!isEmpty(value) && replace(value.split(".")[0], "-", "").length > limit) {
    record.validate(
      field,
      (value) => false,
      `Ensure that there are no more than ${limit} digits before the decimal point.`
    );
  }
};

export const dateValidator = (record, field) => {
  record.validate(
    field,
    (value) => {
      let error = true;
      if (
        !isEmpty(value) &&
        !moment(value, ["M/D/YYYY", "M-D-YYYY"], true).isValid()
      ) {
        error = false;
      }
      return error;
    },
    "Please enter a valid date (supported formats : MM/DD/YYYY, M/D/YYYY, MM-DD-YYYY, M-D-YYYY)"
  );
};

export const crossColumnExistValidator = (
  record,
  field,
  targetValue,
  targetColName
) => {
  record.validate(
    field,
    (value) => !(value === targetValue && isEmpty(record.get(targetColName))),
    `${targetColName} is missing in uploaded file. It is required when ${field} is set to ${targetValue}.`
  );
};

export const isFutureDate = (inputDate) => {
  const dateToCompare = moment(inputDate);
  if (!isEmpty(inputDate)) {
    return !(moment().diff(dateToCompare) >= 0);
  }
};

/*
  Platform flatfile return null as default value for all the fields (string, boolean, date, number)
  This function will set false as default value for boolean field
*/
export const formatBooleanFields = (record, field) => {
  if (isNil(record.get(field))) record.set(field, false);
};

export const requiredWithValidator = (record, field, requiredWithFields) => {
  const excludeField = without(requiredWithFields, field);

  const checkIfNotEmpty = excludeField
    .map((element) => !isEmpty(record.get(element)))
    .filter((item) => item);

  if (isEmpty(record.get(field)) && checkIfNotEmpty.length) {
    record.validate(
      field,
      (value) => false,
      `This field is required when any of the following fields are filled: ${requiredWithFields}`
    );
  }
};

/* List field validations containing invalid string elements */
export const listFieldValidator = (record, field) => {
  if (!isEmpty(record.get(field))) {
    const fieldList = splitCommaSeparatedStringToArray(record.get(field));
    const filteredList = fieldList.filter((element) => {
      /* Swap left/right microsoft/html quotes with standard quotes */
      element = element.replace(/”|“/g, '"');
      element = element.replace(/’|‘/g, "'");

      let regex = new RegExp(/"(\s.+)"|""|'(\s.+)'|''/g);
      let filteredQuoteElements = element.match(regex);

      return (
        isEmpty(
          element.trim()
        ) /* Filter elements containing only Whitespaces */ ||
        !element /* Filter empty elements */ ||
        !isNull(
          filteredQuoteElements
        ) /* Filter elements containing only quotes OR whitespaces within quotes */ ||
        element.length > 100 /* max length is 100 char */
      );
    });
    record.validate(
      field,
      (value) => isEmpty(filteredList),
      "Please enter a valid data. Each string cannot be more than 100 characters"
    );
  }
};

/*
Validates the "Year 2038 problem" which arises from the use of 32-bit signed integers to represent time in computer systems.
These integers count seconds since the "epoch," which is typically January 1, 1970, at 00:00:00 UTC.
The maximum value for a signed 32-bit integer (2,147,483,647) corresponds to January 19, 2038, at 03:14:07 UTC.
All our timestamp fields (like adunit.start_date and placement.end_date) are defined as int32 in pxproto.
This leads to the Snowplow ref jobs failing with error "Failed to parse end_date field: Value out of range: 3187291800"
When trying to parse timestamps greater than int32.
The function allows dates until January 18, 2038, to prevent issues related to the Year 2038 problem.
Reference links:
- https://en.wikipedia.org/wiki/Year_2038_problem
*/
export const int32MaxDateValidation = (record, field) => {
  const dateValue = record.get(field);
  const errorMessage = "Cannot be greater than 18 January 2038";
  const int32MaxDate = settings.INT32_MAX_DATE.split("T")[0];

  if (
    !isEmpty(dateValue) &&
    dateCompare(
      moment(dateValue, ["M/D/YYYY", "M-D-YYYY"]),
      moment(int32MaxDate, "YYYY-M-D")
    )
  ) {
    record.validate(field, (value) => false, errorMessage);
  }
};

export const checkValueExistInList = (record, field) => {
  const availableOptions = getAvailableChoices(field);
  const validateFieldinList = (fieldList) => {
    for (const field of fieldList) {
      if (!availableOptions.includes(field.trim())) {
        return `${field}: Invalid ${field}, please enter a valid choice`;
      }
    }
  };
  if (!isEmpty(record.get(field))) {
    const errorText = validateFieldinList(
      splitCommaSeparatedStringToArray(record.get(field))
    );
    record.validate(field, (value) => isEmpty(errorText), errorText);
  }
};
