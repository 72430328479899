import React from "react";
import PropType from "prop-types";

import { OrderFormProvider } from "./OrderFormContext";
import FormOrder from "./FormOrder";

import "./style.css";

class FormAddOrder extends React.Component {
  render() {
    return (
      <OrderFormProvider {...this.props}>
        <FormOrder {...this.props} />
      </OrderFormProvider>
    );
  }
}

FormAddOrder.contextTypes = {
  formDefaultValues: PropType.any,
  isEditMode: PropType.any,
  orgId: PropType.any,
};

export default FormAddOrder;
