import React from "react";
import {
  MultiSelect,
  getFieldValue,
  setFieldValue,
} from "@px/px_design_system";
import { forIn, isEmpty, map, sortBy } from "lodash";

import { GetGeo } from "../../../../context/GetGeo/index.js";

export default class GeoForm extends React.Component {
  state = {
    region_value: [],
    zip_code_value: [],
    dma_code_value: [],
    region_options: [],
    dma_code_options: [],
    zip_code_options: [],
    country_value: this.props?.countryList,
    loading: true,
  };

  componentDidUpdate(prevProps) {
    const { geoFormSize } = this.props;
    if (prevProps.geoFormSize !== geoFormSize) {
      this.props?.disableButton(this.checkDisableButton());
    }
  }

  componentDidMount = async () => {
    this.setState({
      loading: true,
    });
    const { region, zip, dma_code } = this.props?.value;
    const geoData = await GetGeo(
      `${getFieldValue(`${this.props?.name}.country`)?.value}`
    );

    // Set options in case of edit as country will be already selected
    this.setState(
      {
        region_options: this.getOptions(this.props?.name, "region", geoData),
        dma_code_options: this.getOptions(
          this.props?.name,
          "dma_code",
          geoData
        ),
        zip_code_options: this.getOptions(this.props?.name, "zip", geoData),
        region_value: region,
        zip_code_value: zip,
        dma_code_value: dma_code,
        loading: false,
      },
      () => {
        // Set values in case of edit
        setFieldValue(`${this.props?.name}.region`, region);
        setFieldValue(`${this.props?.name}.zip`, zip);
        setFieldValue(`${this.props?.name}.dma_code`, dma_code);
      }
    );
    this.props?.disableButton(this.checkDisableButton());
  };

  setOthers = (name, item) => {
    this.setState({ [name]: item });
  };

  setGeoData(countries, geo_field, geoData) {
    if (!isEmpty(countries)) {
      let zip = [];
      let regionList = [];
      let dma = [];
      map(geoData, (item) => {
        if (item.country === countries.value) {
          switch (geo_field) {
            case "dma_code":
              forIn(item.dma, (value, key) => {
                dma.push({ label: key, value: value });
              });
              dma = sortBy(dma, "label");
              break;
            case "zip":
              map(item.zip, (zip_value) => {
                zip.push({ label: zip_value, value: zip_value });
              });
              zip = sortBy(zip, "label");
              break;
            case "region":
              map(item.region, (region) => {
                regionList.push({ label: region, value: region });
              });
              regionList = sortBy(regionList, "label");
              break;
            default:
              break;
          }
        }
      });

      if (geo_field === "dma_code") {
        return dma;
      } else if (geo_field === "zip") {
        return zip;
      } else if (geo_field === "region") {
        return regionList;
      }
    }
  }

  checkDisableButton = () => {
    const { geoFormSize } = this.props;
    for (let i = 0; i < geoFormSize; i++) {
      if (isEmpty(getFieldValue(`targeting.${i}.geo.country`)?.value)) {
        return true;
      }
    }
    return false;
  };

  clearOthers = async (name) => {
    this.props?.disableButton(this.checkDisableButton());
    this.setState({
      loading: true,
    });
    const geoData = await GetGeo(`${getFieldValue(`${name}.country`)?.value}`);
    this.setState({
      region_value: [],
      zip_code_value: [],
      dma_code_value: [],
      region_options: this.getOptions(this.props?.name, "region", geoData),
      dma_code_options: this.getOptions(this.props?.name, "dma_code", geoData),
      zip_code_options: this.getOptions(this.props?.name, "zip", geoData),
      loading: false,
    });
  };

  getOptions = (name, item, geoData) => {
    if (getFieldValue) {
      const options = this.setGeoData(
        getFieldValue(`${name}.country`),
        item,
        geoData
      );
      return options;
    }
  };

  render() {
    const {
      loading,
      region_value,
      zip_code_value,
      dma_code_value,
      country_value,
      region_options,
      dma_code_options,
      zip_code_options,
    } = this.state;
    const copyPastWarningMsg =
      "Values don’t apply to inventory currently registered in Place Exchange";

    return (
      <div
        className={`geo__form ${loading ? "loading" : ""} ${(this.props?.name)
          .split(".")
          .join("-")} ${this.props?.geoFormSize === 1 ? "hide--remove" : ""}`}
      >
        <MultiSelect
          label="Country"
          subTitle={`${
            this.props?.name === "targeting.0.geo"
              ? "Target requests from any country (e.g. United States)"
              : "Select another country to be included. If left blank this will be ignored."
          }`}
          placeholder={`${
            this.props?.geoFormSize === 1 ? "All countries" : "Select"
          }`}
          isSearchable={true}
          options={map(country_value, (item) => {
            return { label: item, value: item };
          })}
          isValidNewOption={() => false}
          getValue={this.clearOthers.bind(this, this.props?.name)}
          key={1}
          errors={this.props?.errors}
          getValues={this.props?.getValues}
          history={this.props?.history}
          location={this.props?.location}
          match={this.props?.match}
          name={`${this.props?.name}.country`}
          onBlur={this.props?.onBlur}
          onChange={this.props?.onChange}
          setValue={this.props?.setValue}
          value={this.props?.value?.country}
          isClearable={this.props?.geoFormSize === 1}
        />
        <div
          className={`geo__form--others ${
            !isEmpty(getFieldValue(`${this.props?.name}.country`))
              ? "enable"
              : ""
          }`}
        >
          <MultiSelect
            label="Region/State"
            subTitle="Target requests from any state(s) (e.g. New York)"
            placeholder="All regions or states (or select options)"
            isMulti={true}
            isSearchable={true}
            closeMenuOnSelect={false}
            options={region_options}
            isClearable={true}
            isValidNewOption={() => false}
            name={`${this.props?.name}.region`}
            key={2}
            errors={this.props?.errors}
            getValues={this.props?.getValues}
            history={this.props?.history}
            location={this.props?.location}
            match={this.props?.match}
            onBlur={this.props?.onBlur}
            onChange={this.props?.onChange}
            setValue={this.props?.setValue}
            value={region_value}
            getValue={this.setOthers.bind(this, "region_value")}
            copyPastWarningMsg={copyPastWarningMsg}
          />
          <p className="logical_condition">OR</p>
          <MultiSelect
            label="Metro/DMA"
            subTitle="Target requests from any DMA(s) (e.g. 538, Rochester NY)"
            placeholder="All metros or DMAs (or select options)"
            isMulti={true}
            copyPastWarningMsg={copyPastWarningMsg}
            isSearchable={true}
            closeMenuOnSelect={false}
            options={dma_code_options}
            isClearable={true}
            isValidNewOption={() => false}
            name={`${this.props?.name}.dma_code`}
            key={3}
            errors={this.props?.errors}
            getValues={this.props?.getValues}
            history={this.props?.history}
            location={this.props?.location}
            match={this.props?.match}
            onBlur={this.props?.onBlur}
            onChange={this.props?.onChange}
            setValue={this.props?.setValue}
            value={dma_code_value}
            getValue={this.setOthers.bind(this, "dma_code_value")}
          />
          <p className="logical_condition">OR</p>
          <MultiSelect
            label="Postal/Zip Code"
            subTitle="Target requests from any postal code(s) (e.g. 10001)"
            placeholder="All postal or zip codes (or select options)"
            isMulti={true}
            isSearchable={true}
            closeMenuOnSelect={false}
            options={zip_code_options}
            isClearable={true}
            isValidNewOption={() => false}
            name={`${this.props?.name}.zip`}
            key={4}
            copyPastWarningMsg={copyPastWarningMsg}
            errors={this.props?.errors}
            getValues={this.props?.getValues}
            history={this.props?.history}
            location={this.props?.location}
            match={this.props?.match}
            onBlur={this.props?.onBlur}
            onChange={this.props?.onChange}
            setValue={this.props?.setValue}
            value={zip_code_value}
            getValue={this.setOthers.bind(this, "zip_code_value")}
          />
        </div>
      </div>
    );
  }
}
