import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { get, includes, indexOf, isEmpty } from "lodash";
import { Spinner } from "@px/px_design_system/lib/components/Spinner";
import {
  constructAdminAPIEndPoint,
  fetchHandler,
  getErrorMessage,
} from "../../../../utils";
import { UserContext } from "../../context/auth";

import "./style.css";

const getEmbedURL =
  (
    setIframeLoaded,
    setEmbedUrl,
    userData,
    orgData,
    isInvalidSuperUser,
    props
  ) =>
  (id) => {
    const isDirectSoldPublisher = get(
      orgData,
      "ext.direct_sold_enabled",
      false
    );

    /* istanbul ignore next */
    if (isDirectSoldPublisher && id === "revenue_summary") {
      id = "revenue_summary_direct_sold";
    }

    setIframeLoaded(false);
    setEmbedUrl("");
    /* Service user creation per organization */
    let emailId = `px-portal@${orgData.id}.com`;
    let firstName = orgData.name;
    let lastName = "Embed";
    let dashboard_url = `/embed/dashboards-next/pub_views::${id}?embed_domain=${window.location.origin}&Currency=${orgData.config.allowed_currency}`;
    const errorMessage = getErrorMessage("delivery overview");

    /* Internal staff members user creation based on user name */
    if (userData[0].email.endsWith("placeexchange.com")) {
      emailId = userData[0].email;
      firstName = userData[0].name.split(".")[0];
      lastName = userData[0].name.split(".")[1];
    }

    const url = "looker/get_embed_url";

    const superusersPerms = [
      "see_looks",
      "see_user_dashboards",
      "explore",
      "create_table_calculations",
      "create_custom_fields",
      "can_create_forecast",
      "save_content",
      "send_outgoing_webhook",
      "send_to_s3",
      "send_to_sftp",
      "schedule_look_emails",
      "schedule_external_look_emails",
      "send_to_integration",
      "create_alerts",
      "download_with_limit",
      "see_sql",
      "embed_browse_spaces",
      "embed_save_shared_space",
    ];

    let permissions = [
      "access_data",
      "clear_cache_refresh",
      "see_lookml_dashboards",
      "download_without_limit",
      "see_drill_overlay",
    ];

    if (isInvalidSuperUser) {
      permissions = [...permissions, ...superusersPerms];
    }

    const apiParams = {
      method: "POST",
      url: constructAdminAPIEndPoint({
        url,
      }),
      headers: {
        "cache-control": "no-cache, no-store",
      },
      body: {
        user_id: emailId,
        first_name: firstName,
        last_name: lastName,
        dashboard_url: dashboard_url,
        org_id: orgData.id,
        permissions: permissions,
        looker_models: ["pub_views"],
        group_ids: [],
        user_attributes: props.placement_id
          ? {
              email: emailId,
              delivery_overview_placement_id: props.placement_id,
            }
          : { email: emailId },
      },
      errorMessage: errorMessage,
    };

    return fetchHandler(apiParams)
      .then((res) => {
        if (res) {
          setEmbedUrl(res.url);
        }
      })
      .catch((error) => {
        if (!error.data) {
          return Promise.reject(error);
        }
        return Promise.reject(errorMessage);
      });
  };

const EmbedIframe = (props) => {
  const { location, history } = props;
  const dashboardIds = [
    "revenue_summary",
    "revenue_daily",
    "revenue_breakout",
    "health_breakout",
    "deal_monitoring",
    "campaign_troubleshooting",
    "placement_delivery",
  ];
  const { data, loading, isInvalidSuperUser } = useContext(UserContext);
  const { orgData, userData } = data;
  const [embedUrl, setEmbedUrl] = useState("");
  const [activeTab, setAciveTab] = useState();
  const [isIframeLoaded, setIframeLoaded] = useState(false);
  const getDashboard = getEmbedURL(
    setIframeLoaded,
    setEmbedUrl,
    userData,
    orgData,
    isInvalidSuperUser,
    props
  );
  const spinnerProps = {
    className: "spinner-icon",
    color: "primary",
    loadingtext: "Loading",
  };

  useEffect(() => {
    if (!isEmpty(orgData) && !loading) {
      const { params } = props.match;
      let prepareID = "revenue_summary";
      if (
        props.reportId ||
        (params.reportingType &&
          params.reportingSubType &&
          includes(
            dashboardIds,
            `${params.reportingType}_${params.reportingSubType}`
          ))
      ) {
        prepareID =
          props.reportId ||
          `${params.reportingType}_${params.reportingSubType}`;
        setAciveTab(indexOf(dashboardIds, prepareID));
        getDashboard(prepareID).catch(() => {});
      } else {
        history.push({
          pathname: `/orgs/${orgData.id}/reporting/revenue/summary`,
        });
      }
    }
  }, [loading, location.pathname]);
  return (
    <div className="embed--reporting__wrapper">
      {!isIframeLoaded && (
        <Spinner {...spinnerProps} spinnerposition="container" />
      )}
      {!isEmpty(embedUrl) && (
        <iframe
          id="embed--reporting"
          className={`${dashboardIds[+activeTab]}--reporting ${
            !isIframeLoaded && "hide"
          }`}
          src={`${embedUrl}`}
          title="reporting"
          onLoad={() => setIframeLoaded(true)}
        />
      )}
    </div>
  );
};

EmbedIframe.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportingType: PropTypes.string,
      reportingSubType: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default EmbedIframe;
