import React from "react";
import PropTypes from "prop-types";

const PrepareSvg = ({ iconComponent, viewBoxDimension = "0 0 30 30" }) => {
  return (
    <svg
      preserveAspectRatio="xMinYMin meet"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`${viewBoxDimension}`}
    >
      {iconComponent}
    </svg>
  );
};

PrepareSvg.propTypes = {
  dimension: PropTypes.string,
  iconComponent: PropTypes.shape(),
  viewBoxDimension: PropTypes.string,
};

export default PrepareSvg;
