import React, { useState } from "react";
import { isEmpty } from "lodash";

import "./style.css";

const DisplayError = (errorMessage = {}) => {
  const {
    contactMessage = "",
    displayIcon = {},
    displaySvg = {},
    mailId = "",
    subTitle = "",
    title = "",
    typeOfError = "",
    customStyle = "",
  } = errorMessage;

  const [isImageLoaded, setImage] = useState(false);
  return (
    <div className={`display-error ${typeOfError} ${customStyle}`}>
      {!isEmpty(displayIcon) && (
        <img
          className="display-error__image"
          src={displayIcon.src}
          alt={displayIcon.alt}
          onLoad={() => setImage(!isImageLoaded)}
          onError={() => setImage(!isImageLoaded)}
        />
      )}
      {!isEmpty(displaySvg) && (
        <div className="display-error__svg">{displaySvg}</div>
      )}
      <>
        {title !== "" && (
          <p className="display-error__title display-error--no-margin">
            {title}
          </p>
        )}
        {subTitle !== "" && (
          <p className="display-error__subTitle">{subTitle}</p>
        )}
        {mailId !== "" && (
          <p className="display-error__contact-support">
            Contact
            <a href={`mailto:${mailId}`} target="_top">
              {` ${mailId} `}
            </a>
            {`${contactMessage}`}
          </p>
        )}
      </>
    </div>
  );
};

export default DisplayError;
