import React, { Component } from "react";
import { isEmpty } from "lodash";
import { Table } from "@px/px_design_system";
import queryString from "query-string";
import PropTypes from "prop-types";
import { fixedEncodeURIComponent } from "../../../../../utils";

class TableView extends Component {
  constructor(props) {
    super(props);
    const { history } = props;
    this.handleTableChange = this.handleTableChange.bind(this);
    const values = queryString.parse(history.location.search);
    this.searchValue = values.search || "";
    this.page = values.page || 1;
    this.ordering = values.ordering || "";
  }

  handleTableChange(response, updatedPage) {
    const { rowDetails, prepareList, updateList } = this.props;
    prepareList({
      ...rowDetails,
      [updateList]: response,
    });
  }

  render() {
    const {
      afterSearch,
      history,
      isUploader,
      fileUpload = () => {},
      defaultSorted,
    } = this.props;

    const onTableSearch = (searchValueParam) => {
      this.searchValue = searchValueParam;
    };

    const onTableSort = (sortField, sortOrder) => {
      const ordering = sortOrder === "desc" ? `-${sortField}` : sortField;
      this.ordering = ordering;
    };

    const afterOnSearch = /* istanbul ignore next */ () => {
      // afterSearch is used to append audit status of adapproval before search query param.
      if (afterSearch) {
        afterSearch(this.searchValue);
      } else {
        setTimeout(() => {
          history.push({
            search: `${
              !isEmpty(this.searchValue)
                ? `?search=${fixedEncodeURIComponent(this.searchValue)}`
                : ""
            }`,
          });
        }, 250);
      }
    };

    return (
      <div className={`table__wrapper ${isUploader ? "with--uploader" : ""}`}>
        <Table
          afterOnSearch={afterOnSearch}
          condensed
          {...this.props}
          defaultSearchValue={this.searchValue}
          delay={1000}
          fileUpload={fileUpload()}
          handleTableChange={this.handleTableChange}
          isSearch
          onTableSort={onTableSort}
          onTableSearch={onTableSearch}
          perPage={false}
          remote={true}
          defaultSorted={defaultSorted}
        />
      </div>
    );
  }
}

TableView.propTypes = {
  fileUpload: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  isUploader: PropTypes.bool,
  prepareList: PropTypes.func,
  rowDetails: PropTypes.shape(),
  updateList: PropTypes.string,
  activeTab: PropTypes.string,
  defaultSorted: PropTypes.array,
};

export default TableView;
