import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import {
  constructAdminAPIEndPoint,
  fetchHandler,
  getUTCDateTime,
  getErrorMessage,
  cursorPaginationApi,
} from "../../../../utils";
import { UserContext } from "../auth";
import { get } from "lodash";
import { GetCount } from "../GetCount";
import PrepareSvg from "../../svgs/PrepareSvg";
import DynamicCreativeIcon from "../../svgs/DynamicCreativeIcon";
import settings from "../../../../settings";
import ErrorMessages from "../../DisplayError/ErrorMessages";

import "./style.css";

const AdApprovalListContext = React.createContext();

let statusArray = [1];
let tabName = "";
const AdApprovalListProvider = (props) => {
  const { organization } = useContext(UserContext);
  const { children, location, history } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalAdApprovals, setTotalAdApprovals] = useState(0);
  const [categoryData, setCategoryData] = useState([]);
  const [networkList, setNetworkList] = useState([]);
  const controllerRef = useRef(null);
  const [errorMessageNoCat, setErrorMessage] = useState([]);
  const queryParamVal = queryString.parse(location.search);
  statusArray = queryParamVal.audit__status || [1];

  const getSearchParam = () => {
    let values = queryString.parse(location.search);
    const auditStatuses = ["1", "2", "3", "2,3", "4", "all"];
    if (
      values.audit__status &&
      auditStatuses.indexOf(values.audit__status) > -1
    ) {
      if (
        values.audit__status === "2,3" ||
        values.audit__status === "2" ||
        values.audit__status === "3"
      ) {
        statusArray = [2, 3];
        tabName = "Approved";
        values.audit__status = "2,3";
        history.push({
          search: queryString.stringify(values),
        });
      } else if (values.audit__status === "4") {
        statusArray = [4];
        tabName = "Rejected";
      } else if (values.audit__status === "all") {
        statusArray = ["all"];
        tabName = "All";
      } else if (values.audit__status === "1") {
        statusArray = [1];
        tabName = "Pending Review";
      }
    } else {
      history.push("?audit__status=1");
      statusArray = [1];
    }
  };

  /* istanbul ignore next */
  const creativeIcon = (cell, row) => {
    let adCreativeKind = cell || "-";
    if (
      row.original.creative.type === "display" &&
      row.original.ad.creative.display.subtype === 14
    ) {
      adCreativeKind = (
        <div className="dynamic-creative-icon">
          {cell}
          <PrepareSvg iconComponent={DynamicCreativeIcon} />
        </div>
      );
    }
    return adCreativeKind;
  };

  /* istanbul ignore next */
  const csvColumnFormation = (item, index) => {
    return {
      serial: index,
      ad__name: item.ad.name || "-",
      ad__organization__name: item.ad.ext.owned_by_name || "-",
      ad__adomain: item.ad.adomain.join(", ") || "-",
      ad__creative__kind: item.ad.creative.ext.type || "-",
      ad__lang_display: item.ad.lang_display || "-",
      id: item.id || "-",
      ts: getUTCDateTime(item.ts) || "-",
      adapprovalaudit__lastmod: get(item.audit, "lastmod")
        ? getUTCDateTime(get(item.audit, "lastmod"))
        : "-",
    };
  };

  const getAdApprovalCount = () => {
    getSearchParam();
    const apiRequestParams = [
      { key: "page", value: 1 },
      { key: "page_size", value: 1 },
      {
        key: "status",
        value: statusArray.toString() !== "all" ? statusArray.toString() : "",
      },
    ];
    GetCount("adapprovals", organization.id, apiRequestParams)
      .then((response) => {
        setTotalAdApprovals(get(response, "adapprovals"));
        setIsLoaded(false);
      })
      .catch((error) => {
        setTotalAdApprovals(0);
        setIsLoaded(false);
      });
  };

  /* istanbul ignore next */
  const fetchCsvData = (urlParams = [], setCount = false, setSize = false) => {
    const signal = controllerRef.current?.signal;
    urlParams = [
      ...urlParams,
      {
        key: "status",
        value: statusArray.toString() !== "all" ? statusArray.toString() : "",
      },
    ];
    const apiParams = {
      url: constructAdminAPIEndPoint({
        url: `orgs/${organization.id}/adapprovals`,
        searchParams: urlParams,
      }),
      getResponseHeaders: true,
      headers: {
        "cache-control": "no-cache, no-store",
      },
      signal,
      errorMessage: getErrorMessage("ad approvals"),
    };
    return fetchHandler(apiParams)
      .then((response) => {
        return {
          data: response.json,
          headers: response.headers,
          last: 0,
        };
      })
      .catch((error) => {
        return Promise.reject(getErrorMessage("ad approvals"));
      });
  };

  const errorMessageCategory = getErrorMessage("category");
  const fetchCategoryData = () =>
    fetchHandler({
      url: constructAdminAPIEndPoint({
        url: `datasets/${settings.DATASETS_ID_LOOKUP.CATEGORY_ID}`,
      }),
      errorMessage: errorMessageCategory,
    })
      .then((data) => {
        if (data.members && data.members.length) setCategoryData(data.members);
        else throw ErrorMessages.NO_CATEGORY_FOUND;
      })
      .catch((error) => {
        setErrorMessage(ErrorMessages.NO_CATEGORY_FOUND);
        if (!error.data) {
          setErrorMessage(ErrorMessages.API_FAILED);
        }
      });

  const fetchAllNetworks = async () => {
    let results = [];
    const headers = {
      "cache-control": "no-cache, no-store",
    };
    const searchParams = [{ key: "fields", value: "name" }];
    results = await cursorPaginationApi(
      organization.id,
      "networks",
      headers,
      searchParams
    );
    if (results[0] && results[0].hasOwnProperty("error")) {
      setErrorMessage(ErrorMessages.API_FAILED);
    }
    setNetworkList(results);
  };

  useEffect(() => {
    setIsLoaded(true);
    getAdApprovalCount();
    fetchCategoryData();
    fetchAllNetworks();
    return () => controllerRef.current?.abort();
  }, [queryParamVal.audit__status]);

  const AdApprovalListProvider = {
    isLoaded,
    statusArray,
    fetchCsvData,
    categoryData,
    networkList,
    csvColumnFormation,
    totalAdApprovals,
    tabName,
    creativeIcon,
    errorMessageNoCat,
  };

  return (
    <AdApprovalListContext.Provider value={{ ...AdApprovalListProvider }}>
      {children}
    </AdApprovalListContext.Provider>
  );
};
const AdApprovalListConsumer = AdApprovalListContext.Consumer;
AdApprovalListProvider.propTypes = {
  children: PropTypes.shape(),
  location: PropTypes.shape(),
  history: PropTypes.shape(),
};
export {
  AdApprovalListProvider,
  AdApprovalListConsumer,
  AdApprovalListContext,
};
