import React, { useContext } from "react";
import { isEmpty } from "lodash";

import {
  MeasurementContext,
  MeasurementProvider,
} from "../../context/Measurement";
import { UserContext } from "../../context/auth";
import DisplayError from "../../DisplayError";
import MeasurementDetails from "./MeasurementDetails";
import Headline from "../Headline";

const MeasurementComp = (props) => {
  const measurement = useContext(MeasurementContext);
  const { data, errorMessage, loading } = useContext(UserContext);
  const { states } = measurement;

  const isSuperUser = !isEmpty(data) && data.userData[1] === 204;

  return (
    <>
      <Headline>Measurement</Headline>
      {!isEmpty(data) && !data.orgData.errors && !loading ? (
        <MeasurementDetails
          {...states}
          {...props}
          isSuperUser={isSuperUser}
          {...data}
        />
      ) : (
        <DisplayError {...errorMessage} />
      )}
    </>
  );
};

const Measurement = (props) => (
  <MeasurementProvider {...props}>
    <MeasurementComp {...props} />
  </MeasurementProvider>
);

export const MeasurementComponent = MeasurementComp;
export default Measurement;
