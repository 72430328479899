/* Include validation functions which are used in only Placements view */
/* Validations added in this file are - 
/* - geoFieldsValidator
/* - venueCategoryValidator
*/
import {
  validateGeoData,
  validateVenueOpenOOHCategory,
} from "../../fieldValidation";

export const geoFieldsValidator = (record, field, geoCountryData) => {
  const error = validateGeoData(record.get(field), field, geoCountryData);
  if (error?.length) {
    record.validate(field, (value) => false, error[0].message);
  }
};

export const venueCategoryValidator = (record, field) => {
  const error = validateVenueOpenOOHCategory(record.get(field));
  if (error?.length) {
    record.validate(field, (value) => false, error[0].message);
  }
};
