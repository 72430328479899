import React from "react";
import { Link } from "react-router-dom";

/* Common heading for publisher reports and pub planner reports */
const CustomerInstanceWrap = () => {
  return (
    <div className="embed--dashboard wrapper">
      <div className="row embed--customer__instance wrapper">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 customer__instance--wrap">
          <p className="customer__instance--text">
            In addition to the curated reports provided in the portal,
            additional data is available for exploration and customization at:
            <Link to={`//${"analytics.placeexchange.com/"}`} target="_blank">
              <span> analytics.placeexchange.com</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
export default CustomerInstanceWrap;
