const REVENUE_REPORT_LOOKER_VIEW = "pub_payment_report";
const HELP_CENTER_BASE_URL =
  window.REACT_APP_IS_PROD_ENABLED === "False"
    ? "//placeexchange--devfull.sandbox.my.site.com/s/"
    : "//help.placeexchange.com/s/";
const HELP_ARTICLES = `${HELP_CENTER_BASE_URL}article/`;
const HELP_COLLECTIONS = `${HELP_CENTER_BASE_URL}collections/`;

export const PERSONAS = {
  BUYER: "Buyer",
  PUBLISHER: "Publisher",
  PUB_PLANNER: "Pub Planner",
};

export const PERSONA_HOME_ROUTE_MAP = {
  [PERSONAS.BUYER]: "/orgs/:orgId/resources/summary",
  [PERSONAS.PUBLISHER]: "/orgs/:orgId/reporting/revenue/summary",
  [PERSONAS.PUB_PLANNER]: "/orgs/:orgId/reporting/campaign/activation_status",
};

export const NO_USER_ORG_ROUTE_PARAM = "no-user-org";

const GlobalConstants = () => ({
  MODEL: "px",
  MONTHS: [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ],
  REVENUE_REPORT_LOOKER_PARAM: "pub_payment_report",
  REVENUE_REPORT_LOOKER_PARAMETERS: {
    DATE: `${REVENUE_REPORT_LOOKER_VIEW}.play_ts_date`,
    CURRENCY: `${REVENUE_REPORT_LOOKER_VIEW}.currency`,
    PUB_CURRENCY: `${REVENUE_REPORT_LOOKER_VIEW}.pub_currency`,
    ORG: `${REVENUE_REPORT_LOOKER_VIEW}.org_name`,
    NETWORK: `${REVENUE_REPORT_LOOKER_VIEW}.network_name`,
    BUYER: `${REVENUE_REPORT_LOOKER_VIEW}.buyer_name`,
    TECH_FEE: `${REVENUE_REPORT_LOOKER_VIEW}.tech_fee_amount`,
    PUB_TECH_FEE: `${REVENUE_REPORT_LOOKER_VIEW}.pub_tech_fee_amount`,
    DEAL: `${REVENUE_REPORT_LOOKER_VIEW}.deal_token`,
    IMPRESSIONS: `${REVENUE_REPORT_LOOKER_VIEW}.impressions`,
    NET_ECPM: `${REVENUE_REPORT_LOOKER_VIEW}.net_ecpm`,
    GROSS_ECPM: `${REVENUE_REPORT_LOOKER_VIEW}.gross_ecpm`,
    NET_REVENUE: `${REVENUE_REPORT_LOOKER_VIEW}.net_seller_revenue`,
    GROSS_REVENUE: `${REVENUE_REPORT_LOOKER_VIEW}.gross_seller_revenue`,
    PUB_NET_ECPM: `${REVENUE_REPORT_LOOKER_VIEW}.pub_net_ecpm`,
    PUB_GROSS_ECPM: `${REVENUE_REPORT_LOOKER_VIEW}.pub_gross_ecpm`,
    PUB_NET_REVENUE: `${REVENUE_REPORT_LOOKER_VIEW}.pub_net_seller_revenue`,
    PUB_GROSS_REVENUE: `${REVENUE_REPORT_LOOKER_VIEW}.pub_gross_seller_revenue`,
    LAST_RUN_AT: `${REVENUE_REPORT_LOOKER_VIEW}.last_run_at`,
  },
  HELP_CENTER: {
    reporting: `${HELP_ARTICLES}Reporting-for-Publishers`,
    reporting_for_pub_sales: `${HELP_ARTICLES}Reporting-for-pub-sales`,
    adapprovals: `${HELP_ARTICLES}Approving-Creatives-through-the-Publisher-Portal`,
    inventory: `${HELP_ARTICLES}Inventory-Management-FAQs-and-Tips`,
    deals: `${HELP_ARTICLES}Deal-Management`,
    orders: `${HELP_ARTICLES}Placements`,
    resources: `${HELP_ARTICLES}Bidding-Flow`,
    news: `${HELP_ARTICLES}Bidding-Flow`,
    measurement: `${HELP_ARTICLES}Measurement-Portal`,
  },
});

export default GlobalConstants();
