import React from "react";
import PropTypes from "prop-types";
import { Card } from "@px/px_design_system";

import "./style.css";

export default class CardList extends React.PureComponent {
  render() {
    const { listOfCard } = this.props;
    return (
      <div className="details__cardblock">
        {listOfCard.map((item) => (
          <div key={item.cardTitle} className="details__single-card">
            <Card {...item} className="details__card-border" />
          </div>
        ))}
      </div>
    );
  }
}

CardList.propTypes = {
  listOfCard: PropTypes.shape(),
};
