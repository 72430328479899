import { constructAdminAPIEndPoint, fetchHandler } from "../../../../utils";

export const GetGeo = (country) => {
  const args = () => ({
    headers: {
      "cache-control": "no-cache, no-store",
    },
    getResponseHeaders: true,
    url: constructAdminAPIEndPoint({
      url: `portal/geos/countries/${country}`,
    }),
  });
  return fetchHandler(args()).then((response) => {
    if (response.json) return response.json;
  });
};
