let settings = {
  AD_LANG_LOOKUP: {
    aa: "Afar",
    ab: "Abkhazian",
    af: "Afrikaans",
    ak: "Akan",
    sq: "Albanian",
    am: "Amharic",
    ar: "Arabic",
    an: "Aragonese",
    hy: "Armenian",
    as: "Assamese",
    av: "Avaric",
    ae: "Avestan",
    ay: "Aymara",
    az: "Azerbaijani",
    ba: "Bashkir",
    bm: "Bambara",
    eu: "Basque",
    be: "Belarusian",
    bn: "Bengali",
    bh: "Bihari languages",
    bi: "Bislama",
    bo: "Tibetan",
    bs: "Bosnian",
    br: "Breton",
    bg: "Bulgarian",
    my: "Burmese",
    ca: "Catalan; Valencian",
    cs: "Czech",
    ch: "Chamorro",
    ce: "Chechen",
    zh: "Chinese",
    cu: "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
    cv: "Chuvash",
    kw: "Cornish",
    co: "Corsican",
    cr: "Cree",
    cy: "Welsh",
    da: "Danish",
    de: "German",
    dv: "Divehi; Dhivehi; Maldivian",
    nl: "Dutch; Flemish",
    dz: "Dzongkha",
    el: "Greek, Modern (1453-)",
    en: "English",
    eo: "Esperanto",
    et: "Estonian",
    ee: "Ewe",
    fo: "Faroese",
    fa: "Persian",
    fj: "Fijian",
    fi: "Finnish",
    fr: "French",
    fy: "Western Frisian",
    ff: "Fulah",
    ka: "Georgian",
    gd: "Gaelic; Scottish Gaelic",
    ga: "Irish",
    gl: "Galician",
    gv: "Manx",
    gn: "Guarani",
    gu: "Gujarati",
    ht: "Haitian; Haitian Creole",
    ha: "Hausa",
    he: "Hebrew",
    hz: "Herero",
    hi: "Hindi",
    ho: "Hiri Motu",
    hr: "Croatian",
    hu: "Hungarian",
    ig: "Igbo",
    is: "Icelandic",
    io: "Ido",
    ii: "Sichuan Yi; Nuosu",
    iu: "Inuktitut",
    ie: "Interlingue; Occidental",
    ia: "Interlingua (International Auxiliary Language Association)",
    id: "Indonesian",
    ik: "Inupiaq",
    it: "Italian",
    jv: "Javanese",
    ja: "Japanese",
    kl: "Kalaallisut; Greenlandic",
    kn: "Kannada",
    ks: "Kashmiri",
    kr: "Kanuri",
    kk: "Kazakh",
    km: "Central Khmer",
    ki: "Kikuyu; Gikuyu",
    rw: "Kinyarwanda",
    ky: "Kirghiz; Kyrgyz",
    kv: "Komi",
    kg: "Kongo",
    ko: "Korean",
    kj: "Kuanyama; Kwanyama",
    ku: "Kurdish",
    lo: "Lao",
    la: "Latin",
    lv: "Latvian",
    li: "Limburgan; Limburger; Limburgish",
    ln: "Lingala",
    lt: "Lithuanian",
    lb: "Luxembourgish; Letzeburgesch",
    lu: "Luba-Katanga",
    lg: "Ganda",
    mk: "Macedonian",
    mh: "Marshallese",
    ml: "Malayalam",
    mi: "Maori",
    mr: "Marathi",
    ms: "Malay",
    mg: "Malagasy",
    mt: "Maltese",
    mn: "Mongolian",
    na: "Nauru",
    nv: "Navajo; Navaho",
    nr: "Ndebele, South; South Ndebele",
    nd: "Ndebele, North; North Ndebele",
    ng: "Ndonga",
    ne: "Nepali",
    nn: "Norwegian Nynorsk; Nynorsk, Norwegian",
    nb: "Bokmål, Norwegian; Norwegian Bokmål",
    no: "Norwegian",
    ny: "Chichewa; Chewa; Nyanja",
    oc: "Occitan post (1500)",
    oj: "Ojibwa",
    or: "Oriya",
    om: "Oromo",
    os: "Ossetian; Ossetic",
    pa: "Panjabi; Punjabi",
    pi: "Pali",
    pl: "Polish",
    pt: "Portuguese",
    ps: "Pushto; Pashto",
    qu: "Quechua",
    rm: "Romansh",
    ro: "Romanian; Moldavian; Moldovan",
    rn: "Rundi",
    ru: "Russian",
    sg: "Sango",
    sa: "Sanskrit",
    si: "Sinhala; Sinhalese",
    sk: "Slovak",
    sl: "Slovenian",
    se: "Northern Sami",
    sm: "Samoan",
    sn: "Shona",
    sd: "Sindhi",
    so: "Somali",
    st: "Sotho, Southern",
    es: "Spanish; Castilian",
    sc: "Sardinian",
    sr: "Serbian",
    ss: "Swati",
    su: "Sundanese",
    sw: "Swahili",
    sv: "Swedish",
    ty: "Tahitian",
    ta: "Tamil",
    tt: "Tatar",
    te: "Telugu",
    tg: "Tajik",
    tl: "Tagalog",
    th: "Thai",
    ti: "Tigrinya",
    to: "Tonga (Tonga Islands)",
    tn: "Tswana",
    ts: "Tsonga",
    tk: "Turkmen",
    tr: "Turkish",
    tw: "Twi",
    ug: "Uighur; Uyghur",
    uk: "Ukrainian",
    ur: "Urdu",
    uz: "Uzbek",
    ve: "Venda",
    vi: "Vietnamese",
    vo: "Volapük",
    wa: "Walloon",
    wo: "Wolof",
    xh: "Xhosa",
    yi: "Yiddish",
    yo: "Yoruba",
    za: "Zhuang; Chuang",
    zu: "Zulu",
  },
  AD_APPROVAL_LOADER_TIME: 1500,
  FLATFILE_LICENSE_KEY: "75951d38-d0d0-4b2e-b68d-435fbd051c60",
  DATASETS_ID_LOOKUP: {
    AUCTION_TYPE_ID: "0c8c1e58-30f3-464b-9da2-3c1868858ff9",
    ADUNIT_INTEGRATION_TYPE_ID: "40f736ab-6652-4d9a-b13a-c1df8e09439b",
    ATTRIBUTE_ID: "38a51b65-519f-4ed0-b451-a5700117a1d2",
    ADUNIT_STATUS_ID: "2d350e95-af50-4ed6-9b22-9670df49acdd",
    ASSET_CATEGORY_ID: "07a61e45-12f1-41ea-841e-3de9c967240c",
    CONTRACT_PUB_ID: "882b097d-14f8-456c-91d3-bc6145c224c4",
    CATEGORY_ID: "22ec5bf0-66b1-44d1-9138-d2533b7a7039",
    DEAL_WBUYER_ID: "fe8db905-28e0-4d11-8677-85f08f95629b",
    DEAL_NON_SANDBOX_WBUYER_ID: "ba01e614-8f0d-11ec-b909-0242ac120002",
    MEASUREMENT_PROVIDER_ID: "283dda2d-b5f3-43f6-ba70-306eccd074f4",
    VENUE_CATEGORY_ID: "cada7be8-0267-4b08-8f0a-62e94e8b60ea",
    DEAL_PRIORITY_ID: "98fb2556-cdbc-47e7-9f8b-01b5a9cc6af4",
    CAP_TYPE_ID: "8f1e5076-14dd-4411-881e-520f227b9520",
    COUNTRY_LIST_ID: "b6fd3872-a806-47b9-bfe3-b3652e02ff06",
    LANGUAGE_ID: "0598d1cf-a1cd-4fac-9f41-ecddd84535c5",
  },
  // Time to preview a snapshot (in ms).
  PREVIEW_TIME: 15000,
  PX_BASE_PATH: "/",
  // These are based on the Status model (in ad.models.Status). Ideally,
  // we wouldn't want two versions of this, but this makes it easier when
  // typing in the filter by hand in the address bar. At a later point if/when
  // this view is made available as a link, the lookup (e.g.:- status=approved)
  // can be swapped out with the status id (e.g.:- status=3) instead.
  STATUS_ID_LOOKUP: {
    1: "Pending audit",
    2: "Pre-approved",
    3: "Approved",
    4: "Denied",
    5: "Changed",
  },
  STATUS_PREVIEW_LOOKUP: {
    approved: 3,
    changed: 5,
    denied: 4,
    pendingaudit: 1,
    preapproved: 2,
  },
  ADAPPROVAL_STATUSES: {
    1: "Pending",
    2: "Approved",
    3: "Approved",
    4: "Rejected",
    5: "Changed",
    all: "All",
  },
  BIDFLOORCUR_ARRAY_LOOKUP: [
    { currency: "USD", symbol: "$" },
    { currency: "CAD", symbol: "$" },
    { currency: "GBP", symbol: "£" },
  ],
  // 7200000 miliseconds (2 hours)
  USER_SESSION_BUFFER_TIME: 7200000,
  // 60 seconds
  ASYNC_UPLOAD_POLLING_TIME: 60,
  /*
  The "Year 2038 problem" arises from the use of 32-bit signed integers to represent time in computer systems.
  These integers count seconds since the "epoch," which is typically January 1, 1970, at 00:00:00 UTC.
  The maximum value for a signed 32-bit integer (2,147,483,647) corresponds to January 19, 2038, at 03:14:07 UTC.
  All our timestamp fields (like adunit.start_date and placement.end_date) are defined as int32 in pxproto.
  This leads to the Snowplow ref jobs failing with error "Failed to parse end_date field: Value out of range: 3187291800"
  When trying to parse timestamps greater than int32.
  Reference links:
  - https://en.wikipedia.org/wiki/Year_2038_problem
  */
  INT32_MAX_DATE: "2038-01-18T23:59",
};

const env_vars = [
  "ADMIN_API_BASE_URL",
  "ANALYTICS_WHITELISTED_ORGS",
  "AUTH0_AUDIENCE",
  "AUTH0_CLIENT_ID",
  "AUTH0_DOMAIN",
  "DATADOG_RUM_APPLICATION_ID",
  "DATADOG_RUM_CLIENT_TOKEN",
  "GF_PLANNING_WHITELISTED_ORGS",
  "IS_PROD_ENABLED",
  "LOOKER_PX_HOST",
  "PORTAL_ENV",
  "PORTAL_INVENTORY_REF_URL",
  "PORTAL_SERVICE",
  "PORTAL_URL",
  "PORTAL_VERSION",
  "PORTAL_FLATFILE_ENVIRONMENT_ID",
  "PORTAL_FLATFILE_PUBLISHABLE_KEY",
  "PUB_PLANNER_WHITELISTED_ORGS",
];

const isLocal = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
const envVarLookup = isLocal ? process.env : window;

for (const envVar of env_vars) {
  settings[envVar] = envVarLookup["REACT_APP_" + envVar];
}

export default settings;
