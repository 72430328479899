import React, { Component } from "react";
import { Button, MultiSelect, ToolTips } from "@px/px_design_system";
import PropType from "prop-types";
import TooltipMessages from "../../Utilities/ToolTip/TooltipMessages";

import "./style.css";

class ApproveAcrossNetworks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acrossAllChecked: true,
      checked: false,
      isApprovedOnAllNetworks: true,
      options: [],
      feedback: "",
    };
    this.onChecked = this.onChecked.bind(this);
  }

  onChecked(event) {
    const { acrossAllChecked } = this.state;

    this.setState({
      acrossAllChecked: !acrossAllChecked,
      isApprovedOnAllNetworks: event.target.checked,
    });
  }

  approveAcrossAllNetworks() {
    const { acrossAllChecked, feedback } = this.state;

    const { networkInfo = [] } = this.props;

    return (
      <div className="approve-ad__across-all">
        <p className="approve-ad__approve-ad-text">Approve Ad?</p>
        <p className="approve-ad__approve-notes-text">Feedback</p>
        <textarea
          id="feedback"
          rows="4"
          cols="75"
          className="approve-ad__approve-textarea"
          placeholder="Additional context about the Ad Approval. Please note it might be shared with the DSP."
          value={feedback}
          onChange={(e) => this.setState({ feedback: e.target.value })}
        />
        <p className="approve-ad__across-all-description">
          By selecting “Approve Across All Networks” you are giving explicit
          consent on behalf of your Organization to run this Ad across all
          inventory within your Organization.
        </p>
        <ToolTips
          text={
            !networkInfo.length
              ? TooltipMessages.AD_APPROVAL.NO_NETWORK.title
              : ""
          }
        >
          <label id="checkboxAcrossAllNetworks" htmlFor="across-all-networks">
            <input
              type="checkbox"
              value="Approve Across all Networks"
              onChange={this.onChecked}
              defaultChecked={acrossAllChecked}
              disabled={!networkInfo.length}
            />
            <span className="approve-ad__approve-across-all-text">
              Approve Across all Networks.
            </span>
          </label>
        </ToolTips>
      </div>
    );
  }

  approveAcrossSpecificNetwork() {
    const { checked } = this.state;

    const { networkInfo = [] } = this.props;
    return (
      <div>
        <p className="approve-ad__across-specific-network-text">
          To approve this Ad only for specific Networks, please select Networks
          below. By doing so, you are giving explicit consent on behalf of your
          Organization to run this Ad on the selected Networks.
        </p>
        <MultiSelect
          name="approved_networks"
          options={networkInfo.map((element) => ({
            label: element.name,
            value: element.name,
          }))}
          isMulti
          isClearable
          isSearchable
          getSelectedValues={(selectedOption) => {
            this.setState({
              options: selectedOption.map((item) => item.value),
            });
          }}
          isValidNewOption={() => false}
        />
      </div>
    );
  }

  approveAdActionButtton() {
    const { isApprovedOnAllNetworks, options, feedback } = this.state;
    const { changeStatusOfAd, confirmApproval } = this.props;
    const cancelButtonProps = {
      style: {
        backgroundColor: "#f1f6fb",
        borderColor: "#2D6BFF",
        color: "#2D6BFF",
      },
      buttontext: "Cancel",
    };
    const iconStyle = {
      style: {
        color: "#ffffff",
        width: "1rem",
        height: "1rem",
        marginRight: "0.625rem",
      },
    };
    const approvalButtonProps = {
      buttontext: "Confirm Approval",
      icontype: ["far", "check-circle"],
      iconstyle: iconStyle.style,
      style: {
        borderColor: "#28a745",
        backgroundColor: "#28a745",
        color: "#ffffff",
      },
    };
    return (
      <div className="approve-ad__action-buttons">
        <Button
          {...cancelButtonProps}
          className="approve-ad__action-buttons--cancel-button"
          onClick={() => confirmApproval(false)}
        />
        <ToolTips
          text={
            !isApprovedOnAllNetworks && !options.length
              ? TooltipMessages.CONFIRM_BUTTON.title
              : ""
          }
        >
          <Button
            id="confirmApprovalBtn"
            {...approvalButtonProps}
            className="approve-ad__action-buttons--confirm-approval-button"
            disabled={!isApprovedOnAllNetworks && !options.length}
            onClick={() =>
              changeStatusOfAd(
                "approveAd",
                isApprovedOnAllNetworks,
                options,
                "",
                feedback
              )
            }
          />
        </ToolTips>
      </div>
    );
  }

  render() {
    const { acrossAllChecked } = this.state;

    return (
      <div className="approve-ad">
        {this.approveAcrossAllNetworks()}
        {!acrossAllChecked && this.approveAcrossSpecificNetwork()}
        {this.approveAdActionButtton()}
      </div>
    );
  }
}

ApproveAcrossNetworks.propTypes = {
  changeStatusOfAd: PropType.func,
  confirmApproval: PropType.func,
  networkInfo: PropType.arrayOf(PropType.object),
};

export default ApproveAcrossNetworks;
