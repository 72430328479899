import React, { createContext, useContext, useEffect, useState } from "react";
import queryString from "query-string";
import PropTypes from "prop-types";
import { Spinner } from "@px/px_design_system";

import { UserContext } from "../../../../context/auth";
import {
  fetchDealPrirityResponse,
  fetchWbuyerResponse,
} from "../../../../context/DealManagement";
import DisplayError from "../../../../DisplayError";
import ErrorMessages from "../../../../DisplayError/ErrorMessages";
import {
  constructAdminAPIEndPoint,
  checkIfModelDataExist,
  fetchHandler,
  getErrorMessage,
} from "../../../../../../utils";
import { dealDefaultPriority } from "../../DealDetails/DealsMetaData/dealMapping";

const DealFormContext = createContext();

const getCreatedByInfo = (userId) => {
  return fetchHandler({
    method: "GET",
    url: constructAdminAPIEndPoint({
      url: `users/${userId}`,
    }),
    headers: {
      "cache-control": "no-cache, no-store",
    },
  })
    .then((json) => json.name || "-")
    .catch((error) =>
      error.status === 403 && error.data.message === "Forbidden"
        ? "Place Exchange"
        : "-"
    );
};

const wbuyer = (dealData, wbuyerList) =>
  dealData.wbuyer.map((item) => {
    if (item in wbuyerList) {
      return { value: item, label: wbuyerList[item] };
    }
  });

// TODO: Reduce cognitive complexity
const DealFormProvider = (props) => {
  const { isImportEnabled, loading, organization } = useContext(UserContext);
  const [isEditMode, setEditMode] = useState(false);
  const [singleDealData, setDealData] = useState();
  const [isFormReady, setFormReady] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [wbuyerOptions, setWbuyerOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [formDefaultValues, setFormDefaultValues] = useState({
    name: "",
    notes: "",
    at: "",
    archived: "",
    bidfloorcur: "",
    bidfloor: "",
    priority: "30",
    status: "paid",
    wbuyer: "",
    wadomain: [],
    wseat: [],
  });

  const getDealData = async (name, wbuyerList) => {
    const dealData = await checkIfModelDataExist(
      organization.id,
      "deals",
      name,
      "name"
    );
    if (dealData.name) {
      const createdByName = await getCreatedByInfo(dealData.created_by);
      setEditMode(true);
      setFormDefaultValues({
        name: dealData.name,
        notes: dealData.notes,
        at: dealData.at,
        bidfloorcur: dealData.bidfloorcur,
        bidfloor: dealData.bidfloor,
        status: dealData.status,
        priority: dealData.priority,
        wbuyer: wbuyer(dealData, wbuyerList),
        wadomain: dealData.wadomain.map((element) => ({
          label: element,
          value: element,
        })),
        wseat: dealData.wseat.map((element) => ({
          label: element,
          value: element,
        })),
        archived: dealData.archived,
      });
      setDealData({ ...dealData, created_by_name: createdByName });
      setFormReady(true);
    } else if (dealData.message === "Not Found")
      props.history.push("../deals/summary");
  };

  const getDropdownOptions = async (name) => {
    const wbuyerList = await fetchWbuyerResponse();
    const dealPriorityList = await fetchDealPrirityResponse();
    setWbuyerOptions(wbuyerList);
    setPriorityOptions(dealPriorityList);
    if (name) getDealData(name, wbuyerList);
    else {
      setFormDefaultValues({
        ...formDefaultValues,
      });
      setFormReady(true);
    }
  };

  useEffect(() => {
    if (!loading) {
      setDisplayError(false);
      setFormReady(false);
      const queryParams = queryString.parse(props.location.search);
      getDropdownOptions(queryParams.name);
    }
  }, [loading]);

  return (
    <DealFormContext.Provider
      value={{
        isEditMode,
        isImportEnabled,
        formDefaultValues,
        orgId: organization.id,
        singleDealData,
        loading,
        wbuyerOptions,
        organization,
        priorityOptions,
      }}
    >
      {!loading && isFormReady ? (
        displayError ? (
          <DisplayError {...getErrorMessage("deal")} />
        ) : (
          props.children
        )
      ) : (
        <Spinner color="primary" />
      )}
    </DealFormContext.Provider>
  );
};

const DealFormConsumer = DealFormContext.Consumer;

DealFormProvider.contextTypes = {
  formDefaultValues: PropTypes.any,
  isEditMode: PropTypes.any,
  loading: PropTypes.any,
  orgId: PropTypes.any,
  singleDealData: PropTypes.any,
  wbuyerOptions: PropTypes.any,
  priorityOptions: PropTypes.any,
};

export { DealFormProvider, DealFormConsumer, DealFormContext };
