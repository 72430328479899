import React from "react";

const LogoutIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <defs>
      <path
        id="logout-icon"
        d="M6.777 10.872L7.926 12 12 8 7.926 4 6.777 5.128 8.879 7.2H1v1.6h7.88l-2.103 2.072zM13.444 1H2.556C1.692 1 1 1.7 1 2.556v3.11h1.556v-3.11h10.888v10.888H2.556v-3.11H1v3.11C1 14.3 1.692 15 2.556 15h10.888C14.3 15 15 14.3 15 13.444V2.556C15 1.7 14.3 1 13.444 1z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="logout-mask" fill="#fff">
        <use xlinkHref="#logout-icon" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#logout-icon" />
      <g fill="#B5BABE" mask="url(#logout-mask)">
        <path d="M0 0h16v16H0z" />
      </g>
    </g>
  </svg>
);

export default LogoutIcon;
