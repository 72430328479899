import { isEmpty } from "lodash";

import { constructAdminAPIEndPoint, fetchHandler, history } from "..";

import fetchOrgContract from "../orgcontractapi";

const getUserOrgData = (user, orgid) => {
  // TODO - Remove the superuser check once switching between orgs
  // becomes a thing.
  const checkIfSuperuser = fetchHandler({
    url: constructAdminAPIEndPoint({
      url: "am_i_superuser",
    }),
    headers: { "cache-control": "no-cache, no-store" },
    responseType: "status",
  })
    .then((response) => response)
    .catch((error) => Promise.reject(error));

  const fetchUserData = fetchHandler({
    url: constructAdminAPIEndPoint({
      url: "users",
      searchParams: [
        {
          key: "name",
          value: user,
        },
      ],
    }),
  })
    .then((response) => (response.length ? response[0] : []))
    .catch((error) => Promise.reject(error));

  const fetchOrgByNameData = (userData, id = 0) =>
    fetchHandler({
      url: constructAdminAPIEndPoint({
        url: "orgs",
        searchParams: [
          {
            key: "name",
            value: userData[0].orgs[id],
          },
        ],
      }),
      headers: { "cache-control": "no-cache, no-store" },
    }).then((response) => {
      if (response) {
        if (
          !response[0].config.is_sandbox ||
          userData[0].orgs.length - 1 === id
        ) {
          history.push(`/orgs/${response[0].id}/reporting/revenue/summary`);
          return { userData, orgData: response };
        }
        if (userData[0].orgs.length - 1 !== id) {
          let nextOrgId = id;
          return fetchOrgByNameData(userData, ++nextOrgId);
        }
      }
    });

  const fetchOrgByID = (userData, id) =>
    fetchHandler({
      url: constructAdminAPIEndPoint({
        url: `orgs/${id}`,
      }),
      headers: { "cache-control": "no-cache, no-store" },
    })
      .then(async (response) => {
        if (response) {
          let orgContractData = await fetchOrgContract(id);
          response["orgContractData"] = orgContractData;
          return { userData, orgData: response };
        }
      })
      .catch((error) => ({ userData, orgData: error.data }));

  const apiResponse = Promise.all([fetchUserData, checkIfSuperuser])
    .then((response) => {
      if (
        !("status" in response) &&
        !isEmpty(response[0]) &&
        response[0].orgs.length
      ) {
        // response[0].orgs.sort();
        return orgid &&
          orgid !== "null" &&
          orgid !== "no-user-org" &&
          orgid !== undefined
          ? fetchOrgByID(response, orgid)
          : fetchOrgByNameData(response);
      }
      return response;
    })
    .catch((error) => Promise.reject(error));
  return apiResponse;
};

export default getUserOrgData;
