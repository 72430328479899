import React from "react";
import PropType from "prop-types";

import { AdunitFormProvider } from "./AdunitFormContext";
import FormAdunit from "./FormAdunit";

// import './style.css';

const FormAddAdunit = (props) => (
  <AdunitFormProvider {...props}>
    <FormAdunit {...props} />
  </AdunitFormProvider>
);

FormAddAdunit.contextTypes = {
  formDefaultValues: PropType.any,
  isEditMode: PropType.any,
  orgId: PropType.any,
};

export default FormAddAdunit;
