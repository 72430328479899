/* istanbul ignore file */

import { isEmpty } from "lodash";

import { splitCommaSeparatedStringToArray } from "../../../../../../utils";

const validate = require("uuid-validate");

const getKeyByValue = (object, value) => {
  /* Return if the value is uuid */
  if (validate(value)) {
    return value;
  }
  /* istanbul ignore next */
  return Object.keys(object).find((key) => object[key] === value);
};
//Flatfile add deals fields
export const postProcessFields = [
  {
    key: "priority",
    process: (value) => (isEmpty(value) ? "30" : value),
  },
  {
    key: "status",
    process: (value) => (isEmpty(value) ? "" : value),
  },
  {
    key: "wadomain",
    process: (value) => splitCommaSeparatedStringToArray(value),
  },
  {
    key: "wbuyer",
    process: (value, options) => {
      return isEmpty(value) || value === "[]" || value === "All DSPs"
        ? []
        : [getKeyByValue(options, value)];
    },
    alternate: "wbuyer_single_item",
  },
  {
    key: "wseat",
    process: (value) => splitCommaSeparatedStringToArray(value),
  },
];

// Flatfile Archive deals fields
export const postProcessArchiveFields = [
  { key: "archived", process: (value) => value },
];
