/* istanbul ignore file */

export const archived__deals__sheet = [
  {
    name: "Archived Deals Sheet",
    slug: "archived__sheet",
    readonly: false,
    mappingConfidenceThreshold: 0.9,
    fields: [
      {
        label: "Deal Name(s)",
        key: "name",
        description:
          "Include deal(s) that will be targeted by the placement and also, associated with inventory selected earlier.",
        type: "string",
        constraints: [{ type: "required" }, { type: "unique" }],
      },
      {
        label: "Archived",
        key: "archived",
        description:
          "Archival status of the deal. Archived Deals are not used in active campaigns. Default value is false",
        type: "boolean",
      },
    ],
  },
];
