/* istanbul ignore file */

import api from "@flatfile/api";
import { FlatfileListener } from "@flatfile/listener";
import { bulkRecordHook, recordHook } from "@flatfile/plugin-record-hook";

import { handleSubmit } from "../../../Utilities/FlatFileComponent/Platform/listeners/submit";
import networkModel from "../../../../models/Network";
import { modelFormatter, urlRegEx } from "../../../../../../utils";
import {
  beforeAfterSpaceValidation,
  checkValueExistInList,
  countLengthValidator,
  digitBeforeValidator,
  lengthValidator,
  listFieldValidator,
  regexValidator,
  requiredWithValidator,
  textRangeValidator,
  trimWhitespacesAndSetRecord,
} from "../../../Utilities/FlatFileComponent/validations/common";
import { postProcessFields } from "../AdUnitMetaData/customValidationFields";
import { regex } from "../../../Utilities/FlatFileComponent/utils/regexConstants";

const flatten = require("flat");

const networkModelFlatDefault = flatten(
  modelFormatter("default", networkModel)
);

let errorJson = [];

export const listenerNetworks = (orgData, flatfileClose, abortController) =>
  FlatfileListener.create((listener) => {
    /* Track space_id in the console, to be able to share it with Flatfile team while reporting issues */
    listener.on("workbook:created", async ({ context: { spaceId } }) => {
      console.log("spaceId", spaceId);
    });

    listener.use(
      bulkRecordHook("networks__sheet", async (records) => {
        records.map(async (record) => {
          trimWhitespacesAndSetRecord(record);
          return record;
        });
      })
    );

    listener.use(
      recordHook("networks__sheet", async (record) => {
        /* name */
        beforeAfterSpaceValidation(record, "name");
        lengthValidator(record, "name", 100);

        /* Auction fields */
        /* auction.alt_bidfloors.banner */
        regexValidator(
          record,
          "auction.alt_bidfloors.banner",
          regex.positiveNumberWithTwoDecimal.pattern,
          regex.positiveNumberWithTwoDecimal.errorMsg
        );
        countLengthValidator(record, "auction.alt_bidfloors.banner", 6);
        digitBeforeValidator(record, "auction.alt_bidfloors.banner", 4);

        /* auction.alt_bidfloors.video */
        regexValidator(
          record,
          "auction.alt_bidfloors.video",
          regex.positiveNumberWithTwoDecimal.pattern,
          regex.positiveNumberWithTwoDecimal.errorMsg
        );
        countLengthValidator(record, "auction.alt_bidfloors.video", 6);
        digitBeforeValidator(record, "auction.alt_bidfloors.video", 4);

        /* auction.bidfloor */
        regexValidator(
          record,
          "auction.bidfloor",
          regex.positiveNumberWithTwoDecimal.pattern,
          regex.positiveNumberWithTwoDecimal.errorMsg
        );
        countLengthValidator(record, "auction.bidfloor", 6);
        digitBeforeValidator(record, "auction.bidfloor", 4);

        /* Branding fields */
        /* branding.bundle */
        requiredWithValidator(record, "branding.bundle", [
          "branding.shortcode",
          "branding.shorturl",
          "branding.url",
        ]);
        lengthValidator(record, "branding.bundle", 100);

        /* branding.shortcode */
        requiredWithValidator(record, "branding.shortcode", [
          "branding.bundle",
          "branding.shorturl",
          "branding.url",
        ]);
        lengthValidator(record, "branding.shortcode", 100);

        /* branding.shorturl */
        requiredWithValidator(record, "branding.shorturl", [
          "branding.bundle",
          "branding.shortcode",
          "branding.url",
        ]);
        regexValidator(
          record,
          "branding.shorturl",
          urlRegEx(),
          "Enter a valid URL."
        );
        lengthValidator(record, "branding.shorturl", 200);

        /* branding.url */
        requiredWithValidator(record, "branding.url", [
          "branding.bundle",
          "branding.shortcode",
          "branding.shorturl",
        ]);
        regexValidator(
          record,
          "branding.url",
          urlRegEx(),
          "Enter a valid URL."
        );
        lengthValidator(record, "branding.url", 200);

        /* notes */
        textRangeValidator(record, "notes", 2, 4000);

        /* Measurement */
        /* measurement.duration */
        regexValidator(
          record,
          "measurement.duration",
          regex.positiveNumberWithTwoDecimal.pattern,
          regex.positiveNumberWithTwoDecimal.errorMsg
        );
        countLengthValidator(record, "measurement.duration", 6);

        /* measurement.imp_four_week */
        regexValidator(
          record,
          "measurement.imp_four_week",
          regex.number.pattern,
          regex.number.errorMsg
        );

        /* measurement.imp_x */
        regexValidator(
          record,
          "measurement.imp_x",
          regex.numberGreaterOrEqualTo0.pattern,
          regex.numberGreaterOrEqualTo0.errorMsg
        );

        /* measurement.segment */
        lengthValidator(record, "measurement.segment", 100);

        /* measurement_config.ads_per_hour */
        regexValidator(
          record,
          "measurement_config.ads_per_hour",
          regex.positiveNumberWithTwoDecimal.pattern,
          regex.positiveNumberWithTwoDecimal.errorMsg
        );
        digitBeforeValidator(record, "measurement_config.ads_per_hour", 4);

        /* Restrictions */

        checkValueExistInList(record, "restrictions.battr");

        checkValueExistInList(record, "restrictions.bcat");

        checkValueExistInList(record, "restrictions.wlang");

        checkValueExistInList(record, "restrictions.btype");

        listFieldValidator(record, "restrictions.badomain");

        listFieldValidator(record, "restrictions.bbundle");

        listFieldValidator(record, "restrictions.bbuyer");

        listFieldValidator(record, "restrictions.bcid");

        listFieldValidator(record, "restrictions.bcrid");

        listFieldValidator(record, "restrictions.bseat");

        return record;
      })
    );

    listener.filter({ job: "workbook:submitActionNetworks" }, (configure) => {
      configure.on("job:ready", async ({ context: { jobId, workbookId } }) => {
        errorJson = await handleSubmit({
          abortController,
          orgData,
          jobId,
          workbookId,
          flatModel: networkModelFlatDefault,
          postProcessFields,
          model: "networks",
          type: "Networks",
        });
      });
    });

    listener.on("job:outcome-acknowledged", (event) => {
      // Handle the outcome-acknowledged event here
      flatfileClose(errorJson || []);
    });
  });
