/* istanbul ignore file */

import React from "react";
import { ToolTips } from "@px/px_design_system";
import PropTypes from "prop-types";

import ToolTipMessages from "../../../Utilities/ToolTip/TooltipMessages";
import ArchiveIcon from "../../../../svgs/ArchiveIcon";

const ArchiveDeals = (props) => {
  const handlePlatform = () => {
    props.archiveplatformRef?.current.click();
  };
  return (
    <div className="archive__button">
      <ToolTips
        className=""
        disabled={!props.isImportEnabled}
        onClick={handlePlatform}
        text={
          !props.isImportEnabled
            ? ToolTipMessages.ARCHIVE_DEAL.noPermission
            : ToolTipMessages.ARCHIVE_DEAL.title
        }
      >
        <div id="flatfile--deals__archive">
          {props.isImportEnabled ? (
            <span onClick={handlePlatform}>{ArchiveIcon}</span> //if user have import permission
          ) : (
            ArchiveIcon //if user dont have import permission
          )}
        </div>
      </ToolTips>
    </div>
  );
};

ArchiveDeals.propTypes = {
  isImportEnabled: PropTypes.bool,
  orgData: PropTypes.shape(),
  registerDeals: PropTypes.func,
  hideButton: PropTypes.bool,
};

export default ArchiveDeals;
