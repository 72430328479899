import { generatePath } from "react-router";
import {
  NO_USER_ORG_ROUTE_PARAM,
  PERSONA_HOME_ROUTE_MAP,
} from "../../../../global-constants";
import SETTINGS from "../../../../settings";

/*
 * Returns the relative URL to be used by the PX logo in the sidebar based on the given persona.
 * In case the given persona does not exist in `PERSONA_HOME_ROUTE_MAP`
 * will return `PX_BASE_PATH` from the global settings object.
 * Uses the `generatePath` function to do the parameter substitution.
 *
 *
 * Parameters:
 *  persona - (string) Current persona from the UserContext.
 *  orgId   - (string) This value will substitute the :orgId param in the return URL.
 *
 * Returns:
 *  (string) The relative URL to be used by the PX logo.
 */
export function getLogoURL(persona, orgId) {
  const route = PERSONA_HOME_ROUTE_MAP[persona];

  if (!route) {
    return SETTINGS.PX_BASE_PATH;
  }

  return generatePath(route, { orgId: orgId || NO_USER_ORG_ROUTE_PARAM });
}
