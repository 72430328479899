const geoSegmentFirstHalf = {
  adunit_id: "",
  adunit: {
    name: "",
    asset: {
      screen_count: "",
      type: "",
    },
    network_name: "",
    location: {
      dma: "",
    },
    venue: { name: "", openooh_category_id: "", openooh_category: "" },
  },
  provider_name: "",
  segment_name: "",
  geo_type: "",
  geo_code: "",
  geo_name: "",
  play_duration: "",
  info: [],
  in_geo_reach_count: null,
  in_geo_reach_percent: null,
  out_geo_reach_count: null,
  out_geo_reach_percent: null,
  total_reach_count: null,
  total_reach_percent: null,
  in_geo_frequency_avg: null,
  out_geo_frequency_avg: null,
  total_frequency_avg: null,
  in_geo_impression_count: null,
  out_geo_impression_count: null,
  total_impression_count: null,
};

// There are 168 hours in a week; loop below creates 168 distinct hourly_imp_x values
for (let i = 0; i < 168; i++) {
  let key = `hourly_imp_x_${i}`;
  geoSegmentFirstHalf[key] = null;
}

const geoSegmentSecondHalf = {
  id: "",
  name: "",
  venue_hash: "",
  created_by: "",
  owned_by: "",
  lastmod: "",
};

const geoSegmentModel = { ...geoSegmentFirstHalf, ...geoSegmentSecondHalf };

export default geoSegmentModel;
