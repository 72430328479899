const ToolTipMessages = {
  // General tool-tip messages
  AD_APPROVAL: {
    APRROVED: {
      title: "Ad is already approved",
    },
    REJECTED: {
      title: "Ad is already rejected",
    },
    NO_SNAPSHOT: {
      title: "Cannot approve ads when snapshot is not found",
    },
    NO_NETWORK: {
      title: "No networks for this organization",
    },
  },
  CONFIRM_BUTTON: {
    title: "Select at least one network",
  },
  CONFIRM_ADAPPROVAL: {
    title: "Click on Confirm Approval or Cancel",
  },
  CONFIRM_ADAPPROVAL_REJECT: {
    title: "Click on Confirm Reject or Cancel",
  },
  DATASETS_API_ERROR: {
    title: "An error occurred. Please refresh or try again later.",
  },
  DOWNLOAD_CSV: {
    title: "No data available",
  },
  READ_ONLY: {
    title: "User does not have permission to approve/reject ads",
  },
  SNAPSHOT_ERROR: {
    title: "Cannot approve ads when 1.0 snapshot fails to load",
  },
  SNAPSHOT_MISSING: {
    title: "Cannot approve ads when 1.0 snapshot is missing",
  },
  SNAPSHOT_VIDEO_ERROR: {
    title: "Cannot approve ads when video snapshot fails to load",
  },
  ADD_PLACEMENTS: {
    title: "Add Placements",
    noPermission: "User does not have permission to Add Placements",
  },
  UPDATE_PLACEMENTS: {
    title: "Update Placement",
    noPermission: "User does not have permission to Update Placements",
  },
  UPDATE_ADUNITS: {
    title: "Update Adunit",
    noPermission: "User does not have permission to Update Adunits",
  },
  UPDATE_DEALS: {
    title: "Update Deal",
    noPermission: "User does not have permission to Update Deals",
  },
  IMPORT_ADUNIT: {
    title: "Attach and email ad units",
    upsert: "Upsert Ad Units",
    noPermission: "User does not have permission to Import Ad Units",
  },
  IMPORT_NETWORK: {
    upsert: "Upsert Networks",
    noPermission: "User does not have permission to Import Networks",
  },
  ADD_DEAL: {
    title: "Add Deals",
    noPermission: "User does not have permission to Import Deals",
  },
  ARCHIVE_DEAL: {
    title: "Archive Deals",
    noPermission: "User does not have permission to Archive Deals",
  },
  SIDE_NAV_ICON: {
    ad_approval: {
      title: "Ad Approvals",
    },
    adunit_inventory: {
      title: "Inventory Management",
    },
    reports: {
      title: "Reports",
    },
    deal: {
      title: "Deal Management",
    },
    help: {
      title: "Help Center",
    },
    order: {
      title: "Order Management",
    },
    resources: {
      title: "Resources",
    },
    news: {
      title: "News",
    },
  },
  LAUNCH_APPROVAL_NO_DATA: {
    title: "No records available",
  },
  SEARCH_INFO_TEXT: {
    adapprovals:
      "Search by: DSP Creative ID, DSP, Advertiser Domain, Ad Type, Language, Ad Approval ID",
    adunit_inventory: "Search by: Adunit Name, Asset Size, Id",
    network_inventory: "Search by: Network Name, Id",
    placements: "Search by: Placement Name, Id",
    deals: "Search by: Deal Name, Token (DSP Deal ID), Allowed Buyers, Id",
    geo_segment:
      "Search by: Geo Segment Name, Ad Unit Id, Provider, Segment, Geo Code, Geo Name, Geo Type",
  },
};

export default ToolTipMessages;
