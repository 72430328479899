import React from "react";

import { DealFormProvider } from "./DealFormContext";
import FormDeal from "./FormDeal";

const FormAddDeal = (props) => {
  return (
    <DealFormProvider {...props}>
      <FormDeal {...props} />
    </DealFormProvider>
  );
};

export default FormAddDeal;
