import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonDropdown, Icon, ToolTips } from "@px/px_design_system";
import ToolTipMessages from "../ToolTip/TooltipMessages";

import "./style.css";

export default function WithTooltip(props) {
  const {
    data,
    id,
    className,
    handleClick,
    tabName = "",
    text = "",
    componentName = "",
    isDownloadInProgress
  } = props;

  let isDisabled = false;

  if (data === true) {
    isDisabled = true;
  }

  const triggerClick = (event) => {
    if (handleClick) {
      handleClick(event.target.innerText);
    }
  };

  return (
    <ToolTips disabled={isDisabled} text={text}>
      {tabName === "adunits" ||
      tabName === "networks" ||
      tabName === "deals" ||
      tabName === "placements" ||
      componentName === "AdApprovals" ? (
        <ButtonDropdown
          bgColor="secondary"
          id={id}
          className={className}
          icon={<Icon icon={["fas", "arrow-down"]} size="1x" />}
          isDisabled={isDisabled}
          dropdownItems={["Download as Excel"]}
          iconType={[["fas", "arrow-down"]]}
          primaryText="Download as CSV"
          handleClick={(event) => triggerClick(event)}
          size="5x"
        />
      ) : (
        <Button
          id={id}
          className={className}
          buttontext="Download CSV"
          disabled={isDisabled}
          justified
          onClick={(event) => triggerClick(event)}
          icon={<Icon icon={["fas", `${isDownloadInProgress ? "spinner" : "arrow-down"}`]} size="1x" />}
        />
      )}
    </ToolTips>
  );
}

WithTooltip.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  tabName: PropTypes.string,
};
