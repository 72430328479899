import React from "react";
import { ButtonDropdown, ToolTips } from "@px/px_design_system";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import ToolTipMessages from "../../Utilities/ToolTip/TooltipMessages";

const manageDealCreation = (props) => {
  const { isImportEnabled, history, wbuyerOptions, priorityOptions } = props;

  const goToManageDeal = (event) => {
    event.target.innerText === "Add Deal" && history.push(`../deals/add`);
  };

  /* istanbul ignore next */
  const handlePlatform = () => {
    props?.platformRef?.current.click();
  };

  return (
    <ButtonDropdown
      isDisabled={
        !isImportEnabled || isEmpty(wbuyerOptions) || isEmpty(priorityOptions)
      }
      withoutPadding
      dropdownItems={
        isImportEnabled && !(isEmpty(wbuyerOptions) || isEmpty(priorityOptions))
          ? [<div onClick={handlePlatform}>Upload CSV</div>]
          : [<div>Upload CSV</div>]
      }
      primaryText="Add Deal"
      handleClick={(event) => goToManageDeal(event)}
    />
  );
};

const AddDeals = (props) => {
  const { isImportEnabled } = props;

  return (
    <div className="import__button">
      <ToolTips
        disabled={!isImportEnabled}
        text={
          !isImportEnabled
            ? ToolTipMessages.ADD_DEAL.noPermission
            : ToolTipMessages.ADD_DEAL.title
        }
      >
        <div id="flatfile--deals">{manageDealCreation(props)}</div>
      </ToolTips>
    </div>
  );
};

AddDeals.propTypes = {
  history: PropTypes.shape(),
  isImportEnabled: PropTypes.bool,
  priorityOptions: PropTypes.array,
  wbuyerOptions: PropTypes.shape(),
};

export default AddDeals;
