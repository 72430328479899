import React, { useContext } from "react";
import { isEmpty } from "lodash";

import { OrderContext, OrderProvider } from "../../context/OrderManagement";
import { UserContext } from "../../context/auth";
import DisplayError from "../../DisplayError";
import OrderDetails from "./OrderDetails";
import Headline from "../Headline";

const OrderManagement = (props) => {
  const contextProps = useContext(UserContext);
  const { data, errorMessage, loading } = contextProps;
  const { states } = useContext(OrderContext);

  return (
    <>
      <Headline>Campaigns</Headline>
      {!isEmpty(data) && !data.orgData.errors && !loading ? (
        <OrderDetails
          {...props}
          states={states}
          {...data}
          isImportEnabled={contextProps.isImportEnabled}
        />
      ) : (
        <DisplayError {...errorMessage} />
      )}
    </>
  );
};

const Order = (props) => (
  <OrderProvider {...props}>
    <OrderManagement {...props} />
  </OrderProvider>
);

export const OrderManagementComponent = OrderManagement;
export default Order;
