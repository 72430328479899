import React from "react";

import "../../Utilities/commons/buyerStyles/style.css";
import "./style.css";

const ResourceList = (props) => {
  const listItems = props.items.map((el) => {
    return (
      <li>
        <a href={el.link} target="_blank" rel="noopener noreferrer">
          {el.title}
        </a>
      </li>
    );
  });

  return <ul className="buyer-view-link-list">{listItems}</ul>;
};

const ResourcesDetails = () => {
  const generalLinks = ResourceList({
    items: [
      {
        link: "https://www.placeexchange.com/assets/marketing/PX_Presentation_forDSPs.pdf",
        title: "Real Programmatic OOH",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/About_PlaceExchange_DSP.pdf",
        title: "About Place Exchange",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PX_Creative_Best_Practices.pdf",
        title: "PX Creative Best Practices Guide",
      },
    ],
  });

  const supplyLinks = ResourceList({
    items: [
      {
        link: "https://www.placeexchange.com/assets/marketing/PX_MediaKit_DSP.pdf",
        title: "US Media Kit",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PX_InventoryGuide.pdf",
        title: "Inventory Guide (USA)",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PX_Canada_InventoryGuide.pdf",
        title: "Inventory Guide (CAN)",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PX_Canada.pdf",
        title: "PX in Canada",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PX_LATAM_.pdf",
        title: "PX in LATAM",
      },
    ],
  });

  const productLinks = ResourceList({
    items: [
      {
        link: "https://www.placeexchange.com/assets/marketing/PerView_Reach_Frequency_Measurement.pdf",
        title: "PX PerView™️ Reach & Frequency Measurement",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PerView_Reach_Frequency_Measurement_1-Sheet.pdf",
        title: "PX PerView™️ One Sheet",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PXVerticalVideo.pdf",
        title: "PX Vertical Video",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PXVideo_OpenBeta.pdf",
        title: "PX Video Open Beta",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/3PAS_3PMeasurement.pdf",
        title: "PX 3PAS & 3P Measurement",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/CM360_for_DOOH.pdf",
        title: "CM360 for DOOH",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/CM360_tracking_pixel.pdf",
        title: "CM360 Tracking Pixel Setup",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/CM360_for_Display.pdf",
        title: "CM360 for Display",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/CM360_for_Video.pdf",
        title: "CM360 for Video",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/MeasurementMethodologies.pdf",
        title: "Impression Measurement Methodologies",
      },
    ],
  });

  const verticalsEventsLinks = ResourceList({
    items: [
      {
        link: "https://www.placeexchange.com/assets/marketing/PX_Street-Furniture%20Package.pdf",
        title: "Street Furniture",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/SuperBowl.pdf",
        title: "Super Bowl 2022",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PX_Pharma_Best_Practices.pdf",
        title: "PX Pharma Best Practices",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PX_Grocery_and_Convenience.pdf",
        title: "PX Grocery & Convenience Network",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PX_Grocery_Network.pdf",
        title: "PX Grocery Network",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PX%20Grocery%20&%20Convenience%20Network.pdf",
        title: "PX Grocery & Convenience Network + Audio",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PX_Air_Network.pdf",
        title: "PX Air Network",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PX_Moving_Media.pdf",
        title: "PX Moving Media Network",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/WelcomeBackTravel.pdf",
        title: "Return to Travel",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/Pride_2021.pdf",
        title: "Pride Month",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/MarchMadness_2022.pdf",
        title: "March Madness 2022",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/MarchMadness_2021.pdf",
        title: "March Madness General",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/MarchMadness_2021_Indiana.pdf",
        title: "March Madness Indiana & Bars",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/Olympics_2021.pdf",
        title: "Olympics",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/NYC_Street-level_Package.pdf",
        title: "NYC Takeover",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PX_Spectaculars_v1.pdf",
        title: "Spectaculars",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/Top_Spectaculars_Package.pdf",
        title: "Top Spectaculars",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PX_Halloween.pdf",
        title: "Halloween",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PX_Thanksgiving.pdf",
        title: "Thanksgiving",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PX_WinterHolidays.pdf",
        title: "Winter Holidays",
      },
    ],
  });

  const caseStudiesLinks = ResourceList({
    items: [
      {
        link: "https://www.placeexchange.com/assets/marketing/PXCaseStudy_AutoRetailer.pdf",
        title: "Auto Retailer Case Study (Discount Tire)",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PXCaseStudy_Political.pdf",
        title: "Political Case Study (Hawkfish)",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PXCaseStudy_FreshDirect.pdf",
        title: "Fresh Direct Case Study",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PXCaseStudy_DraftKings.pdf",
        title: "DraftKings Case Study",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PXCaseStudy_Bayer.pdf",
        title: "Bayer Case Study",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PXCaseStudy_Retailer.pdf",
        title: "Generic Retailer Mini Case Study",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PXCaseStudy_1partydata.pdf",
        title: "1st Party Data Audience Targeting Case Study",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/M1_Segment_CaseStudy.pdf",
        title: "M1 Posterscope Case Study",
      },
      {
        link: "https://www.placeexchange.com/assets/marketing/PXCaseStudy_REMAX.pdf",
        title: "RE/MAX M1 Posterscope Case Study",
      },
    ],
  });

  return (
    <div className={`resources-list wrapper`}>
      <div className="row">
        <div className="buyer-view-container column">
          <h3 className="resource-sub-heading">General</h3>
          {generalLinks}
          <h3 className="resource-sub-heading">Supply</h3>
          {supplyLinks}
          <h3 className="resource-sub-heading">Product</h3>
          {productLinks}
        </div>
        <div className="buyer-view-container column">
          <h3 className="resource-sub-heading">Verticals & Events</h3>
          {verticalsEventsLinks}
        </div>
        <div className="buyer-view-container column">
          <h3 className="resource-sub-heading">Case Studies</h3>
          {caseStudiesLinks}
        </div>
      </div>
    </div>
  );
};

export default ResourcesDetails;
