import React from "react";

const RevenueIcon = (
  <>
    <defs>
      <path id="dashboard" d="M0 0h30v30H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#999"
        fillRule="nonzero"
        d="M30 8.727c0 1.5-1.227 2.728-2.727 2.728-.246 0-.478-.028-.696-.096L21.723 16.2c.068.218.095.464.095.71 0 1.5-1.227 2.726-2.727 2.726a2.735 2.735 0 0 1-2.727-2.727c0-.245.027-.49.095-.709l-3.477-3.477a2.409 2.409 0 0 1-.71.095c-.245 0-.49-.027-.708-.095L5.359 18.94c.068.218.096.45.096.695 0 1.5-1.228 2.728-2.728 2.728A2.735 2.735 0 0 1 0 19.636c0-1.5 1.227-2.727 2.727-2.727.246 0 .478.027.696.096L9.64 10.8a2.409 2.409 0 0 1-.096-.71c0-1.5 1.228-2.726 2.728-2.726 1.5 0 2.727 1.227 2.727 2.727 0 .245-.027.49-.095.709l3.477 3.477c.218-.068.463-.095.709-.095.245 0 .49.027.709.095l4.84-4.854a2.318 2.318 0 0 1-.095-.696c0-1.5 1.228-2.727 2.728-2.727C28.773 6 30 7.227 30 8.727z"
      />
    </g>
  </>
);

export default RevenueIcon;
