import React from "react";
import { Route, Switch, withRouter, Router } from "react-router-dom";
import PropTypes from "prop-types";

import { createBrowserHistory } from "history";
import Authentication from "./components/PlaceExchange/Auth/Auth";
import settings from "./settings";
import WithLogin from "./components/PlaceExchange/hoc/WithLogin";
import ChildRoutes from "./ChildRoutes";

const history = createBrowserHistory();

// Below line is helpful to available history in utils handleUserSession function.
window.sessionHistory = history;

const MainRoutes = (props) => (
  <Router history={history}>
    <Switch>
      <Route
        component={Authentication.AuthComp}
        path={`${settings.PX_BASE_PATH}`}
        exact
        {...props}
      />
      <Route
        path={`${settings.PX_BASE_PATH}login`}
        component={Authentication.AuthComp}
        exact
        {...props}
      />
      <Route path="/orgs/:id/" component={WithLogin(ChildRoutes)} />
      <Route path="/" component={WithLogin(ChildRoutes)} />
    </Switch>
  </Router>
);

MainRoutes.propTypes = {
  location: PropTypes.shape(),
};

export default withRouter(MainRoutes);
