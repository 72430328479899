const adUnitModel = {
  ad_formats: [],
  allowed_pct: null,
  aspect_ratios: [],
  asset: {
    aspect_ratio: "",
    capability: { audio: "", banner: "", video: "", perview: "" },
    category: null,
    dynamic_creative: null,
    image_url: "",
    mimes: [],
    name: "",
    screen_count: "",
    size: "",
    type: "",
  },
  auction: {
    alt_bidfloors: { banner: null, video: null },
    at: null,
    bidfloor: null,
    bidfloorcur: "",
  },
  created_by: "",
  eids: [],
  ext: null,
  id: "",
  integration_type: "",
  keywords: [],
  lastmod: "",
  location: {
    altitude: null,
    bearing: null,
    city: "",
    country: "",
    dma: "",
    dma_code: null,
    horizontal_accuracy: "",
    lat: "",
    lon: "",
    method: "",
    region: "",
    speed: null,
    vertical_accuracy: null,
    zip: "",
  },
  measurement: {
    duration: null,
    imp_four_week: null,
    imp_x: null,
    provider: "",
    segment: "",
  },
  measurement_config: {
    ads_per_hour: null,
  },
  name: "",
  network_id: null,
  network_name: "",
  notes: "",
  organization: "",
  owned_by: "",
  placements: [],
  planning: {
    base_rate: null,
    rate_cur: "",
  },
  private_auction: null,
  restrictions: {
    badomain: [],
    battr: [],
    bbundle: [],
    bbuyer: [],
    bcat: [],
    bcid: [],
    bcrid: [],
    bseat: [],
    btype: [],
    wlang: [],
  },
  slot: {
    h: "",
    max_duration: "",
    min_duration: "",
    w: "",
  },
  start_date: null,
  status: "",
  ts: "",
  venue: { address: "", hash: "", name: "", openooh_category: "" },
};

export default adUnitModel;
