import React, { useState, useEffect } from "react";
import { Spinner, textTruncate } from "@px/px_design_system";
import PropTypes from "prop-types";

import DisplayError from "../../../DisplayError";
import PaginatedDownloadCsv from "../../Utilities/PaginatedDownloadCsv";
import TableView from "../../Utilities/TableView";
import { noDataFoundText, prepareCsvData } from "../../../../../utils";
import ToolTipMessages from "../../Utilities/ToolTip/TooltipMessages";
import geosegmentModel from "../../../models/Geosegment";
import DownloadDataDict from "../../Utilities/DownloadDataDict";

import "./style.css";
import { get } from "lodash";

const MeasurementDetails = ({
  geosegmentsList,
  measurementData,
  displayError,
  errorMessage,
  fetchGeosegments,
  history,
  measurementDetails,
  measurementMapping,
  isLoaded,
  isDataFetch,
  loading,
  page,
  prepareMeasurementList,
  sizePerPageValue,
  totalGeosegments,
  totalSize,
  isSuperUser,
  tabName,
}) => {
  const choiceFields = {
    ...measurementMapping,
  };
  const [keyField] = useState("id");
  const [finalGeosegments, setFinalGeosegments] = useState([]);
  const [isFinalGeosegmentsReady, setFinalGeosegmentsReady] = useState(false);

  const geosegmentColumn = [
    {
      dataField: "name",
      text: "Geo Segment Name",
    },
    {
      dataField: "adunit_id",
      text: "Ad Unit Id",
    },
    {
      dataField: "provider_name",
      text: "Provider",
    },
    {
      dataField: "segment_name",
      text: "Segment",
    },
    {
      dataField: "geo_code",
      text: "Geo Code",
    },
    {
      dataField: "geo_name",
      text: "Geo Name",
    },
    {
      dataField: "geo_type",
      text: "Geo Type",
    },
    {
      dataField: "lastmod",
      text: "Last Modified",
    },
    {
      dataField: "id",
      text: "Id",
      formatter: textTruncate,
    },
  ];
  const sortGeosegmentColumns = {
    positions: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    sort: true,
  };

  const searchableGeosegmentColumn = [
    "id",
    "name",
    "geo_code",
    "geo_name",
    "geo_type",
  ];

  let measurementObj = {
    columns: geosegmentColumn,
    csvFileName: "Geosegments",
    fetchApiData: fetchGeosegments,
    totalCount: totalGeosegments,
    sortColumns: sortGeosegmentColumns,
    totalDataSize: totalSize,
    modelObject: geosegmentModel,
    searchableColumn: searchableGeosegmentColumn,
    measurementResponse: "geosegmentsResponse",
    measurementList: geosegmentsList,
  };

  useEffect(() => {
    csvDataToDownload(measurementData);
  }, [loading, isLoaded, isFinalGeosegmentsReady]);

  const csvDataToDownload = (data) => {
    const params = {
      list: data,
      fileFormat: "csv",
      modelObj: measurementObj.modelObject,
      dateTimePerseFields: ["lastmod"],
    };
    const getCsvData = prepareCsvData(params);
    getCsvData.forEach((geosegment) => {
      // change column names
      geosegment["adunit"]["venue"]["openooh_category_id"] = get(
        geosegment,
        "adunit.venue.openooh_category",
        ""
      );
      geosegment["adunit"]["venue"]["openooh_category"] = get(
        geosegment,
        "adunit.venue.openooh_category_display",
        ""
      );
      delete geosegment["adunit"]["venue"]["openooh_category_display"];

      for (let i = 0; i < get(geosegment, "hourly_imp_x", []).length; i++) {
        let name = "hourly_imp_x_" + i;
        geosegment[name] = geosegment["hourly_imp_x"][i];
      }
    });
    getCsvData.forEach((geosegment) => {
      delete geosegment["hourly_imp_x"];
    });
    setFinalGeosegments(getCsvData);
    setFinalGeosegmentsReady(true);
  };

  const spinnerProps = {
    className: "spinner-icon",
    color: "primary",
    loadingtext: "Loading",
  };
  const defaultSorted = [
    {
      dataField: "lastmod",
      order: "desc",
    },
  ];

  return (
    <>
      <div
        className={`geosegment--measurement-list  ${
          displayError ? "no--data--found" : ""
        } ${isLoaded ? "wrapper" : "wrapper--no-border"}`}
      >
        {(!isLoaded || loading) && <Spinner {...spinnerProps} />}
        {isLoaded && !loading && (
          <div
            className={`geosegment--measurement-container measurement-container-${tabName}  ${
              isSuperUser ? "super--user" : "non--super--user"
            }`}
          >
            <h2 className="geosegment__headline geosegment__headline--copy sub__headline--count">
              {tabName === "geosegments" ? (
                <>Geo Segments</>
              ) : (
                // currently, Geosegments is the only available tab; short term plans to add addtional
                <>Geo Segments</>
              )}
            </h2>
            <div className="geosegment-buttons">
              <DownloadDataDict />
              <PaginatedDownloadCsv
                disableDownloadCsv={totalGeosegments === 0}
                disableDownloadText={
                  totalGeosegments === 0
                    ? ToolTipMessages.DOWNLOAD_CSV.title
                    : ""
                }
                componentName="Measurement"
                fetchData={measurementObj.fetchApiData}
                csvDataToDownload={csvDataToDownload}
                tabPillCount={measurementObj.totalCount}
                finalData={finalGeosegments}
                removeColumns={measurementObj.removeColumns}
                renameHeaders={{ name: "geosegment_name" }}
                filename={measurementObj.csvFileName}
                tabName={tabName}
                key={tabName}
                dataSize={measurementObj.totalDataSize}
              />
            </div>

            <TableView
              history={history}
              rowDetails={measurementDetails}
              prepareList={prepareMeasurementList}
              columns={measurementObj.columns}
              data={measurementObj.measurementList}
              fetchData={measurementObj.fetchApiData}
              keyField={keyField}
              noDataFoundText={
                displayError ? (
                  <DisplayError {...errorMessage} />
                ) : (
                  noDataFoundText()
                )
              }
              page={page}
              searchableColumn={measurementObj.searchableColumn}
              sizePerPageValue={sizePerPageValue}
              sortColumn={measurementObj.sortColumns}
              totalSize={measurementObj.totalDataSize}
              updateList={measurementObj.measurementResponse}
              activeTab={tabName}
              isDataFetched={isDataFetch}
              key={tabName}
              defaultSorted={defaultSorted}
              searchInfoText={ToolTipMessages.SEARCH_INFO_TEXT.geo_segment}
            />
          </div>
        )}
      </div>
    </>
  );
};

MeasurementDetails.propTypes = {
  geosegmentsList: PropTypes.arrayOf(PropTypes.object),
  measurementData: PropTypes.arrayOf(PropTypes.object),
  displayError: PropTypes.bool,
  errorMessage: PropTypes.shape(),
  fetchGeosegments: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  measurementDetails: PropTypes.shape(),
  measurementMapping: PropTypes.shape(),
  isLoaded: PropTypes.bool,
  isSuperUser: PropTypes.bool,
  loading: PropTypes.bool,
  orgName: PropTypes.string,
  orgData: PropTypes.shape(),
  page: PropTypes.number,
  prepareMeasurementList: PropTypes.func,
  setLoader: PropTypes.func,
  sizePerPageValue: PropTypes.number,
  totalGeosegments: PropTypes.number,
  totalSize: PropTypes.number,
  triggerLoadData: PropTypes.func,
  tabItems: PropTypes.arrayOf(PropTypes.object),
  tabName: PropTypes.string,
  userPermission: PropTypes.bool,
  userData: PropTypes.shape(),
};

export default MeasurementDetails;
