import React from "react";
import PropType from "prop-types";
import * as yup from "yup";
import { every, isNull } from "lodash";
import {
  Card,
  Form,
  Input,
  Section,
  setFormError,
  isFormDirty,
} from "@px/px_design_system";
import { toast } from "react-toastify";

import Headline from "../../../Headline";
import ToastMsg from "../../../Utilities/ToastMsg";
import { isFutureDate } from "../../../Utilities/FlatFileComponent/fieldValidation";
import {
  constructAdminAPIEndPoint,
  checkIfModelDataExist as checkIfAdunitExist,
  fetchHandler,
  fixedEncodeURIComponent,
} from "../../../../../../utils";
import { AdunitFormContext } from "./AdunitFormContext";
import settings from "../../../../../../settings";
import ToolTipMessages from "../../../Utilities/ToolTip/TooltipMessages";
import { triggerDDActionEvent } from "../../../Utilities/FlatFileComponent/lib";
import { Prompt } from "react-router-dom";

import "./style.css";

const moment = require("moment-timezone");

let uploadCount = 0;
let updateCount = 0;
let startTime = 0;
let endTime = 0;
const privateAuctionValues = {
  "": "Select",
  1: "Active",
  0: "Inactive",
};
export default class FormAdunit extends React.Component {
  static contextType = AdunitFormContext;
  context = this.context;

  state = {
    globalError: false,
    /* Disable updates for Read User */
    disableSubmit: this.context.isEditMode && !this.context.isImportEnabled,
    isSubmiting: true,
  };

  formatDate = (date) =>
    moment(
      new Date(moment(date).format("YYYY-MM-DDTHH:mm:ss.[000Z]")).toUTCString()
    ).unix();

  submitAdunit = async (formData) => {
    const formFinalData = { ...formData };
    formFinalData["start_date"] = this.formatDate(formFinalData["start_date"]);
    formFinalData["private_auction"] =
      formFinalData["private_auction"] === ""
        ? null
        : formFinalData["private_auction"];
    if (
      formFinalData["auction"] &&
      formFinalData["auction"]["alt_bidfloors"]["banner"] == ""
    ) {
      formFinalData["auction"]["alt_bidfloors"]["banner"] = null;
    }
    if (
      formFinalData["auction"] &&
      formFinalData["auction"]["alt_bidfloors"]["video"] == ""
    ) {
      formFinalData["auction"]["alt_bidfloors"]["video"] = null;
    }
    const { orgId, isEditMode } = this.context;
    this.setState({ disableSubmit: true, isSubmiting: false });

    const checkDuplicateAdunit = await checkIfAdunitExist(
      orgId,
      "adunits",
      formData?.name,
      "name"
    );
    if (checkDuplicateAdunit?.name && !isEditMode) {
      setFormError("name", {
        type: "manual",
        message: "Adunit name already exists.",
      });
      this.setState({ disableSubmit: false });
    } else {
      startTime = performance.now();
      this.submitFormData(formFinalData);
    }
  };

  componentWillUnmount() {
    uploadCount = 0;
    updateCount = 0;
  }

  submitFormData(dataForm) {
    const { orgId, isEditMode, formDefaultValues } = this.context;
    this.setState({ globalError: false });
    const url = isEditMode
      ? `orgs/${orgId}/adunits/${fixedEncodeURIComponent(
          formDefaultValues?.name
        )}`
      : `orgs/${orgId}/adunits`;
    const apiParams = {
      method: isEditMode ? "PATCH" : "POST",
      url: constructAdminAPIEndPoint({ url: url }),
      headers: { "cache-control": "no-cache, no-store" },
      body: dataForm,
    };
    return fetchHandler(apiParams)
      .then(() => {
        const { history } = this.props;
        endTime = performance.now();
        isEditMode ? updateCount++ : uploadCount++;
        triggerDDActionEvent({
          endTime,
          startTime,
          uploadCount,
          updateCount,
          uploadType: "adunits",
        });
        const props = {
          toastTitle: `${dataForm?.name} Adunit ${
            !isEditMode ? "Added" : "Updated"
          } Successfully!`,
          toastSubTitle:
            "You can modify the adunit details at any time by navigating to this adunit on the Inventory Management page adunit section.",
        };
        toast.success(<ToastMsg {...props} />, {
          toastId: "adunit-success",
          position: "top-center",
        });
        this.setState({ disableSubmit: false });
        history.push(`../summary/adunits`);
      })
      .catch(() => {
        this.setState({ globalError: true });
        this.setState({ disableSubmit: false });
      });
  }

  isUserAuthenticated = () => {
    return localStorage.getItem("isAuthenticated");
  };

  dropDownValues = (dataObj) => {
    const dropdownOptions = [];
    for (let key in dataObj) {
      dropdownOptions.push(
        <option value={key} key={key}>
          {dataObj[key]}
        </option>
      );
    }
    return dropdownOptions;
  };

  yupOptionalDecimal = (testName, message) =>
    yup.lazy((value) =>
      value === "" || value === null
        ? yup.mixed().notRequired(true)
        : yup
            .number()
            .min(0)
            .nullable(true)
            .test(testName, message, (value) =>
              (value + "").match(/^(\d{0,4}\.(\d{0,2})|\d{0,4})?$/)
            )
    );

  yupCheckAuctionIsEmpty = (auction) => {
    return (
      isNull(auction) ||
      (isNull(auction.bidfloor) &&
        every(auction.alt_bidfloors, (v) => v === "" || isNull(v)))
    );
  };

  render() {
    const { isSubmiting, disableSubmit, globalError } = this.state;
    const {
      formDefaultValues,
      isEditMode,
      singleAdunitData,
      integrationTypeOptions,
      networkOptions,
      statusOptions,
      isInvalidSuperUser,
    } = this.context;

    const isDefaultStatusInReview =
      !isInvalidSuperUser && formDefaultValues.status === 6;
    const InReviewStatusInfo = (
      <>
        'In-Review' status can only be set/reset by PX Employees. Refer to{" "}
        <a
          href="https://help.placeexchange.com/s/article/How-In-Review-adunit-status-is-used"
          target="_blank"
          rel="noreferrer"
          className="inreview-status-link"
        >
          help article
        </a>{" "}
        for more details
      </>
    );

    return (
      <>
        <Headline>Adunit</Headline>
        <div className="embed--dashboard wrapper adunit--form-container">
          <h2 className="sub__headline--copy">
            {isEditMode
              ? `Edit - Adunit ID - ${singleAdunitData.id}`
              : "Add Adunit"}
          </h2>
          {isSubmiting && (
            <Prompt
              message={() => {
                return isFormDirty() ? "Are you sure you want to leave?" : true;
              }}
            />
          )}
          <Form
            isUserAuthenticated={this.isUserAuthenticated}
            globalError={globalError}
            onSubmit={(formData) => this.submitAdunit(formData)}
            defaultValues={formDefaultValues}
            redirectUrl={"../summary/adunits"}
            tooltipTitle={ToolTipMessages.UPDATE_ADUNITS.noPermission}
            validationSchema={{
              name: yup.string().max(100).required("Name is a required field"),
              notes: yup.string().max(4000),
              status: yup
                .string()
                .test(
                  "test In-Review adunit status validation",
                  InReviewStatusInfo,
                  function (value) {
                    if (!isInvalidSuperUser && formDefaultValues.status !== 6) {
                      return value === "6" ? false : true;
                    }
                    return true;
                  }
                ),
              start_date: yup
                .string()
                .nullable(true)
                .test(
                  "is-required",
                  "Start Date is required if status is pending",
                  function (value) {
                    const { status } = this.parent;
                    if (status === "1") {
                      if (!value) {
                        return false;
                      }
                    }
                    return true;
                  }
                )
                .test(
                  "is-future-date",
                  "Start Date should be a future date when status is pending",
                  function (value) {
                    const { status } = this.parent;
                    if (status === "1") {
                      if (!isFutureDate(value)) {
                        return false;
                      }
                    }
                    return true;
                  }
                ),
              auction: yup.lazy((value) =>
                this.yupCheckAuctionIsEmpty(value)
                  ? yup.mixed().notRequired()
                  : yup.object().shape({
                      bidfloor: yup.lazy((value) =>
                        value === ""
                          ? yup
                              .string()
                              .required("Floor Price is a required field")
                          : yup
                              .number()
                              .transform((value) =>
                                isNaN(value) || value === null
                                  ? undefined
                                  : value
                              )
                              .required("Floor Price is a required field")
                              .min(0)
                              .test(
                                "is-valid-bidfloor",
                                "Ensure that there are no more than 4 digit before the decimal point.",
                                (value) =>
                                  (value + "").match(
                                    /^(\d{0,4}\.(\d{0,2})|\d{0,4})?$/
                                  )
                              )
                      ),
                      alt_bidfloors: yup.object().shape({
                        banner: this.yupOptionalDecimal(
                          "is-valid-bidfloor-banner",
                          "Ensure that there are no more than 4 digit before the decimal point."
                        ),
                        video: this.yupOptionalDecimal(
                          "is-valid-bidfloor-video",
                          "Ensure that there are no more than 4 digit before the decimal point."
                        ),
                      }),
                    })
              ),
            }}
            submitButtonText={`${isEditMode ? "Update Adunit" : "Add Adunit"}`}
            disableSubmit={disableSubmit}
            displayTooltip={this.context.isImportEnabled}
            {...this.props}
          >
            <Section title="Basic Information" />
            <Input
              type="text"
              name="name"
              label="Name"
              placeholder="Enter name"
              isRequired
              readOnly
            />
            <Input
              type="textarea"
              name="notes"
              label="Notes"
              placeholder="Additional context or notes"
            />
            <Input
              type="select"
              name="integration_type"
              label="Integration Type"
              subTitle="Determines the integration path of the adunit"
            >
              {this.dropDownValues(integrationTypeOptions)}
            </Input>
            <Input
              type="select"
              name="status"
              label="Status"
              disabled={isDefaultStatusInReview}
              subTitle={
                isDefaultStatusInReview ? (
                  <>Status of the adunit. {InReviewStatusInfo}</>
                ) : (
                  "Status of the adunit"
                )
              }
            >
              {this.dropDownValues(statusOptions)}
            </Input>
            <Input
              type="datetime-local"
              max={`${settings.INT32_MAX_DATE}`}
              name="start_date"
              label="Start Date & Time (UTC)"
              subTitle="First date and time the adunit will be available to transact on PX"
            />
            <Input
              type="select"
              name="network_name"
              label="Network"
              subTitle="Externally defined ID for a resource"
            >
              <option value="" disabled selected hidden>
                Select Network
              </option>
              {this.dropDownValues(networkOptions)}
            </Input>

            <Section title="Auction" withAboveBorder={true} />

            <Input type="select" name="private_auction" label="Private Auction">
              {this.dropDownValues(privateAuctionValues)}
            </Input>
            <Input
              type="text"
              formGroupClassName="adunit__auction-bidfloor"
              name="auction.bidfloor"
              label="Bidfloor"
              placeholder="Bidfloor"
              subTitle="Default floor price"
              isRequired
            />
            <div className="adunit__auction-bidfloor-currency">
              <Card
                cardTitle="Bidfloor Currency"
                cardText={formDefaultValues.auction?.bidfloorcur}
              />
            </div>
            <Input
              type="number"
              name="auction.alt_bidfloors.banner"
              label="Alt Bidfloor Banner"
              placeholder="Bidfloor Banner"
            />
            <Input
              type="number"
              name="auction.alt_bidfloors.video"
              label="Alt Bidfloor Video"
              placeholder="Bidfloor Video"
            />
          </Form>
        </div>
      </>
    );
  }
}

FormAdunit.contextTypes = {
  formDefaultValues: PropType.any,
  integrationTypeOptions: PropType.any,
  isEditMode: PropType.any,
  networkOptions: PropType.any,
  orgId: PropType.any,
  singleAdunitData: PropType.any,
  statusOptions: PropType.any,
  isInvalidSuperUser: PropType.any,
};
