import { prepareOptions } from "../../../Utilities/FlatFileComponent/lib";

export const networks__sheet = (inventoryMapping) => {
  return [
    {
      name: "Networks Sheet",
      slug: "networks__sheet",
      readonly: false,
      mappingConfidenceThreshold: 0.9,
      fields: [
        {
          label: "network_name",
          key: "name",
          description: "Publisher defined ID.",
          type: "string",
          constraints: [
            {
              type: "required",
            },
            {
              type: "unique",
            },
          ],
        },
        {
          label: "auction.alt_bidfloors.banner",
          key: "auction.alt_bidfloors.banner",
          description: `Additional bidfloor value that might apply to different types of advertising for a transaction. Values are expected CPM rates represented in full currency units, as defined in bidfloorcur. If empty or null, value is assumed to be equal to bidfloor
          In the event that multiple floors apply, each alt_bidfloors will be matched to their respective capability. The banner alt_bidfloor will be used for banner bids.`,
          type: "number",
        },
        {
          label: "auction.alt_bidfloors.video",
          key: "auction.alt_bidfloors.video",
          description: `Additional bidfloor value that might apply to different types of advertising for a transaction. Values are expected CPM rates represented in full currency units, as defined in bidfloorcur. If empty or null, value is assumed to be equal to bidfloor
          In the event that multiple floors apply, each alt_bidfloors will be matched to their respective capability. The video alt_bidfloor will be used for video bids.`,
          type: "number",
        },
        {
          label: "auction.at",
          key: "auction.at",
          description: `Type of auction that will be run. <br />
          See available options in below dropdown.`,
          type: "enum",
          config: {
            options: prepareOptions("auction.at", inventoryMapping, true),
          },
          constraints: [
            {
              type: "required",
            },
          ],
        },
        {
          label: "auction.bidfloor",
          key: "auction.bidfloor",
          description:
            "Default Floor Price (can be overridden by deal based floor pricing or bid floor sent on an individual ad request.)",
          type: "number",
        },
        {
          label: "auction.bidfloorcur",
          key: "auction.bidfloorcur",
          description: `Floor Price Currency. <br />
          See available options in below dropdown.`,
          type: "enum",
          config: {
            options: prepareOptions("floorCurrency", inventoryMapping),
          },
        },
        {
          label: "branding.bundle",
          key: "branding.bundle",
          description:
            "The identifier for a Mobile App corresponding to a given set of inventory. If no matching Mobile App is available, the Place Exchange integration team will work to assign an appropriate identifier.",
          type: "string",
        },
        {
          label: "branding.shortcode",
          key: "branding.shortcode",
          description:
            "SMS shortcode required for Call-to-Action (CTA) prompts.",
          type: "string",
        },
        {
          label: "branding.shorturl",
          key: "branding.shorturl",
          description:
            "URL shortlink required for Call-to-Action (CTA) prompts.",
          type: "string",
        },
        {
          label: "branding.url",
          key: "branding.url",
          description:
            "Website corresponding to a given set of inventory. If no matching website is available, the Place Exchange integration team will work to assign an appropriate identifier.",
          type: "string",
        },
        {
          label: "measurement.duration",
          key: "measurement.duration",
          description:
            "Typical play duration, used to calculate Per-Play Impression Estimate.",
          type: "number",
        },
        {
          label: "measurement.imp_four_week",
          key: "measurement.imp_four_week",
          description:
            "Estimated number of people who exposed to a play over 4 weeks.",
          type: "number",
        },
        {
          label: "measurement.imp_x",
          key: "measurement.imp_x",
          description: "Per-Play Impression Estimate",
          type: "number",
        },
        {
          label: "measurement.provider",
          key: "measurement.provider",
          description: `Due to demand-side requirements, measurement data should be provided in terms of a Place Exchange approved 3rd-party rating agency. While publisher-determined numbers may be accepted on responses, the billable quantity of impressions for a given play may be capped for unapproved providers / self-declared numbers. If you have questions about a particular provider / methodology, please contact your partnership manager, or email [integrations@placeexchange.com]. <br />
          See available options in below dropdown.`,
          type: "enum",
          config: {
            options: prepareOptions(
              "measurement.provider",
              inventoryMapping,
              true
            ),
          },
        },
        {
          label: "measurement.segment",
          key: "measurement.segment",
          description: `The name of the segment for which Geosegments hourly numbers are calculated. The field is applicable only if impression methodology supports hourly data.<br />Supported values: A16+`,
          type: "string",
        },
        {
          label: "measurement_config.ads_per_hour",
          key: "measurement_config.ads_per_hour",
          description: `Typical number of ads displayed in a 1-hour period when the screen is active (for example, if 1 ad plays every 5 minutes, the ads per hour would be 12). If screens are constantly displaying ads, you may leave this field blank. The field helps buyers better understand the nature of the inventory and is also used to refine Reach & Frequency measurement. Only relevant for digital inventory.`,
          type: "number",
        },
        {
          label: "notes",
          key: "notes",
          description: "Free text field to capture additional information.",
          type: "string",
        },
        {
          label: "private_auction",
          key: "private_auction",
          description: `Indicator of auction eligibility on bids that do not contain dealids where 0= all bids accepted, 1 = bids are restricted to the deals specified <br />
          See available options in below dropdown.`,
          type: "enum",
          config: {
            options: prepareOptions("private_auction", inventoryMapping, true),
          },
        },
        {
          label: "restrictions.badomain",
          key: "restrictions.badomain",
          description:
            "Buyer landing pages that are excluded from being able to participate in auctions.",
          type: "string",
        },
        {
          label: "restrictions.battr",
          key: "restrictions.battr",
          description:
            "Creative attributes that are excluded from being able to participate in auctions.",
          type: "string",
        },
        {
          label: "restrictions.bbundle",
          key: "restrictions.bbundle",
          description:
            "Bundle identifier for app-based ads that are excluded from being able to participate in auctions.",
          type: "string",
        },
        {
          label: "restrictions.bbuyer",
          key: "restrictions.bbuyer",
          description:
            "Buyers (DSPs) that are excluded from being able to participate in auctions.",
          type: "string",
        },
        {
          label: "restrictions.bcat",
          key: "restrictions.bcat",
          description:
            "Ad content categories that are excluded from being able to participate in auctions.",
          type: "string",
        },
        {
          label: "restrictions.bcid",
          key: "restrictions.bcid",
          description:
            "Buyer campaigns that are excluded from being able to participate in auctions identified by their Place Exchange id.",
          type: "string",
        },
        {
          label: "restrictions.bcrid",
          key: "restrictions.bcrid",
          description:
            "Buyer creatives that are excluded from being able to participate in auctions identified by their Place Exchange id.",
          type: "string",
        },
        {
          label: "restrictions.bseat",
          key: "restrictions.bseat",
          description:
            "Buyer seats that are excluded from being able to participate in auctions.",
          type: "string",
        },
        {
          label: "restrictions.btype",
          key: "restrictions.btype",
          description:
            "Banner Ad Types that are excluded from being able to participate in auctions.",
          type: "string",
        },
        {
          label: "restrictions.wlang",
          key: "restrictions.wlang",
          description:
            "Language of the creative using ISO-639-1-alpha-2. This will be 2 letter ISO code for language.",
          type: "string",
        },
      ],
    },
  ];
};
