import React from "react";
import { ButtonDropdown, ToolTips } from "@px/px_design_system";
import PropTypes from "prop-types";

import ToolTipMessages from "../../Utilities/ToolTip/TooltipMessages";

const AddPlacements = ({
  isImportEnabled,
  history,
  resourceAvailable,
  platformRef,
}) => {
  const goToManageOrder = (event, name) => {
    if (event.target.innerText === "Add Placement")
      history.push(`../placement/add${name ? `?name=${name}` : ""}`);
  };

  /* istanbul ignore next */
  const handlePlatform = () => {
    platformRef?.current.click();
  };

  return (
    <div className="import__button">
      <ToolTips
        disabled={!isImportEnabled || !resourceAvailable}
        text={
          !isImportEnabled
            ? ToolTipMessages.ADD_PLACEMENTS.noPermission
            : ToolTipMessages.ADD_PLACEMENTS.title
        }
      >
        <div id="flatfile--placements">
          <ButtonDropdown
            isDisabled={!isImportEnabled || !resourceAvailable}
            withoutPadding
            dropdownItems={
              isImportEnabled && resourceAvailable
                ? [<div onClick={handlePlatform}>Upload CSV</div>]
                : [<div>Upload CSV</div>]
            }
            primaryText="Add Placement"
            handleClick={(event) => goToManageOrder(event)}
          />
        </div>
      </ToolTips>
    </div>
  );
};

AddPlacements.propTypes = {
  isImportEnabled: PropTypes.bool,
};

export default AddPlacements;
