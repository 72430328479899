/* eslint-disable no-use-before-define */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Spinner,
  textTruncate,
  Filter,
  MultiSelect,
} from "@px/px_design_system";
import PropTypes from "prop-types";

import AddDeals from "./AddDeals";
import ArchiveDeals from "./ArchiveDeals/index";
import dealModel from "../../../models/Deal";
import GuidelineSection from "../../GuidelineSection";
import PaginatedDownloadCsv from "../../Utilities/PaginatedDownloadCsv";
import ToastMsg from "../../Utilities/ToastMsg";
import {
  prepareCsvData,
  fixedEncodeURIComponent,
  showCustomTooltip,
  getUTCDateTime,
  splitCommaSeparatedStringToArray,
} from "../../../../../utils";
import { Table } from "../../Utilities/Table";
import { PlatformFlatfile } from "../../Utilities/FlatFileComponent/Platform";

import "./style.css";
import { deals__sheet } from "./DealsMetaData/blueprint";
import { listenerDeals } from "./DealsMetaData/listener";
import { archived__deals__sheet } from "./ArchiveDeals/blueprint";
import { listenerArchiveDeals } from "./ArchiveDeals/listener";
import { postProcessArchiveFields } from "./DealsMetaData/customValidationFields";

let searchValue;
const createLinkToRedirectPlacement = (placements) => {
  const placementLinks = [];
  placements.map((placementName, index) => {
    placementLinks.push(
      <>
        <Link
          to={`../deals/placement/edit${
            searchValue ? searchValue.trim() + "&" : "?"
          }name=${fixedEncodeURIComponent(placementName)}`}
          key={placementName}
        >
          {placementName}
        </Link>
        {index !== placements.length - 1 ? ", " : ""}
      </>
    );
  });
  return (
    <React.Fragment>
      {placementLinks.length ? placementLinks : "-"}
    </React.Fragment>
  );
};

const createLinkToTableId = (cell) => (
  <Link to={`../deals/edit?name=${fixedEncodeURIComponent(cell)}`}>{cell}</Link>
);

const spinnerProps = {
  className: "spinner-icon",
  color: "primary",
  loadingtext: "Loading",
};

const DealDetails = ({ history, isImportEnabled, orgData, states }) => {
  searchValue = history.location.search;
  const platformRef = useRef(null);
  const archiveplatformRef = useRef(null);
  const [finalDeals, setFinalDeals] = useState([]);
  const [isFinalDealsReady, setFinalDealsReady] = useState(false);
  const {
    displayError,
    isLoaded,
    triggerLoadData,
    totalDeals,
    loading,
    setFilterParams,
    fetchDeals,
    filterParams,
  } = states;

  useEffect(() => {
    const { dealDetails } = states;
    csvDataToDownload(dealDetails);
  }, [loading, isLoaded, isFinalDealsReady]);

  const downloadFilteredDeals = (
    urlParams = [],
    setCount = false,
    setSize = false
  ) => {
    const uniqueData = [
      ...urlParams
        .reduce((map, obj) => map.set(obj["key"], obj), new Map())
        .values(),
    ];
    return fetchDeals(uniqueData, setCount, setSize, false);
  };

  const csvDataToDownload = (list, setStates = true) => {
    const listData = list.map((item) => ({
      ...item,
      priority: setStates ? item.priority_display : item.priority,
      wbuyer_display: item.wbuyer_display || "All DSPs",
    }));
    const getCsvData = prepareCsvData({
      list: listData,
      fileFormat: "csv",
      modelObj: dealModel,
      jsonFields: ["ext"],
      dateTimePerseFields: ["lastmod", "ts"],
    });
    /* istanbul ignore next */
    if (!setStates) {
      return getCsvData;
    } else {
      setFinalDeals(getCsvData);
      setFinalDealsReady(true);
    }
  };

  /* istanbul ignore next */
  const registerDeals = () => {
    const { setLoader } = states;
    setFilterParams([{ key: "archived", value: "false" }]);
    setLoader(false);
    triggerLoadData();
  };

  const abortController = new AbortController();

  /* istanbul ignore next */
  const flatfileCloseArchiveDeals = (errorJson = []) => {
    abortController.abort();
    if (errorJson.length) {
      const props = {
        icon: ["fas", "exclamation-triangle"],
        toastTitle: `Please find the failed deals by clicking the 'Download CSV' button`,
        toastSubTitle: `You can use the downloaded csv to re-upload the failed deals`,
        isDownloadable: true,
        csvProps: {
          json: errorJson,
          moveToFirstPosition: ["name", "error_message"],
          removeColumns: ["asset.aspect_ratio"],
          renameHeaders: { name: "deal_name" },
          filename: "Failed_Archiving_Deals",
        },
      };
      toast.error(<ToastMsg {...props} />, {
        toastId: "failed-archive_deals",
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
      });
    }
    registerDeals();
  };
  /* istanbul ignore next */
  const flatfileClose = (errorJson = []) => {
    abortController.abort();
    if (errorJson.length) {
      const props = {
        icon: ["fas", "exclamation-triangle"],
        toastTitle: `Please find the failed deals by clicking the 'Download CSV' button`,
        toastSubTitle: `You can use the downloaded csv to re-upload the failed deals`,
        isDownloadable: true,
        csvProps: {
          json: csvDataToDownload(errorJson, false),
          moveToFirstPosition: ["name", "error_message"],
          removeColumns: [
            "allowed_pct",
            "archived",
            "contract_id",
            "created_by",
            "ext",
            "id",
            "lastmod",
            "owned_by",
            "placements",
            "token",
            "ts",
            "wbuyer_display",
            "wbuyer_single_item",
            "asset.aspect_ratio",
          ],
          renameHeaders: { name: "deal_name" },
          filename: "Failed_Deals",
        },
      };
      toast.error(<ToastMsg {...props} />, {
        toastId: "failed-deals",
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
      });
    }
    registerDeals();
  };

  const importProps = {
    isImportEnabled,
    wbuyerOptions: states.wbuyerOptions,
    priorityOptions: states.priorityOptions,
    history,
    currencyMapping: {
      bidfloorcur: {
        [orgData.config.allowed_currency]: orgData.config.allowed_currency,
      },
    },
  };

  const archiveProps = {
    registerDeals,
    isImportEnabled,
    orgData,
  };

  const updateTableData = (tableData) => {
    setFilterParams(tableData);
  };

  const archiveOptions = [
    { value: true, label: "Archived" },
    { value: false, label: "Unarchived" },
  ];

  /* istanbul ignore next */
  let remote_columns = [
    {
      accessorKey: "name",
      header: "Deal Name",
      Cell: ({ cell }) => {
        return createLinkToTableId(cell.getValue());
      },
    },
    {
      accessorKey: "token",
      header: "Deal Token (DSP Deal ID)",
    },
    {
      accessorKey: "priority_display",
      header: "Priority",
    },
    {
      accessorKey: "at_display",
      header: "Auction Type",
    },
    {
      accessorKey: "wbuyer_display",
      header: "Allowed Buyers",
    },
    {
      accessorKey: "bidfloor",
      header: "Bid Floor",
      Cell: ({ cell }) => {
        return `$${cell.getValue()}`;
      },
    },
    {
      accessorKey: "placements_display",
      header: "Placements",
      Cell: ({ cell }) => {
        const placementsList =
          splitCommaSeparatedStringToArray(cell.getValue()) || [];
        return createLinkToRedirectPlacement(placementsList);
      },
    },
    {
      accessorKey: "lastmod",
      header: "Last Modified",
      Cell: ({ cell }) => {
        return showCustomTooltip(
          getUTCDateTime(cell.getValue()),
          "Time shown in UTC"
        );
      },
      // formatter: (data) => showCustomTooltip(data, "Time shown in UTC"),
    },
    {
      accessorKey: "id",
      header: "Id",
      Cell: ({ cell }) => {
        return textTruncate(cell.getValue());
      },
    },
  ];

  return (
    <div
      className={`deal-list ${displayError ? "no--data--found" : ""} ${
        isLoaded ? "wrapper" : "wrapper--no-border"
      }`}
    >
      {(!isLoaded || loading) && <Spinner {...spinnerProps} />}
      {isLoaded && !loading && (
        <div className="deal-container">
          <h2 className="deal__headline deal__headline--copy sub__headline--count">
            <>Deals</>
          </h2>
          <GuidelineSection>
            <li>
              Updating deal attributes on a live campaign can affect delivery.
            </li>
            <li>Updates can take up to 30 minutes to reflect in ad serving.</li>
          </GuidelineSection>
          <div className="table__wrapper">
            <Filter
              defaultValues={{
                archived: [
                  {
                    value: false,
                    label: "Unarchived",
                  },
                ],
              }}
              updateFilteredData={updateTableData}
            >
              <MultiSelect
                name="archived"
                options={archiveOptions}
                label="Archived"
                isValidNewOption={() => false}
              />
            </Filter>
          </div>
          <Table
            table_columns={remote_columns}
            orgId={orgData.id}
            name="deals"
            extraFilters={filterParams}
            history={history}
            renderTopToolbarCustomActions={() => {
              return (
                <div className="buttons">
                  <PaginatedDownloadCsv
                    filename="Deals"
                    componentName="Deal"
                    tabName="deals"
                    csvDataToDownload={csvDataToDownload}
                    fetchData={downloadFilteredDeals}
                    tabPillCount={totalDeals}
                    finalData={finalDeals}
                    moveToFirstPosition={[
                      "name",
                      "token", // Pubs typically use this to identify deals in conversations with buyers
                    ]}
                    removeColumns={[
                      "at_display",
                      "wbuyer",
                      "placements_display",
                      "priority_display",
                    ]}
                    renameHeaders={{
                      name: "deal_name",
                      token: "deal_token (dsp deal id)",
                      wbuyer_display: "wbuyer",
                    }}
                    dataSize={totalDeals}
                    filterParams={filterParams}
                  />
                  <AddDeals {...importProps} platformRef={platformRef} />
                  <ArchiveDeals
                    {...archiveProps}
                    archiveplatformRef={archiveplatformRef}
                    hideButton={isImportEnabled}
                  />
                </div>
              );
            }}
          />
          {/** Flatfile archive deals model view */}
          <PlatformFlatfile
            name="archive__deals"
            platformRef={archiveplatformRef}
            fields={archived__deals__sheet}
            listener={listenerArchiveDeals(
              orgData,
              flatfileCloseArchiveDeals,
              abortController,
              postProcessArchiveFields
            )}
            submitActionName="submitActionArchiveDeals"
            flatfileClose={flatfileClose}
            hideButton
          />
          {/** Flatfile add deals model view */}
          <PlatformFlatfile
            name="deals"
            platformRef={platformRef}
            fields={deals__sheet(
              importProps.wbuyerOptions,
              importProps.priorityOptions,
              importProps.currencyMapping
            )}
            listener={listenerDeals(
              orgData,
              importProps.priorityOptions,
              flatfileClose,
              abortController,
              importProps.wbuyerOptions
            )}
            submitActionName="submitActionDeals"
            flatfileClose={flatfileClose}
            hideButton
          />
        </div>
      )}
    </div>
  );
};

DealDetails.propTypes = {
  dealDetails: PropTypes.arrayOf(PropTypes.object),
  displayError: PropTypes.bool,
  fetchDeals: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  isLoaded: PropTypes.bool,
  isImportEnabled: PropTypes.bool,
  listOfObjects: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  orgData: PropTypes.shape(),
  page: PropTypes.number,
  rowDetails: PropTypes.shape(),
  sizePerPageValue: PropTypes.number,
  states: PropTypes.shape(),
  totalDeals: PropTypes.number,
  totalSize: PropTypes.number,
  userData: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(), PropTypes.number])
  ),
};

export default DealDetails;
