const networkModel = {
  allowed_pct: null,
  auction: {
    alt_bidfloors: { banner: null, video: null },
    at: null,
    bidfloor: null,
    bidfloorcur: "",
  },
  branding: {
    bundle: "",
    shortcode: "",
    shorturl: "",
    url: "",
  },
  created_by: null,
  dynamic_creative: null,
  ext: null,
  id: "",
  lastmod: "",
  measurement: {
    duration: "",
    imp_four_week: null,
    imp_x: "",
    provider: "",
    segment: "",
  },
  measurement_config: {
    ads_per_hour: null,
  },
  name: "",
  notes: "",
  owned_by: "",
  placements: [],
  private_auction: null,
  restrictions: {
    badomain: [],
    battr: [],
    bbundle: [],
    bbuyer: [],
    bcat: [],
    bcid: [],
    bcrid: [],
    bseat: [],
    btype: [],
    wlang: [],
  },
  ts: "",
};

export default networkModel;
