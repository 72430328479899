import { prepareOptions } from "../../../Utilities/FlatFileComponent/lib";

export const adunits__sheet = (inventoryMapping) => {
  return [
    {
      name: "Adunits Sheet",
      slug: "adunits__sheet",
      readonly: false,
      mappingConfidenceThreshold: 0.9,
      fields: [
        {
          label: "adunit_name",
          key: "name",
          description: "Publisher defined ID.",
          type: "string",
          constraints: [
            {
              type: "required",
            },
            {
              type: "unique",
            },
          ],
        },
        {
          label: "ad_formats",
          key: "ad_formats",
          type: "string",
          description: "Comma-separated ad formats in WxH format.",
        },
        {
          label: "aspect_ratios",
          key: "aspect_ratios",
          type: "string",
          description:
            "Comma-separated Aspect Ratios in WRatio:HRatio format. Supported Aspect Ratios are 16:9, 9:16, 4:3, 3:4",
        },
        {
          label: "asset.aspect_ratio",
          key: "asset.aspect_ratio",
          description: "Slot Aspect Ratio",
          type: "string",
          constraints: [
            {
              type: "required",
            },
          ],
        },
        {
          label: "asset.capability.audio",
          key: "asset.capability.audio",
          description:
            "Indicator of AdUnit's audio support for Video Ads (must be audible) - default value is false (supported=true, not-supported=false)",
          type: "boolean",
        },
        {
          label: "asset.capability.banner",
          key: "asset.capability.banner",
          description:
            "Indicator of Banner Ad support - default value is true (supported=true, not-supported=false)",
          type: "boolean",
        },
        {
          label: "asset.capability.video",
          key: "asset.capability.video",
          description:
            "Indicator of Video Ad support - default value is false (supported=true, not-supported=false)",
          type: "boolean",
        },
        {
          label: "asset.capability.perview",
          key: "asset.capability.perview",
          description:
            "Indicates that the asset will be measured for PerView - default value is false (supported=true, not-supported=false)",
          type: "boolean",
        },
        {
          label: "asset.category",
          key: "asset.category",
          description: `The asset category represents the physical and/or technical profile of the asset itself and may be used to indicate the size, placement and/or capabilities of the display on which an ad will be shown.<br />
        See available options in below dropdown.`,
          type: "enum",
          config: {
            options: prepareOptions("asset.category", inventoryMapping, true),
          },
        },
        {
          label: "asset.image_url",
          key: "asset.image_url",
          description: "Image url associated with Asset.",
          type: "string",
        },
        {
          label: "asset.mimes",
          key: "asset.mimes",
          type: "string",
          description: `Types supported for playback on a given asset. If empty, all values are assumed to be supported.<br/>
        Supported values: text/html, video/mp4, image/jpeg, image/png.`,
        },
        {
          label: "asset.name",
          key: "asset.name",
          type: "string",
          description: "Name for given asset assigned by Publisher.",
        },
        {
          label: "asset.screen_count",
          key: "asset.screen_count",
          type: "number",
          description:
            "Defines how many screens are a part of a given sellable unit. Default value if not specified is 1.",
        },
        {
          label: "asset.size",
          key: "asset.size",
          type: "number",
          description:
            "Diagonal measure of the addressable display area in inches.",
          constraints: [
            {
              type: "required",
            },
          ],
        },
        {
          label: "asset.type",
          key: "asset.type",
          description: `Describes the type of asset. <br />
    See available options in below dropdown.`,
          constraints: [
            {
              type: "required",
            },
          ],
          type: "enum",
          config: {
            options: [
              { value: "digital", label: "digital" },
              { value: "static-fixed", label: "static-fixed" },
              { value: "static-rotating", label: "static-rotating" },
            ],
          },
        },
        {
          label: "auction.alt_bidfloors.banner",
          key: "auction.alt_bidfloors.banner",
          type: "number",
          description: `Additional bidfloor value that might apply to different types of advertising for a transaction. Values are expected CPM rates represented in full currency units, as defined in bidfloorcur. If empty or null, value is assumed to be equal to bidfloor
        In the event that multiple floors apply, each alt_bidfloors will be matched to their respective capability. The banner alt_bidfloor will be used for banner bids.`,
        },
        {
          label: "auction.alt_bidfloors.video",
          key: "auction.alt_bidfloors.video",
          type: "number",
          description: `Additional bidfloor value that might apply to different types of advertising for a transaction. Values are expected CPM rates represented in full currency units, as defined in bidfloorcur. If empty or null, value is assumed to be equal to bidfloor
        In the event that multiple floors apply, each alt_bidfloors will be matched to their respective capability. The video alt_bidfloor will be used for video bids.`,
        },
        {
          label: "auction.at",
          key: "auction.at",
          description: `Type of auction that will be run. <br />
        See available options in below dropdown.`,
          type: "enum",
          config: {
            options: prepareOptions("auction.at", inventoryMapping, true),
          },
        },
        {
          label: "auction.bidfloor",
          key: "auction.bidfloor",
          description:
            "Default Floor Price (can be overridden by deal based floor pricing or bid floor sent on an individual ad request.)",
          type: "number",
        },
        {
          label: "auction.bidfloorcur",
          key: "auction.bidfloorcur",
          description: `Floor Price Currency. <br />
        See available options in below dropdown.`,
          type: "enum",
          config: {
            options: prepareOptions("floorCurrency", inventoryMapping),
          },
        },
        {
          label: "eids",
          key: "eids",
          description: `Extended IDs allow publishers to provide (multiple) screen IDs from sources outside of Place Exchange. <br />
        Please provide EIDs as a comma separated list of values where each value follows a format of source:uid.id <br />
        As an example - source1:uid.id1,source1:uid.id2,source2:uid.id1`,
          type: "string",
        },
        {
          label: "integration_type",
          key: "integration_type",
          description: `Determines the integration path of the ad unit. This would be a <br />
            0 - API (Full integration) <br />
            1 - PXLite (Light integration) <br />
            2 - VAST <br />
            3 - Broadsign <br />
        See available options in below dropdown.`,
          constraints: [
            {
              type: "required",
            },
          ],
          type: "enum",
          config: {
            options: prepareOptions("integration_type", inventoryMapping, true),
          },
        },
        {
          label: "keywords",
          key: "keywords",
          description:
            "Associate keywords with inventory. Comma separated list of keywords is allowed.",
          type: "string",
        },
        {
          label: "location.altitude",
          key: "location.altitude",
          type: "number",
          description:
            "Vertical distance between the asset and the local surface of the Earth.",
        },
        {
          label: "location.bearing",
          key: "location.bearing",
          type: "number",
          description: `Facing direction of the addressable display area.<br />
      *Required for outdoor assets`,
        },
        {
          label: "location.city",
          key: "location.city",
          description: "City name.",
          type: "string",
        },
        {
          label: "location.country",
          key: "location.country",
          description: "Country name.",
          type: "string",
        },
        {
          label: "location.dma",
          key: "location.dma",
          description: `Designated Market Area or a DMA is a geographic area that represents specific television markets as defined by and updated annually by the Nielsen Company.<br />
      >>PX will derive from lat/lon<<`,
          type: "string",
        },
        {
          label: "location.dma_code",
          key: "location.dma_code",
          description: "Designated Market Area (DMA) codes.",
          type: "number",
        },
        {
          label: "location.horizontal_accuracy",
          key: "location.horizontal_accuracy",
          type: "number",
          description:
            "The radius of uncertainty for the location, measured in meters.",
        },
        {
          label: "location.lat",
          key: "location.lat",
          type: "number",
          description: "Latitude of the asset. While 5 decimal precision is recommended, values may be truncated in adserving based on demand side partners' configurations.",
        },
        {
          label: "location.lon",
          key: "location.lon",
          type: "number",
          description: "Longitude of the asset. While 5 decimal precision is recommended, values may be truncated in adserving based on demand side partners' configurations.",
        },
        {
          label: "location.method",
          key: "location.method",
          type: "string",
          description: "The method used to record the location of the asset.",
        },
        {
          label: "location.region",
          key: "location.region",
          type: "string",
          description: "Region codes.",
        },
        {
          label: "location.speed",
          key: "location.speed",
          type: "number",
          description:
            "Average speed of the asset (for moving assets such as taxi top panels, mobile billboards ,etc.)",
        },
        {
          label: "location.vertical_accuracy",
          key: "location.vertical_accuracy",
          type: "number",
          description:
            "The accuracy of the altitude value, measured in meters.",
        },
        {
          label: "location.zip",
          key: "location.zip",
          type: "string",
          description: "Zip code of location.",
        },
        {
          label: "measurement.duration",
          key: "measurement.duration",
          description:
            "Typical play duration, used to calculate Per-Play Impression Estimate.",
          type: "number",
        },
        {
          label: "measurement.imp_four_week",
          key: "measurement.imp_four_week",
          description:
            "Estimated number of people who exposed to a play over 4 weeks.",
          type: "number",
        },
        {
          label: "measurement.imp_x",
          key: "measurement.imp_x",
          description: "Per-Play Impression Estimate.",
          type: "number",
        },
        {
          label: "measurement.provider",
          key: "measurement.provider",
          description: `Due to demand-side requirements, measurement data should be provided in terms of a Place Exchange approved 3rd-party rating agency. While publisher-determined numbers may be accepted on responses, the billable quantity of impressions for a given play may be capped for unapproved providers / self-declared numbers. If you have questions about a particular provider / methodology, please contact your partnership manager, or email [integrations@placeexchange.com]. <br />
        See available options in below dropdown.`,
          type: "enum",
          config: {
            options: prepareOptions(
              "measurement.provider",
              inventoryMapping,
              true
            ),
          },
        },
        {
          label: "measurement.segment",
          key: "measurement.segment",
          type: "string",
          description: `The name of the segment for which Geosegments hourly numbers are calculated. The field is applicable only if impression methodology supports hourly data.<br />Supported values: A16+`,
        },
        {
          label: "measurement_config.ads_per_hour",
          key: "measurement_config.ads_per_hour",
          description: `The typical number of ads displayed in a 1-hour period when the screen is active (for example, if 1 ad plays every 5 minutes, the Ads per Hour would be 12).\n\nThis field helps buyers better understand the nature of the inventory and is also used to refine Reach & Frequency measurement.\n\nIf ads are typically interspersed with content, such that the ads run for some percentage of the time each hour, then the ads per hour can be derived using the measurement duration, using the following formula: Ads per Hour = 3600 seconds * (% of total time that ads play) / measurement duration in seconds.\n\nFor example, if ads run 25% of the time and the measurement duration is 15 seconds, then the Ads Per Hour would be 3600 * 25% / 15 = 60. If this field is left blank, it will be assumed that the screen is constantly displaying ads (i.e., that ads play 100% of the time), and the Ads per Hour will be calculated as (3600 seconds / measurement duration).`,
          type: "number",
        },
        {
          label: "network_id",
          key: "network_id",
          description: "Id of network to which adunit belongs.",
          type: "string",
        },
        {
          label: "network_name",
          key: "network_name",
          description: "Parent-Network Name.",
          type: "string",
        },
        {
          label: "notes",
          key: "notes",
          description: "Free text field to capture additional information.",
          type: "string",
        },
        {
          label: "planning.base_rate",
          key: "planning.base_rate",
          description: "Used as a reference point for planning purposes.",
          type: "number",
        },
        {
          label: "planning.rate_cur",
          key: "planning.rate_cur",
          description: `Currency of planning rate. <br />
        See available options in below dropdown.`,
          type: "enum",
          config: {
            options: prepareOptions("floorCurrency", inventoryMapping),
          },
        },
        {
          label: "private_auction",
          key: "private_auction",
          description: `Indicator of auction eligibility on bids that do not contain dealids where 0= all bids accepted, 1 = bids are restricted to the deals specified <br />
        See available options in below dropdown.`,
          type: "enum",
          config: {
            options: prepareOptions("private_auction", inventoryMapping, true),
          },
        },
        {
          label: "restrictions.badomain",
          key: "restrictions.badomain",
          type: "string",
          description:
            "Buyer landing pages that are excluded from being able to participate in auctions.",
        },
        {
          label: "restrictions.battr",
          key: "restrictions.battr",
          type: "string",
          description:
            "Creative attributes that are excluded from being able to participate in auctions.",
        },
        {
          label: "restrictions.bbundle",
          key: "restrictions.bbundle",
          type: "string",
          description:
            "Bundle identifier for app-based ads that are excluded from being able to participate in auctions.",
        },
        {
          label: "restrictions.bbuyer",
          key: "restrictions.bbuyer",
          type: "string",
          description:
            "Buyers (DSPs) that are excluded from being able to participate in auctions.",
        },
        {
          label: "restrictions.bcat",
          key: "restrictions.bcat",
          type: "string",
          description:
            "Ad content categories that are excluded from being able to participate in auctions.",
        },
        {
          label: "restrictions.bcid",
          key: "restrictions.bcid",
          type: "string",
          description:
            "Buyer campaigns that are excluded from being able to participate in auctions identified by their Place Exchange id.",
        },
        {
          label: "restrictions.bcrid",
          key: "restrictions.bcrid",
          type: "string",
          description:
            "Buyer creatives that are excluded from being able to participate in auctions identified by their Place Exchange id.",
        },
        {
          label: "restrictions.bseat",
          key: "restrictions.bseat",
          type: "string",
          description:
            "Buyer seats that are excluded from being able to participate in auctions.",
        },
        {
          label: "restrictions.btype",
          key: "restrictions.btype",
          type: "string",
          description:
            "Banner Ad Types that are excluded from being able to participate in auctions.",
        },
        {
          label: "restrictions.wlang",
          key: "restrictions.wlang",
          type: "string",
          description:
            "Language of the creative using ISO-639-1-alpha-2. This will be 2 letter ISO code for language.",
        },
        {
          label: "slot.h",
          key: "slot.h",
          type: "number",
          description: "Addressable slot resolution-height in pixels.",
          constraints: [
            {
              type: "required",
            },
          ],
        },
        {
          label: "slot.max_duration",
          key: "slot.max_duration",
          type: "number",
          description: "Maximum Slot Duration in seconds.",
          constraints: [
            {
              type: "required",
            },
          ],
        },
        {
          label: "slot.min_duration",
          key: "slot.min_duration",
          type: "number",
          description: "Minimum Slot Duration in seconds.",
          constraints: [
            {
              type: "required",
            },
          ],
        },
        {
          label: "slot.w",
          key: "slot.w",
          type: "number",
          description: "Addressable slot resolution-width in pixels.",
          constraints: [
            {
              type: "required",
            },
          ],
        },
        {
          label: "start_date",
          key: "start_date",
          type: "date",
          description:
            "First date the adunit will be available to transact on PX (MM/DD/YYYY, M/D/YYYY, MM-DD-YYYY, M-D-YYYY.)",
        },
        {
          label: "status",
          key: "status",
          description: `status of the adunit. Can the adunit be purchased through place exchange today?<br />
          The below options are available options in below dropdown - 
          1. Pending - Registered, but currently not active until start_date <br/>
          2. On-Demand – Available for campaigns, but must be pre-booked/requested <br/>
          3. Live - Active ad units available to buy today <br/>
          4. Inactive - Temporarily unavailable for campaigns. Also adrequests originating from these adunits will be rejected. <br/>
          5. Decommissioned - no longer available for campaigns and not anticipated to be available in the future (e.g., screen removed). Also adrequests originating from these adunits will be rejected. <br/>
          6. In-Review – Adunits with incorrect metadata are flagged by Place Exchange staff, adrequests originating from adunits will be rejected until the inventory metadata is corrected and PX Support changes the status value <br/>`,
          type: "enum",
          config: { options: prepareOptions("status", inventoryMapping, true) },
          constraints: [
            {
              type: "required",
            },
          ],
        },
        {
          label: "venue.address",
          key: "venue.address",
          type: "string",
          description: "Physical address at which asset is located.",
        },
        {
          label: "venue.name",
          key: "venue.name",
          type: "string",
          description:
            "Publisher defined ID for the unique venue in which the Adunit is located.",
        },
        {
          label: "venue.openooh_category",
          key: "venue.openooh_category",
          description: `Open OOH Venue Category. See Venue Categories tab.<br />
        See available options in below dropdown.`,
          type: "enum",
          config: {
            options: prepareOptions(
              "venue.openooh_category",
              inventoryMapping,
              true
            ),
          },
        },
      ],
    },
  ];
};
