import {
  constructAdminAPIEndPoint,
  fetchHandler,
  getDatasetText,
  getErrorMessage,
} from "..";
import settings from "../../settings";

export const fetchDataset = async (datasetId) => {
  const errorMessage = getErrorMessage(getDatasetText(datasetId));

  return fetchHandler({
    url: constructAdminAPIEndPoint({
      url: `datasets/${datasetId}`,
    }),
    headers: {
      "cache-control": "no-cache, no-store",
    },
    errorMessage: errorMessage,
  })
    .then((data) => {
      return data;
    })
    .catch((error) => Promise.reject(error));
};

export const getDatasetData = async () => {
  return Promise.all([
    fetchDataset(settings.DATASETS_ID_LOOKUP.DEAL_WBUYER_ID),
    fetchDataset(settings.DATASETS_ID_LOOKUP.CONTRACT_PUB_ID),
  ])
    .then((data) => [data[0].members, data[1].members])
    .catch((err) => Promise.reject(err));
};

export const getPersonaInfo = (buyerDataset, publisherDataset, orgId) => {
  const personaInfo = {
    buyer: false,
    publisher: false,
  };

  const checkDataset = (dataset, orgType) => {
    for (const orgObj of dataset) {
      if (orgObj.key === orgId) {
        personaInfo[orgType] = true;
        break;
      }
    }
  };

  checkDataset(buyerDataset, "buyer");
  checkDataset(publisherDataset, "publisher");

  return personaInfo;
};
