/* istanbul ignore file */
import { FlatfileListener } from "@flatfile/listener";
import { bulkRecordHook } from "@flatfile/plugin-record-hook";
import { includes, isEmpty, uniq } from "lodash";

import { getAvailableResources } from "../../../Utilities/FlatFileComponent/fieldValidation";
import {
  formatBooleanFields,
  trimWhitespacesAndSetRecord,
} from "../../../Utilities/FlatFileComponent/validations/common";
import { handleSubmit } from "../../../Utilities/FlatFileComponent/Platform/listeners/submit";
import ErrorMessages from "../../../../DisplayError/ErrorMessages";

let errorJson = [];

export const listenerArchiveDeals = (
  orgData,
  flatfileClose,
  abortController,
  postProcessFields
) =>
  FlatfileListener.create((listener) => {
    /* Track space_id in the console, to be able to share it with Flatfile team while reporting issues */
    listener.on("workbook:created", async ({ context: { spaceId } }) => {
      console.log("spaceId", spaceId);
    });

    listener.use(
      bulkRecordHook("archived__sheet", async (records) => {
        /* Check if Deal Name Already Exist */
        const dealNames = records.map((record) => record.get("name")).flat();
        const namesList = dealNames.filter(
          (element) => element && element !== ""
        );

        let getExistingDeals;
        if (!isEmpty(namesList)) {
          const payload = {
            model: "Deal",
            names: uniq(namesList),
            show_names: "true",
          };
          getExistingDeals = await getAvailableResources(orgData?.id, payload);
        }

        records.map((record) => {
          /* Trim spaces if a cell only includes whitespaces */
          trimWhitespacesAndSetRecord(record);

          /* Name */
          if (
            record.get("name") &&
            Array.isArray(getExistingDeals) &&
            getExistingDeals.includes("API Failed")
          ) {
            record.addError("name", ErrorMessages.API_FAILED_MESSAGE);
          } else {
            const exactItemExist = includes(
              getExistingDeals?.resources_available_names,
              record.get("name")
            );
            if (!exactItemExist) {
              record.addError(
                "name",
                "Deal does not exist in the organization. Please enter a valid deal name."
              );
            }
          }

          /* Archived */
          formatBooleanFields(record, "archived");
          return record;
        });
      })
    );

    listener.filter(
      { job: "workbook:submitActionArchiveDeals" },
      (configure) => {
        configure.on(
          "job:ready",
          async ({ context: { jobId, workbookId } }) => {
            errorJson = await handleSubmit({
              abortController,
              orgData,
              jobId,
              workbookId,
              postProcessFields,
              model: "deals",
              type: "Archive Deal",
              flatModel: { name: "", archived: "" },
            });
          }
        );
      }
    );

    listener.on("job:outcome-acknowledged", (event) => {
      // Handle the outcome-acknowledged event here
      flatfileClose(errorJson || []);
    });
  });
