import React from "react";
import NoAdapprovalsImage from "../images/no_adapprovals.png";
import SnapshotNotFound from "../images/snapshot_not_found.png";
import SnapshotErrorImage from "../images/snapshot_error.png";
import DataNotLoaded from "../images/data_not_loaded.png";
import DataNotFound from "../images/data_not_found.png";
import IncorrectUserConfig from "../images/user_error.png";
import SomethingWentWrongAlert from "../svgs/SomethingWentWrongAlert";

export const mailId = "help@placeexchange.com";
const contactMessage = "if you have any questions";
const apiFailedMessage =
  "Please contact Place Exchange Support if this does not resolve after retrying";
const apiFailedGenericMessage = `Unable to process due to technical problem. ${apiFailedMessage}`;

const ErrorMessages = {
  // General error messages
  API_FAILED: {
    title: "Api failed. Please try again after some time",
  },

  API_FAILED_ERROR: {
    title: "Unable to retrieve <<modelText>>",
    subTitle: apiFailedMessage,
  },

  API_FAILED_GENERIC: {
    title: apiFailedGenericMessage,
  },

  API_FAILED_MESSAGE: `${apiFailedGenericMessage}.`,

  DEFAULT_ERROR_MESSAGE: {
    displaySvg: <SomethingWentWrongAlert />,
    title: "Something Went Wrong",
    subTitle: "Please try again later after some time",
  },
  NO_PUB_ORG_FOUND: {
    title: "No pub org data found",
  },
  PX_ERROR: {
    empty_org: {
      contactMessage: "to get this fixed",
      displayIcon: {
        src: IncorrectUserConfig,
        alt: "Incorrect User Configuration",
      },
      mailId,
      title: "User Does Not Belong To Any Organization",
    },
    incorrect_org: {
      contactMessage,
      displayIcon: {
        src: IncorrectUserConfig,
        alt: "Incorrect User Configuration",
      },
      mailId,
      title: "User Not Authorised For This Organization",
    },
    incorrect_user_info: {
      contactMessage: "to get this fixed",
      displayIcon: {
        src: IncorrectUserConfig,
        alt: "Incorrect User Configuration",
      },
      mailId,
      title: "User Is Configured Incorrectly",
    },
    org_without_persona: {
      contactMessage: "to get this fixed",
      displaySvg: <SomethingWentWrongAlert />,
      mailId,
      title: "Org Is Configured Incorrectly",
      subTitle:
        "This organization is currently not configured as a publisher or buyer",
      showOrgInDropdown: true,
    },
  },

  // Spend error messages
  INVALID_START_DATE_RANGE: {
    title: "Start date should not be greater than end date",
  },
  INVALID_END_DATE_RANGE: {
    title: "End date should not be less than start date",
  },
  NO_DATA_FOUND: {
    title: "No data found",
  },
  DATA_NOT_Found: {
    displayIcon: {
      src: DataNotFound,
      alt: "No Spend Data Found",
    },
    subTitle: "Try again after changing the date range",
    title: "No Spend Data Found",
  },
  DATA_NOT_LOADED: {
    contactMessage: "to get this fixed",
    displayIcon: {
      src: DataNotLoaded,
      alt: "No Spend Data Found",
    },
    mailId,
    title: "No Spend Data Found",
  },
  FILTER_ERROR: {
    displayIcon: {
      src: DataNotFound,
      alt: "No Spend Data Found",
    },
    subTitle: "Try again after changing the data filters",
    title: "No Spend Data Found",
  },

  // Adapproval error messages
  APPROVED_ADAPPROVAL_MESSAGE: {
    title: "Your ad has been approved and removed from the Approval Queue",
  },
  NO_AD: {
    title: "No ads found",
  },
  NO_ADAPPROVAL: {
    displayIcon: {
      src: NoAdapprovalsImage,
      alt: "No-AdApproval",
    },
    subTitle: "There are currently 0 pending ad approvals in your queue",
    title: "No ad approvals found",
  },
  NO_ADAPPROVALS_SUMMARY: {
    displayIcon: {
      src: NoAdapprovalsImage,
      alt: "No-AdApproval",
    },
    subTitle: "There are currently 0 ad approvals for this audit status",
    title: "No ad approvals found",
  },
  NO_ATTR_FOUND: {
    title: "No attribute data found",
  },
  NO_CATEGORY_FOUND: {
    title: "No category data found",
  },
  NO_LANG_FOUND: {
    title: "No language data found",
  },
  NO_DSP_ORG_FOUND: {
    title: "No dsp org data found",
  },
  NO_SNAPSHOT: {
    contactMessage,
    displayIcon: {
      src: SnapshotNotFound,
      alt: "Snapshot-not-found",
    },
    mailId,
    subTitle: "The snapshot for this ad approval can not be found",
    title: "Snapshot not found",
  },
  REJECTED_ADAPPROVAL_MESSAGE: {
    title: "Your ad has been rejected and removed from the Approval Queue",
  },
  SNAPSHOT_LOADING: {
    contactMessage,
    displayIcon: {
      src: SnapshotErrorImage,
      alt: "Snapshot-failed-to-load",
    },
    mailId,
    subTitle: "The snapshot for this ad approval failed to load",
    title: "Snapshot error",
  },
  NO_NETWORKS_AVAILABLE: {
    title: "No networks associated with this organization",
  },
  SNAPSHOTS_NOT_AVAILABLE: {
    customStyle: "no-snapshot-view",
    displayIcon: {
      src: NoAdapprovalsImage,
      alt: "No-Snapshots",
    },
    subTitle: "There are currently 0 snapshots for this ad approval",
    title: "No snapshots found",
  },
  USER_PERMISSION_ERROR: {
    title: "This user don't have the permission to approve/reject adapproval",
  },

  // Deal Management error messages
  NO_DEALS: {
    displayIcon: {
      src: NoAdapprovalsImage,
      alt: "No-Deals",
    },
    subTitle: "There are currently 0 deals for this organization",
    title: "No deals found",
  },

  // Inventory Management error messages
  NO_ADUNITS: {
    displayIcon: {
      src: NoAdapprovalsImage,
      alt: "No-AdUnits",
    },
    subTitle: "There are currently 0 ad units for this organization",
    title: "No ad units found",
  },
  NO_NETWORK_FOUND: {
    title: "No network data found",
  },
  NO_ASSET_CATEGORY_FOUND: {
    title: "No asset category data found",
  },
  NO_ADUNIT_STATUS_FOUND: {
    title: "No adunit status data found",
  },
  NO_ADUNIT_INTEGRATION_TYPE_ID: {
    title: "No adunit integration type data found",
  },
  NO_AUCTION_TYPE_FOUND: {
    title: "No auction type data found",
  },
  NO_MEASUREMENT_PROVIDER_FOUND: {
    title: "No measurement provider data found",
  },
  NO_VENUE_CATEGORY_FOUND: {
    title: "No venue category data found",
  },
  NO_CONTENT_LOCATION_FOUND: {
    errors: "No content location header found",
  },
  NO_NETWORKS: {
    displayIcon: {
      src: NoAdapprovalsImage,
      alt: "No-Networks",
    },
    subTitle: "There are currently 0 networks for this organization",
    title: "No networks found",
  },

  // Order Management error messages
  NO_PLACEMENTS: {
    displayIcon: {
      src: NoAdapprovalsImage,
      alt: "No-Placements",
    },
    subTitle: "There are currently 0 placements for this organization",
    title: "No placements found",
  },

  // News error messages
  NO_NEWS: {
    displayIcon: {
      src: DataNotFound,
      alt: "No News found",
    },
    subTitle: "Check back next month",
    title: "No News Found",
  },

  // Measurement error messages
  NO_GEOSEGMENTS: {
    displayIcon: {
      src: NoAdapprovalsImage,
      alt: "No Geo Segments Found",
    },
    subTitle: "There are currently 0 geo segments for this organization",
    title: "No Geo Segments Found",
  },
};

export default ErrorMessages;
