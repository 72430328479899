/* istanbul ignore file */
import { FlatfileListener } from "@flatfile/listener";
import { bulkRecordHook, recordHook } from "@flatfile/plugin-record-hook";
import { difference, includes, isEmpty, uniq } from "lodash";

import {
  requiredWithAllValues,
  setCapValidator,
} from "../../../Utilities/FlatFileComponent/validations/Placements/budgeting";
import {
  crossDateTimeValidator,
  dateTimeFieldValidator,
  getFormattedTime,
  getFormattedTimeForList,
  minMaxTimeValidator,
  timeValidator,
} from "../../../Utilities/FlatFileComponent/validations/Placements/dateAndTime";
import {
  daypartFieldsValidator,
  weekdaysValidator,
} from "../../../Utilities/FlatFileComponent/validations/Placements/dayparts";
import {
  venueCategoryValidator,
  geoFieldsValidator,
} from "../../../Utilities/FlatFileComponent/validations/Placements/index";
import {
  entityValidator,
  keywordValidator,
} from "../../../Utilities/FlatFileComponent/validations/Placements/keyword";
import {
  regexValidator,
  requiredWithValidator,
  textRangeValidator,
  dateValidator,
  beforeAfterSpaceValidation,
  lengthValidator,
  isGreaterThanValidator,
  int32MaxDateValidation,
  trimWhitespacesAndSetRecord,
} from "../../../Utilities/FlatFileComponent/validations/common";
import { handleSubmit } from "../../../Utilities/FlatFileComponent/Platform/listeners/submit";
import { postProcessFields } from "./customValidationFields";
import {
  modelFormatter,
  splitCommaSeparatedStringToArray,
} from "../../../../../../utils";
import placementModel from "../../../../models/Placement";
import {
  getAvailableResources,
  setCountryList,
  setVenueList,
} from "../../../Utilities/FlatFileComponent/fieldValidation";
import ErrorMessages from "../../../../DisplayError/ErrorMessages";
import { getGeoCoutryData } from "../../../Utilities/FlatFileComponent/lib";

const flatten = require("flat");

const placementModelFlatDefault = flatten(
  modelFormatter("default", placementModel())
);

let errorJson = [];

const validateRecordMappings = (record, getExistingNames, key) => {
  const availableNamesList = getExistingNames.resources_available_names;
  const recordList = splitCommaSeparatedStringToArray(record.get(key));

  let nonExistingValues = difference(recordList, availableNamesList);

  if (
    recordList &&
    Array.isArray(getExistingNames) &&
    includes(getExistingNames, "API Failed")
  ) {
    record.addError(key, ErrorMessages.API_FAILED_MESSAGE);
  }

  if (!isEmpty(nonExistingValues)) {
    record.addError(
      key,
      `${nonExistingValues.join(
        ", "
      )} ${key} does not exists in this organization.`
    );
  }
  return record;
};

const checkIfRecordExist = async (
  records,
  model,
  orgData,
  key,
  placementNames = []
) => {
  const record_values = records.map((record) => record.get(key)).flat();
  const namesList = record_values
    .map((item) => splitCommaSeparatedStringToArray(item))
    .flat()
    .filter((element) => element && element !== "");

  if (namesList.length) {
    const payload = {
      model: model,
      names: uniq(namesList),
      show_names: "true",
    };
    if (model === "Deal") {
      payload["placement_names"] = placementNames;
    }
    const getExistingNames = await getAvailableResources(orgData?.id, payload);
    return getExistingNames;
  }
};

export const listenerPlacements = (
  orgData,
  abortController,
  flatfileClose,
  isBudgetingEnabled,
  venueList,
  countryList
) =>
  FlatfileListener.create((listener) => {
    setVenueList(venueList);
    setCountryList(countryList);

    /* Track space_id in the console, to be able to share it with Flatfile team while reporting issues */
    listener.on("workbook:created", async ({ context: { spaceId } }) => {
      console.log("spaceId", spaceId);
    });

    listener.use(
      bulkRecordHook("placement__sheet", async (records) => {
        /* Check if Placement Name Already Exist */
        const placementNames = records
          .map((record) => record.get("name"))
          .flat();
        const namesList = placementNames.filter(
          (element) => element && element !== ""
        );

        let getExistingPlacements;
        if (!isEmpty(namesList)) {
          const payload = {
            model: "Placement",
            names: uniq(namesList),
            show_names: "true",
            show_fields: ["name", "integration_type"],
          };
          getExistingPlacements = await getAvailableResources(
            orgData?.id,
            payload
          );
        }

        /* Check if Adunits associated are valid */
        const existingAdunits = await checkIfRecordExist(
          records,
          "AdUnit",
          orgData,
          "adunits"
        );

        /* Check if Networks associated are valid */
        const existingNetworks = await checkIfRecordExist(
          records,
          "Network",
          orgData,
          "networks"
        );

        /* Check if Deals associated are valid and not archived */
        const existingDeals = await checkIfRecordExist(
          records,
          "Deal",
          orgData,
          "deals",
          placementNames
        );

        records.map(async (record) => {
          /* Trim spaces if a cell only includes whitespaces */
          trimWhitespacesAndSetRecord(record);

          if (!isEmpty(getExistingPlacements)) {
            const availablePlacementsList =
              getExistingPlacements.resources_available;
            const isNameInvalid = availablePlacementsList.filter(
              (placementObj) =>
                placementObj.name === record.get("name") &&
                placementObj.integration_type === 1
            );

            if (
              record.get("name") &&
              Array.isArray(getExistingPlacements) &&
              includes(getExistingPlacements, "API Failed")
            ) {
              record.addError("name", ErrorMessages.API_FAILED_MESSAGE);
            }

            if (!isEmpty(isNameInvalid)) {
              record.addError(
                "name",
                "An internal record for this name already exist. Please enter a valid name."
              );
            }
          }

          if (record.get("adunits")) {
            validateRecordMappings(record, existingAdunits, "adunits");
          }

          if (record.get("networks")) {
            validateRecordMappings(record, existingNetworks, "networks");
          }

          if (record.get("deals")) {
            validateRecordMappings(record, existingDeals, "deals");
            const placementObj =
              existingDeals["placement_mappings"] &&
              existingDeals["placement_mappings"].filter(
                (element) => element["placement_name"] === record.get("name")
              );
            if (
              existingDeals["placement_mappings"] &&
              placementObj.length &&
              record.get("deals")
            ) {
              let mappedDeals = placementObj[0].archived_and_not_mapped_names
                .map((val) => {
                  if (
                    splitCommaSeparatedStringToArray(
                      record.get("deals")
                    ).includes(val)
                  )
                    return val;
                })
                .filter((element) => element);
              if (mappedDeals.length) {
                record.addError(
                  "deals",
                  `${mappedDeals.join(", ")} Deal is archived.`
                );
              }
            }
          }

          return record;
        });
      })
    );

    listener.use(
      recordHook("placement__sheet", async (record) => {
        /* Name validations */
        /* Max length */
        lengthValidator(record, "name", 100);
        /* Leading OR Trailing Spaces */
        beforeAfterSpaceValidation(record, "name");

        /* notes */
        textRangeValidator(record, "notes", 2, 4000);

        /* start_date */
        dateValidator(record, "start_date");
        crossDateTimeValidator(record, "start_date");
        int32MaxDateValidation(record, "start_date");

        /* start_time */
        record.set("start_time", getFormattedTime(record.get("start_time")));
        timeValidator(record, "start_time");
        crossDateTimeValidator(record, "start_time");
        dateTimeFieldValidator(record, "start_time");

        /* end_date */
        dateValidator(record, "end_date");
        crossDateTimeValidator(record, "end_date");
        int32MaxDateValidation(record, "end_date");

        /* end_time */
        record.set("end_time", getFormattedTime(record.get("end_time")));
        timeValidator(record, "end_time");
        crossDateTimeValidator(record, "end_time");
        dateTimeFieldValidator(record, "end_time");

        /* Dayparts Validations */
        /* dayparts.weekday */
        weekdaysValidator(record, "dayparts.weekday");
        daypartFieldsValidator(record, "dayparts.weekday");

        /* dayparts.start_time */
        record.set(
          "dayparts.start_time",
          getFormattedTimeForList(record.get("dayparts.start_time"))
        );
        timeValidator(record, "dayparts.start_time");
        daypartFieldsValidator(record, "dayparts.start_time");
        minMaxTimeValidator(record, "dayparts.start_time");

        /* dayparts.end_time */
        record.set(
          "dayparts.end_time",
          getFormattedTimeForList(record.get("dayparts.end_time"))
        );
        timeValidator(record, "dayparts.end_time");
        daypartFieldsValidator(record, "dayparts.end_time");
        minMaxTimeValidator(record, "dayparts.end_time");

        /* Targetting Validations */
        const targeting = {};
        targeting["targeting.geo.country"] = record.get(
          "targeting.geo.country"
        );
        const geoCountryData = await getGeoCoutryData(targeting);

        /* Geo Fields */
        geoFieldsValidator(record, "targeting.geo.country", geoCountryData);
        requiredWithValidator(record, "targeting.geo.country", [
          "targeting.geo.region",
          "targeting.geo.dma",
          "targeting.geo.zip",
        ]);
        geoFieldsValidator(record, "targeting.geo.region", geoCountryData);
        geoFieldsValidator(record, "targeting.geo.dma", geoCountryData);
        geoFieldsValidator(record, "targeting.geo.zip", geoCountryData);

        /* venue openooh category */
        venueCategoryValidator(record, "targeting.venue_openooh_category");

        /* targeting.keywords */
        entityValidator(record, "targeting.keywords", 35);
        const errorMessage = await keywordValidator(
          record,
          "targeting.keywords",
          orgData.id
        );
        record.addWarning("targeting.keywords", errorMessage);

        /* Budgeting Validations */
        const budgetFields = ["cap_type", "daily", "lifetime"];

        if (isBudgetingEnabled) {
          requiredWithValidator(record, "start_date", budgetFields);
          requiredWithValidator(record, "start_time", budgetFields);
          requiredWithValidator(record, "end_date", budgetFields);
          requiredWithValidator(record, "end_time", budgetFields);

          /* cap_type */
          requiredWithValidator(record, "cap_type", ["daily", "lifetime"]);

          /* daily */
          regexValidator(
            record,
            "daily",
            "^\\s*(?=.*[1-9])\\d*(?:\\.\\d{1,2})?\\s*$",
            "It should be greater than 0 and decimal number"
          );
          isGreaterThanValidator(record, "daily", 999999999999999);
          setCapValidator(record, "daily");
          requiredWithAllValues(record, "daily", "lifetime");

          /* lifetime */
          regexValidator(
            record,
            "lifetime",
            "^\\s*(?=.*[1-9])\\d*(?:\\.\\d{1,2})?\\s*$",
            "It should be greater than 0 and decimal number"
          );
          isGreaterThanValidator(record, "lifetime", 999999999999999);
          setCapValidator(record, "lifetime");
          requiredWithAllValues(record, "lifetime", "daily");
        }

        return record;
      })
    );

    listener.filter({ job: "workbook:submitActionPlacements" }, (configure) => {
      configure.on("job:ready", async ({ context: { jobId, workbookId } }) => {
        errorJson = await handleSubmit({
          abortController,
          orgData,
          jobId,
          workbookId,
          flatModel: placementModelFlatDefault,
          postProcessFields,
          model: "placements",
          type: "Placements",
        });
      });
    });

    listener.on("job:outcome-acknowledged", (event) => {
      // Handle the outcome-acknowledged event here
      flatfileClose(errorJson || []);
    });
  });
