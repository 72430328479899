import React from "react";
import ad_details from "./icons/ad_details.svg";
import adapproval from "./icons/adapproval.svg";
import create_ad from "./icons/create_ad.svg";
import order_submit_icon from "./icons/order_submit_icon.svg";
import order_deals_icon from "./icons/order_deals_icon.svg";
import order_network_icon from "./icons/order_network_icon.svg";
import order_context_icon from "./icons/order_context_icon.svg";
import order_date_budget from "./icons/order_date_budget.svg";
import deal_details from "./icons/deal_details.svg";
import deals_save from "./icons/deals_save.svg";
import restrictions_deals from "./icons/restrictions_deals.svg";

const dealsHelpBody = [
  {
    title: "Enter Deal Info",
    icon: deal_details,
    nextIcon: <span className="help__divider">&#8594;</span>,
  },
  {
    title: "Apply Restriction(s)",
    icon: restrictions_deals,
    nextIcon: <span className="help__divider">&#8594;</span>,
  },
  {
    title: "Save Deal",
    icon: deals_save,
  },
];

const adsHelpBody = [
  {
    title: "Enter Ad Info",
    icon: order_context_icon,
    nextIcon: <span className="help__divider">&#8594;</span>,
    content: "Ad Name, Mimes, Adm, Creative Type",
  },
  {
    title: "Select Ad Approval Type",
    icon: adapproval,
    nextIcon: <span className="help__divider">&#8594;</span>,
    content: "Specify if the Ad is going to need auto or manual approval",
  },
  {
    title: "Create Ad",
    icon: create_ad,
    content: "Click to Create Ad",
  },
];

const getAssociateDealAdDetails = (helpSectionType) => {
  let dealAdDetails = {
    dealAdTitle: "Associate Deals",
    dealAdContent: "You can associate single or multiple deals to a placement",
  };
  if (helpSectionType === "Programmatic") {
    return {
      ...dealAdDetails,
      dealAdTitle: "Associate Deal(s)",
    };
  } else if (helpSectionType === "Direct Sold") {
    return {
      dealAdTitle: "Associate Ad(s)",
      dealAdContent: "You can associate single or multiple Ads to a placement",
    };
  }
  return dealAdDetails;
};

const getPlacementInfoContent = (helpSectionType) => {
  let placementInfoContent =
    "Placement Name and Notes (Context about placements)";
  if (helpSectionType === "Programmatic") {
    return "Placement Name, Programmatic is selected by default (Context about Placement)";
  } else if (helpSectionType === "Direct Sold") {
    return "Placement Name, select Direct Sold Campaign (Context about Placement)";
  }
  return placementInfoContent;
};

export const HelpBody = (props) => {
  const { isEditMode, model, helpSectionType = "" } = props;

  const { dealAdTitle, dealAdContent } =
    getAssociateDealAdDetails(helpSectionType);

  const placementHelpBody = [
    {
      title: "Enter Placement Info",
      icon: order_context_icon,
      content: getPlacementInfoContent(helpSectionType),
      nextIcon: <span className="help__divider">&#8594;</span>,
    },
    {
      title: !helpSectionType ? "Dates" : "Dates & Budget",
      icon: order_date_budget,
      content: !helpSectionType
        ? "Set the date and budget for this campaign"
        : "Set the date, budget and pacing for this campaign",
      nextIcon: <span className="help__divider">&#8330;</span>,
    },
    {
      title: `Configure Targeting`,
      icon: order_network_icon,
      content: `Targeting options to control where the campaign can be executed`,
      nextIcon: <span className="help__divider">&#8594;</span>,
    },
    {
      title: dealAdTitle,
      icon: helpSectionType === "Direct Sold" ? ad_details : order_deals_icon,
      content: dealAdContent,
      nextIcon: <span className="help__divider">&#8594;</span>,
    },
    {
      title: `${isEditMode ? "Update" : "Add"} Placement`,
      icon: order_submit_icon,
      content: `${
        isEditMode
          ? "Click Update Placements to update the changes"
          : !helpSectionType
          ? "Click Add Placement"
          : "Click to Add Placement"
      }`,
      nextIcon: "",
    },
  ];

  const helpBody =
    model === "placements"
      ? placementHelpBody
      : model === "ads"
      ? adsHelpBody
      : dealsHelpBody;
  return helpBody.map((item) => {
    return (
      <>
        <div className="help__body--wrapper">
          <div className="help__body--title">{item.title}</div>
          <div className="help__body--icon">
            <img src={item.icon} alt="context" />
          </div>
          <div className="help__body--copy">{item.content}</div>
        </div>
        {item.nextIcon && item.nextIcon}
      </>
    );
  });
};
