/* istanbul ignore file */
import { sortBy } from "lodash";

const wrapQuotesHelpText = `Names with commas (' , ') should be escaped by wrapping
double quotes around it - e.g., examplescreen,123 as "examplescreen,123".
Names with double quotes (' " ') should be escaped by replacing each double quote with two
and wrapping double quotes around it - e.g. examplescreen"123 as "examplescreen""123".`;

/*
  - Excluded columns -
    - ad_request_interval, line_item_id, order_id, dayparts, start_date, end_date, integration_type -
    Pubs are not expected to configure PXLite type placements yet.
*/

export const placements__sheet = (capTypeOptions, isBudgetingEnabled) => {
  const basicFields = [
    {
      label: "placement_name",
      key: "name",
      description: "Publisher defined ID.",
      type: "string",
      constraints: [{ type: "required" }, { type: "unique" }],
    },
    {
      label: "adunit_names",
      key: "adunits",
      description: `The name of the ad units associated with this placement.
     Comma separated list of ad units is allowed. ${wrapQuotesHelpText}`,
      type: "string",
    },
    {
      label: "dayparts.end_time",
      key: "dayparts.end_time",
      description:
        "Time on the selected day of the week when placements should stop sending requests.Comma separated list of end_time is allowed.(h:mm am/pm)",
      type: "string",
    },
    {
      label: "dayparts.start_time",
      key: "dayparts.start_time",
      description:
        "Time on the selected day of the week when placements should start sending requests.Comma separated list of start_time is allowed.(h:mm am/pm)",
      type: "string",
    },
    {
      label: "dayparts.weekday",
      key: "dayparts.weekday",
      description:
        "Day of week when placements should start and stop sending requests.comma separated list of weekdays is allowed.",
      type: "string",
    },
    {
      label: "deal_names",
      key: "deals",
      description: `The name of the deals associated with this placement.
     Comma separated list of deals is allowed. ${wrapQuotesHelpText}`,
      type: "string",
    },
    {
      label: "end_date",
      key: "end_date",
      description:
        "The date when a given placement should stop sending requests (MM/DD/YYYY, M/D/YYYY, MM-DD-YYYY, M-D-YYYY.), Please enter the date in UTC time zone",
      type: "string",
    },
    {
      label: "end_time",
      key: "end_time",
      description:
        "The time when a given placement should stop sending requests (h:mm am/pm)(Please enter the time in UTC time zone)",
      type: "string",
    },
    {
      label: "network_names",
      key: "networks",
      description: `The name of the networks associated with this placement.
     Comma separated list of networks is allowed. ${wrapQuotesHelpText}`,
      type: "string",
    },
    {
      label: "notes",
      key: "notes",
      description: "Free text field to capture additional info.",
      type: "string",
    },
    {
      label: "start_date",
      key: "start_date",
      description:
        "The date when a given placement should start sending requests (MM/DD/YYYY, M/D/YYYY, MM-DD-YYYY, M-D-YYYY.), Please enter the date in UTC time zone",
      type: "string",
    },
    {
      label: "start_time",
      key: "start_time",
      description:
        "The time when a given placement should start sending requests h:mm am/pm)(Please enter the time in UTC time zone)",
      type: "string",
    },
  ];

  const targetingFields = [
    {
      label: "targeting.geo.country",
      key: "targeting.geo.country",
      description: `Country. Comma separated list of county is allowed.`,
      type: "string",
    },
    {
      label: "targeting.geo.region",
      key: "targeting.geo.region",
      description: `Region. Comma separated list of region is allowed.`,
      type: "string",
    },
    {
      label: "targeting.geo.dma_name",
      key: "targeting.geo.dma",
      description: `DMA Name. Comma separated list of DMA name is allowed. ${wrapQuotesHelpText}`,
      type: "string",
    },
    {
      label: "targeting.geo.zip",
      key: "targeting.geo.zip",
      description: `Zip. Comma separated list of zip is allowed.`,
      type: "string",
    },
    {
      label: "targeting.keywords",
      key: "targeting.keywords",
      description: `Keywords. Comma separated list of keywords is allowed.`,
      type: "string",
    },
    {
      label: "targeting.venue_openooh_category",
      key: "targeting.venue_openooh_category",
      description: `Open OOH Venue Category. Comma separated list of venue_openooh category is allowed. ${wrapQuotesHelpText}`,
      type: "string",
    },
  ];

  const budgetingFields = [
    {
      label: "cap_type",
      key: "cap_type",
      description:
        "Capping is used to set the goal i.e how much your placement is expected to earn. This field indicates the type of cap (Impressions or Budget) to be applied on a placement.",
      type: "enum",
      config: { options: capTypeOptions },
    },
    {
      label: "daily",
      key: "daily",
      description:
        "The upper limit to be earned per day of the selected cap_type.",
      type: "number",
    },
    {
      label: "lifetime",
      key: "lifetime",
      description:
        "The upper limit to be earned throughout the duration of placement of the selected cap_type.",
      type: "number",
    },
  ];

  let fields = [...basicFields, ...targetingFields];
  if (isBudgetingEnabled) {
    fields = [...fields, ...budgetingFields];
  }

  /* Display fields in sorted order keeping placement_name in the 1st column */
  fields.splice(0, 1);
  const sortedFields = sortBy(fields, ["label"]);
  sortedFields.splice(0, 0, basicFields[0]);

  return [
    {
      name: "Placements Sheet",
      slug: "placement__sheet",
      readonly: false,
      mappingConfidenceThreshold: 0.9,
      fields: sortedFields,
    },
  ];
};
