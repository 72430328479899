import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { includes, indexOf, isEmpty } from "lodash";
import { Spinner } from "@px/px_design_system/lib/components/Spinner";
import {
  constructAdminAPIEndPoint,
  fetchHandler,
  getErrorMessage,
} from "../../../../utils";
import { UserContext } from "../../context/auth";

import "./style.css";

/** A function to generate iframe embade url */
const getEmbedURL =
  (setIframeLoaded, setEmbedUrl, userData, orgData, props) => (id) => {
    setIframeLoaded(false);
    setEmbedUrl("");
    /* Service user creation per organization */
    let emailId = `px-portal@${orgData.id}.com`;
    let firstName = orgData.name;
    let lastName = "Embed";
    let dashboard_url = `/embed/dashboards-next/pub_planner::${id}?embed_domain=${window.location.origin}&Currency=${orgData.config.allowed_currency}`;
    const errorMessage = getErrorMessage("delivery overview");
    /* Internal staff members user creation based on user name */
    if (userData[0].email.endsWith("placeexchange.com")) {
      emailId = userData[0].email;
      firstName = userData[0].name.split(".")[0];
      lastName = userData[0].name.split(".")[1];
    }

    const url = "looker/get_embed_url"; // api url for generate iframe embade url
    /** Api payload */
    const apiParams = {
      method: "POST",
      url: constructAdminAPIEndPoint({
        url,
      }),
      headers: {
        "cache-control": "no-cache, no-store",
      },
      body: {
        user_id: emailId,
        first_name: firstName,
        last_name: lastName,
        dashboard_url: dashboard_url,
        org_id: orgData.id,
        permissions: [
          "access_data",
          "clear_cache_refresh",
          "see_lookml_dashboards",
          "download_without_limit",
          "see_drill_overlay",
        ],
        looker_models: ["pub_planner"],
        group_ids: [],
        user_attributes: props.placement_id
          ? {
              email: emailId,
              delivery_overview_placement_id: props.placement_id,
            }
          : { email: emailId },
      },
      errorMessage: errorMessage,
    };

    /** Service to call the api url*/
    return fetchHandler(apiParams)
      .then((res) => {
        if (res) {
          setEmbedUrl(res.url);
        }
      })
      .catch((error) => {
        if (!error.data) {
          return Promise.reject(error);
        }
        /* istanbul ignore next */
        return Promise.reject(errorMessage);
      });
  };

/**
    This is an iframe used for pub planner's activation & delivery status reports which is created for seperate reports based on persona of pub planner and publisher.
    It will be helpful for reduce multiple conditions based on reports and personas in single EmbadeIframe file and make code simple to understand.
*/
const EmbedIframePubPlanner = (props) => {
  const { location, history } = props;
  const { data, loading } = useContext(UserContext);
  /** list of report types */
  const dashboardIds = [
    "campaign_activation_status",
    "campaign_delivery_status",
  ];
  const { orgData, userData } = data;
  const [embedUrl, setEmbedUrl] = useState("");
  const [activeTab, setAciveTab] = useState();
  const [isIframeLoaded, setIframeLoaded] = useState(false);
  const getDashboard = getEmbedURL(
    setIframeLoaded,
    setEmbedUrl,
    userData,
    orgData,
    props
  );
  const spinnerProps = {
    className: "spinner-icon",
    color: "primary",
    loadingtext: "Loading",
  };

  useEffect(() => {
    if (!isEmpty(orgData) && !loading) {
      const { params } = props.match;
      if (
        params.reportingStatus &&
        includes(dashboardIds, `campaign_${params.reportingStatus}`)
      ) {
        let prepareID = `campaign_${params.reportingStatus}`;
        setAciveTab(indexOf(dashboardIds, prepareID));
        getDashboard(prepareID).catch(() => {});
      } else {
        history.push({
          pathname: `/orgs/${orgData.id}/reporting/revenue/summary`,
        });
      }
    }
  }, [loading, location.pathname]);

  return (
    <div className="embed--reporting__wrapper">
      {/* show loading if iframe is not loaded */}
      {!isIframeLoaded && (
        <Spinner {...spinnerProps} spinnerposition="container" />
      )}
      {/* Show iframe if embade url */}
      {!isEmpty(embedUrl) && (
        <iframe
          id="embed--reporting"
          className={`${dashboardIds[+activeTab]}--reporting ${
            !isIframeLoaded && "hide"
          }`}
          src={`${embedUrl}`}
          title="reporting"
          onLoad={() => setIframeLoaded(true)}
        />
      )}
    </div>
  );
};

EmbedIframePubPlanner.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportingType: PropTypes.string,
      reportingSubType: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default EmbedIframePubPlanner;
