import React, { Component, useState } from "react";
import { Button } from "@px/px_design_system";
import PropType from "prop-types";
import "../ApproveAcrossNetworks/style.css";

const AdApprovalReject = ({ changeStatusOfAd, confirmReject }) => {
  const [feedback, setFeedback] = useState("");

  const rejectAdSection = () => {
    return (
      <div className="approve-ad__across-all">
        <p className="approve-ad__approve-ad-text">Reject Ad?</p>
        <p className="approve-ad__approve-notes-text">Feedback</p>
        <textarea
          id="feedback"
          rows="4"
          cols="75"
          className="approve-ad__approve-textarea"
          placeholder="Additional context about the Ad Approval. Please note it might be shared with the DSP."
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
      </div>
    );
  };

  const rejectAdActionButton = () => {
    const cancelButtonProps = {
      style: {
        backgroundColor: "#f1f6fb",
        borderColor: "#2D6BFF",
        color: "#2D6BFF",
      },
      buttontext: "Cancel",
    };
    const iconStyle = {
      style: {
        color: "#ffffff",
        width: "1rem",
        height: "1rem",
        marginRight: "0.625rem",
      },
    };
    const rejectButtonProps = {
      buttontext: "Confirm Reject",
      icontype: ["far", "check-circle"],
      iconstyle: iconStyle.style,
      style: {
        borderColor: "#dc3545",
        backgroundColor: "#dc3545",
        color: "#ffffff",
      },
    };

    return (
      <div className="approve-ad__action-buttons">
        <Button
          {...cancelButtonProps}
          className="approve-ad__action-buttons--cancel-button"
          onClick={() => confirmReject(false)}
        />
        <Button
          id="confirmRejectBtn"
          {...rejectButtonProps}
          className="approve-ad__action-buttons--confirm-approval-button"
          onClick={() =>
            changeStatusOfAd("rejectAd", undefined, [], "", feedback)
          }
        />
      </div>
    );
  };

  return (
    <div className="approve-ad">
      {rejectAdSection()}
      {rejectAdActionButton()}
    </div>
  );
};

AdApprovalReject.propTypes = {
  changeStatusOfAd: PropType.func,
  confirmReject: PropType.func,
};

export default AdApprovalReject;
