import { prepareOptions } from "../../../Utilities/FlatFileComponent/lib";
import dealMapping from "./dealMapping";

export const deals__sheet = (
  wbuyerOptions,
  priorityOptions,
  currencyMapping
) => {
  return [
    {
      name: "Deals Sheet",
      slug: "deal__sheet",
      readonly: false,
      mappingConfidenceThreshold: 0.9,
      fields: [
        {
          label: "deal_name",
          key: "name",
          description: "Publisher defined ID.",
          type: "string",
          constraints: [{ type: "required" }, { type: "unique" }],
        },
        {
          label: "auction type",
          key: "at",
          description: `Optional override of the overall auction type of the bid request where: <br />
        1 - First Price: The highest eligible bid will win, at the price that is bid. <br />
        2 - Second Price: The highest eligible bid will win, at the price of the next highest bid (if available) or bidfloor plus 1 cent. <br />
        3 - Fixed Price: The value passed in bidfloor is the agreed upon deal price.`,
          type: "enum",
          config: { options: prepareOptions("at", dealMapping(), true) },
          constraints: [{ type: "required" }],
        },
        // It should be a positive number with maximum 2 decimal places.
        {
          label: "bidfloor",
          key: "bidfloor",
          description: "Minimum bid for this impression expressed in CPM.",
          type: "number",
          constraints: [{ type: "required" }],
        },
        {
          label: "bidfloorcur",
          key: "bidfloorcur",
          description:
            "Currency specified using ISO-4217 alpha codes. This maybe different from bid currency returned by bidder if this is allowed by the exchange.",
          type: "enum",
          config: { options: prepareOptions("bidfloorcur", currencyMapping) },
          constraints: [{ type: "required" }],
        },
        {
          label: "notes",
          key: "notes",
          description: "Free text field to capture additional information.",
          type: "string",
          defaultValue: "Default plan",
        },
        {
          label: "priority",
          key: "priority",
          description: `The priority decides the outcome of the auction. priority values are: <br />
        10 - Guaranteed <br />
        20 - High <br />
        30 - Medium (default priority) <br />
        40 - Low <br />
        50 - Very Low <br />
        Deals marked with priority:10 (guaranteed) will win over deals with other priorities (non-guaranteed deals)`,
          type: "enum",
          config: {
            options: dealMapping(wbuyerOptions, priorityOptions).priority,
          },
        },
        {
          label: "status",
          key: "status",
          description: `Provides extra indications for the publisher
        about any special handling the publisher wants to be able to make against the deal -
        this does not affect Place Exchange treatment of fees or accounting,
        but will be preserved to allow pivoting of reporting.`,
          type: "enum",
          config: {
            options: prepareOptions(
              "status",
              dealMapping(wbuyerOptions, priorityOptions)
            ),
          },
        },
        {
          label: "wadomain",
          key: "wadomain",
          description:
            "Buyer landing pages that are allowed to bid on this deal.",
          type: "string",
        },
        {
          label: "wbuyer",
          key: "wbuyer",
          description: "Buyers (DSPs) allowed to bid on this deal.",
          type: "reference",
          config: {
            ref: "wbuyer_sheet",
            key: "wbuyer_name",
            relationship: "has-one",
          },
        },
        {
          label: "wseat",
          key: "wseat",
          description: "Buyer seats allowed to bid on this deal.",
          type: "string",
        },
      ],
    },
    {
      name: "Wbuyer Sheet",
      slug: "wbuyer_sheet",
      readonly: true,
      fields: [
        {
          key: "wbuyer_name",
          label: "Wbuyer Name",
          type: "string",
        },
      ],
    },
  ];
};
