/* istanbul ignore file */
import React, { useContext, useState } from "react";
import { Button, Icon } from "@px/px_design_system";
import { initializeFlatfile } from "@flatfile/react";
import { workbook } from "./workbook";
import { handleUserSession } from "../../../../../../utils";
import { UserContext } from "../../../../context/auth";

import settings from "../../../../../../settings";

import "./style.css";

export const PlatformFlatfile = ({
  fields,
  name,
  listener,
  submitActionName,
  platformRef,
  flatfileClose = () => {},
  buttonText = "Import Data",
  icon = <Icon icon={["fas", "plus"]} size="1x" />,
  hideButton = false,
  renderButton,
}) => {
  const {
    data: { userData, orgData },
  } = useContext(UserContext);

  const spaceProps = {
    name: `${name} Space`,
    environmentId: settings.PORTAL_FLATFILE_ENVIRONMENT_ID,
    publishableKey: settings.PORTAL_FLATFILE_PUBLISHABLE_KEY,
    workbook: workbook(name, fields, submitActionName),
    listener,
    sidebarConfig: {
      showSidebar: false,
    },
    userInfo: {
      userId: userData ? userData[0].id : "",
      name: userData ? userData[0].name : "",
      companyId: orgData ? orgData.id : "",
      companyName: orgData ? orgData.name : "",
    },
    themeConfig: {
      root: {
        primaryColor: "var(--primary)",
        warningColor: "var(--warning)",
        dangerColor: "var(--danger)",
        successColor: "var(--success)",
      },
      sidebar: {
        logo: "https://images.ctfassets.net/hjneo4qi4goj/gL6Blz3kTPdZXWknuIDVx/7bb7c73d93b111ed542d2ed426b42fd5/flatfile.svg",
      },
    },
    closeSpace: {
      operation: submitActionName,
      onClose: () => {
        flatfileClose();
        setShowSpace(false);
      },
    },
  };

  const [showSpace, setShowSpace] = useState(false);
  const { Space, OpenEmbed } = initializeFlatfile(spaceProps);

  const onOpenSpace = async () => {
    handleUserSession(true);
    setShowSpace(!showSpace);
    await OpenEmbed();
  };

  return (
    <div className="platform--modal">
      {/*Button to trigger the modal */}
      <div
        className={`platform--btn ${hideButton && "hide"}`}
        onClick={onOpenSpace}
        ref={platformRef}
      >
        {renderButton ? (
          React.cloneElement(renderButton)
        ) : (
          <Button
            style={{ backgroundColor: "#2D6BFF", borderColor: "#2D6BFF" }}
            buttontext={buttonText}
            icon={icon}
          />
        )}
      </div>
      {showSpace && <Space key={name} />}
    </div>
  );
};
