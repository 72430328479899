// eslint-disable-next-line no-restricted-globals
import Auth0Lock from "auth0-lock";
import { shape, string } from "prop-types";
import React, { Component } from "react";

import settings from "../../../settings";
import { Login } from "./login";

import "./styles.css";
import logo from "../images/mobile_logo.svg";

class Auth extends Component {
  isMounted1 = false;

  constructor(props) {
    super(props);
    this.state = { loggedIn: false };
    this.checkSessionCallback = this.checkSessionCallback.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getUserName = this.getUserName.bind(this);
    this.getExpiryDate = this.getExpiryDate.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.logout = this.logout.bind(this);
    this.onAuthenticated = this.onAuthenticated.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.setAuth0Lock = this.setAuth0Lock.bind(this);
    this.setSession = this.setSession.bind(this);
    this.getOrgId = this.getOrgId.bind(this);
    this.tempValue = "";
  }

  componentDidMount() {
    this.isMounted1 = true;
    this.setAuth0Lock();
    this.onAuthenticated();
    const { location } = this.props;
    const { hash } = location;
    // Avoid showing Lock when hash is parsed.
    if (location !== undefined && !this.isAuthenticated()) {
      if (!/access_token|id_token|error/.test(hash)) {
        if (location.pathname === "/") {
          window.location.replace(`${settings.PX_BASE_PATH}login`);
        }
        this.auth.show();
      }
    } else {
      this.setState({
        loggedIn: true,
      });
    }
  }

  componentWillUnmount() {
    this.isMounted1 = false;
  }

  onAuthenticated() {
    this.auth.on("authenticated", (authResult) => {
      this.renewSession(authResult);
    });
  }

  setAuth0Lock() {
    this.auth = new Auth0Lock(settings.AUTH0_CLIENT_ID, settings.AUTH0_DOMAIN, {
      configurationBaseUrl: "https://cdn.auth0.com",
      closable: false,
      auth: this.authProps(),
      theme: {
        logo,
        primaryColor: "#2D6BFF",
      },
      languageDictionary: {
        emailInputPlaceholder: "Enter your email address",
        passwordInputPlaceholder: "Enter your password",
        title: "",
        usernameOrEmailInputPlaceholder: "Enter your username",
      },
      allowSignUp: false,
      _enableIdPInitiatedLogin: true,
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire after 2 hour
    const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    localStorage.setItem("px_access_token", authResult.accessToken);
    localStorage.setItem("px_expires_at", expiresAt);
    localStorage.setItem("px_user", authResult.idTokenPayload.nickname);
    this.scheduleRenewal();
  }

  getExpiryDate() {
    return JSON.stringify(new Date(localStorage.getItem("px_expires_at")));
  }

  getAccessToken() {
    return localStorage.getItem("px_access_token");
  }

  getUserName() {
    return localStorage.getItem("px_user");
  }

  getOrgId() {
    return localStorage.getItem("px_default_org_id");
  }

  // At the moment pretty simple, but can be used to make the
  // redirects smarter.
  setRedirectUrl(redirectUrl) {
    localStorage.setItem("redirectURL", redirectUrl);
  }

  isAuthenticated(bufferTime = false) {
    // Check whether the current time is past the access token's expiry time
    const currentTimestamp = new Date().getTime();
    const expiresAt = JSON.parse(localStorage.getItem("px_expires_at"));

    let userAuthenticated = currentTimestamp < expiresAt;

    // Check buffer time for long running process like flatfile upload and download CSV
    if (bufferTime === true) {
      const timeDifference = expiresAt - currentTimestamp;
      userAuthenticated = timeDifference > settings.USER_SESSION_BUFFER_TIME;
    }

    localStorage.setItem("isAuthenticated", userAuthenticated);
    return userAuthenticated;
  }

  checkSessionCallback(err, authResult) {
    if (authResult && authResult.accessToken && authResult.idToken) {
      this.setSession(authResult);
    } else if (err) {
      this.auth.show();
    }
  }

  logout() {
    localStorage.removeItem("px_access_token");
    localStorage.removeItem("px_user");
    localStorage.removeItem("px_default_user_id");
    localStorage.removeItem("redirectURL");
    localStorage.removeItem("px_default_org_id");
    localStorage.removeItem("px_default_org_name");
    localStorage.setItem("px_expires_at", 0);
    localStorage.setItem("isAuthenticated", false);

    // TODO - Check if really needed, i.e., persist last visited URL.
    window.location.replace(`${settings.PX_BASE_PATH}login`);
  }

  renewSession() {
    this.auth.checkSession({}, this.checkSessionCallback);
  }

  scheduleRenewal() {
    if (this.isMounted1) {
      const expiresAt = localStorage.getItem("px_expires_at");
      const timeout = expiresAt - Date.now();
      if (timeout > 0) {
        this.tokenRenewalTimeout = setTimeout(() => {
          this.renewSession();
        }, timeout);
        this.setState({ loggedIn: true });
      }
    }
  }

  authProps() {
    return {
      responseType: "token id_token",
      audience: settings.AUTH0_AUDIENCE,
      sso: false,
      _enableIdPInitiatedLogin: true,
    };
  }

  render() {
    const { loggedIn = false } = this.state;
    return loggedIn && <Login />;
  }
}

Auth.defaultProps = {
  location: {},
};

Auth.propTypes = {
  location: shape({
    hash: string,
  }),
};

const Authentication = {
  AuthFunc: new Auth(),
  AuthComp: Auth,
};

export default Authentication;
