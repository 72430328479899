import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { isNil } from "lodash";
import { Spinner } from "@px/px_design_system";
import { UserContext } from "../../../../context/auth";
import { getNetwork } from "../../../Utilities/FlatFileComponent/fieldValidation";
import {
  constructAdminAPIEndPoint,
  checkIfModelDataExist,
  fetchHandler,
  getDatasetText,
  getErrorMessage,
} from "../../../../../../utils";
import DisplayError from "../../../../DisplayError";
import ErrorMessages from "../../../../DisplayError/ErrorMessages";
import settings from "../../../../../../settings";

const moment = require("moment-timezone");

const AdunitFormContext = createContext();

const AdunitFormProvider = (props) => {
  const { isImportEnabled, loading, organization, isInvalidSuperUser } =
    useContext(UserContext);

  const [displayError, setDisplayError] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [isFormReady, setFormReady] = useState(false);
  const [singleAdunitData, setAdunitData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [integrationTypeOptions, setIntegrationTypeOptions] = useState([]);
  const [networkOptions, setNetworkOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [formDefaultValues, setFormDefaultValues] = useState({
    name: "",
    notes: "",
    integration_type: "",
    status: "",
    start_date: null,
    network_name: "",
  });

  const getAdunitData = async (name) => {
    let adunitData = await checkIfModelDataExist(
      organization.id,
      "adunits",
      name,
      "name"
    );
    if (adunitData?.name) {
      setEditMode(true);
      setFormDefaultValues({
        name: adunitData.name,
        notes: adunitData.notes,
        integration_type: adunitData.integration_type,
        status: adunitData.status,
        start_date: adunitData.start_date
          ? moment(adunitData.start_date, "X")
              .utc()
              .format("YYYY-MM-DD[T]HH:mm:ss")
          : null,
        network_name: adunitData.network_name,
        private_auction: isNil(adunitData.private_auction)
          ? ""
          : adunitData.private_auction.toString(),
        auction: adunitData.auction,
      });

      setAdunitData(adunitData);
    } else if (adunitData.message === "Not Found")
      props.history.push("../summary");
  };

  /* TODO: Fetch datasets reponse in a generic manner */
  const fetchDatasets = (apiUrl, errorMessage) => {
    const errorMsg = getErrorMessage(getDatasetText(apiUrl));

    return fetchHandler({
      url: constructAdminAPIEndPoint({
        url: `datasets/${apiUrl}`,
      }),
      errorMessage: errorMsg,
    })
      .then((response) => {
        if (response.members && response.members.length) {
          const choicesArray = {};
          response.members.forEach((item) => {
            choicesArray[item.key] = item.value;
          });
          return choicesArray;
        }
        throw errorMessage;
      })
      .catch((error) => {
        if (!error.data) return error;
        return errorMsg;
      });
  };

  const setDataContext = async (adunitName) => {
    const integrationTypeList = await fetchDatasets(
      settings.DATASETS_ID_LOOKUP.ADUNIT_INTEGRATION_TYPE_ID,
      ErrorMessages.NO_ADUNIT_INTEGRATION_TYPE_ID
    );
    const statusList = await fetchDatasets(
      settings.DATASETS_ID_LOOKUP.ADUNIT_STATUS_ID,
      ErrorMessages.NO_ADUNIT_STATUS_FOUND
    );
    const networkList = await getNetwork(organization.id);
    const networkChoiceObject = {};
    networkList.forEach((network) => {
      const networkName = network.networkName;
      networkChoiceObject[networkName] = networkName;
    });
    setIntegrationTypeOptions(integrationTypeList);
    setNetworkOptions(networkChoiceObject);
    setStatusOptions(statusList);
    if (adunitName) {
      await getAdunitData(adunitName);
    }
    setFormReady(true);
  };

  useEffect(() => {
    if (!loading) {
      setDisplayError(false);
      setFormReady(false);
      const { location } = props;
      const queryParams = queryString.parse(location.search);
      setSearchValue(queryParams.search || "");
      setDataContext(queryParams.name || "");
    }
  }, [loading]);

  return (
    <AdunitFormContext.Provider
      value={{
        formDefaultValues,
        isEditMode,
        isImportEnabled,
        orgId: organization.id,
        singleAdunitData,
        searchValue,
        integrationTypeOptions,
        networkOptions,
        statusOptions,
        isInvalidSuperUser,
      }}
    >
      {!loading && isFormReady ? (
        displayError ? (
          <div className="embed--dashboard wrapper">
            <DisplayError {...getErrorMessage("adunit")} />
          </div>
        ) : (
          props.children
        )
      ) : (
        <Spinner color="primary" />
      )}
    </AdunitFormContext.Provider>
  );
};

const AdunitFormConsumer = AdunitFormContext.Consumer;

AdunitFormProvider.contextTypes = {
  data: PropTypes.bool,
  loading: PropTypes.any,
  formDefaultValues: PropTypes.any,
  isEditMode: PropTypes.any,
  orgId: PropTypes.any,
  singleAdunitData: PropTypes.any,
};

export { AdunitFormProvider, AdunitFormConsumer, AdunitFormContext };
