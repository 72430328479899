import React, { Component } from "react";
import {
  Col,
  Row,
  Tab,
  Table,
  ToggleButton,
  MultiSelect,
} from "@px/px_design_system";
import { capitalize, isEmpty, join } from "lodash";
import PropTypes from "prop-types";

import DisplayError from "../../../DisplayError";
import ErrorMessages from "../../../DisplayError/ErrorMessages";
import { sortDimensions } from "../../../../../utils/index";

import "./style.css";

let columns = [
  {
    dataField: "id",
    text: "id",
    hidden: true,
  },
  {
    dataField: "width",
    text: "Width",
    hidden: true,
  },
  {
    dataField: "height",
    text: "Height",
    hidden: true,
  },
  {
    dataField: "resolution",
    text: "Resolution",
    sortFunc: sortDimensions,
  },
  {
    dataField: "mime",
    text: "Mime Type",
  },
];

const displayColumns = [
  ...columns,
  {
    dataField: "scaling_factor",
    text: "Scale",
  },
];

const videoColumns = [
  ...columns,
  {
    dataField: "bitrate",
    text: "Bit Rate (Kbps)",
  },
  {
    dataField: "aspect_ratio",
    text: "Aspect Ratio",
  },
];

class AdApprovalTabularData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      networkCount: props.networkCount,
      toggleAcrossAll: props.toggleAcrossAll,
      toggleNetwork: props.toggleNetwork,
      displayMultiSelect: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { networkCount, toggleAcrossAll, toggleNetwork } = this.props;
    if (
      prevProps.toggleAcrossAll !== toggleAcrossAll ||
      prevProps.networkCount !== networkCount ||
      prevProps.toggleNetwork !== toggleNetwork
    ) {
      this.setState({
        toggleAcrossAll,
        networkCount,
        toggleNetwork,
        displayMultiSelect: true,
      });
    }
  }

  adMetaData(adData) {
    return adData.map((ref) => (
      <Row key={ref.heading}>
        <Col sm="5" md="4" lg="2" className="metadata-heading-wrapper">
          <p className="metaData-heading">{ref.heading}</p>
        </Col>
        <Col sm="7" md="8" lg="10" className="metadata-value-wrapper">
          <p className="metaData-value">{ref?.value ? ref?.value : "-"}</p>
        </Col>
      </Row>
    ));
  }

  availableNetworksData(toggle, toggleAcross, toggleAcrossAll, toggleNetwork) {
    const { disableStatusUpdate, networkInfo } = this.props;
    const defaultBackground = {
      active: {
        base: disableStatusUpdate ? "rgb(144,238,144)" : "rgb(1, 124, 66)",
      },
      inactive: {
        base: disableStatusUpdate ? "rgb(220, 150, 150)" : "rgb(220, 53, 69)",
      },
    };
    return (
      <div className="networks-tab">
        <p className="networks-tab__manage-networks">Manage Network Approval</p>
        <p className="networks-tab__toggle-networks">
          Toggle available networks to “Off” to limit approval of this ad.
        </p>
        <div className="networks-tab__network-section">
          <div className="networks-tab__network-info">
            <div className="networks-tab__network-name">All Networks</div>
            <div
              className={
                disableStatusUpdate
                  ? "networks-tab__network__toggle"
                  : "networks-tab__network-status"
              }
            >
              <ToggleButton
                className="toggle-button-across-all"
                colors={defaultBackground}
                value={toggleAcrossAll}
                onToggle={() => toggleAcross(toggleNetwork, toggleAcrossAll)}
              />
            </div>
            <div className="clear" />
            {!toggleAcrossAll && this.state.displayMultiSelect && (
              <div className="networks-tab__select_multiple_networks">
                <MultiSelect
                  name="available_networks"
                  options={networkInfo.map((element) => ({
                    label: element.name,
                    value: element.name,
                  }))}
                  isMulti={true}
                  isClearable={true}
                  isSearchable={true}
                  closeMenuOnSelect={false}
                  getSelectedValues={(selectedOption) =>
                    toggle(selectedOption.map((item) => item?.value))
                  }
                  defaultValue={toggleNetwork.map((element) => ({
                    label: element,
                    value: element,
                  }))}
                  isValidNewOption={() => false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  snapshotMetaData(adApprovalData) {
    const {
      creative: { snapshots = [] },
    } = adApprovalData;
    const snapTabularRows = [];
    snapshots.map((item, index) => {
      snapTabularRows.push({
        id: index,
        mime: item.mime || "-",
        width: item.w || "-",
        height: item.h || "-",
        bitrate: item.bitrate || "-",
        resolution: item.w || item.h ? `${item.w}x${item.h}` : "-",
        duration: item.duration || "-",
        aspect_ratio: item.aspect_ratio || "-",
        scaling_factor: item.scaling_factor || "-",
      });
      return snapTabularRows;
    });
    columns =
      adApprovalData.creative.type === "display"
        ? displayColumns
        : videoColumns;
    const keyField = "id";
    const sortColumn = {
      positions:
        adApprovalData.creative.type === "display" ? [3, 4, 5] : [3, 4, 5, 6],
      sort: true,
    };
    const defaultSorted = [
      {
        dataField: "resolution",
        order: "desc",
      },
    ];
    const errorMessage = ErrorMessages.SNAPSHOTS_NOT_AVAILABLE;
    return (
      <Table
        columns={columns}
        condensed
        data={snapTabularRows}
        defaultSorted={defaultSorted}
        isPagination
        keyField={keyField}
        noDataFoundText={<DisplayError {...errorMessage} />}
        perPage={false}
        showTotal={false}
        sizePerPageValue={5}
        sortColumn={sortColumn}
      />
    );
  }

  input(adApprovalData, attrInfo, catInfo, langInfo) {
    const displayMetadata = [
      {
        heading: "Place Exchange Ad Approval ID:",
        value: adApprovalData.id,
      },
      {
        heading: "DSP:",
        value: adApprovalData.dspOrgname || "-",
      },
      {
        heading: "DSP Creative ID:",
        value: adApprovalData.creative.name,
      },
      {
        heading: "Advertiser Domain:",
        value: join(adApprovalData.ad.adomain.map((item) => `${item}`)),
      },
      {
        heading: "Ad Type:",
        value: capitalize(adApprovalData.creative.type),
      },
      {
        heading: "Dynamic Creative:",
        value:
          adApprovalData.creative.type === "display" &&
          adApprovalData.ad.creative.display.subtype === 14
            ? "Yes"
            : "No",
      },
      {
        heading: "Content Category:",
        value: join(catInfo.map((item) => ` ${item?.key} - ${item?.value}`)),
      },
      {
        heading: "Language:",
        value: langInfo,
      },
      {
        heading: "Attributes:",
        value: join(attrInfo.map((item) => ` ${item?.key} - ${item?.value}`)),
      },
      {
        heading: "Feedback:",
        value: isEmpty(adApprovalData.audit.feedback)
          ? "-"
          : adApprovalData.audit.feedback,
      },
    ];
    const videoMetadata = [
      ...displayMetadata,
      {
        heading: "Duration:",
        value: adApprovalData.ad.creative.video.dur
          ? `${adApprovalData.ad.creative.video.dur} seconds`
          : "-",
      },
    ];
    const metadata =
      adApprovalData.creative.type === "display"
        ? displayMetadata
        : videoMetadata;
    return metadata;
  }

  render() {
    const { toggleAcrossAll, toggleNetwork, networkCount } = this.state;
    const { adApprovalData, attrInfo, catInfo, langInfo, toggle, toggleAcross } =
      this.props;
    const {
      creative: { snapshots = [] },
    } = adApprovalData;
    const tabs = [
      {
        title: "Ad Metadata",
        content: this.adMetaData(this.input(adApprovalData, attrInfo, catInfo, langInfo)),
      },
      {
        title: "Snapshot Metadata",
        content: this.snapshotMetaData(adApprovalData),
        isBadge: true,
        badgeValue: snapshots.length || 0,
      },
    ];
    const params =
      adApprovalData.activeStatus !== "approved"
        ? {
            tabItems: tabs,
          }
        : {
            justified: true,
            tabItems: [
              ...tabs,
              {
                title: "Available Networks",
                content: this.availableNetworksData(
                  toggle,
                  toggleAcross,
                  toggleAcrossAll,
                  toggleNetwork
                ),
                isBadge: true,
                badgeValue: networkCount,
              },
            ],
          };

    return <Tab {...params} />;
  }
}

AdApprovalTabularData.propTypes = {
  adApprovalData: PropTypes.shape(),
  attrInfo: PropTypes.arrayOf(PropTypes.object),
  catInfo: PropTypes.arrayOf(PropTypes.object),
  langInfo: PropTypes.string,
  videoSnapInfo: PropTypes.arrayOf(PropTypes.object),
  disableStatusUpdate: PropTypes.bool,
  dspOrgName: PropTypes.string,
  networkCount: PropTypes.number,
  networkInfo: PropTypes.arrayOf(PropTypes.object),
  toggle: PropTypes.func,
  toggleAcross: PropTypes.func,
  toggleAcrossAll: PropTypes.bool,
  toggleNetwork: PropTypes.arrayOf(PropTypes.object),
};

export default AdApprovalTabularData;
