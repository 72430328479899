/* istanbul ignore file */
import { FlatfileListener } from "@flatfile/listener";
import api from "@flatfile/api";
import { includes } from "lodash";
import { bulkRecordHook } from "@flatfile/plugin-record-hook";

import { getAvailableResources } from "../../../Utilities/FlatFileComponent/fieldValidation";
import { eventBus } from "@px/px_design_system";
import { handleSubmit } from "../../../Utilities/FlatFileComponent/Platform/listeners/submit";

export const listener = (orgData) =>
  FlatfileListener.create((listener) => {
    const { id: orgId } = orgData;

    /* Track space_id in the console, to be able to share it with Flatfile team while reporting issues */
    listener.on("workbook:created", async ({ context: { spaceId } }) => {
      console.log("spaceId", spaceId);
    });

    listener.use(
      bulkRecordHook("associate_adunits__sheet", async (records) => {
        const adunit_names = [];
        records.map(async (record) => {
          adunit_names.push(record["data"]["adunits"]);
        });
        const getExistingAdunits = await getAvailableResources(orgId, {
          model: "AdUnit",
          names: adunit_names,
          show_names: "true",
        });
        records.map((record) => {
          if (
            !includes(
              getExistingAdunits.resources_available_names,
              record["data"]["adunits"]
            )
          ) {
            record.addError(
              "adunits",
              "Ad Unit does not belong to this organization"
            );
          }
          return record;
        });
      })
    );

    listener.filter(
      { job: "workbook:submitActionAssociateAdunits" },
      (configure) => {
        configure.on(
          "job:ready",
          async ({ context: { jobId, workbookId } }) => {
            /* Fetch submitted records from flatfile */
            const records = await handleSubmit({
              workbookId,
              jobId,
              restrictAPISubmission: true,
            });
            await api.jobs.complete(jobId, {
              outcome: {
                acknowledge: true,
                message: `Successfully selected ${records.length} Ad Unit(s) to the placement. Changes will take effect when placement is saved.`,
                next: {
                  type: "wait",
                },
              },
            });
            eventBus.dispatch("updateTable", {
              data: records,
              type: "adunits",
            });
          }
        );
      }
    );
  });
