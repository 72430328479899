import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Alert, Fade, Icon, Spinner } from "@px/px_design_system";
import { isEmpty } from "lodash";
import PropType from "prop-types";

import queryString from "query-string";

import { UserContext } from "../../context/auth";
import AdApprovalTabularData from "./AdApprovalTabularData";
import AdDetails from "./AdDetails";
import ApproveAcrossNetworks from "./ApproveAcrossNetworks";
import AdApprovalReject from "./AdApprovalReject";
import ChangeStatus from "./ChangeStatus";
import DisplayError from "../../DisplayError";
import Snapshots from "./Snapshots";
import TopNavigation from "./TopNavigation";

import settings from "../../../../settings";
import ErrorMessages from "../../DisplayError/ErrorMessages";
import ToolTipMessages from "../Utilities/ToolTip/TooltipMessages";
import {
  constructAdminAPIEndPoint,
  fetchHandler,
  fixedEncodeURIComponent,
} from "../../../../utils";
import {
  AdApprovalSummaryContext,
  AdApprovalSummaryProvider,
} from "../../context/AdApprovalSummaryContext";
import "./style.css";

const AdApprovalSummaryComponent = (props) => {
  const { location, history } = props;
  const { organization } = useContext(UserContext);
  const values = queryString.parse(location.search);
  const {
    activeTab,
    userPermission,
    adApprovalQueueData,
    attributeList,
    categoryList,
    languageList,
    displayError,
    errorMessage,
    isLoaded,
    isSingleAdApproval,
    navigateToPath,
    networkList,
    setIsLoaded,
    setRouteVerification,
    totalAdApprovalCount,
  } = useContext(AdApprovalSummaryContext);
  const [adApprovalData, setAdApprovalData] = useState({});
  const [currentAdApproval, setCurrentAdApproval] = useState(+values.view);
  const [showAlert, setAlert] = useState(false);
  const [catInfo, setCatInfo] = useState([]);
  const [attrInfo, setAttrInfo] = useState([]);
  const [langInfo, setLangInfo] = useState("");
  const [networkInfo, setNetworkInfo] = useState([]);
  const [disableStatusUpdate, setDisableStatusUpdate] = useState(false);
  const [toggleAcrossAll, setToggleAcrossAll] = useState(false);
  const [alertProps, setAlertProps] = useState({});
  const [showConfirmApprovalField, setShowConfirmApprovalField] =
    useState(false);
  const [showConfirmRejectField, setShowConfirmRejectField] = useState(false);
  const [toggleNetwork, setToggleNetwork] = useState([]);
  const [networkCount, setNetworkCount] = useState(0);
  const [fadeIn, setFadeIn] = useState(false);
  const [checkDefaultState, setCheckDefaultState] = useState(false);
  const [isSnapshotFailed, setSnapshotFailed] = useState(false);
  const [videoSnapInfo, setVideoSnapInfo] = useState({});
  const [tooltipMessageObject, setTooltipMessageObject] = useState({
    rejetMessage: ToolTipMessages.READ_ONLY.title,
    approveMessage: ToolTipMessages.READ_ONLY.title,
  });

  const isDefaultScale = (creatives) => {
    const { snapshots } = creatives;
    if (!isEmpty(snapshots)) {
      if (creatives.type === "video") {
        return true;
      }
      const isScaleOne = snapshots.find(
        (snapshot) => snapshot.scaling_factor === "1.0"
      );
      if (isScaleOne) {
        return true;
      }
    }
    return false;
  };

  const approvedNetworks = (adApprovalDataParam, networkInfoParam) => {
    const selectedNetworks = networkInfoParam
      .map((item) => {
        if (adApprovalDataParam.approved_networks.includes(item.name)) {
          return item.name;
        }
        return null;
      })
      .filter((element) => element);
    return selectedNetworks;
  };

  const setDataState = (data) => {
    if (data && categoryList.length && attributeList.length && languageList.length && data.ad.cat) {
      const categoryListData = data.ad.cat.map((ref) =>
        categoryList.find((element) => element.key === ref)
      );
      const attributeListData = data.ad.creative.attr.map((ref) =>
        attributeList.find((element) => element.key === String(ref))
      );
      const languageData = languageList.find((element) => element.key === data.ad.lang);
      const languageValue = isEmpty(languageData) ? "" : languageData.value;
      const checkAdDefaultState = !userPermission
        ? !isDefaultScale(data.creative)
        : false;
      if (!userPermission) {
        if (data.creative.snapshots && data.creative.snapshots.length === 0) {
          tooltipMessageObject.rejetMessage =
            data.activeStatus === "rejected"
              ? ToolTipMessages.AD_APPROVAL.REJECTED.title
              : ToolTipMessages.AD_APPROVAL.NO_SNAPSHOT.title;
          setTooltipMessageObject(tooltipMessageObject);
          tooltipMessageObject.approveMessage =
            ToolTipMessages.AD_APPROVAL.NO_SNAPSHOT.title;
        } else if (
          (checkAdDefaultState || isSnapshotFailed) &&
          data.activeStatus !== "approved"
        ) {
          tooltipMessageObject.rejetMessage =
            data.activeStatus === "rejected"
              ? ToolTipMessages.AD_APPROVAL.REJECTED.title
              : "";
          if (data.creative.type === "video") {
            tooltipMessageObject.approveMessage =
              ToolTipMessages.SNAPSHOT_VIDEO_ERROR.title;
          } else {
            tooltipMessageObject.approveMessage = checkAdDefaultState
              ? ToolTipMessages.SNAPSHOT_MISSING.title
              : ToolTipMessages.SNAPSHOT_ERROR.title;
          }
          setTooltipMessageObject(tooltipMessageObject);
        } else if (!userPermission && data.activeStatus === "rejected") {
          tooltipMessageObject.rejetMessage =
            ToolTipMessages.AD_APPROVAL.REJECTED.title;
          tooltipMessageObject.approveMessage = "";
          setTooltipMessageObject(tooltipMessageObject);
        } else if (!userPermission && data.activeStatus === "approved") {
          tooltipMessageObject.rejetMessage = "";
          tooltipMessageObject.approveMessage =
            ToolTipMessages.AD_APPROVAL.APRROVED.title;
          setTooltipMessageObject(tooltipMessageObject);
        }
      }

      setCheckDefaultState(checkAdDefaultState);
      setAdApprovalData(data);
      setCatInfo(categoryListData);
      setAttrInfo(attributeListData);
      setLangInfo(languageValue);
      setNetworkInfo(networkList);
      setFadeIn(true);
      setDisableStatusUpdate(userPermission);
      setToggleAcrossAll(data.is_approved_on_all_networks);
      setToggleNetwork(approvedNetworks(data, networkList));
      setNetworkCount(
        data.is_approved_on_all_networks
          ? networkList.length
          : approvedNetworks(data, networkList).length
      );
    }
  };

  const updateAdApprovalData = () => {
    setDataState(adApprovalQueueData);
  };

  const handleNextPrevAd = (action) => {
    const value = queryString.parse(location.search);
    if (showConfirmRejectField) setShowConfirmRejectField(false);
    if (showConfirmApprovalField) setShowConfirmApprovalField(false);

    if (action === "prev") {
      value.view = +value.view - 1;
    } else if (action === "next") {
      value.view = +value.view + 1;
    }

    history.push(`?${queryString.stringify(value)}`);
    setCurrentAdApproval(value.view);
    setRouteVerification(false);
  };

  const getNextAdHandler = () => {
    setIsLoaded(false);
    handleNextPrevAd("next");
  };

  const getPreviousAdHandler = () => {
    setIsLoaded(false);
    handleNextPrevAd("prev");
  };

  const confirmApproval = (showApprovalReason) => {
    if (showApprovalReason) {
      tooltipMessageObject.approveMessage =
        ToolTipMessages.CONFIRM_ADAPPROVAL.title;
      tooltipMessageObject.rejetMessage =
        ToolTipMessages.CONFIRM_ADAPPROVAL.title;
    } else {
      tooltipMessageObject.rejetMessage =
        ToolTipMessages.AD_APPROVAL.REJECTED.title;
    }
    setTooltipMessageObject(tooltipMessageObject);
    setDisableStatusUpdate(showApprovalReason);
    setShowConfirmApprovalField(showApprovalReason);
  };

  const confirmReject = (showRejectReason) => {
    if (showRejectReason) {
      tooltipMessageObject.approveMessage =
        ToolTipMessages.CONFIRM_ADAPPROVAL_REJECT.title;
      tooltipMessageObject.rejetMessage =
        ToolTipMessages.CONFIRM_ADAPPROVAL_REJECT.title;
    } else {
      tooltipMessageObject.rejetMessage =
        ToolTipMessages.AD_APPROVAL.REJECTED.title;
    }
    setTooltipMessageObject(tooltipMessageObject);
    setDisableStatusUpdate(showRejectReason);
    setShowConfirmRejectField(showRejectReason);
  };

  const changeStatusOfAd = (
    typeOfStatus,
    isApprovedOnAllNetworks,
    options,
    approveThrough,
    feedback
  ) => {
    if (approveThrough !== "toggle") {
      setIsLoaded(false);
    }
    const apiParams = {
      method: "PATCH",
      body: {
        audit: {
          status: typeOfStatus === "approveAd" ? "3" : "4",
          feedback: feedback,
        },
        is_approved_on_all_networks: isApprovedOnAllNetworks,
        approved_networks: options,
      },
      url: constructAdminAPIEndPoint({
        url: `orgs/${
          adApprovalData.owned_by
        }/adapprovals/${fixedEncodeURIComponent(adApprovalData.name)}`,
      }),
    };
    return fetchHandler(apiParams)
      .then(() => {
        if (approveThrough !== "toggle") {
          const alertHeading =
            typeOfStatus === "approveAd" ? "Ad Approved" : "Ad Rejected";
          const alertText =
            typeOfStatus === "approveAd"
              ? ErrorMessages.APPROVED_ADAPPROVAL_MESSAGE.title
              : ErrorMessages.REJECTED_ADAPPROVAL_MESSAGE.title;
          const color = typeOfStatus === "approveAd" ? "success" : "danger";
          confirmApproval(false);
          confirmReject(false);
          setAlertProps({ alertHeading, alertText, color });
          setAlert(true);
          const loaderTime = setTimeout(() => {
            clearTimeout(loaderTime);
            if (isSingleAdApproval) {
              navigateToPath();
            } else setRouteVerification(false);
          }, settings.AD_APPROVAL_LOADER_TIME);
        }
      })
      .catch((error) => Promise.reject(error));
  };

  const onSnaspshotLoad = (isFailed) => {
    setSnapshotFailed(isFailed);
  };

  // Get selected Video snapshot details
  const onSnapshotChange = (snapData) => {
    setVideoSnapInfo(snapData);
  };

  const toggle = (selectedNetworks) => {
    if (!disableStatusUpdate) {
      setToggleNetwork(selectedNetworks);
      setNetworkCount(selectedNetworks.length);

      changeStatusOfAd(
        "approveAd",
        toggleAcrossAll,
        selectedNetworks,
        "toggle"
      ).catch(() => {});
    }
  };

  const toggleAcross = (networklist, checkAcrossAll) => {
    if (!disableStatusUpdate) {
      if (!checkAcrossAll) {
        setNetworkCount(networkInfo.length);
        setToggleNetwork([]);
      } else {
        setNetworkCount(networklist.length);
      }

      setToggleAcrossAll(!checkAcrossAll);
      changeStatusOfAd(
        "approveAd",
        !toggleAcrossAll,
        networklist,
        "toggle"
      ).catch(() => {});
    }
  };

  useEffect(() => {
    if (isLoaded) {
      setAlert(false);
      setCurrentAdApproval(+values.view);
      updateAdApprovalData();
      setDataState(adApprovalQueueData);
    }
  }, [isLoaded, isSnapshotFailed, adApprovalData, adApprovalQueueData]);

  const spinnerProps = {
    className: "spinner-icon",
    color: "primary",
    loadingtext: "Loading",
  };

  return (
    <div
      className={`adApproval ${
        !displayError && isLoaded
          ? "wrapper--top-margin wrapper"
          : "wrapper--no-border"
      }`}
    >
      {!isLoaded ? <Spinner {...spinnerProps} /> : null}
      {showAlert ? <Alert {...alertProps} /> : null}
      {!displayError && !isEmpty(adApprovalData) && (
        <>
          <div className="adApproval--close">
            <Link
              to={{
                pathname: `/orgs/${organization.id}/adapprovals/summary`,
                search: location.search,
              }}
              from={`/orgs/${organization.id}/adapprovals/queue`}
            >
              <Icon
                icon={["fas", "times"]}
                style={{ color: "rgba(128, 128, 128, 1)" }}
                size="2x"
              />
            </Link>
          </div>
          <TopNavigation
            nextAd={getNextAdHandler}
            prevAd={getPreviousAdHandler}
            currentAdApproval={currentAdApproval}
            adApprovalTotalCount={totalAdApprovalCount}
            activeTab={activeTab}
          />
          {isLoaded && (
            <Fade in={fadeIn}>
              <ChangeStatus
                tooltipMessageObject={tooltipMessageObject}
                adId={adApprovalData.id}
                changeStatusOfAd={changeStatusOfAd}
                disableStatusUpdate={disableStatusUpdate}
                confirmApproval={confirmApproval}
                activeStatus={adApprovalData.activeStatus}
                isSnapshotFailed={isSnapshotFailed}
                checkDefaultState={checkDefaultState}
                confirmReject={confirmReject}
              />
              {showConfirmApprovalField && (
                <ApproveAcrossNetworks
                  changeStatusOfAd={changeStatusOfAd}
                  confirmApproval={confirmApproval}
                  networkInfo={networkInfo}
                />
              )}
              {showConfirmRejectField && (
                <AdApprovalReject
                  changeStatusOfAd={changeStatusOfAd}
                  confirmReject={confirmReject}
                />
              )}

              <AdDetails adApprovalData={adApprovalData} catInfo={catInfo} />
              <div className="contactMessage__container border-bottom">
                <span className="contactMessage">
                  For any ad-related questions, please contact
                  <a
                    className="contactEmail"
                    href="mailto: creative-review@placeexchange.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    creative-review@placeexchange.com
                  </a>
                  .
                </span>
              </div>
              <Snapshots
                snapshots={
                  !isEmpty(adApprovalQueueData) &&
                  adApprovalQueueData.creative.snapshots &&
                  adApprovalQueueData.creative.snapshots.length !== 0
                    ? adApprovalQueueData.creative.snapshots
                    : []
                }
                snapshotsType={
                  !isEmpty(adApprovalQueueData) &&
                  adApprovalQueueData.creative.snapshots &&
                  adApprovalQueueData.creative.snapshots.length !== 0
                    ? adApprovalQueueData.creative.type
                    : ""
                }
                adApprovalData={adApprovalQueueData}
                onSnaspshotLoad={onSnaspshotLoad}
                onSnapshotChange={onSnapshotChange}
                {...props}
              />
              <AdApprovalTabularData
                adApprovalData={adApprovalData}
                catInfo={catInfo}
                attrInfo={attrInfo}
                langInfo={langInfo}
                videoSnapInfo={videoSnapInfo}
                networkCount={networkCount}
                toggleAcrossAll={toggleAcrossAll}
                toggleNetwork={toggleNetwork}
                toggle={toggle}
                toggleAcross={toggleAcross}
                disableStatusUpdate={disableStatusUpdate}
                networkInfo={networkInfo}
              />
            </Fade>
          )}
        </>
      )}

      {displayError && isLoaded && <DisplayError {...errorMessage} />}
    </div>
  );
};

AdApprovalSummaryComponent.propTypes = {
  orgname: PropType.arrayOf(PropType.string),
  adApprovalType: PropType.string,
  location: PropType.shape(),
  history: PropType.shape(),
};

function AdApprovalSummary(props) {
  const { match } = props;
  const { data, errorMessage, loading } = useContext(UserContext);
  return (
    <>
      {!isEmpty(data) && !data.orgData.errors && !loading ? (
        <AdApprovalSummaryProvider
          key={match.params.id}
          {...props}
          isAdApprovalDetails
        >
          <AdApprovalSummaryComponent {...props} />
        </AdApprovalSummaryProvider>
      ) : (
        <DisplayError {...errorMessage} />
      )}
    </>
  );
}

AdApprovalSummary.propTypes = {
  match: PropType.shape(),
};

export const AdApprovalSummaryProviders = AdApprovalSummaryComponent;
export default AdApprovalSummary;
